import DragScrollContainer from 'components/ui/DragScrollContainer';
import { useField, useForm } from 'frmx';
import { useTranslation } from 'react-i18next';
import { getColor } from 'modules/tickets/ticket-list/utils';
import useKeys from '@flowsn4ke/usekeys';

export default function BookmarkBar({ bookmarkState }) {
  const { t } = useTranslation();
  const { value = [], setValue } = useField('bookmarks');
  const { handleSubmit } = useForm();

  if (!bookmarkState?.bookmarks?.length) return null;
  const { bookmarks = [], isLoading = false } = bookmarkState;

  if (isLoading) {
    return (
      <DragScrollContainer
        className="relative py-1 overflow-hidden"
        sortingOrders={[]}
      >
        {' '}
        {Array.from({ length: 5 }).map((_, idx) => (
          <div
            key={idx}
            className="whitespace-nowrap border-2 rounded-full py-[1px] px-10 bg-gray-200 animate-pulse"
          >
            &nbsp; {/* Empty space for skeleton loading */}
          </div>
        ))}
      </DragScrollContainer>
    );
  }

  const toggleBookmark = ({ key }) => {
    if (value.includes(key)) {
      setValue(value.filter((cle) => cle !== key));
    } else {
      setValue([...value, key]);
    }
    handleSubmit();
  };

  return (
    <DragScrollContainer
      className="relative py-1 overflow-hidden"
      sortingOrders={[]}
    >
      {bookmarks.map((bookmark, bookmarkIdx) => {
        const { activeTextColor, activeBorderColor, activeBackgroundColor } = getColor(bookmark.color);
        const isActive = value.includes(bookmark.key); // Check if the bookmark is active

        return (
          <div
            key={bookmarkIdx}
            className={`whitespace-nowrap border rounded-full py-0.5 px-3 cursor-pointer
               ${isActive && activeBackgroundColor} 
              ${activeBorderColor}
             ${isActive ? 'text-white' : activeTextColor}  
            transition`}
            onClick={() => toggleBookmark({ key: bookmark.key })}
          >
            {t(bookmark.label)}
          </div>
        );
      })}
    </DragScrollContainer>
  );
}

export function BookmarkBar2({ bookmarks = [] }) {
  const k = useKeys();

  if (!bookmarks.length) return null;

  return (
    <DragScrollContainer
      className="relative py-1"
      sortingOrders={[]}
    >
      {bookmarks.map((bookmark, bookmarkIdx) => (
        <div key={k(bookmarkIdx)}>{bookmark}</div>
      ))}
    </DragScrollContainer>
  );
}
