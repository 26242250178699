import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: ({ isDialog }) => ({
    display: 'flex',
    height: '100%',
    width: '100%',
    ...(isDialog
      ? {
          justifyContent: 'center'
        }
      : {})
  }),
  element: ({ isDialog, noTabs, page }) => ({
    padding: !isDialog && 10,
    paddingLeft: page && 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    maxHeight: !isDialog && !noTabs && 'calc(100vh - 64px)',
    [theme.breakpoints.down(1024)]: {
      padding: 0,
      maxHeight: !noTabs && 'calc(100vh - 54px)'
    }
  }),
  placeholder: ({ isDialog }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: isDialog && 'white',
    paddingBottom: isDialog ? 0 : 90,
    userSelect: 'none',
    minHeight: 400,
    [theme.breakpoints.down(1024)]: {
      background: 'white',
      borderRadius: theme.radius
    }
  }),
  placeholderIcon: ({ isLoading }) => ({
    color: isLoading ? theme.palette.primary.light : '#e2e2e2',
    fontSize: 80,
    marginBottom: 18
  }),
  placeholderText: ({ isLoading }) => ({
    height: 30,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    color: isLoading ? theme.palette.primary.light : '#cacaca'
  }),
  error: () => ({
    color: theme.palette.error.light
  }),
  spinner: {
    marginRight: 10,
    ...theme.spinnerAnimation,
    color: theme.palette.primary.light
  }
}));
