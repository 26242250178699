import { IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import classNames from 'classnames';
import NotEmptyIndicator from 'components/filters/NotEmptyIndicator';
import Tooltip from 'components/ToolTip';
import FAIcon from 'components/ui/FAIcon';

import { useRole } from 'hooks/useRole';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function NumberColumn({ ticket, setOpenedTicket, setOpenTicketId, setAnchorEl, open, setOpen }) {
  const { parentId, facture_state, _facture_tickets, number, _id } = ticket;
  const accounting_email = (ticket._locations || [])[0]?.accounting_email;

  const role = useRole();
  const { t } = useTranslation();

  const nbHiddenTickets = _facture_tickets.slice(2).length;

  const { isLoadingId } = useSelector((state) => state.accountingList);
  const isLoading = isLoadingId === _id;

  return (
    <>
      <div className="flex items-start md:items-center flex-col md:flex-row">
        {isLoading ? (
          <FAIcon
            icon="spinner"
            collection="fas"
            size="md"
            className="animate-spin text-gray-400"
          />
        ) : (
          <IconButton
            style={{ visibility: _facture_tickets.length && !parentId ? '' : 'hidden' }}
            aria-label="expand row"
            size="small"
            onClick={(ev) => [ev.currentTarget, setOpen((prev) => !prev)]}
          >
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        )}

        {Boolean(!parentId && role.permission('accounting', 'link')) && (
          <Tooltip title={t('manageRelatedTickets')}>
            <IconButton
              disabled={facture_state.sent}
              onClick={(ev) => {
                setOpenedTicket(ticket);
                setAnchorEl(ev.currentTarget);
              }}
              className="mx-8"
            >
              <NotEmptyIndicator
                counter={1 + _facture_tickets.length}
                showIndicator
              />
              <FAIcon
                icon="link"
                collection="fal"
                size="lg"
              />
            </IconButton>
          </Tooltip>
        )}
        <div className="flex flex-col gap-0.5">
          <div className="flex gap-0.5">
            <TicketNumber
              number={number}
              onClick={() => {
                setOpenTicketId(_id);
              }}
            />

            {!parentId && _facture_tickets.length > 0 && (
              <>
                {_facture_tickets.slice(0, 2).map((t, i) => {
                  const sub_accounting_email = (t._locations || [])[0]?.accounting_email;
                  const accounting_emails_are_identical = accounting_email === sub_accounting_email;
                  const has_accounting_email = Boolean(sub_accounting_email);

                  return (
                    <>
                      ,{' '}
                      <TicketNumber
                        warning={!accounting_emails_are_identical && has_accounting_email}
                        number={t.number}
                        onClick={() => setOpenTicketId(t._id)}
                      />
                    </>
                  );
                })}
              </>
            )}
          </div>
          {!parentId && _facture_tickets.length > 0 && (
            <>
              {_facture_tickets.slice(2).length > 0 && (
                <Tooltip
                  title={_facture_tickets
                    .slice(2)
                    .map((t) => `#${t.number}`)
                    .join(', ')}
                >
                  <span className="font-bold">
                    +{nbHiddenTickets} ticket{nbHiddenTickets > 1 ? 's' : ''}
                  </span>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

const TicketNumber = ({ number, onClick, warning }) => (
  <span
    onClick={onClick}
    className={classNames('cursor-pointer hover:underline hover:font-bold', { 'text-red-500': warning })}
  >
    #{number}
  </span>
);
