import { createSlice } from '@reduxjs/toolkit';
import generateAsyncThunk from 'utils/generateAsyncThunk';
// Import all actions tickets
import {
  createElement,
  updateElement,
  deleteElement,
  restoreElement,
  duplicateElement,
  cancelElement,
  acceptDevis,
  acceptElement,
  finishElement,
  updateManagers,
  closeElement,
  openElement,
  relanceIntervener,
  renoticeIntervener,
  assignIntervener,
  proposeIntervener,
  cancelIntervener,
  rateIntervener,
  addAccounting,
  addQuote,
  addInvoice,
  removeQuote,
  removeInvoice,
  updateAccounting,
  removeAccounting,
  updatePrice,
  linkElements,
  toReplan,
  setDate,
  updateDate,
  createDeadline,
  updateDeadline,
  deleteDeadline,
  createNote,
  updateNote,
  deleteNote,
  createInterventionReport,
  readInterventionReports,
  updateInterventionReport,
  deleteInterventionReport,
  uploadReport,
  newUploadReport,
  liftTheReserve,
  consumeStock,
  putDelays,
  deleteDelay
} from './ticketsSlice';

import { addBudgetOnTicket } from 'store/budgetsSlice';

export const syncTicketsList = generateAsyncThunk({ type: 'GET', endpoint: 'ticket-list/sync' });
export const ticketsList = generateAsyncThunk({ type: 'POST', endpoint: 'ticket-list' });
export const moreTickets = generateAsyncThunk({ type: 'POST', endpoint: 'ticket-list' });
export const ticketsExport = generateAsyncThunk({ type: 'POST', endpoint: 'ticket-list/export' });
export const getBoomarksList = generateAsyncThunk({ type: 'POST', endpoint: 'ticket-list/bookmarks' });

const initialState = {};

const handleTicketState = (state, listId, updates) => {
  if (!listId) return;
  state[listId] = { ...state[listId], ...updates };
};

const ticketsListSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    addList: (state, action) => {
      const listId = action.payload;
      if (!state[listId]) {
        state[listId] = { tickets: [], count: 0, isLoading: false, hasMore: false };
      }
    },
    removeList: (state, action) => {
      const listId = action.payload;
      delete state[listId]; // Supprime complètement la liste du state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ticketsList.pending, (state, action) =>
        handleTicketState(state, action.meta.arg.params?.listId, {
          tickets: [],
          count: 0,
          isLoading: true,
          hasMore: false
        })
      )
      .addCase(ticketsList.rejected, (state, action) =>
        handleTicketState(state, action.meta.arg.params?.listId, {
          tickets: [],
          count: 0,
          isLoading: false,
          hasMore: false
        })
      )
      .addCase(ticketsList.fulfilled, (state, action) => {
        const { listId } = action.meta || {};
        if (!listId) return;
        const { tickets, count } = action.payload.data;
        handleTicketState(state, listId, { tickets, count, isLoading: false, hasMore: tickets.length < count });
      })
      .addCase(moreTickets.fulfilled, (state, action) => {
        const { listId } = action.meta || {};
        if (!listId) return;
        const { tickets, count } = action.payload.data;
        Object.assign(state[listId], {
          tickets: [...state[listId].tickets, ...tickets],
          count,
          hasMore: state[listId].tickets.length < count
        });
      });

    builder.addCase(finishElement.fulfilled, (state, action) => {
      const { id } = action.meta;
      const { element } = action.payload.data;
      if (!element?.contract?.state) return;

      // On parcourt toutes les listes dynamiques du state
      Object.keys(state).forEach((list) => {
        const index = state[list].tickets.findIndex((t) => t.i === id);
        if (index !== -1) {
          state[list].tickets[index].data.status = element.contract.state;
        }
      });
    });

    const updateTickets = (state, action) => {
      const { id } = action.meta;
      const { element, ticket } = action.payload.data;
      const transformed_ticket = transformOldToNewTicket(element || ticket);

      Object.keys(state).forEach((list) => {
        // Assurer que la liste a une structure correcte
        state[list] = state[list] || { tickets: [], count: 0, isLoading: false, hasMore: false };

        const index = state[list].tickets.findIndex((t) => t.i === id);

        if (index !== -1) {
          // Met à jour le ticket existant
          state[list].tickets[index] = transformed_ticket;
        } else {
          // Ajoute le ticket au début de la liste
          state[list].tickets.unshift(transformed_ticket);
          state[list].count += 1;
        }
      });
    };

    [
      createElement,
      updateElement,
      deleteElement,
      restoreElement,
      duplicateElement,
      cancelElement,
      acceptDevis,
      acceptElement,
      updateManagers,
      closeElement,
      openElement,
      relanceIntervener,
      renoticeIntervener,
      assignIntervener,
      proposeIntervener,
      cancelIntervener,
      rateIntervener,
      addAccounting,
      addQuote,
      addInvoice,
      removeQuote,
      removeInvoice,
      updateAccounting,
      removeAccounting,
      updatePrice,
      linkElements,
      toReplan,
      setDate,
      updateDate,
      createDeadline,
      updateDeadline,
      deleteDeadline,
      createNote,
      updateNote,
      deleteNote,
      consumeStock,
      putDelays,
      deleteDelay
    ].forEach((actionType) => builder.addCase(actionType.fulfilled, updateTickets));
  }
});

export const { addList, removeList } = ticketsListSlice.actions;
export default ticketsListSlice.reducer;

// This function is used to transform old tickets to new tickets for the preview
const transformOldToNewTicket = (oldTicket, previousTicket) => {
  const ticket = {
    _id: oldTicket._id,
    i: oldTicket._id,
    t: oldTicket.title,
    d: oldTicket.description,
    n: oldTicket.number,
    cat: oldTicket.created_at,
    uat: oldTicket.created_at,
    del: !!(oldTicket.deleted_at || oldTicket.deleted_by),
    m: mergeMetadatas(previousTicket?.m, {
      k: 'reschedule',
      t: String(!!oldTicket.notice_replan),
      f: ''
    }),
    data: {
      status: oldTicket.state || (oldTicket.draft ? 'draft' : ''),
      equipments: oldTicket._equipments || [],
      locations: oldTicket._locations || [],
      skills: oldTicket._jobs || [],
      quotes: getQuotes(oldTicket) || [],
      priority: getPriority(oldTicket),
      notes: {
        shared: oldTicket.notes || [],
        internal: oldTicket.viewNotes || [],
        managers: oldTicket.viewNotesManagers || []
      },
      planned_dates: oldTicket.visit_dates || [],
      intervention_dates: oldTicket.intervention_dates || [],
      alert: oldTicket.deadline || null,
      late: !!(oldTicket.intervention_after_deadline || oldTicket.resolution_after_deadline),
      created_at: oldTicket.created_at
    }
  };
  return ticket;
};

const getPriority = (oldTicket) => {
  const urgencyIndex = oldTicket.urgency;
  const urgencies = oldTicket._client?._configuration?.urgencies || oldTicket._contractor?._configuration?.urgencies || {}; // here we have the urgencies {'1': {label: 'Faible', color: '#827596'}, '2': {label: 'Moyenne', color: '#fbb034'}, '3': {label: 'Haute', color: '#ee744a'}, '4': {label: 'Critique', color: '#e63d53'}}
  return urgencies[urgencyIndex] || null; // here we have the good urgency {label: 'Moyenne', color: '#fbb034'}
};

const mergeMetadatas = (previousMetadatas = [], newMetadata) => {
  const exists = previousMetadatas.some((msg) => msg.k === newMetadata.k);
  return exists ? previousMetadatas : [...previousMetadatas, newMetadata];
};

const getQuotes = (oldTicket) => {
  const quotes = oldTicket.devis || [];
  return quotes.map((quote) => {
    return {
      reference: quote.reference,
      amount: quote.price,
      approved_at: quote.validated.state ? quote.validated.date : null,
      declined_at: quote.dropped.state ? quote.dropped.date : null,
      del: quote.deleted.state || false
    };
  });
};
