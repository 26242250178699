//! These lines must be the first lines in index.js
// See https://stackoverflow.com/questions/43756211/best-way-to-polyfill-es6-features-in-react-app-that-uses-create-react-app
// Also https://github.com/facebook/create-react-app/tree/main/packages/react-app-polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'styles/base.css';
import i18n from './i18n';
import 'config/fontawesome/fad';
import 'config/fontawesome/fal';
import 'config/fontawesome/fas';
import 'config/fontawesome/far';
import 'config/fontawesome/fab';
import projectMeta from '../package.json';

import axios from 'axios';

import { render } from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';

import App from 'App';
import store from 'store';
import { I18nextProvider } from 'react-i18next';
import { initCrisp } from 'utils/uiUtils';
import initSentry from 'utils/initSentry';

export const KINGFISHER_DEMO_ACCOUNT_ID = '65bd22951d57c5001531c693';
export const KINGFISHER_DEMO_ACCOUNT_TICKET_DOMAIN_INVESTISSEMENT_ID = '65c0d11a4b8963b6b673986a';

export const SEPHORA_DEMO_ACCOUNT_ID = '654cb8308c71d800187bff43'

if (
  !!window?.location &&
  (process.env.REACT_APP_SENTRY_ENV === 'staging' || process.env.REACT_APP_SENTRY_ENV === 'production')
) {
  initSentry(process.env.REACT_APP_SENTRY_ENV);
  console.log('Sentry initialized : ' + process.env.REACT_APP_SENTRY_ENV);
}

initCrisp();

export const apiBaseURL = process.env.REACT_APP_API_URL;

console.log({
  api: apiBaseURL,
  version: projectMeta.version
});

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

axios.defaults.withCredentials = true;

render(
  <StoreProvider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </StoreProvider>,
  document.getElementById('root')
);
