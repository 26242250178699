import {
  Grid,
  TextField,
  Accordion,
  AccordionDetails,
  Checkbox,
  AccordionSummary,
  Box,
  ListItem,
  InputBase,
  Button,
  List,
  Badge,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import { useAuth } from 'hooks/useAuth';
import { Field, useField, useForm, useFieldObserver } from 'frmx';

import { roleTypes, getPermissions } from 'config/role';
import entities from 'config/entities';
import { ArrowDropDown, CheckCircle, Block } from '@material-ui/icons';
import { useState, useEffect, useMemo } from 'react';

import useStyle from 'styles/Form.styles';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';

export default function RoleForm({ element }) {
  const classes = useStyle();

  const auth = useAuth();
  const configuration = auth.interface._company._configuration;
  const { setOneField } = useForm();
  const permissionsField = useFieldObserver('permissions');
  const typeField = useFieldObserver('type');
  const [searchTicket, setSearchTicket] = useState('');

  const { t } = useTranslation();

  const type = roleTypes[auth.interface.type].find((r) => r.value === typeField);

  const permissions = useMemo(
    () => (!type ? {} : getPermissions(configuration, { type: type?.value })),
    [configuration, type?.value]
  );

  useEffect(() => {
    if (type) {
      if (!Object.keys(permissionsField)?.length) {
        unselectAll();
      } else {
        Object.keys(permissions).forEach((p) => {
          if (!permissionsField[p]) {
            setOneField('permissions.' + p, []);
          }
        });
      }
    }
  }, [type?.value]);

  const getPermissionsEntity = (entity) =>
    permissions[entity].filter(
      (element) =>
        entity !== 'tickets' ||
        !searchTicket?.length ||
        searchTicket?.toLowerCase()?.includes(t(element.label)?.toLowerCase())
    );

  const k1 = useKeys();
  const k2 = useKeys();

  const editable_notification_preferences = Object.keys(element.notification_preferences || {})
    .filter(
      (notification_workflow) =>
        element.notification_preferences[notification_workflow].email.editable ||
        element.notification_preferences[notification_workflow].sms.editable
    )
    .map((notification_workflow) => ({
      workflow: notification_workflow,
      ...element.notification_preferences[notification_workflow]
    }))
    .sort((a, b) => a.index - b.index);

  const [allPermissionsSelected, setAllPermissionsSelected] = useState(false);
  const [allPermissionUnselected, setAllPermissionUnselected] = useState(false);

  const selectAll = () => {
    setAllPermissionsSelected(!allPermissionsSelected);
    setAllPermissionUnselected(!allPermissionUnselected);
  };

  const unselectAll = () => {
    setAllPermissionUnselected(true);
    setAllPermissionsSelected(false);
  };

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <FormControl
          variant="outlined"
          fullWidth
        >
          <InputLabel
            htmlFor="outlined-age-native-simple"
            className="bg-white"
          >
            {t('roleType')}
          </InputLabel>
          <Field path="type">
            <Select fullWidth>
              {roleTypes[auth.interface.type].map((role, i) => (
                <MenuItem
                  key={k1(i)}
                  value={role.value}
                >
                  {t(role.label)}
                </MenuItem>
              ))}
            </Select>
          </Field>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Field
          path="name"
          isErrorProp="error"
        >
          <TextField
            variant="outlined"
            fullWidth
            label={t('roleName')}
          />
        </Field>
      </Grid>
      <Grid
        container
        style={{ marginTop: 16 }}
        className="justify-end"
      >
        {!allPermissionsSelected ? (
          <Button
            onClick={selectAll}
            size="small"
            startIcon={<CheckCircle />}
          >
            {t('selectAll')}
          </Button>
        ) : (
          <Button
            onClick={unselectAll}
            size="small"
            startIcon={<Block />}
          >
            {t('unselectAll')}
          </Button>
        )}
      </Grid>
      {Boolean(editable_notification_preferences.length) && (
        <Grid
          item
          xs={12}
        >
          <Accordion
            dense
            className="outlined"
            style={{ width: '100%' }}
          >
            <AccordionSummary expandIcon={<ArrowDropDown />}>
              <Badge
                className="relativeRight"
                color="secondary"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  fontSize={13}
                >
                  <FAIcon
                    style={{ marginRight: 10 }}
                    collection="fad"
                    icon="bell"
                    size="small"
                  />
                  <strong>Notifications</strong>
                </Box>
              </Badge>
            </AccordionSummary>
            <AccordionDetails className="flex flex-col gap-y-4">
              {editable_notification_preferences.map((workflow, i) => (
                <NotificationWorkflowPreference config={workflow} />
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}

      {Object.keys(permissions).map((entity, i) => (
        <EntityPermission
          key={k2(i)}
          entity={entity}
          classes={classes}
          searchTicket={searchTicket}
          setSearchTicket={setSearchTicket}
          getPermissionsEntity={getPermissionsEntity}
          allPermissionsSelected={allPermissionsSelected}
          allPermissionUnselected={allPermissionUnselected}
          setAllPermissionsSelected={setAllPermissionsSelected}
        />
      ))}
    </Grid>
  );
}

function EntityPermission({
  entity,
  classes,
  searchTicket,
  setSearchTicket,
  getPermissionsEntity,
  allPermissionsSelected,
  allPermissionUnselected,
  setAllPermissionsSelected
}) {
  const { value: permissions, setValue: setPermissions } = useField(`permissions.${entity}`);

  const { t } = useTranslation();
  const k = useKeys();

  const gotAll = getPermissionsEntity(entity)?.length === permissions?.length;

  const handleSelectAll = () => {
    const allPermissions = getPermissionsEntity(entity).map((permission) => permission.key);
    setPermissions(allPermissions);
  };

  const handleDeselectAll = () => {
    setPermissions([]);
  };

  useEffect(() => {
    // Mettez à jour les permissions en fonction de l'état global
    if (allPermissionsSelected) {
      handleSelectAll();
    } else if (allPermissionUnselected) {
      handleDeselectAll();
    }
  }, [allPermissionsSelected]);

  return (
    <Grid
      item
      xs={12}
    >
      <Box
        display="flex"
        width="100%"
      >
        <Accordion
          dense
          className="outlined"
          style={{ width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDown />}>
            <Badge
              className="relativeRight"
              color="secondary"
              badgeContent={!permissions ? 0 : permissions?.length}
            >
              <Box
                display="flex"
                alignItems="center"
                fontSize={13}
              >
                <FAIcon
                  style={{ marginRight: 10 }}
                  collection="fad"
                  icon={entities[entity].icon}
                  size="small"
                />
                <strong>{t(entities[entity].name)}</strong>
              </Box>
            </Badge>
          </AccordionSummary>
          <AccordionDetails
            style={
              entity === 'tickets'
                ? {
                    width: '100%',
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column'
                  }
                : { width: '100%', padding: 0 }
            }
          >
            {entity === 'tickets' && (
              <InputBase
                style={{ padding: '4px 16px', borderBottom: '1px solid #c4c4c4' }}
                placeholder={t('findPermission')}
                fullWidth
                value={searchTicket}
                onChange={(e) => setSearchTicket(e.target.value)}
              />
            )}

            <List style={{ width: '100%', maxHeight: 300, overflow: 'auto' }}>
              {!getPermissionsEntity(entity)?.length ? (
                <Box className={classes.listEmpty}>{t('noPermissionFound')}</Box>
              ) : (
                <div className="flex justify-end gap-x-2 p-2">
                  {!gotAll && (
                    <Button
                      onClick={handleSelectAll}
                      size="small"
                      startIcon={<CheckCircle />}
                    >
                      {t('selectAll')}
                    </Button>
                  )}
                  {gotAll && (
                    <Button
                      onClick={handleDeselectAll}
                      size="small"
                      startIcon={<Block />}
                    >
                      {t('unselectAll')}
                    </Button>
                  )}
                </div>
              )}
              {!!getPermissionsEntity(entity)?.length &&
                getPermissionsEntity(entity).map((permission, i) => (
                  <div className={`${permission.label === 'validateEstimatePermission' && 'flex flex-col justify-around'}`}>
                    <ListItem
                      key={k(i)}
                      button
                      dense={true}
                      onClick={(e) => {
                        setAllPermissionsSelected(false);
                        setPermissions(
                          !permissions
                            ? [permission.key]
                            : permissions.includes(permission.key)
                            ? permissions.filter((r) => r !== permission.key)
                            : [...permissions, permission.key]
                        );
                      }}
                    >
                      <Checkbox
                        checked={!permissions ? false : permissions.includes(permission.key)}
                        color="secondary"
                      />
                      <span style={permissions && permissions.includes(permission.key) ? { fontWeight: 500 } : {}}>
                        {t(permission.label)}
                      </span>
                    </ListItem>

                    {permission.key === 'validate_devis' && permissions?.includes(permission.key) && (
                      <RangeValidateQuotes
                        permissions={permissions}
                        setPermissions={setPermissions}
                      />
                    )}
                  </div>
                ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  );
}

const RangeValidateQuotes = ({ permissions, setPermissions }) => {
  const key = 'validate_devis_range';
  const perm = permissions.find((p) => p.includes(key));
  const defaultRange = perm?.split(':')[1] || '0-0';
  const [minVal, setMinVal] = useState(defaultRange.split('-')[0] || 0);
  const [maxVal, setMaxVal] = useState(defaultRange.split('-')[1] === '0' ? 'infinity' : defaultRange.split('-')[1]);
  const { t } = useTranslation();
  const company = useAuth().interface._company;

  useEffect(() => {
    const updatedPermissions = permissions.filter((p) => !p.includes(`${key}:`));
    updatedPermissions.push(`${key}:${minVal}-${maxVal === 'infinity' ? '0' : maxVal}`);
    setPermissions(updatedPermissions);
  }, [minVal, maxVal]);

  const handleMinChange = (e) => setMinVal(e.target.value);

  const handleMaxChange = (e) => setMaxVal(e.target.value === '0' ? 'infinity' : e.target.value);

  const toggleInfinity = (e) => setMaxVal(e.target.checked ? 'infinity' : 1);

  return (
    <div className="py-2 px-4">
      <div className="flex gap-3 justify-center items-center mb-3 text-sm">
        {t('between')}
        <TextField
          type="number"
          style={{ width: '100px' }}
          value={minVal}
          onChange={handleMinChange}
          inputProps={{ min: 0 }}
        />
        {t('and')}
        {maxVal === 'infinity' ? (
          <FAIcon
            collection="fal"
            icon="infinity"
            size="small"
            onClick={() => setMaxVal(1)}
          />
        ) : (
          <TextField
            type="number"
            style={{ width: '100px' }}
            value={maxVal}
            onChange={handleMaxChange}
            inputProps={{ min: 0 }}
          />
        )}
        {company?.currency?.symbol}
      </div>
      <div className="flex items-center mb-1">
        {t('switch-to-infinity')}
        <Switch
          checked={maxVal === 'infinity'}
          onChange={toggleInfinity}
        />
      </div>
      <p>{t('role-approval-quotes')}</p>
    </div>
  );
};

function NotificationWorkflowPreference({ config }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const isRoot = auth.interface.isRoot;

  const title = t(config.workflow + '-title');
  const description = t(config.workflow + '-description');

  const email_is_editable = config.email.editable;
  const sms_is_editable = config.sms.editable;

  const { value: email_is_active, setValue: set_email_is_active } = useField(
    'notification_preferences.' + config.workflow + '.email.value'
  );
  const { value: sms_is_active, setValue: set_sms_is_active } = useField(
    'notification_preferences.' + config.workflow + '.sms.value'
  );
  // const { value: app_is_active, setValue: set_app_is_active } = useField("notification_preferences." + config.workflow + ".app.value")

  return (
    <div className="flex w-full">
      <div className="grow flex flex-col gap-y-2">
        <div className="flex items-center justify-between">
          <Typography variant="subtitle2">{t(title)}</Typography>
          <div className="flex ">
            <div className="w-24">
              {Boolean(sms_is_editable || isRoot) && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={sms_is_active}
                      onChange={(e) => set_sms_is_active(e.target.checked)}
                    />
                  }
                  label={<span>SMS&nbsp;&nbsp;</span>}
                />
              )}
            </div>
            <div className="w-24">
              {Boolean(email_is_editable || isRoot) && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={email_is_active}
                      onChange={(e) => set_email_is_active(e.target.checked)}
                    />
                  }
                  label="Email"
                />
              )}
            </div>
          </div>
        </div>
        <Typography variant="body2">{t(description)}</Typography>
      </div>
    </div>
  );
}
