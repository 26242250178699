import { Empty } from 'layouts/entities/List';
import {
  makeStyles,
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon
} from '@material-ui/core';

import FAIcon from 'components/ui/FAIcon';
import useKeys from '@flowsn4ke/usekeys';

import historyEntries from 'constants/historyEntries';
import { dateToLocalFormat } from 'utils/dates';

import bobdepannage from 'images/bobdepannage.png';

import { isFunction, isArray } from 'lodash-es';
import clsx from 'clsx';
import { useState } from 'react';
import PopoverDrawer from 'components/ui/PopoverDrawer';
import { EmailItem, PhoneItem } from 'components/views/ViewItems';
import useFileViewer from 'hooks/useFileViewer';
import { useTranslation } from 'react-i18next';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tooltip from 'components/ToolTip';

const useStyles = makeStyles((theme) => ({
  badgeSuccess: {
    ['& > span']: {
      backgroundColor: theme.palette.success.dark,
      margin: 0,
      padding: '1px',
      fontSize: '9px'
    }
  },
  list: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: 0,
    ['& > :nth-child(even)']: {
      background: '#ebebeb'
    }
  }
}));

export default function History({ entries }) {
  const classes = useStyles();

  const k1 = useKeys();
  const k2 = useKeys();
  const k3 = useKeys();

  const { t } = useTranslation();

  const [transportsPopOver, setTransportsPopOver] = useState(false);
  const [items, setItems] = useState(null);

  const { openFiles } = useFileViewer();

  return (
    <>
      {!entries?.length ? (
        <Empty
          translations={{
            noResultLabel: 'historic'
          }}
          icon="fa-history"
        />
      ) : (
        <List className={classes.list}>
          {entries.map((entry, i) => {
            const hasTransport =
              isArray(entry?.transport?.email) &&
              (!!entry?.transport?.email?.length || !!entry?.transport?.sms?.length);
            const isOpen = hasTransport && !!entry?.transport?.email.find((mail) => mail.opened);
            const transportCount =
              hasTransport && (entry?.transport?.email?.length || 0) + (entry?.transport?.sms?.length || 0);

            const is_from_api = !!entry?.payload?.fromApi;

            return (
              <ListItem key={k1(i)}>
                <ListItemIcon>
                  <FAIcon
                    style={{ marginRight: '10px' }}
                    size="medium"
                    icon={historyEntries[entry.type]?.icon || 'asterisk'}
                    collection="fal"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box
                      component="span"
                      display="inline-flex"
                      alignItems="center"
                      marginBottom="6px"
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        flexWrap: 'wrap'
                      }}
                    >
                      {!!entry.bobdepannage && (
                        <img
                          src={bobdepannage}
                          style={{ width: 30 }}
                          alt=""
                        />
                      )}

                      {!!entry._user && (
                        <Box
                          marginRight="4px"
                          display="inline-flex"
                          alignItems="center"
                          paddingLeft="0px"
                        >
                          {/* <Avatar style={{ marginRight: 12, position: 'relative', left: 3 }} isUser round size={"chip"} entity={entry._user} size={"xsxsmall"} /> */}
                          {`${entry._user.lastName?.toUpperCase()} ${entry._user.firstName}`}
                        </Box>
                      )}
                      {entry?.payload?.isPublic && entry?.payload?.creator && (
                        <Box
                          marginRight="4px"
                          display="inline-flex"
                          alignItems="center"
                          paddingLeft="0px"
                        >
                          {`${entry?.payload?.creator.toUpperCase()}`}
                        </Box>
                      )}
                      {entry.payload?.companyName && (
                        <strong style={{ fontSize: 12, marginRight: 4 }}>({entry.payload?.companyName})</strong>
                      )}

                      {isFunction(historyEntries[entry.type].label)
                        ? historyEntries[entry.type].label({ payload: entry.payload })
                        : historyEntries[entry.type].label}

                      {/* Marker API */}
                      {is_from_api && (
                        <Box
                          marginLeft="4px"
                          display="inline-flex"
                          alignItems="center"
                          paddingLeft="0px"
                        >
                          {t('fromApi')}
                        </Box>
                      )}
                      {/* End Marker API */}
                    </Box>
                  }
                  secondary={t('onDateX', { date: dateToLocalFormat(entry.date, 'PPPPp') })}
                />
                <ListItemSecondaryAction>
                  {hasTransport && (
                    <Box>
                      {!!entry.transport?.attachments?.length && (
                        <Tooltip
                          position="left"
                          title={t('attachments')}
                        >
                          <IconButton
                            onClick={() =>
                              openFiles(
                                entry.transport?.attachments.map((a) => ({ url: a, mimetype: 'application/pdf' }))
                              )
                            }
                          >
                            <Badge
                              color="secondary"
                              badgeContent={entry.transport.attachments?.length}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                            >
                              <FAIcon
                                icon="paperclip"
                                collection="fas"
                                size="small"
                              />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      )}
                      <IconButton onClick={(e) => [setTransportsPopOver(e.currentTarget), setItems(entry)]}>
                        <Badge
                          color="secondary"
                          className={clsx({
                            [classes.badgeSuccess]: isOpen
                          })}
                          badgeContent={transportCount}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                        >
                          <NotificationsIcon />
                        </Badge>
                      </IconButton>
                    </Box>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
      <PopoverDrawer
        anchorEl={transportsPopOver}
        open={!!transportsPopOver}
        onClose={() => [setTransportsPopOver(null), setItems(null)]}
        transitionDuration={0}
        direction={'bottom'}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {!!items && (
          <List
            component="nav"
            style={{
              backgroundColor: 'white',
              minHeight: '100%',
              paddingTop: 0,
              paddingBottom: 0,
              width: '100%'
            }}
          >
            {items?.transport?.email &&
              items?.transport?.email.map((e, i) => {
                const isMailOpen = e.opened;
                const isMailDrop = e.dropped;
                return (
                  <ListItem key={k2(i)}>
                    <EmailItem element={e} />
                    <Tooltip
                      position="left"
                      title={
                        isMailOpen
                          ? `Ouvert le ${dateToLocalFormat(e.opened, 'PPPp')}`
                          : isMailDrop
                          ? t('mailNotDistributed')
                          : t('notOpen')
                      }
                    >
                      <IconButton>
                        <Badge
                          color="secondary"
                          className={clsx({ [classes.badgeSuccess]: isMailOpen })}
                        >
                          <FAIcon
                            icon={isMailOpen ? 'envelope-open' : 'envelope'}
                            collection="fas"
                            size="small"
                          />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                );
              })}
            {items?.transport?.sms &&
              items?.transport?.sms?.map((s, i) => {
                return (
                  <ListItem key={k3(i)}>
                    <PhoneItem element={{ phone: s.phone }} />
                  </ListItem>
                );
              })}
          </List>
        )}
      </PopoverDrawer>
    </>
  );
}
