import { Box, Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { fetchUserData, retrieveUserInfos } from 'store/authSlice';
import useStyles from './Portal.styles';
import logo from 'images/logo.svg';
import Splash from 'components/Splash';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useDispatch } from 'react-redux';
import { INTERFACE_ID } from 'constants/localStorage';
import { supabase } from 'utils/supabaseClient';

export default function AuthWrapper({ title, backTo = '', children }) {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasUserInfo, setHasUserInfo] = useState(false);
  const [error, setError] = useState(null);
  const { dispatch: asyncDispatch } = useAsyncDispatch();
  const dispatch = useDispatch();
  const location = useLocation();

  const auth = useAuth();

  const fetchSession = useCallback(async () => {
    try {
      const {
        data: { session }
      } = await supabase.auth.getSession();
      if (session) {
        asyncDispatch(
          retrieveUserInfos,
          { token: session.access_token, interface_id: localStorage.getItem(INTERFACE_ID) },
          {
            onError: (error) => {
              setError('Error getting user info', error);
              setIsLoading(false);
            },
            onSuccess: (data) => {
              setSession(session);
              setHasUserInfo(true);
              setIsLoading(false);
            }
          }
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setSession(null);
      setError('Error getting session', error);
      setIsLoading(false);
    }
  }, [asyncDispatch]);

  useEffect(() => {
    // Réinitialise l'état d'erreur lorsque la localisation change
    setError(null);
  }, [location]);

  useEffect(() => {
    fetchSession();
    const { data: subscription } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    return () => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };
  }, [fetchSession]);

  useEffect(() => {
    window.$crisp.push(['do', 'chat:show']);
    if (auth.accessToken) {
      window.$crisp.push(['do', 'chat:hide']);
      dispatch(fetchUserData());
    }
  }, [auth.accessToken, dispatch]);

  if (isLoading) return <LoadingScreen />;
  else if ((!session || error) && !auth.accessToken)
    return (
      <LoginForm
        title={title}
        backTo={backTo}
        children={children}
        error={error}
      />
    );
  else if (!hasUserInfo || !auth.accessToken) return <LoadingScreen />;
  else return children;
}

function LoginForm({ title, backTo = '', children, error }) {
  const isRouterLink = backTo.startsWith('/');
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={`max-h-screen h-screen flex flex-col justify-center items-center ${classes.portalContainer}`}>
      <Box>
        <div className="flex items-center justify-between mb-4">
          <IconButton
            href={!isRouterLink ? backTo : undefined}
            to={isRouterLink ? backTo : undefined}
            component={isRouterLink ? Link : undefined}
          >
            <ArrowBackIcon className="text-white" />
          </IconButton>

          <img
            alt="bob! desk logo"
            src={logo}
            className="h-10 mb-2"
          />
        </div>
        <Box className="bg-white p-8 mx-3 rounded-lg shadow-lg flex flex-col gap-4 sm:min-w-[480px] max-w-[550px] max-h-[calc(100vh-80px)] overflow-auto">
          <Typography variant="h3">{t(`${title}`)}</Typography>
          <Box>{children}</Box>
          {error && <p className="text-center text-red-500 text-sm">{error}</p>}
        </Box>
      </Box>
    </Box>
  );
}

function LoadingScreen() {
  const classes = useStyles();

  return (
    <Box className={`max-h-screen h-screen flex flex-col justify-center items-center ${classes.portalContainer}`}>
      <Splash />
    </Box>
  );
}
