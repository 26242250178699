import { ListItem, Box, Checkbox, Radio, ListItemIcon } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useEntity } from 'contexts/entities/entityContext';

import ViewMenu from './ViewMenu';

import useStyles from './Preview.styles';
import clsx from 'clsx';
import { useMemo } from 'react';
import { isArray } from 'lodash-es';
import { useAuth } from 'hooks/useAuth';

export default function Preview({
  variant,
  element,
  setIsOpenForm,
  fldx,
  stylePreview,
  previewComponent: _previewComponent,
  calendar,
  onClick,
  previewPayload = {},
  disableClick
}) {
  const {
    previewComponent,
    page,
    intervenerPicker,
    disabled,
    selectedId,
    setSelectedId,
    picker,
    closePicker,
    updateOnClick,
    pickerUniqRadio,
    afterClick,
    scheduler,
    pickerUniq,
    stopPropagation,
    onClickPreview
  } = useEntity();

  const previewComponent_ = _previewComponent || previewComponent;

  const history = useHistory();

  const selected =
    !picker && !fldx
      ? selectedId === element._id
      : pickerUniq
      ? fldx?.value?._id === element._id
      : !!fldx?.value?.some((el) => el?._id === element._id || el === element._id);

  const classes = useStyles({ picker, updateOnClick, scheduler });

  const getNextArr = (arr, element, selected) => {
    if (selected) {
      return arr?.filter((el) => el._id !== element._id && el !== element._id);
    } else {
      return isArray(arr) ? [...arr, element] : [element];
    }
  };

  const handleClick = (element) => {
    if (disableClick) {
      return;
    } else if (onClickPreview) {
      onClickPreview(element);
    } else if (onClick) {
      onClick(element);
    } else {
      if (picker && fldx) {
        if (pickerUniq) {
          if (!fldx.value || fldx.value?._id !== element._id) fldx.setValue(element);
          else if (fldx.value?._id === element._id) fldx.setValue(null);
          if (closePicker) closePicker();
        } else {
          let next;
          // Specific logic for intervener selection
          if (intervenerPicker?.type === 'assign') {
            if (element?.isContractor) {
              next = [element];
            } else {
              next = getNextArr(fldx?.value, element, selected).filter((el) => el.isCollaborator);
            }
          } else {
            next = getNextArr(fldx?.value, element, selected);
          }
          fldx.setValue(next);
        }
      } else if (!page) {
        if (updateOnClick) {
          setIsOpenForm(element._id);
        } else {
          setSelectedId(element._id);
        }
      } else {
        if (updateOnClick) {
          setIsOpenForm(element._id);
        } else {
          elementPushHistory(history, selectedId, element._id);

          if (afterClick) {
            afterClick(element);
          }
        }
      }
    }
  };

  return (
    <PreviewAlt
      intervenerPicker={intervenerPicker}
      variant={variant}
      disabled={disabled}
      selected={selected}
      stylePreview={stylePreview}
      pickerUniqRadio={pickerUniqRadio}
      classes={classes}
      calendar={calendar}
      handleClick={handleClick}
      picker={picker}
      pickerUniq={pickerUniq}
      updateOnClick={updateOnClick}
      setIsOpenForm={setIsOpenForm}
      element={element}
      stopPropagation={stopPropagation}
      previewComponent={previewComponent_}
      previewPayload={previewPayload}
    />
  );
}

export function elementPushHistory(history, selectedId, elementId) {
  history.push(
    selectedId === elementId
      ? history.location.pathname.replace('/' + selectedId, '')
      : history.location.pathname.includes(selectedId)
      ? history.location.pathname.replace(selectedId, elementId)
      : history.location.pathname + '/' + elementId
  );
}

function PreviewAlt({
  variant,
  intervenerPicker,
  selected,
  classes,
  disabled,
  calendar,
  stylePreview,
  handleClick,
  picker,
  pickerUniqRadio,
  pickerUniq,
  updateOnClick,
  setIsOpenForm,
  element,
  previewPayload,
  stopPropagation,
  previewComponent: PreviewComponent
}) {
  const auth = useAuth();
  const { previewComponentFn, scheduler, entity, Draggable, consumptionTicket } = useEntity();

  const PreviewComponentAlt = useMemo(() => {
    return previewComponentFn ? PreviewComponent({ element }) : PreviewComponent;
  }, [previewComponentFn, PreviewComponent, element]);

  return (
    <ListItem
      selected={selected}
      button
      disabled={disabled}
      data-id={element._id}
      className={clsx(classes.previewButton, {
        [classes.previewVariant]: variant,
        ['Draggable']: !!Draggable
      })}
      onClick={(e) => [stopPropagation && e.stopPropagation(), handleClick(element)]}
      style={{
        width: '100%',
        ...(scheduler && ['tickets', 'maintenances'].includes(entity) ? { cursor: 'move' } : {}),
        ...(stylePreview || {})
      }}
    >
      {picker && !pickerUniq && (
        <ListItemIcon>
          <Checkbox
            style={
              intervenerPicker
                ? {
                    color: element.isCollaborator ? '#fbb034' : !!element.partner ? '#535b7c' : '#31b559'
                  }
                : {}
            }
            disableRipple
            color="primary"
            checked={selected}
            className={classes.previewCheckbox}
          />
        </ListItemIcon>
      )}

      {pickerUniqRadio && (
        <ListItemIcon>
          <Radio
            disableRipple
            color="primary"
            checked={selected}
            className={classes.previewCheckbox}
          />
        </ListItemIcon>
      )}

      <PreviewComponentAlt
        isActive={selected}
        element={element}
        payload={{
          consumptionTicket,
          scheduler,
          calendar,
          ...previewPayload
        }}
      />

      {(picker || updateOnClick) && !auth.interface.isPublic && (
        <Box marginLeft="auto">
          <Box marginRight="3px">
            <ViewMenu
              preview
              element={element}
              setIsOpenForm={setIsOpenForm}
            />
          </Box>
        </Box>
      )}
    </ListItem>
  );
}
