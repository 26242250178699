import { formatFilter, LIMIT } from 'modules/tickets/ticket-list/baseFilters';
import { prepareFiltersForApi } from 'modules/tickets/ticket-list/utilsFun';

export const transformAlertExceeded = (key, value) => {
  if (!value) return [];

  return [{ key: 'alert_at', val: String(new Date().getTime()), op: 'lt' }];
};

export const transformMyInterventions = (key, value, auth) => {
  if (!value) return [];

  if (auth?.interface?.isManager) return [{ key: 'managers', val: [auth?.user?._id], op: 'in' }];
  if (auth?.interface?.isUser) return [{ key: 'contacts', val: [auth?.interface?._contact?._id], op: 'in' }];
};

export const transformHasContractor = (key, value) => {
  // le filtre s'appelle `Sans intervenant` donc pour le ressortir il faux dire has_contractor === false
  if (!value) return [];

  return [{ key: 'has_contractor', val: false, op: 'equals' }];
};

export const transformHasManagers = (key, value) => {
  if (!value) return [];

  return [{ key: 'has_managers', val: false, op: 'equals' }];
};

export const transformHasSkills = (key, value) => {
  if (!value) return [];

  return [{ key: 'has_skills', val: false, op: 'equals' }];
};

export const transformHasNotes = (key, value) => {
  if (!value) return [];

  return [{ key: 'has_notes', val: true, op: 'equals' }];
};

export const transformLate = (key, value) => {
  if (!value) return [];

  return [{ key: 'late', val: true, op: 'equals' }];
};

export const transformSpecialType = (key, value) => {
  const prefix = key.slice(0, -1); // "quote" ou "invoice"
  const results = [];

  // Ajouter has_<prefix> seulement si "with" ou "without" est activé, mais pas les deux
  if (value.with && !value.without) {
    results.push({ key: `has_${prefix}`, val: true, op: 'equals' });
  } else if (!value.with && value.without) {
    results.push({ key: `has_${prefix}`, val: false, op: 'equals' });
    return results;
  }

  if (value.amount && Array.isArray(value.amount.values)) {
    const [min, max] = value.amount.values;
    if (min !== null && max !== null && value.amount.op === 'range') {
      results.push({ key: `${prefix}_amount`, val: [min, max], op: 'range' });
    } else if (min !== null && value.amount.op !== 'range') {
      const nonNullValue = min !== null ? min : max;
      results.push({ key: `${prefix}_amount`, val: nonNullValue, op: value.amount.op });
    }
  }

  if (value.validatedBy && Array.isArray(value.validatedBy)) {
    const ids = value.validatedBy.map((item) => item._id || item); // Extraire `_id` si objets
    if (ids.length > 0) {
      results.push({ key: `${prefix}_validated_by`, val: ids, op: 'in' });
    }
  }

  if (value.reference && value.reference.trim()) {
    results.push({ key: `${prefix}_reference`, val: value.reference.trim(), op: 'equals' });
  }

  if (value.validated && value.noValidated) return results;
  else if (value.validated) results.push({ key: `has_unvalidated_${prefix}`, val: false, op: 'equals' });
  else if (value.noValidated) results.push({ key: `has_unvalidated_${prefix}`, val: true, op: 'equals' });

  return results;
};

export const transformStatus = (states) => (key, value) => value ? [{ key: 'status', val: states, op: 'in' }] : [];

const bookmarksFilters = () => {
  return {
    toplan: [{ key: 'bookmarks', val: ['toplan'], op: 'in' }],
    toreplan: [{ key: 'bookmarks', val: ['toreplan'], op: 'in' }],
    waitingPlan: [{ key: 'bookmarks', val: ['waitingPlan'], op: 'in' }],
    opened: [{ key: 'bookmarks', val: ['opened'], op: 'in' }],
    waiting: [{ key: 'bookmarks', val: ['waiting'], op: 'in' }],
    validation: [{ key: 'bookmarks', val: ['validation'], op: 'in' }],
    validation_waiting: [{ key: 'bookmarks', val: ['validation_waiting'], op: 'in' }],
    validation_plan: [{ key: 'bookmarks', val: ['validation_plan'], op: 'in' }],
    assigned: [{ key: 'bookmarks', val: ['assigned'], op: 'in' }],
    visit: [{ key: 'bookmarks', val: ['visit'], op: 'in' }],
    finished: [{ key: 'bookmarks', val: ['finished'], op: 'in' }],

    to_complete: [{ key: 'bookmarks', val: ['to_complete'], op: 'in' }],
    to_check: [{ key: 'bookmarks', val: ['to_check'], op: 'in' }],
    to_waiting: [{ key: 'bookmarks', val: ['to_waiting'], op: 'in' }],
    to_valid: [{ key: 'bookmarks', val: ['to_valid'], op: 'in' }],
    analyse: [{ key: 'bookmarks', val: ['analyse'], op: 'in' }],
    reserve: [{ key: 'bookmarks', val: ['reserve'], op: 'in' }],
    to_send: [{ key: 'bookmarks', val: ['to_send'], op: 'in' }],
    sent: [{ key: 'bookmarks', val: ['sent'], op: 'in' }],
    available: [{ key: 'bookmarks', val: ['available'], op: 'in' }]
  };
};

export const getAssociatedFiltersFromBookmarks = (bookmarkNames, appliedFilters, auth) => {
  const results = {};

  // Format and prepare filters for the API
  const filter_to_send = formatFilter(appliedFilters, auth, 0, LIMIT);
  const apiFilters = prepareFiltersForApi({ filters: filter_to_send });
  const conditionsMap = bookmarksFilters();

  bookmarkNames.forEach((bookmarkName) => {
    if (conditionsMap[bookmarkName]) {
      const combinedFilters = [...conditionsMap[bookmarkName], ...apiFilters];
      results[bookmarkName] = combinedFilters;
    }
  });

  return results;
};

export const transformReports = (key, value) => {
  const results = [];

  if (value.ir?.with && !value.ir?.without) {
    results.push({ key: 'has_report', val: true, op: 'equals' });
  } else if (!value.ir?.with && value.ir?.without) {
    results.push({ key: 'has_report', val: false, op: 'equals' });
  }

  if (value.reserve?.with && !value.reserve?.without) {
    results.push({ key: 'has_report_reserve', val: true, op: 'equals' });
  } else if (!value.reserve?.with && value.reserve?.without) {
    results.push({ key: 'has_report_reserve', val: false, op: 'equals' });
  }

  return results;
};

export const transformValidedPeriod = (key, value) => {
  const results = [];

  if (value?.with && !value?.without) {
    results.push({ key: 'has_unvalidated_period', val: false, op: 'equals' });
  } else if (!value?.with && value?.without) {
    results.push({ key: 'has_unvalidated_period', val: true, op: 'equals' });
  }

  return results;
};

export const transformCustomFields = (values) => {
  // values = [{_field: "field_id", value: ["value1", "value2"], op: "in"}]
  const results = [];

  if (values?.length) {
    values.forEach((value) => {
      results.push({ key: value._field, val: value.value, op: value.op || 'equals' });
    });
  }

  return results;
};

export const transformDates = (key, value) => {
  const results = [];

  Object.entries(value).forEach(([dateKey, dateValue]) => {
    if (dateValue?.values) {
      const [min, max] = dateValue.values;

      if (min !== null && max !== null && dateValue.op === 'range') {
        results.push({ key: dateKey, val: [Date.parse(min), Date.parse(max)], op: 'range' });
      } else if (min !== null && dateValue.op !== 'range') {
        const singleValue = min !== null ? min : max;
        results.push({ key: dateKey, val: Date.parse(singleValue), op: dateValue.op });
      }
    }
  });

  return results;
};

// exemple:
// before quotes:
// {"key":"quotes","val":{"with":true,"without":true,"amount":{"values":[23,null],"op":"equals"},"reference":"","validated":false,"noValidated":false,"validatedBy":[]},"op":"object"}
// expected result:
// {key:"has_quote", val:true, op:"equals"}
// {key:"quote_amount", val:["12", "24"], op:"range"} \ OR if only one value at [0] {key:"quote_amount", val:"23", op:"equals"}
// {key:"quote_validated_by", val:["user_id"], op:"in"}
// {key:"quote_reference", val:"#234sfsjdk234", op:"equals"}
// {key:"has_unvalidated_quote", val:true, op:"equals"}

// exemple 2:
// before quotes:
// {"key":"invoices","val":{"with":true,"without":true,"amount":{"values":[12,null],"op":"equals"},"reference":""},"op":"object"}
// expected result:
// {key:"has_invoice", val:"true", op:"equals"}
// {key:"invoice_amount", val:["12", "24"], op:"range"} \ OR if only one value at [0] {key:"invoice_amount", val:"12", op:"equals"}
// {key:"invoice_reference", val:"#234sfsjdk234", op:"equals"}

// exemple 3:
// before reports:
// {"key":"reports","val":{"ir":{"with":true,"without":false},"reserve":{"with":true,"without":false}},"op":"object"}
// expected result:
// {key:"has_report", val:true, op:"equals"}
// {key:"has_reserve", val:true, op:"equals"}

// exemple 4:
// before dates:
// {"key":"dates","val":{"creationAt":{"values":[12,null],"op":"gt"},"flagFinished":{"values":[12,null],"op":"gte"},"interventionDate":{"values":[12,46],"op":"range"},"visitDate":{"values":'12',"op":"lte"}},"op":"object"}
// expected result:
// {key:"creation_at", val:'12', op:"gt"}
// {key:"flag_finished", val:'12', op:"gte"}
// {key:"intervention_date", val:[12, 46], op:"range"}
// {key:"visit_date", val:'12', op:"lte"}

// exemple 5:
// before customFields:
// {"key":"customFields","val":[{"field":"field_id","value":["value1","value2"],"op":"in"}],"op":"object"}
// expected result:
// {key:"field_id", val:["value1", "value2"], op:"in"}

// exemple 6:
// before validatedPeriod:
// {"key":"validatedPeriod","val":{"with":true,"without":true},"op":"object"}
// expected result:
// {key:"validated_period", val:true, op:"equals"}
