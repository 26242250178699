import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  Checkbox,
  FormGroup
} from '@material-ui/core';
import { useField } from 'frmx';

import EntityX from 'components/inputs/EntityX';

import { Fingerprint } from '@material-ui/icons';
import Manager from 'entities/Manager/Manager';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';

export default function NewAccountingFilters({ type, path }) {
  const { value, setValue } = useField(path);

  const { t } = useTranslation();

  const auth = useAuth();
  const company = auth?.interface?._company;

  const radioButtonChange = (e) => {
    const returnValue = parseInt(e.target.value, 10);

    if (returnValue === 0) setValue({ ...value, with: true, without: true });
    if (returnValue === 1) setValue({ ...value, with: true, without: false });
    if (returnValue === 2) setValue({ ...value, with: false, without: true });
  };

  // Définition de la valeur par défaut de RadioGroup
  const getValueRadioButton = () => {
    if (value.with && value.without) return '0';
    if (value.with && !value.without) return '1';
    if (!value.with && value.without) return '2';
    return '0';
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Grid
        container
        spacing={3}
      >
        <Grid item>
          <Box>
            <FormControl component="fieldset">
              <RadioGroup
                style={{
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                value={getValueRadioButton()}
                onChange={radioButtonChange}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label={t('withOrWithout')}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={t('with')}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label={t('without')}
                />
              </RadioGroup>
            </FormControl>

            {type === 'quotes' && value.with === true && value.without === false && (
              <FormGroup
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '6px',
                  justifyContent: 'center'
                }}
              >
                <FormControlLabel
                  control={value.validated ? <Checkbox checked /> : <Checkbox />}
                  label="Validé"
                  value={value.validated}
                  onChange={() => {
                    setValue({ ...value, validated: !value.validated });
                  }}
                />
                <FormControlLabel
                  control={value.noValidated ? <Checkbox checked /> : <Checkbox />}
                  label={t('waitingForValidation')}
                  value={value.noValidated}
                  onChange={() => {
                    setValue({ ...value, noValidated: !value.noValidated });
                  }}
                />
              </FormGroup>
            )}
          </Box>
        </Grid>

        {((value.with === true && value.without === false) || (value.without === true && value.with === true)) && (
          <>
            {type === 'quotes' && (
              <Grid
                item
                xs={12}
              >
                <EntityX
                  path="quotes.validatedBy"
                  entityName="managers"
                  entity={Manager}
                  placeholder={t('validatedBy')}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Fingerprint />
                    </InputAdornment>
                  )
                }}
                variant={'outlined'}
                fullWidth
                placeholder={t('reference')}
                label={t('reference')}
                value={value.reference}
                onChange={(e) => setValue({ ...value, reference: e.target.value })}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Select
                fullWidth
                variant={'outlined'}
                value={value.amount.op}
                onChange={(e) => {
                  setValue({
                    ...value,
                    amount: { ...value.amount, op: e.target.value }
                  });
                }}
              >
                <MenuItem value="equals">{t('equalTo')}</MenuItem>
                <MenuItem value="lt">{t('lessThan')}</MenuItem>
                <MenuItem value="gt">{t('greaterThan')}</MenuItem>
                <MenuItem value="range">{t('between')}</MenuItem>
              </Select>
            </Grid>
            <Box
              padding="12px"
              width="50%"
              display="flex"
              flexDirection="column"
            >
              <Grid
                item
                xs={12}
                style={{ marginBottom: 10 }}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <span className="text-xl mr-3">{company?.currency?.symbol || '€'}</span>
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ type: 'number', min: 0 }}
                  type="number"
                  placeholder={t('noVATAmount')}
                  marginBottom="10px"
                  value={value.amount.values[0]}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      amount: { ...value.amount, values: [e.target.value || null, null] }
                    });
                  }}
                />
              </Grid>
              {value.amount.op === 'range' && (
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <span className="text-xl mr-3">{company?.currency?.symbol || '€'}</span>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                    fullWidth
                    inputProps={{ type: 'number', min: 0 }}
                    type={'number'}
                    placeholder={t('noVATAmount')}
                    value={value.amount.values[1]}
                    onChange={(e) => {
                      setValue({
                        ...value,
                        amount: { ...value.amount, values: [value.amount.values[0] || null, e.target.value || null] }
                      });
                    }}
                  />
                </Grid>
              )}
            </Box>
          </>
        )}
      </Grid>
    </Box>
  );
}
