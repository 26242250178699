import { Fragment, useRef } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'utils/classNames';
import { useTranslation } from 'react-i18next';
import { DAY_VIEW_TYPE, MONTH_VIEW_TYPE, WEEK_VIEW_TYPE } from './config';
import { add, format } from 'date-fns';
import { useSize } from 'hooks/useSize';
import { useUI } from 'hooks/useUI';
import ButtonGroup from 'components/forms/ButtonGroup';
import { useIsBelowMd, useIsBelowSm } from 'hooks/useMQ';

export default function Toolbar({
  now = new Date(),
  advance,
  go_back,
  go_to_today,
  set_view_type,
  days_offset,
  months_offset,
  should_fetch_interventions,
  set_should_fetch_interventions,
  should_fetch_unavailabilities,
  set_should_fetch_unavailabilities,
  set_open_unavailability_creation_dialog
}) {
  const { t } = useTranslation();
  const isBelowSm = useIsBelowSm();
  const isBelowMd = useIsBelowMd();

  const toolbar_ref = useRef();
  const size = useSize(toolbar_ref);

  const ui = useUI();
  const locale = ui.get_date_time_locale();

  const show_checkboxes = size.width > 450;

  return (
    <header
      ref={toolbar_ref}
      className="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6"
    >
      <h1 className="text-lg font-semibold text-gray-900">
        <time
          dateTime={now.toISOString()}
          className="capitalize"
        >
          {format(add(now, { days: days_offset, months: months_offset }), 'MMMM yyyy', { locale })}
        </time>
      </h1>

      {show_checkboxes && (
        <ButtonGroup
          options={[
            { value: 'interventions', label: 'Interventions' },
            { value: 'unavailabilities', label: 'Indisponibilités' }
          ]}
          value={[
            ...(should_fetch_interventions ? ['interventions'] : []),
            ...(should_fetch_unavailabilities ? ['unavailabilities'] : [])
          ]}
          onChange={(selected_keys) => {
            set_should_fetch_interventions(selected_keys.includes('interventions'));
            set_should_fetch_unavailabilities(selected_keys.includes('unavailabilities'));
          }}
        />
      )}

      <div className="flex items-center">
        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
          <button
            onClick={() => go_back()}
            type="button"
            className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          >
            <span className="sr-only">{t('Previous month')}</span>
            <ChevronLeftIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
          </button>
          <button
            onClick={() => go_to_today()}
            type="button"
            className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
          >
            {t('Today')}
          </button>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button
            onClick={() => advance()}
            type="button"
            className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          >
            <span className="sr-only">{t('Next month')}</span>
            <ChevronRightIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
          </button>
        </div>
        <Menu
          as="div"
          className="relative ml-6"
        >
          <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span className="sr-only">{t('openMenu')}</span>
            <EllipsisHorizontalIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => set_open_unavailability_creation_dialog(true)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm text-left w-full'
                      )}
                    >
                      {t('createUnavailability')}
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => go_to_today()}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm w-full text-left'
                      )}
                    >
                      {t('goToToday')}
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => set_view_type(DAY_VIEW_TYPE)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm text-left w-full'
                      )}
                    >
                      {t('dayView')}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => set_view_type(WEEK_VIEW_TYPE)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm text-left w-full'
                      )}
                    >
                      {isBelowSm ? t('3DaysViews') : isBelowMd ? t('5DaysViews') : t('weekView')}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => set_view_type(MONTH_VIEW_TYPE)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm text-left w-full'
                      )}
                    >
                      {t('monthView')}
                    </button>
                  )}
                </Menu.Item>
                {/* <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm text-left w-full'
                    )}
                  >
                    Year view
                  </a>
                )}
              </Menu.Item> */}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  );
}
