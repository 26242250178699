import { useRef, useState, useMemo } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  Box,
  Badge,
  useTheme,
  Grid,
  List,
  IconButton,
  Button,
  ListItem,
  TextField,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Checkbox
} from '@material-ui/core';

import FAIcon from 'components/ui/FAIcon';

import { Add, EventNote, Timer, Edit } from '@material-ui/icons';
import { useRole } from 'hooks/useRole';
import { dateToLocalFormat } from 'utils/dates';
import PopoverDrawer from 'components/ui/PopoverDrawer';
import CalendarPopover from 'components/ui/CalendarPopover';
import useStyles from 'layouts/entities/View.styles';
import { useContract } from 'contexts/entities/contractContext';
import useKeys from '@flowsn4ke/usekeys';

import moment from 'moment';

import { setDate, updateDate } from 'store/ticketsSlice';
import { useConfiguration } from 'hooks/useConfiguration';
import { useAuth } from 'hooks/useAuth';
import { CONTRACT_TOREPLAN } from 'lists/contractStates';

import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { useIsBelowSm } from 'hooks/useMQ';
import classNames from 'utils/classNames';
import Tooltip from 'components/ToolTip';

export default function ContractDate({ isParent, contract, type }) {
  const auth = useAuth();
  const configuration = useConfiguration();
  const role = useRole();
  const theme = useTheme();
  const {
    isProposition,
    dispatch,
    order: { _id: ticket_id },
    isLoading
  } = useContract();
  const [isLoadingDate, setIsLoadingDate] = useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [dateToDelete, setDateToDelete] = useState(null);
  const canUpdateDate = !isProposition && role.permission('tickets', type + '_date');
  const { t } = useTranslation();
  const isBelowSm = useIsBelowSm();

  const listItemRef = useRef(null);

  const addDate = ({ date, message, compute = false }) => {
    setIsLoadingDate(true);
    const body = { type, compute, date, message, isParent };
    dispatch(
      setDate,
      body,
      { onSuccess: () => setIsLoadingDate(false), onError: () => setIsLoadingDate(false) },
      { id: ticket_id }
    );
  };

  // TODO: WTF is this ?
  const editDate = ({ index, prop, value, compute = false }) => {
    setIsLoadingDate(true);
    const body = { type, isParent, index, prop, value, compute };
    dispatch(
      updateDate,
      body,
      { onSuccess: () => setIsLoadingDate(false), onError: () => setIsLoadingDate(false) },
      { id: ticket_id }
    );
  };

  const classes = useStyles();
  const k = useKeys();

  const dateEntries = contract[type + '_dates']?.filter((date) => !date.deleted.state) || [];

  const sort_date_entries_by_desc =
    dateEntries.length > 0 ? dateEntries.sort((a, b) => new Date(b.date) - new Date(a.date)) : [];

  const getRealDateIndex = (id) => contract[type + '_dates'].findIndex((date) => date._id === id);

  const current_date = contract[type + '_date'];
  const dateLabel = type === 'visit' ? t('visitDate') : t('interventionDate');

  const renderDate = (index, fromItem = false) => {
    return (
      <span style={{ position: 'relative' }}>
        {contract.state === CONTRACT_TOREPLAN &&
          fromItem &&
          t('toReplan') + (contract.replan_reason ? ' - ' + contract.replan_reason : '')}
        {(contract.state !== CONTRACT_TOREPLAN || !fromItem) &&
          !!sort_date_entries_by_desc[index] &&
          !!sort_date_entries_by_desc[index].date &&
          dateToLocalFormat(sort_date_entries_by_desc[index].date, 'PPPPp')}
        {(contract.state !== CONTRACT_TOREPLAN || !fromItem) &&
          !!sort_date_entries_by_desc[index] &&
          !!sort_date_entries_by_desc[index].message && (
            <Tooltip
              position="right"
              title={sort_date_entries_by_desc[index].message}
            >
              <EventNote
                style={{
                  height: 18,
                  cursor: 'pointer',
                  position: 'relative',
                  right: '-5px',
                  top: '3px'
                }}
              />
            </Tooltip>
          )}
      </span>
    );
  };

  const totalDuration = useMemo(
    () =>
      sort_date_entries_by_desc
        .filter((date) => date.compute)
        .reduce((a, date) => a + date.duration, 0),
    [sort_date_entries_by_desc]
  );

  const enableInterventionDatepicker =
    type !== 'intervention' ||
    !configuration.ticket_unique_intervention_date ||
    (!!configuration.ticket_unique_intervention_date && !current_date);

  return (
    <Grid
      ref={listItemRef}
      item
      xs={12}
      sm={6}
      className={classes.listItemSecondary}
    >
      <ListItem
        disabled={isLoading}
        dense
        button
        className={classNames(classes.listItemSecondary, classes.listItem)}
        onClick={() =>
          !!current_date
            ? setIsListOpen(true)
            : canUpdateDate && enableInterventionDatepicker
              ? setIsPickerOpen(true)
              : null
        }
      >
        <ListItemIcon>
          <FAIcon
            icon={
              (type === 'visit' && !contract['intervention_date']) ||
                (type === 'intervention' && !!current_date)
                ? 'calendar-day'
                : 'calendar'
            }
            collection="fal"
            className={classes.listIcon}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <span style={{ position: 'relative' }}>
              <Badge
                className={classes.listItemPrimaryBadge}
                color="secondary"
                badgeContent={
                  sort_date_entries_by_desc?.length > 1 ? sort_date_entries_by_desc?.length : 0
                }
              >
                <strong>{dateLabel}</strong>
              </Badge>
            </span>
          }
          secondary={
            isLoading ? (
              <Skeleton
                animation="wave"
                variant="text"
                width={64}
                height={10}
              />
            ) : current_date ? (
              renderDate(0, true)
            ) : (
              t('noDate')
            )
          }
        />
        {/* Bouton de suivi en temps reel */}
        {canUpdateDate && (
          <ListItemSecondaryAction className={classes.listItemSecondary}>
            <Box
              className="hover"
              display="flex"
              alignItems="center"
            >
              {type === 'intervention' &&
                !isLoadingDate &&
                configuration.feature.streetview &&
                (auth.interface.isTechnician || contract.intervention_compute) && (
                  <Tooltip
                    position="right"
                    title={
                      contract.intervention_compute
                        ? t('endIntervention')
                        : !auth.interface.isTechnician
                          ? t('intervenerOnTheSpot')
                          : t('statIntervention')
                    }
                  >
                    <IconButton
                      style={{}}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (auth.interface.isTechnician || !contract.intervention_compute) {
                          if (contract.intervention_compute) {
                            editDate({
                              index: 0,
                              prop: 'duration',
                              compute: true,
                              value: moment().diff(moment(contract.intervention_compute), 'minutes')
                            });
                          } else {
                            addDate({ date: new Date(), compute: true });
                          }
                        }
                      }}
                    >
                      <FAIcon
                        style={contract.intervention_compute ? { color: '#f1c40f' } : {}}
                        collection="fad"
                        icon="street-view"
                      />
                    </IconButton>
                  </Tooltip>
                )}
              {!isLoadingDate && enableInterventionDatepicker && (
                <IconButton
                  disabled={isLoading}
                  onClick={() => setIsPickerOpen(true)}
                >
                  <Add />
                </IconButton>
              )}
            </Box>
            {isLoadingDate && (
              <CircularProgress
                size={22}
                style={{ marginRight: 6 }}
              />
            )}
          </ListItemSecondaryAction>
        )}
      </ListItem>

      <PopoverDrawer
        open={isListOpen}
        onClose={() => setIsListOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transitionDuration={0}
        style={{ width: isBelowSm ? '100%' : listItemRef.current?.offsetWidth }}
        anchorEl={listItemRef.current}
      >
        <Box
          width="100%"
          bgcolor="white"
        >
          <Box
            padding="6px 6px 6px 16px"
            height={42}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="inline-flex"
              fontWeight={500}
              fontSize="14px"
              color={theme.palette.primary.main}
            >
              {type === 'visit'
                ? t('visitDates')
                : type === 'intervention'
                  ? t('interventionDates')
                  : ''}
            </Box>
            {canUpdateDate && enableInterventionDatepicker && (
              <Box>
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  style={{ marginLeft: 6 }}
                  color="primary"
                  endIcon={<Add />}
                  size="small"
                  className={classes.viewActionVisitDate}
                  onClick={() => setIsPickerOpen(true)}
                >
                  {t('addDate')}
                </Button>
              </Box>
            )}
          </Box>

          <Box overflow="hidden">
            <List className={classes.listPopover}>
              <ConfirmDialog
                text={
                  <span>
                    {t('areYouSureYouWantToDelete')}{' '}
                    {type === 'visit' ? t('theVisitDate') : t('theInterventionDate')} {t('ofThe')}{' '}
                    <strong>
                      {dateToDelete?.date ? dateToLocalFormat(dateToDelete?.date, 'PPPPp') : ''}
                    </strong>{' '}
                    ?
                  </span>
                }
                open={Boolean(dateToDelete)}
                onClose={(e) => setDateToDelete(null)}
                onConfirm={() =>
                  editDate({ index: getRealDateIndex(dateToDelete?._id), prop: 'delete' })
                }
              />
              {sort_date_entries_by_desc?.map((date, i) => (
                <ListItem key={k(i)}>
                  <ListItemText
                    primary={
                      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                          <strong>{renderDate(i)}</strong>
                          <span>
                            {date._user?.firstName} {date._user?.lastName}
                          </span>
                        </Box>
                        {canUpdateDate && (
                          <Box display={'inline-flex'}>
                            <Tooltip
                              position={'top'}
                              title={'Modifier'}
                            >
                              <IconButton
                                disabled={isLoading}
                                size={'small'}
                                style={{ marginLeft: 10 }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setIsPickerOpen({ date, i });
                                }}
                              >
                                <Edit style={{ height: 14 }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </span>
                    }
                  />

                  <Box>
                    <Box
                      alignItems={'center'}
                      display={'flex'}
                    >
                      {!i && !!contract.intervention_compute && (
                        <Tooltip
                          position="left"
                          title={t('inTheMaking')}
                        >
                          <Box>
                            <FAIcon
                              style={{ color: '#f1c40f', marginRight: 16, cursor: 'pointer' }}
                              collection="fad"
                              icon="street-view"
                            />
                          </Box>
                        </Tooltip>
                      )}
                      {!!date.duration && (!!i || (!i && !contract.intervention_compute)) && (
                        <>
                          <DurationItem
                            onUpdate={(value) => [
                              editDate({ index: i, prop: 'duration', value }),
                              setIsListOpen(false)
                            ]}
                            duration={date.duration}
                            style={{
                              cursor: 'pointer',
                              alignItems: 'center',
                              display: 'flex'
                            }}
                          />
                          <Box marginLeft="6px">
                            <Tooltip
                              position="left"
                              title={t('accountForThatInInterventionDuration')}
                            >
                              <Checkbox
                                disabled={!canUpdateDate}
                                onChange={(e) =>
                                  editDate({
                                    index: i,
                                    prop: 'compute',
                                    value: e.target.checked,
                                    compute: false
                                  })
                                }
                                checked={date.compute}
                              />
                            </Tooltip>
                          </Box>
                        </>
                      )}
                      {!!date.coords && (
                        <Box marginLeft="6px">
                          <Tooltip
                            position="left"
                            title={t('openGeoPosition')}
                          >
                            <IconButton onClick={(e) => alert('Open map with location !')}>
                              {
                                <FAIcon
                                  collection="fad"
                                  icon="street-view"
                                />
                              }
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      {canUpdateDate && (
                        <Tooltip
                          position={'top'}
                          title={t('delete')}
                        >
                          <IconButton
                            disabled={isLoading}
                            size="small"
                            className="ml-0"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDateToDelete(date);
                            }}
                          >
                            <FAIcon
                              collection="fas"
                              icon="trash-alt"
                              size="sm"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                </ListItem>
              ))}

              {!!totalDuration && (
                <ListItem
                  style={{
                    justifyContent: 'flex-end',
                    backgroundColor: 'white',
                    cursor: 'pointer',
                    borderTop: '1px solid #363640'
                  }}
                >
                  <Box>
                    <DurationItem
                      duration={totalDuration}
                      total
                      style={{
                        cursor: 'pointer',
                        marginRight: 0,
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    />
                  </Box>
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
      </PopoverDrawer>

      {!!isPickerOpen && (
        <CalendarPopover
          time
          withMessage
          onChange={({ date, message }) => {
            if (isPickerOpen === true) {
              addDate({ date, message });
            } else {
              editDate({ index: isPickerOpen.i, prop: 'date', value: date });
            }

            setIsListOpen(false);
            setIsPickerOpen(false);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          date={isPickerOpen === true ? new Date() : isPickerOpen.date.date}
          style={{ width: isBelowSm ? '100%' : listItemRef.current?.offsetWidth }}
          anchorEl={listItemRef.current}
          open={!!isPickerOpen}
          onClose={() => [setIsPickerOpen(false)]}
        />
      )}
    </Grid>
  );
}

function DurationItem({ style, duration, total, onUpdate }) {
  const { t } = useTranslation();

  const timeConvert = (n) => {
    const num = n;
    const hours = num / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    return { h: rhours, m: rminutes };
  };

  const time = timeConvert(duration);

  const [isEdit, setIsEdit] = useState(false);
  const [hours, setHours] = useState(time.h);
  const [minutes, setMinutes] = useState(time.m);

  const save = () => {
    onUpdate(parseInt(hours) * 60 + parseInt(minutes));
  };

  return (
    <>
      <Box
        style={style}
        onClick={(e) => {
          if (!!onUpdate) {
            setIsEdit(e.currentTarget);
          }
        }}
      >
        {total && <strong>{t('totalDuration')} : </strong>}
        <strong style={{ marginLeft: 4, cursor: 'pointer' }}>
          {time.h}h : {time.m}min
        </strong>
      </Box>
      <PopoverDrawer
        anchorEl={isEdit}
        onClose={() => setIsEdit(false)}
        open={!!isEdit}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          padding="16px"
          display="flex"
          flexDirection="column"
        >
          <TextField
            label={t('hours')}
            style={{ marginBottom: 16 }}
            fullWidth
            variant="outlined"
            placeholder={t('hours')}
            value={hours}
            onChange={(e) => setHours(e.target.value)}
          />
          <TextField
            label={t('minutes')}
            style={{ marginBottom: 16 }}
            fullWidth
            variant="outlined"
            placeholder={t('minutes')}
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
          />

          <Button
            fullWidth
            onClick={() => [setIsEdit(false), save()]}
            color="primary"
            endIcon={<Timer />}
            variant="contained"
          >
            {t('save2')}
          </Button>
        </Box>
      </PopoverDrawer>
    </>
  );
}
