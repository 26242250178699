import { TextField, Grid } from '@material-ui/core';

import { useEntity } from 'contexts/entities/entityContext';
import { Field, useForm } from 'frmx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ScopeForm() {
  const { translations, hiddenFilters } = useEntity();
  const { t } = useTranslation();

  const { setOneField } = useForm();

  useEffect(() => {
    const _parent =
      hiddenFilters?.parents && hiddenFilters?.parents[0] ? hiddenFilters?.parents[0] : null;

    if (_parent) setOneField('_parent', _parent);
  }, []);

  return (
    <>
      <Grid
        item
        md={12}
      >
        <Field path="name">
          <TextField
            fullWidth
            label={t(translations.entityName)}
            variant="outlined"
          />
        </Field>
      </Grid>
    </>
  );
}
