import useClipboard from 'hooks/useClipboard';
import { FileCopy, Phone } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import formatPhoneNumber from 'utils/formatPhoneNumber';

export default function CopyablePhoneNumber({ phone }) {
  const call = () => window.open(`tel: ${phone}`, '_self');
  const { copy, copied } = useClipboard();
  const { t } = useTranslation();

  return (
    <div className="flex justify-center bg-blue gap-x-3">
      {formatPhoneNumber(phone)}
      <button onClick={() => call()}>
        <Phone style={{ fontSize: 13, width: 13, height: 13 }} />
      </button>
      <Tooltip title={t(copied ? 'copied-to-clipboard' : 'copy-to-clipboard')}>
        <button onClick={() => copy(phone)}>
          <FileCopy style={{ fontSize: 13, width: 13, height: 13 }} />
        </button>
      </Tooltip>
    </div>
  );
}
