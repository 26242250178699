import { isArray, mergeWith } from 'lodash-es';

export function oldMergeWithArrays(prev = {}, ...next) {
  return mergeWith(prev, ...next, (objValue, srcValue) => {
    if (isArray(objValue)) {
      try {
        if (Array.isArray(srcValue) && srcValue[0]?._id) {
          return srcValue.map((el) => oldMergeWithArrays(objValue.find((item) => item._id === el._id) || {}, el));
        }
      } catch (error) {
        console.error(error);
      }

      return srcValue;
    }
  });
}
