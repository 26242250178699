import lighten_hex_color, { get_hex_color_luminance } from 'utils/colors';
import {
  EVENT_BORDER_WIDTH,
  HEX_BACKGROUND_TRANSPARENCY,
  INTERVENTION_TYPE,
  PREVIEW_TYPE,
  UNAVAILABILITY_TYPE
} from 'modules/calendar/config';
import { differenceInMinutes, format } from 'date-fns';
import { useMemo, createElement, useEffect } from 'react';
import { useUI } from 'hooks/useUI';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { ItemTypes } from 'constants/dnd';
import empty_base_64_image from 'utils/empty_base_64_image';
import { bubbleUpCells } from 'modules/calendar/utils';
import { useIsBelowLg } from 'hooks/useMQ';

export default function Event({
  start,
  end,
  real_start,
  real_end,
  title,
  description,
  number,
  component = 'li',
  done,
  color = '#d4d4d4',
  date_type,
  type,
  top,
  left,
  width,
  height,
  zIndex,
  ticket_id,
  unavailability_id,
  set_ticket_in_modal,
  set_unavailability_id_to_edit,
  _id: event_id
}) {
  const start_date = new Date(start);
  const color_luminance = get_hex_color_luminance(color);
  const text_color = lighten_hex_color(color, color_luminance < 0.5 ? 0.8 : -0.4);
  const background_color = lighten_hex_color(color, color_luminance < 0.5 ? -0.2 : 0.2);
  const ui = useUI();
  const locale = ui.get_date_time_locale();
  const isBelowLg = useIsBelowLg()

  const children = useMemo(() => {
    return (
      <>
        <p style={{ color: text_color }}>
          <time
            className="text-xs"
            dateTime={start_date.toISOString()}
          >
            {format(start_date, 'p', { locale })} - #{number}
          </time>
        </p>
        <p
          style={{ color: text_color }}
          className="mt-1 font-semibold"
        >
          {title}
        </p>
        <p
          style={{ color: text_color }}
          className="mt-1 font-light"
        >
          {description}
        </p>
      </>
    );
  }, [color, start_date, title, description]);

  const [{ isDragging, item }, drag, preview] = useDrag(() => ({
    type: ItemTypes.CALENDAR_EVENT,
    item: {
      ticket_id,
      unavailability_id,
      event_id,
      title,
      done,
      type,
      description,
      color,
      date_type,
      duration_in_minutes: differenceInMinutes(real_end, real_start)
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem()
    })
  }));

  useEffect(() => {
    const event_is_being_dragged = item?.event_id === event_id && isDragging;
    if (event_is_being_dragged) {
      bubbleUpCells();
    }
  }, [isDragging, item]);

  return (
    <>
      <DragPreviewImage
        connect={preview}
        src={empty_base_64_image}
      />
      {createElement(
        component,
        {
          ref: isBelowLg ? null : drag,
          id: event_id,
          onClick: () =>
            type === UNAVAILABILITY_TYPE
              ? set_unavailability_id_to_edit(unavailability_id)
              : set_ticket_in_modal(ticket_id),
          className: 'p-2 rounded-md hover:cursor-pointer overflow-hidden select-none',
          style: {
            position: 'absolute',
            ...(done ? { borderLeftColor: '#22c55e', borderLeftWidth: EVENT_BORDER_WIDTH } : {}),
            ...(type === UNAVAILABILITY_TYPE
              ? {
                  border: `1px solid ${lighten_hex_color(color, -0.1)}`,
                  background: `repeating-linear-gradient(
          45deg,
          ${color + HEX_BACKGROUND_TRANSPARENCY},
          ${color + HEX_BACKGROUND_TRANSPARENCY} 5px,
          ${lighten_hex_color(color, -0.1) + HEX_BACKGROUND_TRANSPARENCY} 5px,
          ${lighten_hex_color(color, -0.1) + HEX_BACKGROUND_TRANSPARENCY} 10px
        )`
                }
              : type === INTERVENTION_TYPE || type === PREVIEW_TYPE
                ? {
                    backgroundColor: background_color + HEX_BACKGROUND_TRANSPARENCY
                  }
                : {}),
            top,
            left,
            width,
            height,
            zIndex
          }
        },
        children
      )}
    </>
  );
}
