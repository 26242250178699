import { Button, Box, Fab, List, Paper } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import { Empty } from 'layouts/entities/List';
import { useState } from 'react';

import useKeys from '@flowsn4ke/usekeys';
import { useAuth } from 'hooks/useAuth';
import SelectReportTemplateDialog from './SelectReportTemplateDialog';
import UploadReportDialog from './UploadReportDialog';
import ReportEditor from './ReportEditor';
import { useRole } from 'hooks/useRole';
import ReportItem from './ReportItem';

import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useEffect } from 'react';

import { readInterventionReports } from 'store/ticketsSlice';
import { isArray } from 'lodash-es';
import BlurredProgress from 'components/BlurredProgress';
import useNotifications from 'hooks/useNotifications';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { useEntity } from 'contexts/entities/entityContext';

export default function TicketReportsTab({ element }) {
  const { page } = useEntity();
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedReport, setSelectedReport] = useState();

  const ticket_state_waiting = element?.contract?.state === 'opened';

  const company = useAuth().interface._company;
  const { permission } = useRole();
  const canCreateReports = permission('reports', 'create_report');

  const k = useKeys();

  const { t } = useTranslation();

  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();

  const dispatchCallbacks = {
    onError: () => notify.error()
  };

  useEffect(
    () =>
      dispatch(readInterventionReports, undefined, dispatchCallbacks, {
        id: element._id
      }),
    []
  );

  return (
    <>
      <SelectReportTemplateDialog
        selectIsOpen={selectIsOpen}
        setSelectIsOpen={setSelectIsOpen}
        setUploadIsOpen={setUploadIsOpen}
        company={company}
        ticket={element}
        setSelectedTemplate={setSelectedTemplate}
        setFormIsOpen={setFormIsOpen}
      />
      <UploadReportDialog
        client_id={element.contract._client._id}
        ticket={element}
        open={uploadIsOpen}
        onClose={() => setUploadIsOpen(false)}
        ticketNumber={element.number}
        ticketId={element._id}
      />
      <ReportEditor
        ticket={element}
        formIsOpen={formIsOpen}
        report={selectedReport}
        template={selectedTemplate}
        onClose={() => [setFormIsOpen(false), setSelectedTemplate(null), setSelectedReport(null)]}
      />
      {(() => {
        if (requestStatus === 'loading') {
          return (
            <BlurredProgress
              in
              border
              backgroundColor="#ffffff"
            >
              <FAIcon
                collection="fas"
                icon="spinner"
                className="fa-spin"
                style={{ marginRight: '.5em' }}
              />
              {t('loadingReportsPleaseWait')}
            </BlurredProgress>
          );
        } else if (requestStatus === 'error') {
          return <Box>{t('somethingWentWrongSorry')}</Box>;
        } else if (isArray(element.reports) && !!element.reports?.length && requestStatus === 'success') {
          return (
            <Box
              style={{
                minHeight: '100%',
                position: 'relative',
                background: 'white',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box
                style={{
                  flex: 1,
                  overflowY: 'auto',
                  paddingBottom: '80px' // Adding a extra padding so that the icons do not overlap with the + bttn ;P
                }}
              >
                <List style={{ paddingTop: 0 }}>
                  {sortReports(element.reports).map((report, i) => (
                    <Paper
                      key={k(i)}
                      elevation={1}
                      style={{
                        padding: '12px 1em',
                        margin: '.8em 0',
                        position: 'relative'
                      }}
                    >
                      <ReportItem
                        ticket={element}
                        deletable={element.companyCreator._id === company._id || report._owner === company._id}
                        key={k(i)}
                        report={report}
                        company={company}
                        setFormIsOpen={setFormIsOpen}
                        setSelectedTemplate={setSelectedTemplate}
                        setSelectedReport={setSelectedReport}
                      />
                    </Paper>
                  ))}
                </List>
              </Box>
              {canCreateReports && (
                <Fab
                  onClick={() => setSelectIsOpen((prev) => !prev)}
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    margin: 0,
                    position: 'absolute',
                    right: 16,
                    bottom: 16,
                    zIndex: 999
                  }}
                  disabled={ticket_state_waiting}
                >
                  <FAIcon
                    collection="fal"
                    icon="plus"
                    size="medium"
                    className="text-white"
                  />
                </Fab>
              )}
            </Box>
          );
        } else {
          return (
            <Empty
              icon="file-chart-pie"
              translations={{
                noResultLabel: t('reportsTitle'),
                noResultText: t('createOrSeeReports')
              }}
              cta={
                canCreateReports && (
                  <Button
                    variant="outlined"
                    onClick={() => setSelectIsOpen((prev) => !prev)}
                    endIcon={<AddIcon />}
                    disabled={ticket_state_waiting}
                  >
                    {t('selectReport')}
                  </Button>
                )
              }
            />
          );
        }
      })()}
    </>
  );
}

const sortReports = (n) => [...n].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
