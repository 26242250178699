import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { CloudDownload, Print, Close, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import useNotifications from 'hooks/useNotifications';
import Avatar from 'components/Avatar';
import useStyles from './FileViewer.styles';

import { dateToLocalFormat } from 'utils/dates';
import { useEvent } from 'react-events-utils';
import axios from 'axios';
import { apiBaseURL } from 'index';
import { useMemo, createElement } from 'react';
import Tooltip from 'components/ToolTip';
import { useIsBelowMd } from 'hooks/useMQ';
import { useTranslation } from 'react-i18next';

export default function FileViewer({ actions, onClose, openIndex, setOpenIndex, files }) {
  const { t } = useTranslation();
  const can_swipe_left = files?.length > 1 && openIndex > 0;
  const can_swipe_right = files?.length > 1 && openIndex < files?.length - 1;

  useEvent(window, 'keydown', (event) => {
    if (event.key === 'Escape') onClose();
    else if (event.key === 'ArrowLeft' && can_swipe_left) setOpenIndex(openIndex - 1);
    else if (event.key === 'ArrowRight' && can_swipe_right) setOpenIndex(openIndex + 1);
  });

  const printImage = (file) => {
    const win = window.open(file, '_blank', 'fullscreen=yes');
    win.document.write(
      `<iframe src="${file}" frameborder="0" style="width: 100vw; height: 100vh;" onLoad="window.print();window.close();"></iframe>`
    );
    win.focus();
  };

  const printPDF = (file) => {
    const win = window.open(file, '_blank', 'fullscreen=yes');
    win.document.write(
      `<embed src="${file}" type="application/pdf" style="width: 100vw; height: 100vh;" onLoad="window.print();"></embed>`
    );
    win.focus();
  };

  const classes = useStyles();
  const notify = useNotifications();
  const isBelowMd = useIsBelowMd();
  const file = useMemo(() => files[openIndex] || {}, [files, openIndex]);

  const mimetype = file?.mimetype || (file?.url?.split('.').at(-1) === 'pdf' ? 'application/pdf' : null);
  const is_image = mimetype?.includes('image');
  const is_video = mimetype?.includes('video');
  const is_pdf = mimetype?.includes('pdf');

  if (!mimetype) {
    // Handle the case where mimetype is not defined
    console.error("Mimetype is not defined for the file. The file viewer won't open");
    //Show an error popup
    notify.error();
    onClose();
  }

  const downloadFile = async (file) => {
    const document_id = file?._id;

    return await axios
      .get(`${apiBaseURL}/ged/download/${document_id}`, {
        responseType: 'blob'
      })
      .then((res) => {
        const file_name = res.headers['content-disposition'].match(/filename="(.+)"/)[1];
        const file_blob = res.data;

        const url = window.URL.createObjectURL(new Blob([file_blob]));
        const link = document.createElement('a');

        link.setAttribute('href', url);
        link.setAttribute('download', file_name);

        document.body.appendChild(link);

        link.click();
        link.remove();
      })
      .catch((err) => {
        notify.error();
        throw new Error(err);
      });
  };

  const prevent = (e) => [e?.preventDefault(), e?.stopPropagation()];

  return (
    <Dialog
      classes={{
        paper: classes.fileViewer
      }}
      open={!!files?.length}
      transitionDuration={0}
      onClose={onClose}
      fullScreen={true}
    >
      <DialogContent className="p-0">
        {/* Infos fichier + Téléchargement / Impression */}
        {!!file && (
          <div className="flex justify-between items-center px-2.5 py-1 gap-0.5 bg-black/40 rounded-full absolute bottom-5 left-7 md:right-7 md:left-auto z-20">
            {file._user && (
              <Tooltip
                position="left"
                title={
                  <ul className="p-0 m-0 list-none">
                    <li>{file.value}</li>
                    {file._user && (
                      <li>
                        {file._user.firstName} {file._user.lastName}
                      </li>
                    )}
                    {file.date && <li>Le {dateToLocalFormat(file.date, 'PPPp')}</li>}
                  </ul>
                }
              >
                <IconButton className="text-white bg-gray-800 rounded-full">
                  <Avatar
                    isUser
                    round
                    entity={{ name: file._user }}
                    white
                  />
                </IconButton>
              </Tooltip>
            )}

            <div
              className="p-2.5 rounded-full cursor-pointer z-20 hover:bg-black/60 transition"
              onClick={() => downloadFile(file)}
            >
              <CloudDownload className="text-white" />
            </div>

            <div
              className="p-2.5 rounded-full cursor-pointer z-20 hover:bg-black/60 transition"
              onClick={() => (is_pdf ? printPDF(file.url) : printImage(file.url))}
            >
              <Print className="text-white" />
            </div>
          </div>
        )}

        {/* Bouton fermer */}
        <div
          onClick={onClose}
          className="flex justify-between items-center p-2.5 bg-black/50 rounded-full absolute top-6 right-7 hover:bg-black/60 cursor-pointer z-20 transition"
        >
          <Close className="text-white" />
        </div>

        {/* Boutons navigation gauche */}
        {can_swipe_left && (
          <div
            onClick={() => setOpenIndex(openIndex - 1)}
            className="absolute top-1/2 -left-[3px] md:left-2 transform -translate-y-1/2 rounded-full p-4 z-20"
          >
            <div className=" bg-black/50 p-2.5 rounded-full cursor-pointer hover:bg-black/60 transition">
              <KeyboardArrowLeft className="text-white" />
            </div>
          </div>
        )}

        {/* Contenu du fichier */}
        <div className="h-full w-full flex justify-center items-center rounded-xl pb-1">
          {is_pdf && !isBelowMd && (
            <iframe
              src={file.url + '#toolbar=0&navpanes=0&page=1'}
              className="h-full w-[90%] rounded-xl"
              title={file.name}
            />
          )}

          {is_pdf && isBelowMd && (
            <a
              href={file.url}
              rel="noreferrer"
              className="relative block"
            >
              <img
                src={file.url}
                className="h-full w-full rounded-xl"
              />
              <div className="absolute -top-[35px] w-full text-center p-2 rounded-lg text-white text-sm font-bold">
                📄 {t('clickToOpenPDF')}
              </div>
            </a>
          )}

          {Boolean(file?.views?.length) &&
            file.views.map((view, id) => (
              <iframe
                key={id}
                src={view.url + '#toolbar=0&navpanes=0&page=1'}
                className="h-screen w-full rounded-xl"
                title={file.name}
              />
            ))}

          {is_image && !Boolean(file?.views?.length) && (
            <img
              onClick={prevent}
              className="max-h-full max-w-full object-contain rounded-xl"
              src={file.url}
              alt={file.value}
            />
          )}

          {is_video && (
            <video
              key={file.url}
              controls
              autoPlay
              className="max-h-[90%] max-w-[90%] min-w-[280px] min-h-[200px] object-contain rounded-xl z-10"
            >
              <source
                src={file.url}
                // type={mimetype}
              />
              Ton navigateur ne supporte pas la lecture de cette vidéo.
            </video>
          )}
        </div>

        {/* Actions */}
        {!!actions && <div className="absolute z-20 bottom-6 md:bottom-24 right-4 md:right-7">{createElement(actions)}</div>}

        {/* Boutons navigation droite */}
        {can_swipe_right && (
          <div
            onClick={() => setOpenIndex(openIndex + 1)}
            className="top-1/2 -right-[3px]  md:right-2 transform -translate-y-1/2 absolute rounded-full p-4 z-20"
          >
            <div className="bg-black/50 p-2.5 rounded-full cursor-pointer hover:bg-black/60 transition">
              <KeyboardArrowRight className="w-10 h-10 text-white" />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
