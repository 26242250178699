import LinkifiedText from 'components/LinkifiedText';
import useClipboard from 'hooks/useClipboard';
import { FileCopy } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function CopyableLink({ link }) {
  const { copy, copied } = useClipboard();
  const { t } = useTranslation();

  return (
    <div className="flex justify-center gap-x-2">
      <LinkifiedText>{link}</LinkifiedText>
      <Tooltip title={t(copied ? 'copied-to-clipboard' : 'copy-to-clipboard')}>
        <button onClick={() => copy(link)}>
          <FileCopy style={{ fontSize: 13, width: 13, height: 13 }} />
        </button>
      </Tooltip>
    </div>
  );
}
