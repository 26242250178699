import { Fab } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import { Form, Submit, useField } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useIsBelowMd } from 'hooks/useMQ';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

export default function EntityTabPicker({
  Entity,
  defaultFilters = {},
  initialValues = {},
  fieldPath = '',
  elementId,
  action,
  formatAfterSubmit = (v) => ({
    [fieldPath]: (v[fieldPath] || []).map((el) => (el?._id ? el._id : el))
  }),
  ...rest
}) {
  const { dispatch, requestStatus } = useAsyncDispatch();

  const notify = useNotifications();
  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  return (
    <Form
      style={{ height: '100%' }}
      disabled={requestStatus === 'loading'}
      initialValues={initialValues}
      onSubmit={(v) => {
        dispatch(action, formatAfterSubmit(v), dispatchCallbacks, { id: elementId });
      }}
    >
      <FormContent
        Entity={Entity}
        defaultFilters={defaultFilters}
        disabled={requestStatus === 'loading'}
        fieldPath={fieldPath}
        {...rest}
      />
    </Form>
  );
}

function FormContent({ Entity, defaultFilters, disabled, fieldPath, ...rest }) {
  const fldx = useField(fieldPath);
  const { t } = useTranslation();
  const isBelowMd = useIsBelowMd();

  return (
    <>
      <Entity
        fldx={fldx}
        disabled={disabled}
        picker
        disableGutters
        disableShadow
        // disableCreate
        // We use defaultFilters instead of hiddenFilters because the latter triggers rerenders
        defaultFilters={defaultFilters}
        fieldPath={fieldPath}
        {...rest}
      />
      <Submit>
        <Fab
          style={{
            margin: 0,
            position: 'absolute',
            right: 16,
            bottom: 16,
            zIndex: 999
          }}
          color="primary"
          variant={isBelowMd ? 'contained' : 'extended'}
          size={isBelowMd ? 'small' : 'medium'}
        >
          <span className='hidden md:inline'>{t('save')}</span>
          <FAIcon
            collection="fal"
            icon="floppy-disk"
            className="text-white md:ml-2"
            size='medium'
          />
        </Fab>
      </Submit>
    </>
  );
}
