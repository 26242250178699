import { memo, useState, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import { ReactComponent as FullTrash } from '../../assets/icons/full_trash.svg';
import { ReactComponent as EmptyTrash } from '../../assets/icons/empty_trash.svg';

export default memo(function DMSTrash({
  trashId,
  setViewTrash,
  trash_is_empty,
  deleteDocuments,
  isSelected,
  setSelectedDocuments
}) {
  const [isDropTarget, setIsDropTarget] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div
        className="flex flex-col items-center text-center m-2 cursor-pointer hover:bg-opacity-50 hover:bg-white p-1 rounded-lg document-iem hidden sm:flex"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setSelectedDocuments(isSelected ? [] : [{ _id: trashId }]);
        }}
        onContextMenu={(event) => event.preventDefault()}
        onDoubleClick={() => setViewTrash(true)}
        onDragLeave={(event) => {
          setIsDropTarget(false);
        }}
        onDragEnter={(event) => {
          setIsDropTarget(false);
        }}
        onDragOver={(event) => {
          event.preventDefault(); // this is in order to allow dropping elements
          setIsDropTarget(true);
        }}
        onDrop={(event) => {
          event.preventDefault();
          setIsDropTarget(false);
          const document_ids = event.dataTransfer.getData('_ids').split(',');
          deleteDocuments(document_ids);
        }}
      >
        <div
          className={classNames('w-[88px] h-[88px] flex flex-col items-center text-black text-xl font-black rounded')}
        >
          {createElement(trash_is_empty ? EmptyTrash : FullTrash, {
            draggable: 'false',
            className: classNames(
              'pointer-events-none h-16 relative top-2.5',
              isDropTarget || isSelected ? 'text-red-500' : 'text-slate-500'
            ),
            style: { height: 64 }
          })}
        </div>
        <p
          className={classNames(
            'selection:bg-transparent w-16 break-words text-xs rounded px-1 py-0.5 mt-0.5',
            isDropTarget || isSelected ? 'bg-red-500 text-white' : ''
          )}
        >
          {t('trash')}
        </p>
      </div>
    </>
  );
});
