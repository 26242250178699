import { assign } from '@recursive/assign';
import { mergeWith, pick, isArray } from 'lodash-es';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { oldMergeWithArrays } from './mergeWithArrays';

export const resetPassword = generateAsyncThunk({ type: 'POST', endpoint: 'auth/forgot' });

export const filterElementFromLists = (state, id) => {
  state.lists = Object.keys(state.lists).reduce((a, b) => {
    const elements = state.lists[b].filter((e) => e._id !== id);
    elements.count--;

    return {
      ...a,
      [b]: elements
    };
  }, {});
};

export function generateEntityBoilerplate({
  extraInitialStateState = {},
  createElement,
  updateElement,
  getElement,
  getElements,
  getPublicElements,
  deleteElement,
  restoreElement,
  deleteElementNext,
  createElementNext,
  updateElementNext,
  getElementNext,
  getElementsPrev,
  formatElement = (el) => el
}) {
  const initialState = { lists: {}, db: {}, ...extraInitialStateState };

  const reducers = {
    flushElements: (state, action) => {
      delete state.lists[action.payload.listId];
    },
    flushElement: (state, action) => {
      filterElementFromLists(state, action.payload.id);
    }
  };
  const extraReducers = {
    ...(!getElement
      ? {}
      : {
          [getElement.fulfilled]: (state, action) => {
            const { element } = action.payload.data;
            state.db[element._id] = {
              ...(state.db[element._id] || {}),
              ...(formatElement ? formatElement(element) : element)
            };

            if (getElementNext) {
              getElementNext(state, action);
            }
          }
        }),

    ...(!getElements
      ? {}
      : {
          [getElements.fulfilled]: (state, action) => {
            const { listId, start } = action.meta;
            let { elements } = action.payload.data;
            const { count } = action.payload.data;

            if (getElementsPrev) {
              getElementsPrev(elements);
            }

            elements.forEach((element) => {
              const prev = state.db[element._id] || {};
              const next = formatElement(element);

              state.db[element._id] = assign(prev, next);
            });

            elements = elements.map((element) => pick(element, ['_id']));

            state.lists[listId] = !state.lists[listId] || start ? elements : state.lists[listId].concat(elements);
            state.lists[listId].count = count;
          }
        }),

    ...(!getPublicElements
      ? {}
      : {
          [getPublicElements.fulfilled]: (state, action) => {
            const { listId, start } = action.meta;
            let { elements } = action.payload.data;
            const { count } = action.payload.data;

            elements.forEach((element) => {
              const a = state.db[element._id] || {};
              const b = formatElement ? formatElement(element) : element;
              state.db[element._id] = mergeWith(a, b, (objValue, srcValue) => {
                if (srcValue === false && !!objValue) return objValue;

                if (isArray(objValue)) return srcValue;
              });
            });

            elements = elements.map((element) => pick(element, ['_id']));

            state.lists[listId] = !state.lists[listId] || start ? elements : state.lists[listId].concat(elements);
            state.lists[listId].count = count;
          }
        }),

    ...(!deleteElement
      ? {}
      : {
          [deleteElement.fulfilled]: (state, action) => {
            const id = action.meta.arg.params.id;

            filterElementFromLists(state, id);

            if (deleteElementNext) {
              deleteElementNext(state, action);
            }
          }
        }),

    ...(!restoreElement
      ? {}
      : {
          [restoreElement.fulfilled]: (state, action) => {
            const id = action.meta.arg.params.id;

            state.db[id].deleted_at = null;
            state.db[id].deleted_by = null;

            filterElementFromLists(state, id);
          }
        }),

    ...(!createElement
      ? {}
      : {
          [createElement.fulfilled]: (state, action) => {
            const { listId } = action.meta.arg.params;
            const { element } = action.payload.data;

            state.db[element._id] = formatElement ? formatElement(element) : element;

            if (listId && state.lists[listId]) {
              state.lists[listId] = [pick(element, ['_id']), ...state.lists[listId]];
              state.lists[listId].count += 1;
            }

            if (createElementNext) {
              createElementNext(state, action);
            }
          }
        }),

    ...(!updateElement
      ? {}
      : {
          [updateElement.fulfilled]: (state, action) => {
            const { id } = action.meta.arg.params;
            const { params, ...updates } = action.meta.arg;
            const { formIds } = action.meta;
            const { element } = action.payload.data;

            state.db[id] = oldMergeWithArrays(state.db[id], updates, formIds, element);

            if (updateElementNext) updateElementNext(state, action);
          }
        }),
    [resetPassword.fulfilled]: (state, action) => {
      if (state.db[action.meta.id]) {
        if (state.db[action.meta.id]._user) {
          state.db[action.meta.id]._user.resetPasswordToken = action.payload.data.token;
        } else {
          state.db[action.meta.id].resetPasswordToken = action.payload.data.token;
        }
      }
    }
  };

  return {
    initialState,
    reducers,
    extraReducers
  };
}
