import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Virtuoso } from 'react-virtuoso';
import GroupedVirtuosoNew from './GroupedVirtuosoNew';
import TicketPreviewNew from './TicketPreviewNew';
import { LinearProgress } from '@material-ui/core';
import { useEffect, useRef } from 'react';
export default function PreviewRenderer({
  entity,
  data,
  endReached,
  hasMore,
  appliedFilters,
  activeTicket,
  setActiveTicket,
  page
}) {
  const location = useLocation();
  const ticketPaths = ['calendar'];
  const isTicketUrl = entity === 'tickets' && ticketPaths.some((path) => !location.pathname.includes(path));
  const groupedVirtuosoRef = useRef(null);
  const virtuosoRef = useRef(null);
  // scroll all list to top if appliedFilters change
  useEffect(() => {
    if (groupedVirtuosoRef.current) {
      groupedVirtuosoRef.current.scrollToIndex({
        index: 0,
        align: 'start',
        behavior: 'auto'
      });
    }
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex({
        index: 0,
        align: 'start',
        behavior: 'auto'
      });
    }
  }, [appliedFilters]);
  if (isTicketUrl)
    return (
      <GroupedVirtuosoNew
        appliedFilters={appliedFilters}
        groupRef={groupedVirtuosoRef}
        data={data}
        endReached={endReached}
        Footer={Footer}
        hasMore={hasMore}
        activeTicket={activeTicket}
        setActiveTicket={setActiveTicket}
        page={page}
      />
    );
  return (
    <Virtuoso
      ref={virtuosoRef}
      data={data}
      itemContent={(_, element) => (
        <TicketPreviewNew
          ticket={element}
          activeTicket={activeTicket}
          setActiveTicket={setActiveTicket}
          page={page}
        />
      )}
      endReached={endReached}
      components={{ Footer: () => <Footer hasMore={hasMore} /> }}
    />
  );
}
const Footer = ({ hasMore }) => {
  if (!hasMore) return null;
  return (
    <div className="p-2 flex items-center justify-center">
      <div className="w-[70%]">
        <LinearProgress color="secondary" />
      </div>
    </div>
  );
};
