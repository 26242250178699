import { useField, useFieldObserver, useForm } from 'frmx';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { getColor, statesClosed, statesOngoing } from 'modules/tickets/ticket-list/utils';

export default function SectionPicker({ path, label, color = 'green', isLock }) {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const isChecked = useFieldObserver(path);
  const { setValue: setIsChecked } = useField(path);
  const { activeIconColor, inactiveIconColor, activeTextColor, inactiveBorderColor, activeBorderColor } =
    getColor(color);

  const { setValue: setStatus } = useField('status');
  const status = useFieldObserver('status');
  const ongoing = useFieldObserver('ongoing');
  const closed = useFieldObserver('closed');

  const updateStatus = (status, ongoing, closed) => {
    if (!status) return [];
    if (ongoing && closed) return [...new Set([...status, ...statesOngoing, ...statesClosed])];
    if (ongoing) return [...new Set([...status.filter((s) => !statesClosed.includes(s)), ...statesOngoing])];
    if (closed) return [...new Set([...status.filter((s) => !statesOngoing.includes(s)), ...statesClosed])];
    return status.filter((s) => !statesOngoing.includes(s) && !statesClosed.includes(s));
  };

  const onCheck = () => {
    const newCheckValue = !isChecked;
    const newOngoing = path === 'ongoing' ? newCheckValue : ongoing;
    const newClosed = path === 'closed' ? newCheckValue : closed;
    setStatus(() => updateStatus(status, newOngoing, newClosed));
    setIsChecked(newCheckValue);
    handleSubmit();
  };

  return (
    <button
      disabled={isLock}
      onClick={onCheck}
      className={`border ${isChecked ? activeBorderColor : inactiveBorderColor}  
      ${isChecked ? activeTextColor : ''} inline-flex items-center pl-2 pr-3 h-8 rounded-full text-xs font-medium ${
        isLock
          ? 'text-gray-300 bg-gray-50 cursor-not-allowed'
          : 'cursor-pointer text-gray-500 hover:bg-gray-50 transition'
      } `}
    >
      <FAIcon
        collection={isChecked ? 'fas' : 'fal'}
        icon={isChecked ? 'circle-check' : 'circle'}
        size="small"
        className={`mr-1 ${isChecked ? activeIconColor : inactiveIconColor}`}
      />
      <span className="whitespace-nowrap">{t(label)}</span>
    </button>
  );
}
