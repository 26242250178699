import { Box, CardContent, Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import axios from 'axios';
import BlurredProgress from 'components/BlurredProgress';
import { apiBaseURL } from 'index';
import { useEffect, useState, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsBelowUp } from 'hooks/useMQ';
import Cacher from 'utils/Cacher';
import { useAuth } from 'hooks/useAuth';

const periodsMenu = [
  {
    period: 7,
    unit: 'days'
  },
  {
    period: 1,
    unit: 'months'
  },
  {
    period: 3,
    unit: 'months'
  },
  {
    period: 6,
    unit: 'months'
  },
  {
    period: 12,
    unit: 'months'
  }
];

export default function StatCard({
  noRangePicker,
  title,
  chart,
  column1Name,
  column2Name,
  endpoint,
  formatYAxis = (n) => n,
  formatXAxis = (n) => n,
  formatDatasetsLabels = (l) => l,
  formatCount = (c) => c,
  formatDatasets = (datasets) => ({ datasets }),
  style = {},
  scroll = {},
  is_overriden_tooltip_label,
  format_tooltip_labels
}) {
  const [status, setStatus] = useState();
  const [labels, setLabels] = useState();
  const [tooltipLabels, setTooltipLabels] = useState();
  const [datasets, setDatasets] = useState();
  const [datasetsLabels, setDatasetsLabels] = useState();
  const [colors, setColors] = useState([]);
  const [notEnoughData, setNotEnoughData] = useState(false);

  const { t } = useTranslation();

  const [anchorEL, setAnchorEl] = useState(null);
  const openPeriodsMenu = (e) => [
    e.preventDefault(),
    e.stopPropagation(),
    setAnchorEl(e.currentTarget)
  ];
  const closePeriodsMenu = (e) => [e.preventDefault(), e.stopPropagation(), setAnchorEl(null)];
  const [period, setPeriod] = useState(periodsMenu[1].period);
  const [unit, setUnit] = useState(periodsMenu[1].unit);
  const [isLoading, setIsLoading] = useState(false);
  const isBelowXxl = useIsBelowUp(1690);

  const auth = useAuth();
  const locations = auth.interface?._locations || [];

  useEffect(() => {
    const url = `${apiBaseURL}/${endpoint}` + (noRangePicker ? '' : `?last=${period}&unit=${unit}`);
    const cached_data = Cacher.get_cached_data({ key: url });

    if (cached_data) {
      deal_with_data(cached_data);
      setStatus(200);
    } else {
      setIsLoading(true);
      axios
        .post(url, { locations })
        .then((res) => {
          Cacher.cache_data({ key: url, data: res.data.data });
          deal_with_data(res.data.data);
          setStatus(res.status);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [period]);

  const deal_with_data = (data) => {
    if (!data?.length && !Object.keys(data).length) {
      setNotEnoughData(true);
    } else {
      const { datasets, datasetsLabels = [], labels, colors, tooltipLabels } = formatDatasets(data);
      tooltipLabels && setTooltipLabels(tooltipLabels);
      datasets && setDatasets(datasets);
      labels && setLabels(labels);
      datasetsLabels && setDatasetsLabels(datasetsLabels.map((l) => formatDatasetsLabels(l)));
      Array.isArray(colors) && colors.length && setColors(colors);
      setNotEnoughData(false);
    }
  };

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={isBelowXxl ? 4 : 6}
      style={style}
    >
      <Box
        style={{
          border: '1px solid #E4E4E4',
          borderRadius: 8,
          height: 350,
          backgroundColor: '#fff'
        }}
        boxShadow={2}
      >
        <CardContent style={{ height: '100%', position: 'relative' }}>
          {title && (
            <Typography
              variant="h6"
              color="textSecondary"
              gutterBottom
              style={{
                margin: '0',
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                color: '#003366'
              }}
            >
              {title}
            </Typography>
          )}

          {!noRangePicker && (
            <>
              <div style={{ position: 'absolute', top: 5, right: 5 }}>
                <Typography
                  variant="body1"
                  component="span"
                  style={{ color: '#003366', fontSize: '11px' }}
                >
                  {unit === 'months' &&
                    t(period === 1 ? 'thisMonth' : 'lastMonths', { count: period })}
                  {unit === 'days' && t('lastDays', { count: period })}
                </Typography>
                <IconButton
                  aria-haspopup="true"
                  onClick={openPeriodsMenu}
                  style={{ paddingTop: '12px', color: '#003366' }}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </div>

              <Menu
                anchorEl={anchorEL}
                transitionDuration={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={!!anchorEL}
                onClose={closePeriodsMenu}
              >
                {periodsMenu.map((p, key) => (
                  <MenuItem
                    key={key}
                    selected={p.unit === unit && period === p.period}
                    onClick={(e) => [setPeriod(p.period), setUnit(p.unit), closePeriodsMenu(e)]}
                  >
                    {p.unit === 'months' &&
                      t(p.period === 1 ? 'thisMonth' : 'lastMonths', { count: p.period })}
                    {p.unit === 'days' && t('lastDays', { count: p.period })}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}

          <Box
            style={{
              paddingTop: '1em',
              display: 'flex',
              position: 'relative',
              height: '100%',
              ...scroll
            }}
          >
            <BlurredProgress in={isLoading} />
            {status &&
              (status !== 200 ? (
                <p>{t('anErrorHasOccurredPleaseTryAgainLater')}</p>
              ) : notEnoughData ? (
                <p>{t('youDoNotHaveEnoughDataToDisplayThisTable')}</p>
              ) : (
                createElement(chart, {
                  formatYAxis,
                  formatXAxis,
                  datasets,
                  tooltipLabels,
                  labels,
                  colors,
                  column1Name,
                  column2Name,
                  datasetsLabels,
                  formatCount,
                  is_overriden_tooltip_label,
                  format_tooltip_labels
                })
              ))}
          </Box>
        </CardContent>
      </Box>
    </Grid>
  );
}
