import { Box, Menu, IconButton, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import {
  MoreVert as MoreVertIcon,
  OpenInNew as OpenInNewIcon,
  Delete as DeleteIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
  Edit as EditIcon,
  Refresh
} from '@material-ui/icons';

import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useEntity } from 'contexts/entities/entityContext';
import useAsyncDispatch from 'hooks/useAsyncDispatch';

import ConfirmDialog from 'components/dialogs/ConfirmDialog';

import useStyles from './View.styles';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import useKeys from '@flowsn4ke/usekeys';
import ReasonDialog from 'components/dialogs/ReasonDialog';
import { useTranslation } from 'react-i18next';
import useNotifications from 'hooks/useNotifications';
import useFileViewer from 'hooks/useFileViewer';

export default function ViewMenu({
  refresh,
  setIsOpenForm,
  element: _element,
  preview,
  extraMenus,
  originPosition = 'right',
  isLoading,
  onClose,
  className
}) {
  const {
    viewComponent: ViewComponent,
    setSelectedId,
    restoreElement,
    disableDelete,
    getMenus,
    deleteElement,
    listId,
    entity,
    entityMenu,
    element,
    deleteElementPayload = {},
    restoreElementPayload = {}
  } = useEntity();

  const auth = useAuth();

  const element_ = useMemo(() => {
    const e = { ...(_element || element) };

    e._company =
      e._contractor ||
      e._client ||
      (e._clients?.length ? e._clients : null) ||
      (e._contractors?.length ? e._contractors : null) ||
      e.companyCreator;

    e.companyPermission =
      auth.interface.isMaster ||
      e._company?._id === auth.interface._company._id ||
      e._company === auth.interface._company._id ||
      (Array.isArray(e._company) && e._company.some((e) => e === auth.interface._company._id));

    return e;
  }, [_element, element]);

  const role = useRole();
  const history = useHistory();
  const { requestStatus, dispatch } = useAsyncDispatch();
  const [menuIsLoading, setMenuIsLoading] = useState(false);
  const [anchorElMain, setAnchorElMain] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmMenuOpen, setConfirmMenuOpen] = useState(false);
  const [reasonMenuOpen, setReasonMenuOpen] = useState(false);
  const notify = useNotifications();
  const { openFiles } = useFileViewer();

  const menus = useMemo(() => {
    // TODO: Args as object
    return getMenus(
      element_,
      auth,
      dispatch,
      role,
      element_.companyPermission,
      listId,
      setMenuIsLoading,
      notify
    ).filter((menu) => menu.show);
  }, [element, auth]);

  const menusList = useMemo(() => {
    let m = [];

    if (
      ((entity === 'suppliers' && role.permission('stocks', 'update_supplier')) ||
        role.permission(entityMenu || entity, 'update')) &&
      element_.companyPermission
    ) {
      m.push({
        icon: <EditIcon />,
        label: 'edit',
        action: () => setIsOpenForm(element_._id)
      });
    }

    if (!!refresh) {
      m.push({
        icon: <Refresh />,
        label: 'refresh',
        action: () => refresh()
      });
    }

    if (preview && !!ViewComponent) {
      m.push({
        icon: <OpenInNewIcon />,
        label: 'open',
        action: () => setSelectedId(element_._id)
      });
    }

    if (
      (entity === 'guarantees' || entity === 'leases' || entity === 'contracts') &&
      element_?._document
    ) {
      m.push({
        icon: (
          <FAIcon
            collection="fas"
            icon="fa-file-contract"
          />
        ),
        label: 'openAssociateDocument',
        action: () => openFiles([element_?._document])
      });
    }

    m = m.concat(menus.filter((menu) => !preview || menu.preview));

    if (extraMenus) {
      m = m.concat(extraMenus);
    }

    if (
      !disableDelete &&
      ((entity === 'suppliers' && role.permission('stocks', 'delete_supplier')) ||
        role.permission(entityMenu || entity, 'delete')) &&
      element_.companyPermission &&
      !element_?.deleted_at
    ) {
      m.push({
        icon: <DeleteIcon />,
        label: 'delete',
        action: () => setConfirmDeleteOpen(true)
      });
    }

    if (
      ((entity === 'suppliers' && role.permission('stocks', 'restore_supplier')) ||
        role.permission(entityMenu || entity, 'restore')) &&
      element_.companyPermission &&
      (element_?.deleted_by || element_?.deleted_at)
      //  || element_?.flag_canceled
      //  || element_?.flag_declined
    ) {
      m.push({
        icon: <RestoreFromTrashIcon />,
        label: 'restore',
        action: () => dispatch(restoreElement, restoreElementPayload, {}, { id: element_._id })
      });
    }

    return m;
  }, [element, menus]);

  const prevent = (e) => [e.stopPropagation(), e.preventDefault()];

  const classes = useStyles();
  const k = useKeys();

  const loadingButton = isLoading || requestStatus === 'loading' || menuIsLoading;

  const { t } = useTranslation();

  return (
    <Box className={className}>
      <Menu
        transitionDuration={0}
        anchorEl={anchorElMain}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: originPosition
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: originPosition
        }}
        open={!!anchorElMain}
        onMouseDown={prevent}
        onClose={(e) => {
          prevent(e);
          setAnchorElMain(null);
        }}
        keepMounted
      >
        {menusList.map((menu, i) => (
          <MenuItem
            key={k(i)}
            dense
            onMouseDown={prevent}
            onClick={(e) => {
              prevent(e);
              setAnchorElMain(null);
              if (menu.confirm) {
                setConfirmMenuOpen(menu);
              } else if (menu.reason) {
                setReasonMenuOpen(menu);
              } else {
                menu.action();
              }
            }}
          >
            <ListItemIcon>{menu?.icon}</ListItemIcon>
            <ListItemText primary={t(menu.label)} />
          </MenuItem>
        ))}
      </Menu>

      {!!menusList?.length && (
        <IconButton
          style={{ padding: 6 }}
          onMouseDown={prevent}
          onClick={(e) => {
            prevent(e);
            if (!loadingButton) {
              setAnchorElMain(e.currentTarget);
            }
          }}
        >
          {!loadingButton && <MoreVertIcon className={!preview ? classes.menuIcon : ''} />}
          {loadingButton && (
            <FAIcon
              icon="spinner"
              className={['fa-spin', classes.menuLoading]}
              collection="fad"
            />
          )}
        </IconButton>
      )}

      {/* TODO: stopPropagation */}
      <ConfirmDialog
        text={
          <span>
            {t('areYouSureYouWantToDelete')} <strong>{element_.name}</strong> ?
          </span>
        }
        open={confirmDeleteOpen}
        onClose={(e) => [setConfirmDeleteOpen(false), typeof onClose === 'function' && onClose()]}
        onConfirm={() =>
          dispatch(
            deleteElement,
            deleteElementPayload,
            {
              onSuccess: () => {
                history.push(history.location.pathname.replace('/' + element_._id, ''));
              }
            },
            { id: element_._id }
          )
        }
      />

      {!!confirmMenuOpen && (
        <ConfirmDialog
          text={<span>{confirmMenuOpen.confirmMessage}</span>}
          open={!!confirmMenuOpen}
          onClose={(e) => [setConfirmMenuOpen(false)]}
          onConfirm={() => confirmMenuOpen.action()}
        />
      )}

      {!!reasonMenuOpen && (
        <ReasonDialog
          open={!!reasonMenuOpen}
          onClose={(e) => [setReasonMenuOpen(false)]}
          onConfirm={(reason) => reasonMenuOpen.action(reason)}
        />
      )}
    </Box>
  );
}
