import { Box } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import { range, debounce, cloneDeep, isObject, isArray } from 'lodash-es';

import { useState, useMemo, useEffect, useCallback } from 'react';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useDebouncedState from 'hooks/useDebouncedState';
import { useAuth } from 'hooks/useAuth';
import { useDispatch } from 'react-redux';

import VirtuosoTable from './VirtuosoTable';
import FilterBar from './FilterBar';
import SetManager from './SetManager';
import SearchBar from './SearchBar';
import Bookmarks from './Bookmarks';

import { useEntity } from 'contexts/entities/entityContext';
import EntityBreadcrumbs from './EntityBreadcrumbs';

import useStyles from './List.styles';
import useKeys from '@flowsn4ke/usekeys';
import FilterTab from 'components/filters/FilterTab';
import { useTranslation } from 'react-i18next';

import { MAINTENANCE_PAGE_SIZE } from 'constants/maintenances';
import { Form } from 'frmx';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import GroupedVirtuosoList from './GroupedVirtuosoList';
import VirtuosoList from './VirtuosoList';

export default function List({ setIsOpenForm }) {
  const auth = useAuth();
  const {
    filtersSave,
    trash,
    getBookmarks,
    translations,
    scheduler,
    icon,
    elements,
    getElements,
    entity,
    form,
    listComponent: _ListComponent,
    skeletonComponent: SkeletonPreview,
    noFetch,
    listId,
    flushElements,
    sorts,
    disableFilter,
    disableSearch,
    disableTabs,
    filter,
    filters,
    prependList,
    setSublistParent,
    sublistParent,
    sublistParentKey,
    timeline,
    page,
    technicians,
    calendar,
    bookmarks,
    tab,
    list,
    defaultBookmarks,
    defaultFilters,
    hiddenFilters,
    picker,
    formatFilter,
    elementsPicker,
    userMaintenance,
    isListTable,
    refreshFilters
  } = useEntity();

  const location = useLocation();

  const ticketPaths = ['calendar'];
  const isTicketUrl = entity === 'tickets' && ticketPaths.some((path) => !location.pathname.includes(path));

  const _filters = filters.filter((filter) => !filter.hideByDefault && filter.type !== 'Tab');

  const setsKey = userMaintenance ? 'userMaintenance' : entity + '_sets_' + auth.interface._id;

  const [sort, setSort] = useState(sorts[0]);
  const [textSearch, setTextSearch] = useState('');
  const [debouncedTextSearch, setDebouncedTextSearch] = useState(textSearch);
  const [manageFilterBar, setManageFilterBar] = useState(false);

  const [visibleBookmarks, setVisibleBookmarks] = useState([]);
  const [appliedBookmarks, setAppliedBookmarks] = useState(defaultBookmarks || []);
  const [appliedFilters, setAppliedFilters, debouncedAppliedFilters] = useDebouncedState({});
  const [appliedTechnicians, setAppliedTechnicians] = useState([]);

  const [isResetable, setIsResetable] = useState(false);

  const [sets, setSets] = useState([]);
  const [set, setSet] = useState(sets.find((s) => s.active));

  const [filterIndex, setFilterIndex] = useState(null);

  const { t } = useTranslation();

  const query = useQuery();
  const history = useHistory();

  const ticketStates = useMemo(() => {
    switch (query.get('filters')) {
      case 'w84ew':
        return ['opened', 'waiting'];
      case 'b75w45':
        return ['proposed', 'assigned', 'validation', 'validated'];
      case 'p7841aw':
        return ['intervention', 'visit', 'visit_devis', 'intervention_finish'];
      case 'ch785ft':
        return ['finished'];
      default:
        return [];
    }
  }, []);

  const buildFilters = useCallback(
    ({ tab, set, init, states } = {}) => {
      const setValues = set?.values;
      const setSections = set?.values?.section;

      const formattedFilters = filters.reduce((acc, curr) => {
        switch (curr.type) {
          case 'Tab': {
            return {
              ...acc,
              tab: !tab
                ? appliedFilters.tab
                : {
                    ...(acc.tab || {}),
                    [curr.key]: tab ? tab === curr.key || tab === 'all' : curr.default
                  }
            };
          }
          case 'Section': {
            // defaultFilters?.section && defaultFilters?.section[curr.key] && setIsResetable(true)
            return {
              ...acc,
              section: {
                ...(acc.section || {}),
                [curr.key]: setSections
                  ? setSections[curr.key]
                  : init && defaultFilters?.section
                  ? defaultFilters?.section[curr.key] || curr.default
                  : curr.default
              }
            };
          }
          default: {
            // defaultFilters[curr.key] && !curr.hidden && !curr.lock && setIsResetable(true)
            return {
              ...acc,
              [curr.key]: setValues
                ? setValues[curr.key]
                : init
                ? defaultFilters[curr.key] || curr.default
                : curr.lock
                ? defaultFilters[curr.key]
                : curr.default
            };
          }
        }
      }, {});

      return states ? { ...formattedFilters, states } : formattedFilters;
    },
    [filters, tab, appliedFilters.tab, query]
  );

  const newSet = () => ({
    title: sets?.length !== 0 ? t('newSetFilter') + ' ' + sets?.length : t('defaultSetFilter'),
    active: true,
    filters: _filters.map((filter) => filter.key),
    index: false,
    values: {}
  });

  useEffect(() => {
    const setInStorage = localStorage.getItem(setsKey);

    if (!!setInStorage && (page || filtersSave)) {
      setSets(JSON.parse(setInStorage));
    } else {
      const setsObject = [
        {
          title: t('defaultSetFilter'),
          active: true,
          filters: _filters.map((filter) => filter.key),
          index: 0,
          values: buildFilters({ init: true })
        }
      ];

      (page || filtersSave) && localStorage.setItem(setsKey, JSON.stringify(setsObject));
      setSets(setsObject);
    }
  }, [auth.interface._id, auth.interface._locations, page, filtersSave]);

  const initFilters = () => {
    const set_ = set || sets.find((s) => s.active);

    if (set_) {
      setSet(set_);

      if (!query.get('filters')) {
        if (isObject(set_.values) && !!Object.keys(set_.values)?.length && !trash) {
          setAppliedFilters(buildFilters({ tab, set: set_ }));
        } else {
          setAppliedFilters(buildFilters({ tab }));
        }
      }
    } else {
      setAppliedFilters(buildFilters({ init: true, tab, states: ticketStates }));
    }
  };

  const { id: urlId } = useParams();

  useEffect(() => {
    initFilters();
  }, [sets, tab]);

  useEffect(() => {
    if (refreshFilters) {
      initFilters();
    }
  }, [urlId]);

  const filterTabs =
    !appliedFilters || (!page && !form && !scheduler && !filter && !picker) || !appliedFilters.tab
      ? []
      : filters.filter((filter) => !filter.hide && filter.type === 'Tab');

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        if (debouncedTextSearch !== value) setDebouncedTextSearch(value);
      }, 700),
    [debouncedTextSearch]
  );

  const handleTextSearch = (value) => {
    setTextSearch(value);
    debouncedSearch(value);
  };

  const syncDispatch = useDispatch();
  const { dispatch, requestStatus, setRequestStatus } = useAsyncDispatch({ virtualized: true });
  const { dispatch: dispatchBookmarks } = useAsyncDispatch({ virtualized: true });

  useEffect(() => {
    setRequestStatus('loading');
  }, [appliedFilters]);

  const isLoading = requestStatus === 'loading';

  const fetchBookmarks = () => {
    dispatchBookmarks(
      getBookmarks,
      {
        filters: getXhrFilters()
      },
      {
        onSuccess: ({ bookmarks: _bookmarks }) => {
          setVisibleBookmarks(
            Object.keys(_bookmarks).reduce((a, b) => {
              if (_bookmarks[b]) {
                return [...a, b];
              }
              return a;
            }, [])
          );
        }
      },
      {}
    );
  };

  const fetchElements = (_virtuoso, start = false, _startIndex = false, pageSize = null) => {
    if (!pageSize) {
      pageSize = entity === 'periods' ? MAINTENANCE_PAGE_SIZE : 30;
    }

    const startIndex = _startIndex || (start ? 0 : elements?.length);

    const stopIndex =
      start && !_startIndex
        ? pageSize
        : elements.count - startIndex > pageSize
        ? startIndex + pageSize
        : startIndex + (elements.count - startIndex);

    if (stopIndex - startIndex > 0 && getElements) {
      return dispatch(
        getElements,
        {
          start,
          limit: { startIndex, stopIndex },
          ...(!!elementsPicker ? { elementsPicker } : {}),
          filters: {
            ...getXhrFilters(),
            bookmarks: appliedBookmarks,
            ...(technicians?.length ? { interveners: appliedTechnicians } : {})
          },
          search: debouncedTextSearch
        },
        {},
        { listId, start }
      );
    }
  };

  const getXhrFilters = () => {
    let appliedFiltersFormatted = cloneDeep(appliedFilters);

    if (formatFilter) {
      appliedFiltersFormatted = formatFilter(appliedFiltersFormatted, auth);
    }

    Object.keys(appliedFiltersFormatted).forEach((f) => {
      const filter = filters.find((_f) => _f.key === f);
      if (filter && filter.type === 'Entity') {
        appliedFiltersFormatted[f] = isArray(appliedFiltersFormatted[f])
          ? appliedFiltersFormatted[f].map((el) => (isObject(el) ? el._id : el))
          : isObject(appliedFiltersFormatted[f])
          ? (appliedFiltersFormatted[f] = [appliedFiltersFormatted[f]._id])
          : appliedFiltersFormatted[f];
      }

      if (isObject(appliedFiltersFormatted[f])) {
        delete appliedFiltersFormatted[f]['isDirty'];
      }
    });

    return {
      deleted: !!trash,
      sort,
      ...(sublistParent ? { subs: [sublistParent._id] } : {}),
      ...appliedFiltersFormatted,
      ...hiddenFilters
    };
  };

  useEffect(() => () => flushElements && syncDispatch(flushElements({ listId })), []);

  useEffect(() => {
    if (appliedFilters && set && !noFetch) {
      if (query.get('filters')) {
        const pathname = history.location.pathname;
        history.push(pathname.replace('?filters=', '/'));
      }

      if (!!elements && flushElements) {
        syncDispatch(flushElements({ listId }));
      }

      fetchElements(null, true);

      if (getBookmarks && bookmarks) {
        fetchBookmarks();
      }
    }
  }, [
    appliedBookmarks,
    appliedTechnicians,
    calendar ? hiddenFilters : null,
    sublistParent,
    debouncedTextSearch,
    sort,
    debouncedAppliedFilters
  ]);

  const clearFilter = ({ resetForm }) => {
    debouncedSearch('');
    setTextSearch('');
    setIsResetable(false);
    setAppliedFilters(buildFilters());
    resetForm();
  };

  const classes = useStyles({ calendar, timeline, isLoading, isList: !page, picker, page, list });

  const k = useKeys();

  const currentTab = filterTabs.find((tab) => tab.render && appliedFilters.tab[tab.key]);

  const ListComponent = useMemo(() => {
    return _ListComponent || currentTab?.render;
  }, [_ListComponent, currentTab]);

  const filtered_filters = useMemo(() => {
    return filters
      .filter((filter) => !trash || filter.type !== 'Section')
      .filter((filter) => !(filter.type === 'Entity' && filter.key === 'preventifs'))
      .filter((filter) => filter.type !== 'Tab' && !filter.hidden && (set?.filters || []).includes(filter.key))
      .sort((a, b) => {
        const indexA = set.filters.indexOf(a.key);
        const indexB = set.filters.indexOf(b.key);

        return indexA > indexB ? 1 : -1;
      });
  }, [filters, set]);

  const preventifsFilter = filters[filters.findIndex((f) => f.key === 'preventifs' && f.type === 'Entity')];
  const _filter = filterIndex !== null ? (filterIndex === -1 ? preventifsFilter : filtered_filters[filterIndex]) : null;

  return (
    <Box
      boxShadow={page ? 1 : 0}
      className={classes.root}
    >
      {!!filterTabs?.length && appliedFilters.tab && !disableTabs && (
        <FilterTab
          setAppliedFilters={setAppliedFilters}
          appliedFilters={appliedFilters}
          tabs={filterTabs}
        />
      )}

      {(!currentTab || !currentTab.disableSearch) && (
        <Box
          display="flex"
          flexDirection="column-reverse"
          boxShadow={0}
          borderBottom="1px solid #eaeaea"
          zIndex={1}
        >
          {calendar && Boolean(technicians?.length) && !auth?.interface?.isTechnician && (
            <div className="hidden md:block">
              <Bookmarks
                setAppliedBookmarks={setAppliedTechnicians}
                appliedBookmarks={appliedTechnicians}
              />
            </div>
          )}

          {Boolean(visibleBookmarks?.length) && !manageFilterBar && (
            <Bookmarks
              visibleBookmarks={visibleBookmarks}
              setAppliedBookmarks={setAppliedBookmarks}
              appliedBookmarks={appliedBookmarks}
            />
          )}

          {appliedFilters && (
            <Form
              refresh
              afterChange={() => setIsResetable(true)}
              disableIfNoUpdates
              initialValues={appliedFilters}
              onReset={() => setFilterIndex(null)}
              onSubmit={(newFilters) => {
                const toApply = { ...newFilters };

                if (_filter.key === 'preventifs' && _filter.type === 'Entity') {
                  toApply.section = {
                    ...toApply.section,
                    preventifs: true
                  };
                }
                setAppliedFilters(toApply);
              }}
            >
              {!timeline && !manageFilterBar && !disableSearch && (
                <SearchBar
                  setAppliedFilters={setAppliedFilters}
                  appliedFilters={appliedFilters}
                  newSet={newSet}
                  getXhrFilters={getXhrFilters}
                  setManageFilterBar={setManageFilterBar}
                  manageFilterBar={manageFilterBar}
                  isLoading={isLoading}
                  fetchElements={fetchElements}
                  setSearch={setDebouncedTextSearch}
                  newTextSearch={textSearch}
                  handleTextSearch={handleTextSearch}
                  isResetable={isResetable}
                  clearFilter={clearFilter}
                  showFilters={!!_filters?.length}
                  sets={sets}
                  setSets={setSets}
                  setSet={setSet}
                  set={set}
                  setsKey={setsKey}
                  buildFilters={buildFilters}
                />
              )}

              {!!_filters?.length && !disableFilter && !manageFilterBar && (
                <FilterBar
                  setIsResetable={setIsResetable}
                  preventifsFilter={preventifsFilter}
                  filter={_filter}
                  filterIndex={filterIndex}
                  setFilterIndex={setFilterIndex}
                  sort={sort}
                  setSort={setSort}
                  isLoading={isLoading}
                  setAppliedFilters={setAppliedFilters}
                  appliedFilters={appliedFilters}
                  filters={filtered_filters}
                  set={set}
                  sets={sets}
                  setSets={setSets}
                  manageFilterBar={manageFilterBar}
                  setManageFilterBar={setManageFilterBar}
                />
              )}
            </Form>
          )}

          {!timeline && !!manageFilterBar && (
            <SetManager
              setsKey={setsKey}
              setSet={setSet}
              setManageFilterBar={setManageFilterBar}
              manageFilterBar={manageFilterBar}
              setSets={setSets}
              sets={sets}
              setAppliedFilters={setAppliedFilters}
              appliedFilters={appliedFilters}
              filters={_filters}
            />
          )}
        </Box>
      )}

      {!!sublistParent && (
        <EntityBreadcrumbs
          element={sublistParent}
          page={page}
          sublistParentKey={sublistParentKey}
          setSublistParent={setSublistParent}
        />
      )}

      <Box className={classes.listContainer}>
        {(() => {
          if (ListComponent) {
            return ListComponent({
              elements,
              setIsOpenForm,
              fetchElements,
              isLoading,
              sort,
              getXhrFilters,
              appliedFilters,
              appliedBookmarks
            });
          } else if (!elements && isLoading) {
            return (
              <Box className={classes.listLoading}>
                {range(50).map((r, i) => !!SkeletonPreview && <SkeletonPreview key={k(i)} />)}
              </Box>
            );
          } else if (elements?.length) {
            return isListTable ? (
              <VirtuosoTable
                elements={elements}
                isLoading={isLoading}
                fetchElements={fetchElements}
              />
            ) : (
              <Box
                height="100%"
                display="flex"
                flexDirection="column"
              >
                {!!prependList && <Box>{prependList}</Box>}
                {isTicketUrl ? (
                  <GroupedVirtuosoList
                    setIsOpenForm={setIsOpenForm}
                    loading={isLoading}
                    fetchElements={fetchElements}
                  />
                ) : (
                  <VirtuosoList
                    setIsOpenForm={setIsOpenForm}
                    loading={isLoading}
                    fetchElements={fetchElements}
                  />
                )}
              </Box>
            );
          } else if (requestStatus === 'error') {
            return (
              <Box
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                Une erreur est survenue. Veuillez recharger la page.
              </Box>
            );
          } else {
            return (
              <Empty
                page={page}
                trash={trash}
                icon={icon}
                translations={translations}
              />
            );
          }
        })()}
      </Box>
    </Box>
  );
}

export const Empty = ({ page, trash, cta, icon, collection, translations }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  return (
    <Box className={classes.noResult}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {page && (
          <FAIcon
            noIconClass
            className={classes.noResultIcon}
            collection="fad"
            icon={icon}
            size="large"
          />
        )}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <strong className={classes.noResultLabel}>
            {!page && (
              <FAIcon
                noIconClass
                className={classes.noResultIconSmall}
                collection={collection || 'fad'}
                icon={trash ? 'trash' : icon}
                size="small"
              />
            )}
            {t(trash ? 'emptyTrash' : translations.noResultLabel)}
          </strong>
          {!trash && <span className={classes.noResultText}>{t(translations.noResultText)}</span>}
        </Box>
      </Box>
      {cta && cta}
    </Box>
  );
};
