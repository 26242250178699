import { useCallback, useState } from 'react';
import { Box, TextField, IconButton, Typography, Badge, useTheme } from '@material-ui/core';
import { Add, Remove, WarningRounded } from '@material-ui/icons';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { consumeStock } from 'store/ticketsSlice';
import useStyles from 'layouts/entities/Preview.styles';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import Stock from 'entities/StockPage/Stock';
import { useRole } from 'hooks/useRole';
import { debounce } from 'lodash-es';
import Tooltip from 'components/ToolTip';

export default function StockPreview({ element, payload: { consumptionTicket } }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [openDetailsStock, setOpenDetailsStock] = useState();
  const { dispatch, requestStatus } = useAsyncDispatch({ virtualized: true });
  const isLoading = requestStatus === 'loading';

  const [localQuantity, setLocalQuantity] = useState(
    element._consumptions.find((c) => c._ticket === consumptionTicket?._id)?.quantity || 0
  );
  // besoin de la quantité précédente pour pouvoir la remettre en cas d'erreur
  const [previousQuantity, setPreviousQuantity] = useState(localQuantity);
  const [error, setError] = useState('');

  const debounceConsume = useCallback(
    debounce((quantity) => {
      consume(quantity);
      setPreviousQuantity(quantity);
    }, 700),
    []
  );

  const consume = async (quantity) => {
    try {
      await dispatch(
        consumeStock,
        {
          _product: element._id,
          quantity
        },
        {},
        { id: consumptionTicket?._id }
      );
      setError('');
    } catch (err) {
      setError(t('errorConsumingStock'));
      setLocalQuantity(previousQuantity);
    }
  };

  const updateQuantity = (newQuantity) => {
    if (newQuantity < 0) {
      setError(t('quantityCannotBeNegative'));
      setLocalQuantity(previousQuantity);
      return;
    } else if (isNaN(newQuantity)) {
      setError(t('quantityMustBeANumber'));
      setLocalQuantity(previousQuantity);
      return;
    } else if (newQuantity > parseInt(element.stock) + parseInt(previousQuantity)) {
      newQuantity = parseInt(element.stock) + parseInt(previousQuantity);
    }

    debounceConsume(newQuantity);
    setLocalQuantity(newQuantity);
  };

  const handleQuantityChange = (e) => updateQuantity(parseInt(e.target.value));
  const handleIncrement = (e) => updateQuantity(parseInt(localQuantity) + 1);
  const handleDecrement = (e) => updateQuantity(parseInt(localQuantity) - 1);

  const { permission } = useRole();
  const isUserHavePermissionToConsumeStocks = permission('stocks', 'consommation_ticket');

  return consumptionTicket ? (
    <Box style={{ width: '100%' }}>
      <Box className={classes.l}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          marginRight={'16px'}
          flexDirection={'column'}
        >
          <div className="flex items-center justify-center mb-1">
            <IconButton
              disabled={isLoading || localQuantity <= 0 || !isUserHavePermissionToConsumeStocks}
              onClick={handleDecrement}
            >
              <Remove />
            </IconButton>
            <TextField
              disabled={!isUserHavePermissionToConsumeStocks}
              className="w-24"
              inputProps={{
                style: { textAlign: 'center' }
              }}
              variant={'outlined'}
              value={localQuantity}
              onChange={(e) => setLocalQuantity(e.target.value)}
              onBlur={handleQuantityChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleQuantityChange(e);
              }}
              error={!!error}
            />
            <IconButton
              disabled={
                isLoading ||
                !isUserHavePermissionToConsumeStocks ||
                parseInt(element.stock) <= 0 ||
                parseInt(localQuantity) >= parseInt(element.stock) + parseInt(previousQuantity)
              }
              onClick={handleIncrement}
            >
              <Add />
            </IconButton>
          </div>

          <div className="text-red-500">{error}</div>
        </Box>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Box className="flex flex-col text-sm font-semibold gap-1">
              {element.referenceMaker && <p className="text-[#363640] text-xs">#{element.referenceMaker}</p>}
              <p className="font-medium">{element.name}</p>
            </Box>
            <Typography variant="body2">{element?._warehouse?.name || ''}</Typography>
          </div>
          <div style={{ marginRight: '1em', display: 'flex', alignItems: 'center' }}>
            {parseInt(element?.stock) <= parseInt(element?.orderStock) && (
              <Tooltip
                title={t('alertThresholdHasBeenReached')}
                position="bottom"
              >
                <WarningRounded style={{ color: 'orange', marginRight: '0.5em' }} />
              </Tooltip>
            )}

            {!!element?.pendingOrder && (
              <Tooltip
                title={t('outOfStock')}
                position="bottom"
              >
                <Badge
                  color="primary"
                  badgeContent={element?.pendingOrder?.quantity}
                  max={Infinity}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <FAIcon
                    collection="fas"
                    icon="fa-shipping-timed"
                    color={theme.palette.success.main}
                    style={{ marginRight: 12 }}
                  />
                </Badge>
              </Tooltip>
            )}

            <Badge
              color="primary"
              badgeContent={element?.stock || 0}
              max={Infinity}
              showZero
            >
              <FAIcon
                collection="fad"
                icon="warehouse-full"
              />
            </Badge>
            <IconButton
              style={{ marginLeft: '0.3em' }}
              onClick={() => setOpenDetailsStock(element?._id)}
            >
              <FAIcon
                collection="fad"
                icon="eye"
              />
            </IconButton>
            {/* MODAL STOCK */}
            <Stock
              isDialog
              noFetch
              defaultOpenView
              childrenId={openDetailsStock}
              childrenAuto
              afterDialogClose={() => setOpenDetailsStock(null)}
            >
              <></>
            </Stock>
          </div>
        </div>
      </Box>
    </Box>
  ) : (
    <StockPreviewWithoutConsumption element={element} />
  );
}

function StockPreviewWithoutConsumption({ element }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <Box className="flex flex-col font-semibold text-sm gap-2">
          {element.referenceMaker && <p className="text-[#363640] text-xs">#{element.referenceMaker}</p>}
          <p className="font-medium">{element.name}</p>
        </Box>
        <Typography variant="body2">
          {element?._warehouse?.name || ''} {element?.locationPrecision && `(${element?.locationPrecision})`}
        </Typography>
      </div>
      <div style={{ marginRight: '1em', display: 'flex', alignItems: 'center' }}>
        {parseInt(element?.stock) <= parseInt(element?.orderStock) && (
          <Tooltip
            title={t('alertThresholdHasBeenReached')}
            position="bottom"
          >
            <WarningRounded style={{ color: 'orange', marginRight: '0.5em' }} />
          </Tooltip>
        )}

        {!!element?.pendingOrder && (
          <Tooltip
            title={t('outOfStock')}
            position="bottom"
          >
            <Badge
              color="primary"
              badgeContent={element?.pendingOrder?.quantity}
              max={Infinity}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <FAIcon
                collection="fas"
                icon="fa-shipping-timed"
                color={theme.palette.success.main}
                style={{ marginRight: 12 }}
              />
            </Badge>
          </Tooltip>
        )}

        <Badge
          color="primary"
          badgeContent={element?.stock || 0}
          max={Infinity}
          showZero
        >
          <FAIcon
            collection="fad"
            icon="warehouse-full"
          />
        </Badge>
      </div>
    </div>
  );
}
