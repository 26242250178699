import { Box, Chip, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import FAIcon from 'components/ui/FAIcon';
import Avatar from 'components/Avatar';

import { dateToLocalFormat } from 'utils/dates';
import useStyles from 'layouts/entities/View.styles';
import { useConfiguration } from 'hooks/useConfiguration';
import { useAuth } from 'hooks/useAuth';
import ViewItem from 'components/views/ViewItem';

import { PhoneItem, EmailItem, ManagerItem, ContractorItem } from 'components/views/ViewItems';
import CustomFields from '../CustomFields';
import Flag from 'react-flagkit';
import { countryLangage } from 'config/languages';
import { useTranslation } from 'react-i18next';
import CopyableLink from 'components/CopyableLink';

export default function ClientView({ element }) {
  const auth = useAuth();
  const config = useConfiguration();
  const contractorId = auth.interface._company?._id;
  const publicInterfacesFeatureActivated = config.feature.ticket_public;
  const clientInterfaceActivated = auth.interface._company._configuration.ticket_public?.global_client;
  const interfaceURL = `${process.env.REACT_APP_URL || 'https://bob-desk.com'}/public/${
    element._id
  }/client/${contractorId}`;

  const isPro = element.regime === 'pro';

  const _company = element._client || element._contractor;
  const companyPermission = auth.interface.isMaster || _company?._id === auth.interface._company._id;

  const lang = (element || element._user)?.lang || 'fr';

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.l1}>
        <Box
          alignItems={'center'}
          display={'flex'}
        >
          <Avatar
            className={classes.avatarl1}
            entity={{
              name: element.name,
              avatar: element.avatar
            }}
            isUser={!isPro}
            round={!isPro}
            shadow={false}
            size={'small'}
          />
          <Box
            flexDirection={'column'}
            alignItems={'flex-start'}
          >
            <Box className="text-base font-bold flex gap-2 items-center">
              {element?.number && <span className={classes.l1reference}>#{element.ref}</span>}
              {!!(isPro ? element.name : element.lastName) && (
                <>{isPro ? element.name : (element.firstName + ' ' + element.lastName)?.trim()}</>
              )}
              <Flag
                country={countryLangage[lang]}
                className="mb-[2px] rounded-sm"
                size={20}
              />
            </Box>
            {element._category && (
              <Box marginTop={'10px'}>
                {
                  <Chip
                    className={classes.l1chip}
                    color="secondary"
                    size="small"
                    label={element._category.name}
                  />
                }
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <List className={classes.list}>
        <CustomFields element={element}>
          {config.isMaster && element._client && (
            <ListItem
              dense
              button
              className={classes.listItem}
            >
              <ListItemText
                primary={<strong>{t('creator-of-client')}</strong>}
                secondary={'Bob! Desk'}
              />
            </ListItem>
          )}

          {config.isMaster && element._contractor && (
            <ContractorItem
              primary={t('creatorOfContractor')}
              contractor={element._contractor}
            />
          )}

          {companyPermission && (
            <>
              {isPro && element.userInterface && <ManagerItem user={element._user} />}

              {isPro &&
                (!element.userInterface || !element._user) &&
                (!!element.firstName?.length || !!element.lastName?.length) && (
                  <ListItem
                    dense
                    className={classes.listItem}
                  >
                    <ListItemIcon>
                      <Avatar
                        className={classes.listIcon}
                        isUser
                        round
                        entity={{ avatar: '' }}
                        shadow={false}
                        size={'xsmall'}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={<strong>{t('principal-manager')}</strong>}
                      secondary={element.firstName + ' ' + element.lastName}
                    />
                  </ListItem>
                )}
            </>
          )}

          {!!element.description?.length && (
            <ListItem
              dense
              className={classes.listItem}
            >
              <ListItemIcon>
                {
                  <FAIcon
                    icon={'quote-left'}
                    collection={'fas'}
                    className={classes.listIcon}
                  />
                }
              </ListItemIcon>
              <ListItemText secondary={element.description} />
            </ListItem>
          )}

          {!!element.phone?.length && <PhoneItem element={element} />}
          {!!element.fix?.length && (
            <PhoneItem
              element={element}
              fix
            />
          )}
          {!!element.email?.length && <EmailItem element={element} />}

          {config.isMaster && (
            <ListItem
              dense
              className={classes.listItem}
            >
              <ListItemIcon>
                {
                  <FAIcon
                    icon={'calendar'}
                    collection={'fal'}
                    className={classes.listIcon}
                  />
                }
              </ListItemIcon>
              <ListItemText
                primary={<strong>{t('createdDate')}</strong>}
                secondary={dateToLocalFormat(element.created_at, 'PP')}
              />
            </ListItem>
          )}

          {publicInterfacesFeatureActivated &&
            clientInterfaceActivated &&
            (auth.interface.isClient || element._contractor === auth.interface._company._id) && (
              <ViewItem
                icon="link"
                secondaryIcon
                primary="Interface publique"
                secondary={<CopyableLink link={interfaceURL} />}
              />
            )}
        </CustomFields>
      </List>
    </>
  );
}
