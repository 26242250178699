import Tooltip from 'components/ToolTip';
import FactureHelper from 'utils/invoice';

export default function ReferenceColumn({ parentId, factures }) {
  const references = FactureHelper.getAll({ factures })
    .map((d) => d.reference)
    .join(', ');
  const should_truncate = references.length > 8;

  return parentId ? null : (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', maxWidth: 60 }}>
      {factures.length > 0 && (
        <Tooltip
          title={references}
          disableHoverListener={!should_truncate}
          className="cursor-default"
        >
          <div>
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {should_truncate ? `${references.slice(0, 5)}...` : references}
            </span>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
