// import Notes from "entities/Ticket/Tabs/Notes"
import Reports from 'entities/Ticket/Tabs/Reports/TicketReportsTab';
import History from 'entities/Ticket/Tabs/History';
import TicketLinks from 'entities/Ticket/Tabs/TicketLinks';
import TabDatas from './Tabs/TabDatas';
import NotesTab from './Tabs/NotesTab/NotesTab';
import Stock from 'entities/StockPage/Stock';
import UsersTab from './Tabs/UsersTab';
import { isArray } from 'lodash-es';
import { Empty } from 'layouts/entities/List';
import Documents from 'modules/documents';

const TicketTabs = (config, element, role, auth) =>
  !!element &&
  [
    {
      label: 'menuItemUsers',
      icon: 'user',
      count: element?._contacts?.filter((c) => !!c).length || 0,
      show: true,
      render: () => (
        <UsersTab
          element={element}
          elementsPicker={isArray(element?._contacts) ? element._contacts.filter((c) => !!c).map((c) => c._id) : []}
        />
      )
    },
    {
      label: 'menuItemDocuments',
      icon: 'clouds',
      count: element.tabs?.docs || 0,
      show: true,
      render: (element, isLoading) =>
        !element.documents_tab_id ? (
          <Empty
            icon="clouds"
            translations={{
              noResultLabel: 'errorLabelNoDocuments',
              noResultText: 'errorTextNoDocuments'
            }}
          />
        ) : (
          <Documents
            endpoint_prefix={`ticket_documents/${element._id}`}
            tab_id={element.documents_tab_id}
          />
        )
    },
    {
      label: 'reportsTitle',
      icon: 'file-chart-pie',
      count: element.tabs?.reports || 0,
      show: role?.permission('reports', 'read_report'),
      render: (element) => <Reports element={element} />
    },
    {
      label: 'menuItemInventory',
      icon: 'box-taped',
      count: element.contract?._consumptions?.length,
      show: role.checkForPermissions('stocks') && (config.feature.inventory || config.isMaster),
      render: () => (
        <Stock
          consumptionTicket={element}
          hiddenFilters={{ or: true }}
          defaultFilters={{ locations: element._locations || [] }}
          disableGutters
          disableShadow
          onClickPreview={() => {}}
        />
      )
    },
    {
      label: 'datas',
      icon: 'sliders-v',
      count: 0,
      show: !config.isUser,
      render: () => <TabDatas element={element} />
    },
    {
      label: 'notes',
      icon: 'notes',
      count:
        element.notes?.length * 1 + element.viewNotes?.length * 1 + element.viewNotesManagers?.length * config.isManager * 1,
      show: true,
      render: () => <NotesTab ticket={element} />
    },
    {
      label: 'links',
      icon: 'link',
      count: (element?._tickets_linked?.length || 0) + (element?._ticket_parents?.length || 0),
      show: true,
      render: () =>
        !!element && (
          <TicketLinks
            element={element}
            hiddenFilters={{ locations: [element._locations.map((l) => l._id)] }}
          />
        )
    },
    {
      label: 'historic',
      icon: 'history',
      count: element.contract.history?.length,
      show: true,
      render: (element, isLoading) => <History entries={element.contract.history} />
    }
  ].filter((e) => e.show);

export default TicketTabs;
