import TicketHeader from './View/TicketHeader';
import MaintenanceItems from './View/MaintenanceItems';
import OrderItems from './View/OrderItems';
import ContractParent from './View/ContractParent';
import ContractTechnician from './View/ContractTechnician';
import ContractContractor from './View/ContractContractor';
import ContractPropositions from './View/ContractPropositions';
import TicketActionButtons from './View/TicketActionButtons';
import IntervenerPicker from './View/IntervenerPicker';

import { Dialog, Grid, List } from '@material-ui/core';

import useStyles from 'layouts/entities/View.styles';

import CustomFields from '../CustomFields';
import { CONTRACT_PROPOSED } from 'lists/contractStates';

import { ContractContext } from 'contexts/entities/contractContext';
import { useEntity } from 'contexts/entities/entityContext';
import { useState } from 'react';
import { useDocumentListener, trigger } from 'react-events-utils';

export default function TicketView({ element, isLoading, dispatch }) {
  const { entity } = useEntity();
  const isProposition = entity === 'contractPropositions';
  const order = isProposition ? element._ticket : element;

  const {
    contract,
    contractParent,
    contractContractor,
    contractTechnician,
    companyCreator,
    _locations
  } = order;

  const [intervenerPicker, setIntervenerPicker] = useState(false);

  const [ticketDialogContents, setTicketDialogContents] = useState(null);

  useDocumentListener(`ticket-modal-action-open-${element._id}`, (e) => {
    const data = e.detail;
    setTicketDialogContents(data.content);
  });

  useDocumentListener(`ticket-modal-action-close-${element._id}`, () =>
    setTicketDialogContents(null)
  );

  const classes = useStyles();

  return (
    <ContractContext.Provider
      value={{
        order,
        contract,
        contractParent,
        contractContractor,
        contractTechnician,
        setIntervenerPicker,
        intervenerPicker,
        isLoading,
        dispatch,
        companyCreator,
        isProposition,
        _ticket_reserve: element._ticket_reserve
      }}
    >
      <Dialog
        maxWidth="xs"
        fullWidth
        open={!!ticketDialogContents}
        onClose={() => trigger(`ticket-modal-action-close-${element._id}`)}
      >
        {ticketDialogContents && ticketDialogContents}
      </Dialog>

      <TicketHeader />

      <Grid
        container
        style={{ overflowY: 'auto' }}
      >
        <List className={classes.list}>
          <ContractParent>
            {!!order.period && !order.period.ticketPeriodNotFound && <MaintenanceItems />}
            <CustomFields element={order}>
              <OrderItems ticket={element} />
            </CustomFields>
          </ContractParent>
          {!isProposition && (
            <>
              {(contractTechnician || contract.summonsIsCollaborator) && (
                <ContractTechnician ticket={element} />
              )}
              {(!!contractContractor || contract.summonsIsContractor) && (
                <ContractContractor ticket={element} />
              )}
              {contract.state === CONTRACT_PROPOSED && <ContractPropositions />}
            </>
          )}
        </List>

        <TicketActionButtons />

        <IntervenerPicker
          locationsPrio={_locations.reduce((a, b) => a.concat(b._contractors), [])}
        />
      </Grid>
    </ContractContext.Provider>
  );
}
