import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from 'store/authSlice';
import { Route, Redirect } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { STARTUP, STARTUP_MOBILE } from 'config/menus';
import { useIsBelowMd } from 'hooks/useMQ';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const EmptyLayout = ({ children }) => children;

export default function RouteWrapper({
  component: Page,
  layout: Layout = EmptyLayout,
  privateRoute,
  redirectIfLogged,
  title,
  backTo,
  path,
  ...rest
}) {
  const auth = useSelector(selectAuth);
  const query = useQuery();
  const isBelowMd = useIsBelowMd();
  const ctaContainer = useRef(null);

  const { t } = useTranslation();

  const history = useHistory();

  const pathname = rest.location.pathname;

  // Replace the // by / to have a clean version of the URL.
  if (pathname.includes('//')) history.push(pathname.replace('//', '/'));

  // If the user is not authenticated and is in a private route then redirect them to the Home page = tickets page
  // else: add to the url a paramater to redirect the user once authenticated to the page previously asked.
  if (privateRoute && !auth.user) {
    return <Redirect to={window.location.pathname !== '/' ? `/?redirect=${window.location.pathname}` : '/'} />;
  } else if (redirectIfLogged && auth.user) {
    // Checks if a redirect parameter is present in the URL "via query.get('redirect')" and, if it exists, redirects to that URL.
    const redirectURL = query.get('redirect');
    return (
      <Redirect to={redirectURL ? redirectURL : (isBelowMd ? STARTUP_MOBILE : STARTUP)[auth.interface.type].url} />
    );
  } else {
    return (
      <Route
        {...rest}
        path={path}
        render={(props) => (
          <Layout
            title={t(title)}
            backTo={backTo}
            {...props}
          >
            <div
              id={'cta'}
              ref={ctaContainer}
            />
            <Page
              {...props}
              {...rest}
              ctaContainer={ctaContainer}
            />
          </Layout>
        )}
      />
    );
  }
}
