import FAIcon from 'components/ui/FAIcon';
import { useAuth } from 'hooks/useAuth';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/formatCurrency';
import { Popover } from '@material-ui/core';
import { contractStates } from './contractStates';
import Tooltip from 'components/ToolTip';
import { add } from 'date-fns';

export const operational_states_equipment = [
  {
    label: 'functionalStatus',
    value: 'FUNCTIONAL',
    icon: 'check-circle',
    color: 'text-green-500',
    hoverColor: 'text-green-300',
    bgColor: 'bg-green-500'
  },
  {
    label: 'limitedFonctionnality',
    value: 'DEFECT_NOTED',
    icon: 'check-circle',
    color: 'text-yellow-500',
    hoverColor: 'text-yellow-400',
    bgColor: 'bg-yellow-500'
  },
  {
    label: 'notFunctionalStatus',
    value: 'NON_FUNCTIONAL',
    icon: 'check-circle',
    color: 'text-red-500',
    hoverColor: 'text-red-400',
    bgColor: 'bg-red-500'
  },
  {
    label: 'notSpecifiedStatus',
    value: 'NOT_SPECIFIED',
    icon: 'check-circle',
    color: 'text-gray-400',
    hoverColor: 'text-gray-300',
    bgColor: 'bg-gray-400'
  }
];

export const replacement_date_planned = (replacement_date) => {
  if (replacement_date === 'NOT_SPECIFIED') {
    return {
      label: 'notSpecifiedReplacement',
      value: 'NOT_SPECIFIED',
      icon: 'arrows-rotate',
      color: 'text-gray-400',
      hoverColor: 'text-gray-300'
    };
  }

  const now = new Date();
  const targetDate = new Date(replacement_date);

  // Vérifiez si la date est dans le passé
  if (targetDate < now) {
    return {
      label: 'lateReplacementDate',
      value: 'REPLACEMENT_PLANNED',
      icon: 'arrows-rotate',
      color: 'text-red-500',
      hoverColor: 'text-red-400'
    };
  }

  // Calcul de la différence en mois
  const monthsDiff = (targetDate.getFullYear() - now.getFullYear()) * 12 + (targetDate.getMonth() - now.getMonth());

  if (monthsDiff > 24) {
    return {
      label: 'notRequiredReplacement',
      value: 'NOT_REQUIRED',
      icon: 'arrows-rotate',
      color: 'text-green-500',
      hoverColor: 'text-green-400'
    };
  }

  // Si la différence est entre 0 et 24 mois
  return {
    label: 'replacementPlanned',
    value: 'REPLACEMENT_PLANNED',
    icon: 'arrows-rotate',
    color: 'text-yellow-500',
    hoverColor: 'text-yellow-400'
  };
};

export const critical_equipment = (criticity) => {
  if (criticity === 'TO_MONITOR') {
    return {
      label: 'toMonitor',
      value: 'TO_MONITOR',
      icon: 'circle-exclamation', // chevrons-up
      collection: 'fas',
      color: 'text-yellow-500',
      hoverColor: 'text-yellow-400'
    };
  } else if (criticity === 'CRITICAL') {
    return {
      label: 'critical',
      value: 'CRITICAL',
      icon: 'circle-exclamation',
      collection: 'fas',
      color: 'text-red-500',
      hoverColor: 'text-red-400'
    };
  } else return null;
};

export const warranty_equipment = (warranties) => {
  if (!warranties || warranties.length === 0 || warranties === 'NOT_SPECIFIED') {
    return {
      label: 'notSpecifiedWarranty',
      value: 'NOT_SPECIFIED',
      icon: 'shield-halved',
      color: 'text-gray-400',
      hoverColor: 'text-gray-300',
      bgColor: 'bg-gray-500'
    };
  }

  const now = new Date();

  let hasActive = false;
  let hasExpireSoon = false;
  let hasPassed = false;
  const activeDates = [];
  const expireSoonDates = [];
  const passedDates = [];

  for (const warranty of warranties) {
    const alert_date = new Date(warranty?.alert_deadline);
    const date = add(new Date(warranty.start_date), {
      [warranty.duration_type]: Number(warranty.duration)
    });
    const duration_type = warranty?.duration_type;
    const is_deleted = warranty?.deleted_by || warranty?.deleted_at;

    if (!date || !duration_type) continue; // Ignore invalid warranty entries

    const active_warranty = date >= now;
    const expire_soon_warranty = date >= now && alert_date <= now;
    const passed_warranty = date < now;

    if (active_warranty && !expire_soon_warranty && !is_deleted) {
      hasActive = true;
      activeDates.push(date);
    }
    if (expire_soon_warranty && !passed_warranty && !is_deleted) {
      hasExpireSoon = true;
      expireSoonDates.push(date);
    }
    if (passed_warranty && !is_deleted) {
      hasPassed = true;
      passedDates.push(date);
    }
  }

  // Return priority-based status
  if (hasActive) {
    return {
      label: 'activeWarranty',
      value: 'ACTIVE',
      icon: 'shield-halved',
      color: 'text-green-500',
      hoverColor: 'text-green-400',
      dates: activeDates,
      bgColor: 'bg-green-500'
    };
  }

  if (hasExpireSoon) {
    return {
      label: 'expireSoonWarranty',
      value: 'EXPIRE_SOON',
      icon: 'shield-halved',
      color: 'text-yellow-500',
      hoverColor: 'text-yellow-400',
      dates: expireSoonDates,
      bgColor: 'bg-yellow-500'
    };
  }

  if (hasPassed) {
    return {
      label: 'passedWarranty',
      value: 'PASSED',
      icon: 'shield-halved',
      color: 'text-red-500',
      hoverColor: 'text-red-400',
      dates: passedDates,
      bgColor: 'bg-red-500'
    };
  }

  // Default to 'not specified' if no valid warranties were found
  return {
    label: 'notSpecifiedWarranty',
    value: 'NOT_SPECIFIED',
    icon: 'shield-halved',
    color: 'text-gray-400',
    hoverColor: 'text-gray-300'
  };
};

export const loss_ratio_equipment = (tickets = []) => {
  const last12MonthTickets = tickets.filter((ticket) => {
    // Convertir `ticket.created_at` en Date si ce n'est pas déjà un objet Date
    const createdAt = new Date(ticket.created_at);

    // Calculer la date il y a 24 mois
    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setFullYear(twelveMonthsAgo.getFullYear() - 2);

    // Retourner uniquement les tickets dans les 24 derniers mois
    return createdAt >= twelveMonthsAgo;
  });

  if (last12MonthTickets?.length >= 6) {
    return {
      label: 'lossRatioHigh12Months',
      value: 'LOSS_RATIO_HIGH',
      icon: 'chart-line-up',
      color: 'text-red-500',
      hoverColor: 'text-red-400'
    };
  }

  if (last12MonthTickets?.length > 2) {
    return {
      label: 'lossRatioMedium12Months',
      value: 'LOSS_RATIO_MEDIUM',
      icon: 'chart-line-up',
      color: 'text-yellow-500',
      hoverColor: 'text-yellow-400'
    };
  }

  return {
    label: 'lossRatioLow12Months',
    value: 'LOSS_RATIO_LOW',
    icon: 'chart-line-down',
    color: 'text-green-500',
    hoverColor: 'text-green-400'
  };
};

export const WarrantyTooltip = ({ warranty }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <div className="flex flex-col gap-2 font-bold">
      <div className="flex items-center gap-1">
        <FAIcon
          icon={warranty.icon}
          collection="fad"
          size={'small'}
          className={`hover:${warranty.hoverColor} ${warranty.color} rounded-full`}
        />
        <span className="text-xs">{t(warranty.label)}</span>
        {warranty.dates?.length === 1 && (
          <div className="flex items-center gap-1">
            <span className="text-xs">
              {warranty.dates[0].toLocaleDateString(auth.company?.currency?.locale, {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
              })}
            </span>
          </div>
        )}
      </div>
      {warranty.dates?.length > 1 && (
        <div className="flex flex-col gap-1 ml-1 font-normal">
          {warranty.dates.map((date, index) => (
            <span
              key={index}
              className="text-xs flex items-center gap-2"
            >
              <div className={`h-2 w-2 rounded-full ${warranty.bgColor}`}></div>
              {date.toLocaleDateString(auth.company?.currency?.locale, {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
              })}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export const OperationnalTooltip = ({ operational }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-1 font-bold">
      <FAIcon
        icon={operational.icon}
        collection="fas"
        size={'small'}
        className={`hover:${operational.hoverColor} ${operational.color} rounded-full`}
      />
      {t(operational.label)}
    </div>
  );
};

export const LossRatioTooltip = ({ loss_ratio }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col font-bold gap-1 h-full">
      <div className="flex items-center gap-1">
        <FAIcon
          icon={loss_ratio.icon}
          collection="fad"
          size={'small'}
          className={`hover:${loss_ratio.hoverColor} ${loss_ratio.color} rounded-full`}
        />
        <span className="text-xs">{t(loss_ratio.label)}</span>
      </div>
    </div>
  );
};

export const LossRatioPopover = ({ loss_ratio, interventions = [], setOpenTicketId, currentTicket = null }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  // Calcul des interventions filtrées et du texte vide
  const { filteredInterventions, emptyText } = useMemo(() => {
    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setFullYear(twelveMonthsAgo.getFullYear() - 2);

    const filtered = interventions
      .filter((i) => {
        const createdAt = new Date(i.created_at);
        return createdAt >= twelveMonthsAgo && i._id !== currentTicket?._id;
      })
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const emptyText = currentTicket && filtered.length === 0 ? t('onlyThisTicket') : t('noTicketInLast24Months');

    return { filteredInterventions: filtered, emptyText };
  }, [interventions, currentTicket, t]);

  return (
    <div className="flex flex-col font-bold gap-2 h-full">
      <div className="flex items-center gap-1">
        <FAIcon
          icon={loss_ratio.icon}
          collection="fad"
          size="small"
          className={`hover:${loss_ratio.hoverColor} ${loss_ratio.color} rounded-full`}
        />
        <span className="text-xs">{t(loss_ratio.label)}</span>
      </div>
      {!!filteredInterventions.length ? (
        <div className="flex flex-col gap-2 font-normal max-h-[220px] overflow-auto">
          {filteredInterventions.map((i, index) => {
            const ticketStatus = contractStates[i?.views?.[0]?.state];
            return (
              <div
                className="flex items-center gap-1.5"
                key={index}
              >
                <div className="w-14 text-center">
                  {new Date(i.created_at).toLocaleDateString(auth.company?.currency?.locale, {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit'
                  })}
                </div>
                <div
                  className="text-blue-500 hover:underline cursor-pointer w-14 text-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenTicketId(i?._id);
                  }}
                >
                  #{i.number}
                </div>
                <div className="w-14 flex items-center justify-center">
                  {i?.views?.[0]?.intervention_price ? (
                    formatCurrency({
                      number: i?.views?.[0]?.intervention_price,
                      locale: auth.company?.currency?.locale,
                      currency: auth.company?.currency?.code,
                      minimumFractionDigits: 0
                    })
                  ) : (
                    <div className="mr-1.5">-</div>
                  )}
                </div>
                {ticketStatus && <div className={`text-[${ticketStatus?.color}] px-1`}>{t(ticketStatus?.label)}</div>}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="ml-1 font-normal">{emptyText}</div>
      )}
    </div>
  );
};

export const ReplacementTooltip = ({ replacement_planned, replacement_date }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 font-bold">
      <div className="flex items-center gap-1">
        <FAIcon
          icon={replacement_planned.icon}
          collection="fad"
          size={'small'}
          className={`hover:${replacement_planned.hoverColor} ${replacement_planned.color} rounded-full`}
        />
        {t(replacement_planned.label)}
      </div>

      {replacement_date !== 'NOT_SPECIFIED' && (
        <div className="ml-1 font-normal">
          {t('nextReplacementScheduledOn')}{' '}
          <span className="text-blue-500">{new Date(replacement_date)?.toLocaleDateString()}</span>
        </div>
      )}
    </div>
  );
};

export const PeriodExpenses = ({ tickets, equipment }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  // État pour gérer l'ouverture du popover
  const [anchorEl, setAnchorEl] = useState(null);

  // Ouvrir le popover
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Fermer le popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Vérifie si le popover est ouvert
  const isOpen = Boolean(anchorEl);

  // Fonction utilitaire pour calculer la différence en mois entre deux dates
  const calculateMonthDifference = (date1, date2) => {
    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();
    return yearDiff * 12 + monthDiff;
  };

  // Calculer les dépenses par période 6, 12, 24, and all
  const expensesByPeriod = useMemo(() => {
    const now = new Date();

    const periods = {
      6: 6,
      12: 12,
      24: 24,
      all: Infinity
    };

    // Initialiser les dépenses totales pour chaque période
    const totals = {
      6: 0,
      12: 0,
      24: 0,
      all: 0
    };

    // Ajouter les dépenses des tickets dans les bonnes périodes
    tickets.forEach((ticket) => {
      const createdAt = new Date(ticket.created_at);

      // Calculer le nombre de mois depuis la création
      const monthDifference = calculateMonthDifference(createdAt, now);

      // Calculer le prix total pour ce ticket
      const interventionPrice = ticket.views?.reduce((sum, view) => sum + (view.intervention_price || 0), 0);

      // Ajouter le prix aux périodes pertinentes
      for (const [key, months] of Object.entries(periods)) {
        if (monthDifference <= months) {
          totals[key] += interventionPrice;
        }
      }
    });

    return totals;
  }, [tickets]);

  // Calculer la somme totale de tous les tickets.views.intervention_price
  const totalExpense = tickets.reduce((total, ticket) => {
    // Si "views" existe, calculer la somme des intervention_price pour ce ticket
    const ticketTotal =
      ticket.views?.reduce((sum, view) => {
        return sum + (view.intervention_price || 0);
      }, 0) || 0;

    // Ajouter la somme du ticket au total général
    return total + ticketTotal;
  }, 0);

  if (!tickets?.length) return null;
  return (
    <div className="mb-0.5">
      <h3
        onClick={handleOpen}
        className="flex gap-4 items-center w-max cursor-pointer hover:bg-zinc-100 rounded p-2 text-[#4F5B62] font-bold"
      >
        <FAIcon
          icon="wallet"
          collection="fad"
          size={'medium'}
          className="text-[#C2C9D0]"
        />
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            {t('totalExpense')}
            <FAIcon
              icon="chevron-down"
              collection="fas"
              size="small"
              className={`text-[#66788A] transition ${isOpen ? 'rotate-180' : 'rotate-0'}`}
            />
          </div>
          <span className="font-normal text-slate-500/95">
            {formatCurrency({
              number: totalExpense,
              locale: auth.company?.currency?.locale,
              currency: auth.company?.currency?.code
            })}
          </span>
        </div>
      </h3>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{
          paper: '!rounded' // Utilise une classe Tailwind pour un rayon réduit
        }}
      >
        <div className="overflow-hidden shadow min-w-[200px]">
          <table className="w-full border-collapse">
            <thead className="bg-gray-200 border border-gray-200">
              <tr>
                <th className="text-gray-700 ">
                  <div className="flex items-center p-2">
                    <FAIcon
                      icon="calendar"
                      collection="fad"
                      size="small"
                      className="text-gray-600 mr-1.5"
                    />
                    {t('period')}
                  </div>
                </th>
                <th className="text-gray-700">
                  <div className="flex items-center p-2">
                    <FAIcon
                      icon="money-bills-simple"
                      collection="fad"
                      size="small"
                      className="text-gray-600 mr-1.5"
                    />
                    {t('expense')}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(expensesByPeriod).map(([period, total], index) => {
                const price = parseInt(equipment?.purchase_price) || 0;
                const purchase_price = formatCurrency({
                  number: price,
                  locale: auth.company?.currency?.locale,
                  currency: auth.company?.currency?.code
                });
                const row_pourcentage = (total / price) * 100;
                const percentage = row_pourcentage.toFixed(2) + '%';

                return (
                  <Tooltip
                    title={!price || !total ? '' : t('ofPurchasePrice', { percentage, purchase_price })}
                    position="top"
                    key={period}
                  >
                    <tr className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100`}>
                      <td className="py-2 px-4 text-gray-700 border border-gray-200">
                        {period === 'all' ? t('since_created') : t('since', { period })}
                      </td>
                      <td className="py-2 px-4 text-gray-700 border border-gray-200">
                        {formatCurrency({
                          number: total,
                          locale: auth.company?.currency?.locale,
                          currency: auth.company?.currency?.code
                        })}
                      </td>
                    </tr>
                  </Tooltip>
                );
              })}
            </tbody>
          </table>
        </div>
      </Popover>
    </div>
  );
};
