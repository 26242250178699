import { configureStore } from '@reduxjs/toolkit';

import authSlice from 'store/authSlice';
import uiSlice from 'store/uiSlice';
import fieldsSlice from 'store/fieldsSlice';
import locationsSlice from 'store/locationsSlice';
import scopesSlice from 'store/scopesSlice';
import periodsSlice from 'store/periodsSlice';
import ticketsSlice from 'store/ticketsSlice';
import rolesSlice from 'store/rolesSlice';
import maintenancesSlice from 'store/maintenancesSlice';
import equipmentsSlice from 'store/equipmentsSlice';
import jobsSlice from 'store/jobsSlice';
import reportsSlice from 'store/reportsSlice';
import clientsSlice from 'store/clientsSlice';
import intervenersSlice from 'store/intervenersSlice';
import usersSlice from 'store/usersSlice';
import managersSlice from 'store/managersSlice';
import stocksSlice from 'store/stocksSlice';
import apisSlice from 'store/apisSlice';
import guaranteesSlice from 'store/guaranteesSlice';
import fieldSectionsSlice from 'store/fieldSectionsSlice';
import contractPropositionsSlice from 'store/contractPropositionsSlice';
import contractsSlice from 'store/contractsSlice';
import leasesSlice from 'store/leasesSlice';
import reportsFileSlice from 'store/reportsFileSlice';
import suppliersSlice from 'store/supplierSlice';
import accountingSlice from 'store/accountingSlice';
import documentssSlice from 'store/documentssSlice';
import budgetsSlice from 'store/budgetsSlice';
import metersSlice from 'store/metersSlice';
import accountingListSlice from 'store/accountingListSlice';
import ticketsListSlice from 'store/ticketsListSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    accounting: accountingSlice,
    accountingList: accountingListSlice,
    clients: clientsSlice,
    interveners: intervenersSlice,
    equipments: equipmentsSlice,
    fields: fieldsSlice,
    fieldSections: fieldSectionsSlice,
    jobs: jobsSlice,
    locations: locationsSlice,
    maintenances: maintenancesSlice,
    periods: periodsSlice,
    managers: managersSlice,
    reports: reportsSlice,
    reportFiles: reportsFileSlice,
    roles: rolesSlice,
    scopes: scopesSlice,
    stocks: stocksSlice,
    guarantees: guaranteesSlice,
    tickets: ticketsSlice,
    ticketList: ticketsListSlice,
    apis: apisSlice,
    ui: uiSlice,
    users: usersSlice,
    contractPropositions: contractPropositionsSlice,
    contracts: contractsSlice,
    leases: leasesSlice,
    suppliers: suppliersSlice,
    tags: documentssSlice,
    budgets: budgetsSlice,
    meters: metersSlice
    // bpus: bpus_slice,
  }
});

export default store;
