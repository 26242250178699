import {
  Box,
  IconButton,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction
} from '@material-ui/core';

import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  ArrowDropDown,
  ArrowDropUp,
  Sort,
  RadioButtonUnchecked,
  CheckCircle
} from '@material-ui/icons';

import clsx from 'clsx';

import { useRef, useState } from 'react';
import { useEntity } from 'contexts/entities/entityContext';
import { Swiper, SwiperSlide } from 'swiper/react';

import useStyles from './FilterBar.styles';
import 'swiper/swiper-bundle.min.css';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';
import PopoverFilter from './PopoverFilter';
import NotEmptyIndicator from 'components/filters/NotEmptyIndicator';
import { useIsBelowMd } from 'hooks/useMQ';

function SortMenu({ anchorElSort, closeSortMenu, sorts, sort, setSort, classes }) {
  const k = useKeys();
  const { t } = useTranslation();
  return (
    <>
      <Menu
        transitionDuration={0}
        anchorEl={anchorElSort}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={!!anchorElSort}
        onClose={closeSortMenu}
        MenuListProps={{ className: classes.moreMenu }}
        className={classes.moreMenu}
      >
        {sorts.map((s, i) => {
          const isSelected = s.value === sort.value && s.sort === sort.sort;
          return (
            <MenuItem
              dense
              key={k(i)}
              onClick={() => [closeSortMenu(), setSort(s)]}
              className={clsx(classes.moreMenuElement, {
                [classes.moreMenuElementOn]: isSelected
              })}
            >
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    style={{ fontWeight: isSelected ? 700 : 400 }}
                  >
                    {t(s.label)}
                  </Typography>
                }
                className={classes.moreMenuText}
              />
              {isSelected && (
                <ListItemSecondaryAction>
                  {sort.sort === -1 ? <ArrowDropDown style={{ margin: 0 }} /> : <ArrowDropUp style={{ margin: 0 }} />}
                </ListItemSecondaryAction>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default function FilterBar({
  isLoading,
  filters,
  filter,
  preventifsFilter,
  sort,
  setSort,
  set,
  sets,
  setSets,
  appliedFilters,
  setAppliedFilters,
  filterIndex,
  setFilterIndex,
  setIsResetable
}) {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const { page, settings, sorts } = useEntity();
  const swiper = useRef(null);
  const filterBar = useRef(null);
  const handleSwipe = (s) => [setIsBeginning(s.isBeginning), setIsEnd(s.isEnd)];
  const classes = useStyles({
    loading: isLoading,
    isList: !page,
    settings,
    page,
    filterTabs: filters.some((f) => f.type === 'Tab')
  });
  const k = useKeys();
  const isBelowMd = useIsBelowMd()

  const { t } = useTranslation();

  const [anchorElSort, setAnchorElSort] = useState(null);

  const openSortMenu = (e) => setAnchorElSort(e.currentTarget);
  const closeSortMenu = (e) => setAnchorElSort(null);

  return !!appliedFilters ? (
    <Box className={clsx(classes.filtersBar, { [classes.filtersBarSettings]: settings })}>
      <IconButton
        onClick={openSortMenu}
        aria-haspopup="true"
        className={classes.entityMenu}
        style={!page && !settings ? { marginLeft: 0 } : {}}
      >
        <Sort />
      </IconButton>

      <SortMenu
        anchorElSort={anchorElSort}
        closeSortMenu={closeSortMenu}
        sorts={sorts}
        sort={sort}
        setSort={setSort}
        classes={classes}
      />
      <Box
        flexGrow={1}
        position="relative"
        overflow="hidden"
        width="0px"
      >
        {!isBeginning && !isBelowMd && (
          <Box className={classes.navFiltersIconsLeft}>
            <IconButton
              className={classes.navFiltersIconButton}
              onClick={() =>
                typeof swiper.current?.swiper?.slidePrev === 'function' && swiper.current.swiper.slidePrev()
              }
            >
              <NavigateBeforeIcon />
            </IconButton>
          </Box>
        )}
        <Swiper
          spaceBetween={0}
          slidesPerView="auto"
          onSwiper={handleSwipe}
          onSlideChange={handleSwipe}
          onInit={handleSwipe}
          ref={swiper}
          centerInsufficientSlides={false}
          centeredSlides={false}
          style={{ width: '100%' }}
        >
          {filters.map((filter, i) => (
            <SwiperSlide
              onClick={(e) => e.stopPropagation()}
              className={classes.slide}
              key={k(i)}
            >
              <Button
                disabled={filter.type !== 'Section' && (filter.disabled || filter.lock)}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={() => {
                  if (filter.type === 'Section' && appliedFilters.section) {
                    if (!(filter.disabled || filter.lock)) {
                      setIsResetable(true);
                      setAppliedFilters({
                        ...appliedFilters,
                        section: {
                          ...appliedFilters.section,
                          [filter.key]: !appliedFilters.section[filter.key]
                        }
                      });
                    }
                  } else {
                    if (filterIndex === i) {
                      setFilterIndex(null);
                    } else {
                      setFilterIndex(i);
                    }
                  }
                }}
                variant="outlined"
                style={
                  appliedFilters.section && filter.type === 'Section' && appliedFilters.section[filter.key]
                    ? {
                        color: filter.color,
                        borderColor: filter.color
                      }
                    : {}
                }
                className={clsx({
                  [classes.filterIndex]: filter.type !== 'Section' && i === filterIndex,
                  [classes.filter]: true
                })}
              >
                <NotEmptyIndicator
                  path={filter.key}
                  showIndicator
                />
                {appliedFilters.section && filter.type === 'Section' && !appliedFilters.section[filter.key] && (
                  <RadioButtonUnchecked style={{ position: 'relative', right: 8 }} />
                )}
                {appliedFilters.section && filter.type === 'Section' && appliedFilters.section[filter.key] && (
                  <CheckCircle style={{ color: filter.color, position: 'relative', right: 8 }} />
                )}
                {t(filter.label)}
                {filter.key === 'preventifs' && (
                  <IconButton
                    size="small"
                    style={{
                      position: 'relative',
                      left: 16,
                      marginLeft: -10
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setFilterIndex(-1);
                    }}
                  >
                    <ArrowDropDown
                      style={{
                        color:
                          preventifsFilter?.key && !!appliedFilters[preventifsFilter.key]?.length
                            ? '#e63d53'
                            : 'rgba(0, 0, 0, 0.54)'
                      }}
                    />
                  </IconButton>
                )}
              </Button>
            </SwiperSlide>
          ))}
        </Swiper>
        {!isEnd && !isBelowMd && (
          <Box className={classes.navFiltersIconsRight}>
            <IconButton
              className={classes.navFiltersIconButton}
              onClick={() =>
                typeof swiper.current?.swiper?.slideNext === 'function' && swiper.current.swiper.slideNext()
              }
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      <Box
        className={classes.filterBarAnchor}
        ref={filterBar}
      />

      {filterIndex !== null && (
        <PopoverFilter
          page={page}
          set={set}
          sets={sets}
          setSets={setSets}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          classes={classes}
          filterBar={filterBar}
          filter={filter}
          filterIndex={filterIndex}
          setFilterIndex={setFilterIndex}
        />
      )}
    </Box>
  ) : null;
}
