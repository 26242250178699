import {
  IconButton,
  Toolbar,
  Button,
  Box,
  Typography,
  MenuItem,
  ListItemText,
  Menu,
  TextField
} from '@material-ui/core';

import { Container, Draggable } from 'react-smooth-dnd';

import useKeys from '@flowsn4ke/usekeys';

import clsx from 'clsx';
import { getDragResult } from 'utils/dnd';

import { useState } from 'react';

import useStyles from './SearchBar.styles';
import useStylesFilters from './FilterBar.styles';
import { useEntity } from 'contexts/entities/entityContext';

import { Add, RemoveCircle, Save, Delete as DeleteIcon, Tune } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import Tooltip from 'components/ToolTip';

export default function SetManager({
  setManageFilterBar,
  manageFilterBar,
  setSets,
  setSet,
  setsKey,
  sets
}) {
  const [title, setTitle] = useState(manageFilterBar.title);
  const [filters, setFilters] = useState(manageFilterBar.filters);

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const { t } = useTranslation();

  const save = () => {
    let newSet;
    if (manageFilterBar.index === false) {
      newSet = [
        ...sets.map((s) => ({ ...s, active: false })),
        {
          title,
          filters,
          active: true,
          index: sets?.length,
          values: {}
        }
      ];
    } else {
      newSet = sets.map((s) =>
        s.index === manageFilterBar.index
          ? {
              title,
              filters,
              active: true,
              index: s.index
            }
          : { ...s, active: false }
      );
    }

    setSet(newSet.find((set) => set.active));
    setSets(newSet);
    localStorage.setItem(setsKey, JSON.stringify(newSet));
    setManageFilterBar(false);
  };

  const remove = () => {
    let deleteSet;

    if (manageFilterBar) {
      deleteSet = sets.filter((f, i) => i !== manageFilterBar.index);
      deleteSet = deleteSet.map((f, i) =>
        i === deleteSet?.length - 1 ? { ...f, active: true, index: i } : f
      );
    }

    setSets(deleteSet);
    localStorage.setItem(setsKey, JSON.stringify(deleteSet));
    setManageFilterBar(false);
  };

  return (
    <>
      <FiltersManager
        filters={filters}
        setFilters={setFilters}
      />

      <TitleManager
        title={title}
        setTitle={setTitle}
        save={save}
        remove={setIsConfirmDeleteOpen}
      />

      <ConfirmDialog
        text={<span>{t('areYouSureYouWantToDeleteFilter')}</span>}
        open={isConfirmDeleteOpen}
        onClose={(e) => setIsConfirmDeleteOpen(false)}
        onConfirm={() => remove()}
      />
    </>
  );
}

function TitleManager({ title, setTitle, save, remove }) {
  const { filter, form, calendar, page } = useEntity();

  const classes = useStyles({ page, loading: false, isList: !page, filter, form, calendar });
  const { t } = useTranslation();

  return (
    <>
      {
        <Toolbar className={classes.searchBar}>
          <Tune style={{ marginLeft: 10 }} />
          <TextField
            className={classes.searchInput}
            placeholder={t('defaultSetFilter')}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            type="text"
            size="small"
            variant="outlined"
          />

          <Button
            style={{ marginLeft: '8px', color: '#e63d53' }}
            onClick={remove}
            endIcon={<DeleteIcon />}
            className={classes.statusClearFilters}
            size={'small'}
          >
            {t('delete')}
          </Button>

          <Button
            style={{ marginLeft: '8px', color: '#31b55a' }}
            onClick={save}
            endIcon={<Save />}
            className={classes.statusClearFilters}
            size={'small'}
          >
            {t('save')}
          </Button>
        </Toolbar>
      }
    </>
  );
}

function FiltersManager({ filters, setFilters }) {
  const { page, settings, filters: allFilters } = useEntity();

  const classes = useStylesFilters({
    loading: false,
    isList: !page,
    settings,
    page,
    filterTabs: false
  });
  const k = useKeys();
  const { t } = useTranslation();

  const [anchorAddFilter, setAnchorAddFilter] = useState(null);

  const openAddMenu = (e) => setAnchorAddFilter(e.currentTarget);
  const closeAddMenu = (e) => setAnchorAddFilter(null);

  const handleDrop = (e) => {
    const res = getDragResult(filters, e);
    setFilters(res);
  };

  const missingFilters = allFilters.filter((f) => !filters.includes(f.key));

  return (
    <Box className={clsx(classes.filtersBar, { [classes.filtersBarSettings]: settings })}>
      <Box
        style={{
          height: '100%',
          overflowY: 'hidden',
          overflowX: 'auto',
          flexGrow: 1,
          overflow: 'auto',
          position: 'relative',
          top: 2
        }}
      >
        <Container
          onDrop={handleDrop}
          orientation="horizontal"
          style={{
            width: '100%',
            height: '100%',
            display: 'block',
            position: 'absolute',
            left: 5,
            top: 0
          }}
        >
          {/* TODO: R */}
          {filters.map((filter, i) => {
            const filterObject = allFilters.find((f) => f.key === filter);
            if (!filterObject) {
              return <></>;
            }
            return (
              <Draggable
                className={classes.slide}
                style={{ position: 'relative', top: 5, whiteSpace: 'nowrap' }}
                key={k(i)}
              >
                <Button
                  endIcon={
                    <IconButton
                      onClick={() => {
                        setFilters(filters.filter((f) => f !== filter));
                      }}
                      className={classes.removeFilter}
                      size={'small'}
                    >
                      <Tooltip title={t('deleteTheFilter')}>
                        <RemoveCircle />
                      </Tooltip>
                    </IconButton>
                  }
                  style={
                    filterObject.type === 'Section'
                      ? {
                          color: filterObject.color,
                          borderColor: filterObject.color
                        }
                      : {}
                  }
                  variant="outlined"
                  className={clsx({
                    [classes.filter]: true,
                    [classes.manageFIlter]: true
                  })}
                >
                  {t(filterObject.label)}
                </Button>
              </Draggable>
            );
          })}
        </Container>

        <FilterAddMenu
          missingFilters={missingFilters}
          setFilters={setFilters}
          filters={filters}
          anchorAddFilter={anchorAddFilter}
          closeAddMenu={closeAddMenu}
          classes={classes}
        />
      </Box>

      <Button
        style={{ whiteSpace: 'nowrap', marginLeft: '10px' }}
        onClick={openAddMenu}
        endIcon={<Add />}
        disabled={!missingFilters?.length}
        size={'small'}
      >
        {t('addSetFilter')}
      </Button>
    </Box>
  );
}

function FilterAddMenu({
  anchorAddFilter,
  classes,
  setFilters,
  filters,
  missingFilters,
  closeAddMenu
}) {
  const { t } = useTranslation();
  const k = useKeys();

  return (
    <>
      <Menu
        transitionDuration={0}
        anchorEl={anchorAddFilter}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={!!anchorAddFilter}
        onClose={closeAddMenu}
        MenuListProps={{ className: classes.moreMenu }}
        className={classes.moreMenu}
      >
        {missingFilters.map((f, i) => (
          <MenuItem
            key={k(i)}
            onClick={() => {
              setFilters([f.key, ...filters]);
              closeAddMenu();
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
            dense
          >
            <ListItemText
              primary={
                <Typography
                  component="span"
                  className={classes.moreMenuText}
                  style={{ fontWeight: 400 }}
                >
                  {t(f.label)}
                </Typography>
              }
              onClick={() => closeAddMenu()}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
