import { Box, Grid, Chip, List, Popover } from '@material-ui/core';
import { useConfiguration } from 'hooks/useConfiguration';

import { equipmentWear } from 'lists/equipmentWear';
import { dateToLocalFormat } from 'utils/dates';
import useStyles from 'layouts/entities/View.styles';
import { ClientItem, DescriptionItem, LocationItem } from 'components/views/ViewItems';
import CustomFields from '../CustomFields';
import ViewItem from 'components/views/ViewItem';
import { useTranslation } from 'react-i18next';
import {
  critical_equipment,
  loss_ratio_equipment,
  LossRatioPopover,
  LossRatioTooltip,
  operational_states_equipment,
  OperationnalTooltip,
  PeriodExpenses,
  replacement_date_planned,
  ReplacementTooltip,
  warranty_equipment,
  WarrantyTooltip
} from 'lists/advance_indicator_equipment';
import { status_of_equipment } from 'lists/status_of_equipment';
import FAIcon from 'components/ui/FAIcon';
import Tooltip from 'components/ToolTip';
import { useState } from 'react';
import Ticket from 'entities/Ticket/Ticket';
import { formatCurrency } from 'utils/formatCurrency';
import { useAuth } from 'hooks/useAuth';

export default function EquipmentView({ element }) {
  const classes = useStyles();
  const configuration = useConfiguration();
  const auth = useAuth();
  const { t } = useTranslation();

  const label_status = status_of_equipment.find((s) => s.value === element.status)?.label;

  const operational_state = element?.operational_state || 'NOT_SPECIFIED';
  const replacement_date = element?.replacement_date || 'NOT_SPECIFIED';
  const warranties = element?._guarantees || 'NOT_SPECIFIED';

  const operational = operational_states_equipment.find((e) => String(e.value) === String(operational_state));
  const replacement_planned = replacement_date_planned(replacement_date);
  const criticity = critical_equipment(element?.criticity);
  const warranty = warranty_equipment(warranties);
  const tickets = element?._tickets || [];
  const loss_ratio = loss_ratio_equipment(tickets);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTicketId, setOpenTicketId] = useState(null);

  const openLossRatioPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeLossRatioPopover = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Box>
        {openTicketId && (
          <Ticket
            isDialog
            defaultOpenView
            selectedId={openTicketId}
            afterDialogClose={() => setOpenTicketId(null)}
          >
            <></>
          </Ticket>
        )}
        <Box>
          <Box>
            <Box className="gap-1.5 flex w-full pr-2 md:pr-10 justify-between">
              <div className="flex gap-1.5 items-center">
                <span className="font-semibold text-base overflow-ellipsis">
                  {element?.name || t('noEquipmentName')}
                </span>
                {!!criticity && configuration.isClient && (
                  <Tooltip
                    title={t(criticity.label)}
                    position="bottom"
                  >
                    <div>
                      <FAIcon
                        icon={criticity?.icon}
                        collection={criticity?.collection}
                        size="medium"
                        className={`hover:${criticity.hoverColor} ${criticity.color} rounded-full`}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>

              {/* IAE */}
              <div className="flex-wrap gap-1.5 items-center hidden lg:flex">
                <Tooltip
                  title={<OperationnalTooltip operational={operational} />}
                  position="bottom"
                >
                  <div className='p-1.5'>
                    <FAIcon
                      icon={operational.icon}
                      collection="fas"
                      size={'medium'}
                      className={`hover:${operational.hoverColor} ${operational.color} rounded-full`}
                    />
                  </div>
                </Tooltip>

                {configuration.isClient && (
                  <>
                    <Tooltip
                      title={<WarrantyTooltip warranty={warranty} />}
                      position="bottom"
                    >
                      <div className='p-1.5'>
                        <FAIcon
                          icon={warranty.icon}
                          collection="fad"
                          size={'medium'}
                          className={`hover:${warranty.hoverColor} ${warranty.color} rounded-full`}
                        />
                      </div>
                    </Tooltip>
                    <div>
                      <Tooltip
                        title={<LossRatioTooltip loss_ratio={loss_ratio} />}
                        position="bottom"
                      >
                        <div
                          onClick={openLossRatioPopover}
                          className="cursor-pointer hover:bg-gray-100 rounded-full p-1.5"
                        >
                          <FAIcon
                            icon={loss_ratio.icon}
                            collection="fad"
                            size={'medium'}
                            className={`hover:${loss_ratio.hoverColor} ${loss_ratio.color} rounded-full`}
                          />
                        </div>
                      </Tooltip>
                      <Popover
                        open={isOpen}
                        anchorEl={anchorEl}
                        onClose={closeLossRatioPopover}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center'
                        }}
                        classes={{
                          paper: '!p-3 !mt-2.5 !shadow !rounded !text-slate-600/95'
                        }}
                      >
                        <LossRatioPopover
                          setOpenTicketId={setOpenTicketId}
                          loss_ratio={loss_ratio}
                          interventions={tickets}
                        />
                      </Popover>
                    </div>
                    <Tooltip
                      title={
                        <ReplacementTooltip
                          replacement_planned={replacement_planned}
                          replacement_date={replacement_date}
                        />
                      }
                      position="bottom"
                    >
                      <div className='p-1.5'>
                        <FAIcon
                          icon={replacement_planned.icon}
                          collection="fad"
                          size={'medium'}
                          className={`hover:${replacement_planned.hoverColor} ${replacement_planned.color} rounded-full`}
                        />
                      </div>
                    </Tooltip>
                  </>
                )}
              </div>
            </Box>

            {(element._category || element._subcategory) && (
              <Box
                display="flex"
                alignItems="center"
                marginTop="10px"
              >
                {element._category && (
                  <Chip
                    className={classes.l1chip}
                    color="secondary"
                    variant="outlined"
                    size="small"
                    label={element._category.name}
                  />
                )}
                {element._subcategory && (
                  <Chip
                    className={classes.l1chip}
                    color="secondary"
                    variant="outlined"
                    size="small"
                    label={element._subcategory.name}
                  />
                )}
                {element.isContract && <strong>{t('underContract')}</strong>}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Grid
        className="mt-2"
        container
      >
        <Grid
          item
          xs={12}
          sm={element._cover ? 6 : 12}
        >
          <List>
            <CustomFields element={element}>
              {configuration.isClient && (
                <PeriodExpenses
                  tickets={tickets}
                  equipment={element}
                />
              )}

              {configuration.isContractor && <ClientItem client={element._client} />}

              <DescriptionItem description={element.description} />

              {!!element._jobs?.length && (
                <ViewItem
                  icon="toolbox"
                  secondaryIcon
                  primary={t('jobs')}
                  secondary={element._jobs.map((j) => t(j.name)).join(', ')}
                />
              )}

              <LocationItem location={element._location} />

              {element.state && t(equipmentWear[element.state]) && (
                <ViewItem
                  icon="asterisk"
                  secondaryIcon
                  primary={t('dilapidationState')}
                  secondary={t(equipmentWear[element.state].label)}
                />
              )}

              {operational_state !== 'NOT_SPECIFIED' && t(operational.label) && (
                <ViewItem
                  icon="asterisk"
                  secondaryIcon
                  primary={t('operationalState')}
                  secondary={t(operational.label)}
                />
              )}

              {element?.status && t(label_status) && (
                <ViewItem
                  icon="asterisk"
                  secondaryIcon
                  primary={t('statusOfEquipment')}
                  secondary={t(label_status)}
                />
              )}

              {!!element.brand?.length && (
                <ViewItem
                  icon="barcode"
                  secondaryIcon
                  primary={t('brand')}
                  secondary={element.brand}
                />
              )}

              {!!element.model?.length && (
                <ViewItem
                  icon="barcode"
                  secondaryIcon
                  primary={t('model')}
                  secondary={element.model}
                />
              )}

              {!!element.serial?.length && (
                <ViewItem
                  icon="barcode"
                  secondaryIcon
                  primary={t('serialNumber')}
                  secondary={element.serial}
                />
              )}

              {element.revision_date && (
                <ViewItem
                  icon="calendar"
                  secondaryIcon
                  primary={t('revisionDate')}
                  secondary={dateToLocalFormat(element.revision_date, 'PP')}
                />
              )}

              {element.replacement_date && (
                <ViewItem
                  icon="calendar"
                  secondaryIcon
                  primary={t('planned-replacement-date')}
                  secondary={dateToLocalFormat(element.replacement_date, 'PP')}
                />
              )}

              {!!element.year?.length && (
                <ViewItem
                  icon="calendar"
                  secondaryIcon
                  primary={t('year')}
                  secondary={element.year}
                />
              )}

              {element.buy_date && (
                <ViewItem
                  icon="calendar"
                  secondaryIcon
                  primary={t('purchaseDate')}
                  secondary={dateToLocalFormat(element.buy_date, 'PP')}
                />
              )}

              {!!element?.purchase_price && (
                <ViewItem
                  icon="money-bills-simple"
                  collection="fad"
                  secondaryIcon
                  primary={t('purchasePrice')}
                  secondary={formatCurrency({
                    number: element?.purchase_price,
                    locale: auth?.company?.currency?.locale,
                    currency: auth?.company?.currency?.code
                  })}
                />
              )}
            </CustomFields>
          </List>
        </Grid>
        {element._cover && (
          <Grid
            item
            xs={12}
            sm={6}
          >
            <div
              style={{
                backgroundImage: `url(${typeof element._cover === 'string' ? element._cover : element._cover.url})`
              }}
              className="w-full h-full bg-contain bg-no-repeat bg-center"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
