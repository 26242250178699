import { Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import IconButton from 'components/IconButton';
import FAIcon from 'components/ui/FAIcon.js';
import { useField } from 'frmx';
import { useConfiguration } from 'hooks/useConfiguration';
import { blachDomains, blachSubDomains } from 'lists/blachere';
import { contractStates } from 'lists/contractStates.js';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { truncateText } from 'utils/uiUtils';
import Tooltip from 'components/ToolTip';
import { useAuth } from 'hooks/useAuth';

function FilterInput({ textFilter, setTextFilter, resetSelection }) {
  const { t } = useTranslation();

  return (
    <div className="sticky top-0 flex items-center h-10 w-full bg-white my-auto shadow rounded-t px-1 z-10">
      <FAIcon
        className="mx-2"
        collection="far"
        icon="magnifying-glass"
        size="small"
      />
      <input
        className="block w-full sm:text-sm md:text-md outline-none"
        placeholder={t('search')}
        value={textFilter}
        onChange={(e) => setTextFilter(e.target.value)}
      />
      <IconButton onClick={resetSelection}>
        <FAIcon
          collection="far"
          icon="circle-xmark"
          size="small"
        />
      </IconButton>
    </div>
  );
}

function OptionButton({ option, isSelected, toggleSelection, expandedParents, toggleExpand }) {
  const truncateSize = 40;
  const isTruncated = option.label.length > truncateSize;
  const hasChildren = option.children?.length > 0;

  const handleClick = (e, callback) => {
    e.preventDefault();
    e.stopPropagation();
    callback(option.value);
  };

  return (
    <Tooltip
      title={isTruncated ? option.label : ''}
      position="right"
      disableHoverListener={!isTruncated}
    >
      <div
        onClick={(e) => handleClick(e, toggleSelection)}
        className={classNames('flex w-full h-10 items-center text-sm font-normal text-gray-900 hover:bg-gray-50', {
          'bg-gray-100': isSelected
        })}
      >
        {hasChildren && (
          <button
            className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-black/5 ml-0.5"
            onClick={(e) => handleClick(e, toggleExpand)}
          >
            <FAIcon
              collection="fas"
              icon="chevron-up"
              className={classNames('transition-transform text-gray-400', {
                'rotate-180': expandedParents[option.value],
                'rotate-90': !expandedParents[option.value]
              })}
            />
          </button>
        )}
        <div className="w-10 flex items-center justify-center">
          <Checkbox
            style={option.color ? { color: option.color } : {}}
            color="primary"
            checked={isSelected}
          />
        </div>
        {truncateText(option.label, truncateSize)}
      </div>
    </Tooltip>
  );
}

function PickerComponent({ fieldKey, optionList }) {
  const { value: elements, setValue: setElements } = useField(fieldKey);
  const { setValue: setOngoing } = useField('ongoing');
  const { setValue: setClosed } = useField('closed');
  const { t } = useTranslation();
  const [textFilter, setTextFilter] = useState('');
  const [expandedParents, setExpandedParents] = useState({});

  // Regroupement des parents et enfants
  const structuredOptions = useMemo(() => {
    const parents = optionList.filter((item) => !item.parent_id);
    const children = optionList.filter((item) => item.parent_id);

    return parents.map((parent) => ({
      ...parent,
      children: children.filter((child) => child.parent_id === parent.value)
    }));
  }, [optionList]);

  // Filtrage par texte
  const filteredOptions = useMemo(() => {
    return structuredOptions
      .map((parent) => {
        const filteredChildren = parent.children.filter((child) =>
          child.label.toLowerCase().includes(textFilter.toLowerCase())
        );

        return parent.label.toLowerCase().includes(textFilter.toLowerCase()) || filteredChildren.length > 0
          ? { ...parent, children: filteredChildren }
          : null;
      })
      .filter(Boolean);
  }, [structuredOptions, textFilter]);

  const resetSelection = () => {
    setElements([]);
    setTextFilter('');
    if (fieldKey === 'status') {
      setOngoing(false);
      setClosed(false);
    }
  };

  const toggleSelection = (value) => {
    const isWithoutDomain = value === 'without-domain';
    const parent = structuredOptions.find((p) => p.value === value);
    const isSelected = elements.includes(value);

    let newElements;

    if (isWithoutDomain) {
      // Si on sélectionne "without-domain", on supprime tous les domaines et sous-domaines
      newElements = isSelected ? [] : ['without-domain'];
    } else {
      // Si on sélectionne un domaine, on s'assure que "without-domain" est désélectionné
      newElements = isSelected
        ? elements.filter((el) => el !== value && (!parent || !parent.children.some((child) => child.value === el)))
        : [
            ...new Set([
              ...elements.filter((el) => el !== 'without-domain'), // Retire "without-domain" si présent
              value,
              ...(parent?.children.map((c) => c.value) || [])
            ])
          ];
    }

    setElements(newElements);
  };

  const toggleExpand = (parentValue) => {
    setExpandedParents((prev) => ({ ...prev, [parentValue]: !prev[parentValue] }));
  };

  return (
    <div className="relative rounded-2xl">
      <FilterInput
        textFilter={textFilter}
        setTextFilter={setTextFilter}
        resetSelection={resetSelection}
      />
      <div className="py-1">
        {filteredOptions.length ? (
          filteredOptions.map((parent) => (
            <div key={parent.value}>
              <div className="flex items-center">
                <OptionButton
                  option={parent}
                  isSelected={elements.includes(parent.value)}
                  toggleSelection={toggleSelection}
                  expandedParents={expandedParents}
                  toggleExpand={toggleExpand}
                />
              </div>

              {expandedParents[parent.value] && parent.children.length > 0 && (
                <div className="ml-14">
                  {parent.children.map((child) => (
                    <OptionButton
                      key={child.value}
                      option={child}
                      isSelected={elements.includes(child.value)}
                      toggleSelection={toggleSelection}
                      expandedParents={expandedParents}
                      toggleExpand={toggleExpand}
                    />
                  ))}
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center p-2">
            <span className="sm:text-sm md:text-md text-center text-gray-700">{t('noElementWasFound')}</span>
          </div>
        )}
      </div>
    </div>
  );
}

// Wrapping components with unique configurations
export function StatesPicker({ path }) {
  const { t } = useTranslation();
  const contractOptions = Object.keys(contractStates).map((s) => ({
    label: t(contractStates[s].label),
    value: s,
    color: contractStates[s].color
  }));

  return (
    <PickerComponent
      fieldKey={path}
      optionList={contractOptions}
    />
  );
}

// Domains or sub domains Blachere:
export function DomainsPicker({ path }) {
  const config = useConfiguration();
  const { t } = useTranslation();
  const auth = useAuth();
  const userLang = auth.user.lang;
  const accountLang = auth.interface._company.lang;

  const domainOptions = config.isBlachere
    ? blachDomains
    : config.job_domains.map((d) => ({
        label: d.i18n?.[userLang] || d.i18n?.[accountLang] || '',
        parent_id: d.parent_id,
        value: d._id
      })) || [];

  // ajoute le sans domaine a la liste (unshift: le met en premier)
  if (!config.isBlachere)
    domainOptions.unshift({ label: t('filter-without-domain'), parent_id: null, value: 'without-domain' });

  return (
    <PickerComponent
      fieldKey={path}
      optionList={domainOptions}
    />
  );
}

export function SubDomainsPicker({ path }) {
  return (
    <PickerComponent
      fieldKey={path}
      optionList={blachSubDomains}
    />
  );
}

export function PrioritiesPicker() {
  const config = useConfiguration();
  const urgencyOptions = config?.urgencies
    ? Object.keys(config.urgencies)
        .map((key) => ({ label: config.urgencies[key].label, value: key }))
        .filter((u) => u?.label?.length > 0)
    : [];

  return (
    <PickerComponent
      fieldKey="priorities"
      optionList={urgencyOptions}
    />
  );
}
