import {
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  makeStyles,
  Menu,
  MenuItem,
  TextField
} from '@material-ui/core';

import { MoreVert as MoreVertIcon, Notifications } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';

import { factureStates } from 'constants/invoiceStates';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useRole } from 'hooks/useRole';
import moment from 'moment';
import { useRef, useState } from 'react';
import { setFactureComment, setFactureState } from 'store/accountingSlice';
import historyEntries from 'constants/historyEntries';
import History from 'entities/Ticket/Tabs/History';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useNotifications from 'hooks/useNotifications';
import { isArray } from 'lodash-es';
import { useConfiguration } from 'hooks/useConfiguration';
import Tooltip from 'components/ToolTip';

export default function StateColumn({ factures, parentId, ticket, viewId }) {
  return parentId ? null : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: '200px'
      }}
    >
      <div>
        <FactureStateLabel ticket={ticket} />
      </div>
      <div>
        <FactureStateMenu
          factures={factures}
          ticket={ticket}
          viewId={viewId}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  badgeSuccess: {
    ['& > span']: {
      backgroundColor: theme.palette.success.dark,
      margin: 0,
      padding: '1px',
      fontSize: '9px'
    }
  },
  badgeError: {
    ['& > span']: {
      backgroundColor: theme.palette.error.dark,
      margin: 0,
      padding: '1px',
      fontSize: '9px'
    }
  }
}));

const FactureStateLabel = ({ ticket: { facture_state, history, _id } }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { color, label } = factureStates[facture_state.key || 'to_complete'];

  if (facture_state.sent) {
    const invoice_sent_history_entry_types = [
      'update-facture-state-sent',
      'update-facture-state-sent-re',
      'update-facture-state-sent-mark'
    ];
    const email_was_delivered = (email) =>
      email && isArray(email) ? email?.some((e) => e?.delivered) : email?.delivered;
    const transmission_attempt_entries = history.filter((h) => invoice_sent_history_entry_types.includes(h.type));
    const last_transmission_attempt = transmission_attempt_entries[0];
    const invoice_was_transmitted = transmission_attempt_entries.some(({ transport }) =>
      email_was_delivered(transport?.email)
    );

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip
          title={`${
            last_transmission_attempt.type.includes('-re')
              ? 'Retransmise'
              : last_transmission_attempt.type.includes('-mark')
              ? 'Marqué comme transmise'
              : 'Transmise'
          } le ${moment(last_transmission_attempt.date).format('DD/MM/YYYY à HH:mm')} par ${
            last_transmission_attempt._user?.firstName
          } ${last_transmission_attempt._user?.lastName[0]}`}
        >
          <strong style={{ color }}>{t(label)}</strong>
        </Tooltip>
        {transmission_attempt_entries.length && (
          <IconButton>
            <Tooltip title={invoice_was_transmitted ? t('emailHasBeenSent') : t('errorTransmissionEmail')}>
              <Badge
                color="secondary"
                className={clsx({
                  [classes.badgeSuccess]: true
                })}
                badgeContent={transmission_attempt_entries.length}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <Notifications color={invoice_was_transmitted ? 'inherit' : 'error'} />
              </Badge>
            </Tooltip>
          </IconButton>
        )}
      </div>
    );
  }

  return <strong style={{ color }}>{t(label)}</strong>;
};

const FactureStateMenu = ({
  factures,
  ticket: { facture_state, _id, facture_comment, history, facture, _locations, _facture_tickets }
}) => {
  const [anchorEL, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [commentDialog, setCommentDialog] = useState({
    isOpen: false,
    ticketId: null,
    comment: ''
  });
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [isSending] = useState(false);
  const [emailDialog, setEmailDialog] = useState({ state: false, re: false });

  const commentDialogTextareaRef = useRef(null);

  const role = useRole();

  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();
  const config = useConfiguration();

  const { t } = useTranslation();

  const location_accounting_feature_is_activated = config.comptability?.location_accounting;
  const local_accounting_email = (_locations || [])[0]?.accounting_email;
  const default_accounting_email = Array.isArray(config.comptability.email)
    ? config?.comptability?.email[0]
    : config.comptability.email;

  const accounting_email = location_accounting_feature_is_activated
    ? local_accounting_email || default_accounting_email
    : default_accounting_email;
  const tooltip_text = t('transmit-to', { email: accounting_email });

  const openMainMenu = (e) => [e.preventDefault(), e.stopPropagation(), setAnchorEl(e.currentTarget), setIsOpen(true)];
  const closeMainMenu = (e) => [e.preventDefault(), e.stopPropagation(), setAnchorEl(null)];

  const handleSend = (rest = {}) => {
    const dispatchCallbacks = {
      onSuccess: () => notify.success(!rest.re ? t('sendEmail') : t('reSendEmail')),
      onError: () => notify.error()
    };

    dispatch(setFactureState, { type: 'sent', value: true, ...rest }, dispatchCallbacks, {
      id: _id
    });

    setEmailDialog(false);
  };

  const factureEvents = Object.entries(historyEntries)
    .filter(([key, e]) => ['calculator', 'calculator'].includes(e.icon) && key !== 'attachments_document')
    .map(([key]) => key);

  const entries = history.filter((e) => factureEvents.includes(e.type));

  const menus = [
    facture_state.sent &&
      role.permission('accounting', 'lock') && {
        label: `${facture_state.lock ? 'openModifications' : 'closeNotifications'}`,
        onClick: () => dispatch(setFactureState, { type: 'lock', value: !facture_state.lock }, {}, { id: _id }),
        icon: facture_state.lock ? 'lock-open' : 'lock'
      },
    facture_state.key === 'sent' &&
      role.permission('accounting', 'send') && {
        label: 'retransmitToAccounting',
        onClick: () =>
          filtered_emails.length > 0 ? setEmailDialog({ state: true, re: true }) : handleSend({ re: true }),
        icon: 'send'
      },
    facture_state.key === 'to_send' &&
      role.permission('accounting', 'mark_as_sent') && {
        label: 'markedAsSent',
        onClick: () => dispatch(setFactureState, { type: 'sent', value: true, mark: true }, {}, { id: _id }),
        icon: 'check'
      },
    (!facture_comment || !facture_comment.length) && {
      label: 'leaveComment',
      onClick: () => setCommentDialog({ ticketId: _id, isOpen: true, comment: facture_comment || '' }),
      icon: 'comment-alt'
    },
    history.some((e) => factureEvents.includes(e.type)) && {
      label: 'history',
      onClick: () => setIsHistoryDialogOpen(true),
      icon: 'history'
    }
  ].filter((menuIcon) => menuIcon);

  const onMenuItemClick = (cb) => (e) => [setIsOpen(false), cb(e)];

  // Obtenir toutes les adresses e-mail des _facture_tickets._locations
  const all_emails_from_all_tickets = _facture_tickets.flatMap((t) =>
    t._locations.map((l) => ({ email: l.accounting_email, location: l, number: t.number }))
  );

  // Filtrer les adresses e-mail et supprimer les undefined
  const filtered_emails = all_emails_from_all_tickets.filter(
    (o) => o.email !== accounting_email && o.email !== undefined && o.email !== '' && o.email !== null
  );

  return (
    <>
      {facture_state.key === 'to_send' &&
        role.permission('accounting', 'send') &&
        (isSending ? (
          <CircularProgress
            size={20}
            style={{ position: 'relative', top: '7px', right: '15px' }}
          />
        ) : // si j'ai plusieur ticket associe et des email different pour certain ticket
        filtered_emails.length > 0 ? (
          <IconButton onClick={() => setEmailDialog({ state: true, re: emailDialog.re })}>
            <FAIcon icon="send" />
          </IconButton>
        ) : (
          <Tooltip title={tooltip_text}>
            <IconButton onClick={() => handleSend()}>
              <FAIcon icon="send" />
            </IconButton>
          </Tooltip>
        ))}

      {menus.length > 0 && (
        <>
          {menus.length === 1 ? (
            <Tooltip title={t(menus[0].label)}>
              <IconButton onClick={menus[0].onClick}>
                <FAIcon icon={menus[0].icon} />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <IconButton
                aria-haspopup="true"
                onClick={openMainMenu}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEL}
                transitionDuration={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={!!anchorEL && !!isOpen}
                onClose={closeMainMenu}
                keepMounted
              >
                {menus.map((menu, i) => {
                  return (
                    <MenuItem
                      key={`action-menu-${_id}-${menu.icon}`}
                      onClick={onMenuItemClick(menu.onClick)}
                    >
                      <FAIcon
                        collection="fad"
                        icon={menu.icon}
                        style={{ marginRight: '8px' }}
                      />
                      {t(menu.label)}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </>
      )}

      {facture_comment && facture_comment.length && (
        <Tooltip title={facture_comment}>
          <IconButton onClick={() => setCommentDialog({ ticketId: _id, isOpen: true, comment: facture_comment || '' })}>
            <FAIcon icon="message" />
            <FAIcon
              collection="fas"
              icon="circle"
              size="xs"
              style={{
                color: 'green',
                position: 'absolute',
                marginTop: '-1.7em',
                marginLeft: '2em'
              }}
            />
          </IconButton>
        </Tooltip>
      )}

      {emailDialog.state && (
        <Dialog
          open={emailDialog.state}
          onClose={() => setEmailDialog({ state: false, re: emailDialog.re })}
        >
          <div className="p-4 w-full">
            <div className="flex justify-end">
              <IconButton onClick={() => setEmailDialog({ state: false, re: emailDialog.re })}>
                <FAIcon icon="times" />
              </IconButton>
            </div>

            {/* title */}
            <div className="flex justify-center p-3">
              <p className="font-bold text-lg">
                {factures.length > 1 ? t('facturesTransmission') : t('factureTransmission')}
              </p>
            </div>

            <div className="flex flex-col justify-center p-4 text-base gap-4">
              <p>
                {t('factureOnlyTo')} : <span className="font-bold">{accounting_email}</span>.
              </p>
              <p>
                {factures.length > 1 ? <span>{t('facturesNotSent')} </span> : <span>{t('factureNotSent')} </span>}
                {filtered_emails.length > 1 ? (
                  <span>{t('factureNotSentTos')} </span>
                ) : (
                  <span>{t('factureNotSentTo')} </span>
                )}
              </p>

              <table className="text-sm">
                <thead>
                  <tr>
                    <th className="border px-4 py-2 w-1/2">{t('email')}</th>
                    <th className="border px-4 py-2 w-1/2">{t('ticketNumber')}</th>
                    {/* <th className="border px-4 py-2">Lieux</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filtered_emails.map((o) => (
                    <tr key={o.email}>
                      <td className="border px-4 py-2">{o.email}</td>
                      <td className="border px-4 py-2">#{o.number}</td>
                      {/* <td className="border px-4 py-2">{o.location.name}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-end">
              <Tooltip title={tooltip_text}>
                <IconButton onClick={() => handleSend({ re: emailDialog.re })}>
                  <FAIcon icon="send" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Dialog>
      )}

      {isHistoryDialogOpen && (
        <Dialog
          maxWidth={'md'}
          open={isHistoryDialogOpen}
          onClose={() => setIsHistoryDialogOpen(false)}
          title="Historique comptabilité"
        >
          <List component="nav">
            <History entries={entries} />
          </List>
        </Dialog>
      )}

      {commentDialog.isOpen && (
        <Dialog
          disableEnforceFocus
          open={commentDialog.isOpen}
          onClose={() => setCommentDialog({ isOpen: false })}
          maxWidth={'xs'}
          fullWidth
        >
          <DialogTitle>{t('comments')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                inputRef={commentDialogTextareaRef}
                fullWidth
                placeholder={t('enterYourCommentHere')}
                multiline
                rows={4}
                rowsMax={10}
                defaultValue={commentDialog.comment}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setCommentDialog({ isOpen: false })}
              color="secondary"
            >
              {t('close')}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setCommentDialog({ isOpen: false });
                dispatch(
                  setFactureComment,
                  { comment: commentDialogTextareaRef.current.value },
                  {},
                  { id: commentDialog.ticketId }
                );
              }}
            >
              {t('save2')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
