import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useField } from 'frmx';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

export default function DomainsSelect({ domains }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const user_lang = auth.user.lang;
  const company_lang = auth.interface._company.lang;

  const sub_domains = domains.filter((d) => d.parent_id);
  domains = domains.filter((d) => !d.parent_id);

  const formattedDomains = domains.map((d) => ({
    ...d,
    sub_domains: sub_domains.filter((sd) => String(sd.parent_id) === String(d._id))
  }));

  const { value: domain, setValue: setDomain } = useField('domain_id');

  return (
    <Grid
      item
      xs={12}
    >
      <FormControl
        variant="outlined"
        fullWidth
      >
        <InputLabel
          htmlFor="outlined-age-native-simple"
          className="bg-white"
        >
          {t('domainLabel')}
        </InputLabel>
        <Select
          fullWidth
          variant="outlined"
          value={domain === null ? 'allDomains' : domain}
          onChange={(e) => setDomain(e.target.value === 'allDomains' ? null : e.target.value)}
        >
          {[
            { _id: null, i18n: { [user_lang]: 'allDomains', [company_lang]: 'allDomains' }, sub_domains: [] },
            ...formattedDomains
          ].flatMap((domain) => [
            <MenuItem
              key={domain._id ?? 'allDomains'}
              value={domain._id === null ? 'allDomains' : domain._id}
            >
              {t(domain.i18n?.[user_lang] || domain.i18n?.[company_lang] || '')}
            </MenuItem>,
            ...domain.sub_domains.map((sub) => (
              <MenuItem
                key={sub._id}
                value={sub._id ?? 'allDomains'}
                style={{ paddingLeft: 32 }}
              >
                {t(sub.i18n?.[user_lang] || sub.i18n?.[company_lang] || '')}
              </MenuItem>
            ))
          ])}
        </Select>
      </FormControl>
    </Grid>
  );
}
