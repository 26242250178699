import { useAuth } from './useAuth';

export const useRole = () => {
  const auth = useAuth();

  // *Checks if a specific permission exist in the passed entity
  const permission = (entity, permission) => {
    if (entity === 'scopes') {
      return true;
    }

    const permissions = auth.interface._role?.permissions[entity];

    return permissions?.includes(permission);
  };

  // *Checks if passed entity is empty
  const checkForPermissions = (entity) => {
    const permissions = auth.interface._role?.permissions[entity];
    return permissions.length > 0;
  };

  return { permission, checkForPermissions };
};
