import {
  TextField,
  Checkbox,
  Divider,
  AccordionActions,
  AccordionDetails,
  Grid,
  FormControlLabel,
  FormHelperText,
  Tabs,
  Tab,
  Button,
  Box,
  Slider,
  Typography,
  IconButton,
  makeStyles,
  Popover,
  useTheme,
  Select,
  MenuItem
} from '@material-ui/core';

import { useCallback, useEffect, useState } from 'react';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { setConfiguration } from 'store/authSlice';
import { Submit, Field, Reset, useFieldObserver, useField } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';

import useStyles from './TicketConfiguration.styles';
import EntityX from 'components/inputs/EntityX';
import Scope from 'entities/Scope/Scope';
import useKeys from '@flowsn4ke/usekeys';
import DisplayMore from 'components/DisplayMore';
import Job from 'entities/Job/Job';
import { useConfiguration } from 'hooks/useConfiguration';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import { useAuth } from 'hooks/useAuth';
import { DragIndicator } from '@material-ui/icons';
import clsx from 'clsx';
import { KINGFISHER_DEMO_ACCOUNT_ID } from 'index';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useConfirm from 'hooks/useConfirm';
import NotEmptyIndicator from 'components/filters/NotEmptyIndicator';
import { languages } from 'config/languages';
import Flag from 'react-flagkit';
import { flag_keys } from 'constants/locales';
import ObjectID from 'bson-objectid';
import { useIsBelowLg } from 'hooks/useMQ';
import { truncateText } from 'utils/uiUtils';

function TabPanel(props) {
  const { children, value, index, ...rest } = props;
  return value === index ? (
    <Box
      p={3}
      {...rest}
    >
      {children}
    </Box>
  ) : null;
}

export default function TicketConfiguration({ section }) {
  const classes = useStyles();
  const [jobDomainError, setJobDomainError] = useState(false);
  const config = useConfiguration();
  const urgencies = config.urgencies;

  const notify = useNotifications();
  const { dispatch, requestStatus } = useAsyncDispatch();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  const { t } = useTranslation();

  const delayTypes = [
    { value: 'days', label: t('days') },
    { value: 'hours', label: t('hours') },
    { value: 'custom', label: t('custom') },
    { value: 'none', label: t('withoutTimeLimit') }
  ];

  const k1 = useKeys();
  const k2 = useKeys();

  const jobd = config?.job_domains?.map((d) => ({
    ...d,
    _categories: [...(Array.isArray(d?._categories) ? d?._categories?.map((c) => ({ ...c })) : [] || [])]
  }));

  const auth = useAuth();
  const company_id = auth?.interface?._company?._id;
  const company_lang = auth?.interface?._company?.lang;

  const domains = jobd.filter((d) => !d.parent_id);
  const sub_domains = jobd.filter((d) => d.parent_id);

  return (
    <AccordionForm
      refresh
      defaultClose
      section={section}
      onSubmit={(updates) => {
        // Fonction pour vérifier que chaque domaine et sous-domaine a une valeur dans i18n[company_lang]
        const isValid = (domain) => domain.i18n?.[company_lang] && domain.i18n?.[company_lang]?.trim() !== '';

        // Vérifier tous les job_domains et sub_job_domains
        const invalidDomains = [...updates.job_domains, ...updates.sub_job_domains].filter((domain) => !isValid(domain));

        if (invalidDomains.length > 0) {
          console.error('Erreur : Tous les domaines et sous-domaines doivent avoir une valeur dans i18n.');
          setJobDomainError(true);
          return; // Arrêter l'exécution si des erreurs sont détectées
        }
        setJobDomainError(false);

        updates.job_domains = updates.job_domains.concat(updates.sub_job_domains);
        delete updates.sub_job_domains;

        dispatch(setConfiguration, updates, dispatchCallbacks);
      }}
      initialValues={{
        _tva: config?._tva,
        default_intervention_date_duration: config?.default_intervention_date_duration || 60,
        job_domains: domains || [],
        sub_job_domains: sub_domains || [],
        ticket_location_mandatory: config?.ticket_location_mandatory || false,
        ticket_job_toassign: config?.ticket_job_toassign || false,
        hide_job: config?.hide_job || false,
        default_job: config?.default_job || null,
        default_seuil: config?.default_seuil || 0,
        default_seuil_enable: config?.default_seuil_enable || false,
        job_mandatory: config?.job_mandatory || false,
        ticket_title_mandatory: config?.ticket_title_mandatory ?? false,
        ticket_description_mandatory: config?.ticket_description_mandatory ?? false,
        ticket_criticity_mandatory: config?.ticket_criticity_mandatory ?? false,
        ticket_contacts_autoselection: config?.ticket_contacts_autoselection ?? false,
        assign_sms: config?.assign_sms || false,
        assign_email: config?.assign_email || false,
        ticket_ir_mandatory: config?.ticket_ir_mandatory || false,
        replan_reasons: config?.replan_reasons || [],
        ticket_domain_mandatory: config?.ticket_domain_mandatory || false,
        ticket_location_unique: config?.ticket_location_unique || false,
        ticket_unique_intervention_date: config?.ticket_unique_intervention_date || false,
        auto_transmission: {
          date_client_to_contractor: config?.auto_transmission?.date_client_to_contractor || false,
          date_contractor_to_client: config?.auto_transmission?.date_contractor_to_client || false,
          date_me_to_contractor: config?.auto_transmission?.date_me_to_contractor || false,
          date_me_to_client: config?.auto_transmission?.date_me_to_client || false,
          document_me_to_client: config?.auto_transmission?.document_me_to_client || false,
          document_me_to_contractor: config?.auto_transmission?.document_me_to_contractor || false,
          document_client_to_contractor: config?.auto_transmission?.document_client_to_contractor || false,
          document_contractor_to_client: config?.auto_transmission?.document_contractor_to_client || false,
          facture_contractor_to_client: config?.auto_transmission?.facture_contractor_to_client || false,
          devis_contractor_to_client: config?.auto_transmission?.devis_contractor_to_client || false
        },
        urgencies: {
          1: {
            color: urgencies['1']?.color || '#000000',
            delay: urgencies['1']?.delay || 0,
            delayType: urgencies['1']?.delayType || '',
            label: urgencies['1']?.label || '',
            roles: []
          },
          2: {
            color: urgencies['2']?.color || '#000000',
            delay: urgencies['2']?.delay || 0,
            delayType: urgencies['2']?.delayType || '',
            label: urgencies['2']?.label || '',
            roles: []
          },
          3: {
            color: urgencies['3']?.color || '#000000',
            delay: urgencies['3']?.delay || 0,
            delayType: urgencies['3']?.delayType || '',
            label: urgencies['3']?.label || '',
            roles: []
          },
          4: {
            color: urgencies['4']?.color || '#000000',
            delay: urgencies['4']?.delay || 0,
            delayType: urgencies['4']?.delayType || '',
            label: urgencies['4']?.label || '',
            roles: []
          }
        },
        follow_up_intervention_delay: config?.follow_up_intervention_delay || false,
        follow_up_resolution_delay: config?.follow_up_resolution_delay || false,
        quotes_price_not_null: config?.quotes_price_not_null || false,
        limit_actions_when_mandatory_quote: config?.limit_actions_when_mandatory_quote || false
      }}
    >
      <AccordionDetails>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('ticketCreation')}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_title_mandatory"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('mandatoryTitle')}
                  />
                  <FormHelperText>{t('mandatoryTitleHelperText')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_description_mandatory"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('mandatoryDescription')}
                  />
                  <FormHelperText>{t('mandatoryDescriptionHelperText')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="hide_job"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('hideJobInModal')}
                  />
                  <FormHelperText>{t('madatoryJobHelperText')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="job_mandatory"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('madatoryJob')}
                  />
                  <FormHelperText>{t('madatoryJobHelperText2')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <EntityX
                    path="default_job"
                    entity={Job}
                    placeholder={t('defaultJob')}
                    pickerUniq
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_location_mandatory"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('mandatoryLocation')}
                  />
                  <FormHelperText>{t('mandatoryLocationHelperText')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_job_toassign"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label="Métier obligatoire pour assigner"
                  />
                  <FormHelperText>{t('noIntervenerOnTicketWithoutJob')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_location_unique"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('uniqueLocation')}
                  />
                  <FormHelperText>{t('uniqueLocationHelperText')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_contacts_autoselection"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('autoSelectUsers')}
                  />
                  <FormHelperText>{t('autoSelectUsersHelperText')}</FormHelperText>
                </Grid>
              </Grid>
            </DisplayMore>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('criticityAndDealines')}>
              <Grid
                container
                spacing={2}
              >
                <Grid item>
                  <Grid>
                    <FormControlLabel
                      control={
                        <Field
                          path="follow_up_intervention_delay"
                          type="checkbox"
                        >
                          <Checkbox />
                        </Field>
                      }
                      label={t('followUpInterventionDelay')}
                    />
                    <FormHelperText>{t('followUpInterventionDelayHelperText')}</FormHelperText>
                  </Grid>
                  <Grid>
                    <FormControlLabel
                      control={
                        <Field
                          path="follow_up_resolution_delay"
                          type="checkbox"
                        >
                          <Checkbox />
                        </Field>
                      }
                      label={t('followUpResolutionDelay')}
                    />
                    <FormHelperText>{t('followUpResolutionDelayHelperText')}</FormHelperText>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_criticity_mandatory"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('mandatoryUrgencies')}
                  />
                  <FormHelperText>{t('mandatoryUrgenciesDes')}</FormHelperText>
                </Grid>
                {Object.keys(urgencies).map((u, i) => (
                  <Grid
                    item
                    xs={12}
                    key={k2(i)}
                  >
                    <Box className={classes.urgencyField}>
                      <Field
                        path={`urgencies.${u}.color`}
                        type="color"
                      >
                        <input className={classes.colorPicker} />
                      </Field>

                      <Field path={`urgencies.${u}.label`}>
                        <TextField
                          label={t('criticity')}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          className={classes.input}
                        />
                      </Field>

                      <Field
                        path={`urgencies.${u}.delay`}
                        type="number"
                      >
                        <TextField
                          label={t('valueTitle')}
                          variant="outlined"
                          className={classNames(classes.input, classes.number)}
                        />
                      </Field>

                      <Field path={`urgencies.${u}.delayType`}>
                        <TextField
                          select
                          variant="outlined"
                          label={t('deadline')}
                          SelectProps={{ native: true }}
                          className={classNames(classes.input, classes.delayType)}
                        >
                          {delayTypes.map((option, i) => (
                            <option
                              key={k1(i)}
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Field>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </DisplayMore>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('notifications')}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="assign_sms"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('preselectSMS')}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="assign_email"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('preselectEmail')}
                  />
                </Grid>
              </Grid>
            </DisplayMore>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('autoTransmission')}>
              <AutomaticTransmissions />
            </DisplayMore>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('domainConfiguration')}>
              <JobDomains />
            </DisplayMore>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('other')}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <EntityX
                    path="_tva"
                    entity={Scope}
                    entityProps={{ type: 'ticketTva' }}
                    placeholder={t('vatRate')}
                    pickerUniq
                  />
                </Grid>

                <InterventionDuration />

                {config.feature?.seuil && <DefaultThreshold />}
              </Grid>
            </DisplayMore>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_ir_mandatory"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('mandatoryIR')}
            />
            <FormHelperText>{t('closeInterventionAfterEnded')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_unique_intervention_date"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('uniqueInterventionDate')}
            />
            <FormHelperText>{t('ticketHaveOneInterventionDate')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="quotes_price_not_null"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('quotes-price-not-null')}
            />
            <FormHelperText>{t('ticket-have-quotes-price-not-null')}</FormHelperText>
          </Grid>

          {KINGFISHER_DEMO_ACCOUNT_ID === company_id && (
            <Grid
              item
              xs={12}
            >
              <FormControlLabel
                control={
                  <Field
                    path="limit_actions_when_mandatory_quote"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('Limiter les actions lorsque le devis est obligatoire')}
              />
              <FormHelperText>
                {t(
                  "Lorsque le devis est obligatoire, bloquer l'entrée d'une date d'intervention, d'une facture ou d'un rapport."
                )}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>

      {jobDomainError && (
        <div className="text-red-500 flex w-full justify-end p-2">
          <p>{t('domain-configuration-error')}</p>
        </div>
      )}

      <Divider />

      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>

        <Submit disabled={requestStatus === 'loading'}>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}

function AutomaticTransmissions() {
  const [tab, setTab] = useState(0);
  const handleChange = (_, newValue) => setTab(newValue);

  const auth = useAuth();
  const is_not_client_interface = !auth?.interface?.isClient;

  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      width="100%"
      flexDirection={'column'}
    >
      <Box>
        {is_not_client_interface ? (
          <>
            <Tabs
              value={tab}
              style={{
                display: 'flex',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: 8
              }}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab
                style={{
                  width: '25%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.23)'
                }}
                label={t('dates')}
              />
              <Tab
                style={{
                  width: '25%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.23)'
                }}
                label={t('docs')}
              />
              <Tab
                style={{
                  width: '25%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.23)'
                }}
                label={t('invoices')}
              />
              <Tab
                style={{ width: '25%' }}
                label={t('quotes')}
              />
            </Tabs>
          </>
        ) : (
          <FormControlLabel
            control={
              <Field
                path="auto_transmission.document_me_to_contractor"
                type="checkbox"
              >
                <Checkbox />
              </Field>
            }
            label={t('autoTransmissionDocsMeToContractor')}
          />
        )}
      </Box>

      <Box>
        {is_not_client_interface && (
          <>
            <TabPanel
              value={tab}
              index={0}
            >
              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.date_client_to_contractor"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDateClientToContractor')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.date_contractor_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDateContractorToClient')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.date_me_to_contractor"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDateMeToContractor')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.date_me_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDateMeToClient')}
              />
            </TabPanel>

            <TabPanel
              value={tab}
              index={1}
            >
              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.document_me_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDocsMeToClient')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.document_me_to_contractor"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDocsMeToSubContractor')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.document_client_to_contractor"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDocsClientToContractor')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.document_contractor_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDocsContractorToClient')}
              />
            </TabPanel>

            <TabPanel
              value={tab}
              index={2}
            >
              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.facture_contractor_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionInvoiceContractorToClient')}
              />
            </TabPanel>

            <TabPanel
              value={tab}
              index={3}
            >
              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.devis_contractor_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionQuoteContractorToClient')}
              />
            </TabPanel>
          </>
        )}
      </Box>
    </Box>
  );
}

const useStyles_drag = makeStyles((theme) => ({
  dragHandle: {
    '&:hover': {
      cursor: 'move'
    }
  }
}));

const get_random_id = (id) => new ObjectID(id).toString();

function JobDomains() {
  const { t } = useTranslation();
  const theme = useTheme();
  const auth = useAuth();
  const defaultLang = auth.interface?._company?.lang || 'fr';
  const job_domains_path = 'job_domains';
  const { value: job_domains, setValue: set_job_domains } = useField(job_domains_path);
  const { value: sub_job_domains, setValue: set_sub_job_domains } = useField('sub_job_domains');

  const handle_drop_job_domain = useCallback(
    ({ removedIndex, addedIndex }) => {
      if (removedIndex < 0 || addedIndex < 0) return;
      const newFields = [...job_domains];
      const [removed] = newFields.splice(removedIndex, 1);
      newFields.splice(addedIndex, 0, removed);
      set_job_domains(newFields);
    },
    [job_domains, set_job_domains]
  );

  const handle_add_job_domain = useCallback(() => {
    set_job_domains([
      ...job_domains,
      { _id: get_random_id(), _job: null, _categories: null, parent_id: null, i18n: { [defaultLang]: '' } }
    ]);
  }, [job_domains, set_job_domains]);

  const handle_delete_job_domain = useCallback(
    (id) => {
      set_job_domains(job_domains.filter(({ _id }) => _id !== id));
      set_sub_job_domains(sub_job_domains.filter(({ parent_id }) => parent_id !== id));
    },
    [job_domains, set_job_domains]
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ marginBottom: '1em' }}
      >
        <FormControlLabel
          control={
            <Field
              path="ticket_domain_mandatory"
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('mandatoryTicketDomainLabel')}
        />
        <FormHelperText>{t('mandatoryTicketDomainDescription')}</FormHelperText>
      </Grid>
      <div
        container
        className="w-full"
      >
        <DomainList
          domains={job_domains}
          set_domains={set_job_domains}
          sub_domains={sub_job_domains}
          set_sub_domains={set_sub_job_domains}
          path={job_domains_path}
          handle_delete_job_domain={handle_delete_job_domain}
          handle_drop_job_domain={handle_drop_job_domain}
        />
        <Grid
          item
          xs={12}
        >
          <div className="w-full flex justify-end">
            <button
              onClick={handle_add_job_domain}
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText
              }}
              className="flex items-center gap-1 p-1.5 pr-2.5 shadow-md text-sm text-white rounded-md hover:!opacity-90 transition mt-1.5"
            >
              <FAIcon
                collection="fal"
                icon="circle-plus"
              />
              {t('addDomain')}
            </button>
          </div>
        </Grid>
      </div>
    </Grid>
  );
}

function InterventionDuration(props) {
  const duration = useFieldObserver('default_intervention_date_duration');
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
    >
      <Typography gutterBottom>
        {t('defaultInterventionDuration')}: <strong>{duration}</strong> {t('minutes')}
      </Typography>

      <Field
        path="default_intervention_date_duration"
        type="range"
        getValueFromArgs={(args) => args[1]}
      >
        <Slider
          min={0}
          max={180}
          step={1}
          valueLabelDisplay="auto"
        />
      </Field>
    </Grid>
  );
}

function DefaultThreshold() {
  const val = useFieldObserver('default_seuil');
  const enabled = useFieldObserver('default_seuil_enable');
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth?.interface?._company;

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Field
              path="default_seuil_enable"
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('activateDefaultThreshold')}
        />
        <FormHelperText>{t('activateDefaultThresholdHelperText')}</FormHelperText>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography gutterBottom>
          {t('defaultThresholdWithoutValidation')}:{' '}
          <strong>
            {enabled ? val : '0'}
            {company?.currency?.symbol ? company?.currency?.symbol : '€'}
          </strong>
        </Typography>

        <Field
          path="default_seuil"
          type="range"
          getValueFromArgs={(args) => args[1]}
          disabled={!enabled}
        >
          <Slider
            min={0}
            max={2000}
            step={10}
            valueLabelDisplay="auto"
          />
        </Field>
      </Grid>
    </>
  );
}

const DomainList = ({
  domains,
  set_domains,
  sub_domains,
  set_sub_domains,
  path,
  handle_delete_job_domain,
  handle_drop_job_domain
}) => {
  const classes = useStyles_drag();
  const [collapsedDomains, setCollapsedDomains] = useState({});

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const toggleSection = (domainId) => {
    setCollapsedDomains((prev) => ({
      ...prev,
      [domainId]: !prev[domainId]
    }));
  };

  const expandSection = (domainId) => {
    setCollapsedDomains((prev) => ({
      ...prev,
      [domainId]: true
    }));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = domains.findIndex((domain) => domain._id === active.id);
      const newIndex = domains.findIndex((domain) => domain._id === over.id);
      handle_drop_job_domain({ removedIndex: oldIndex, addedIndex: newIndex });
    }
  };

  if (!Array.isArray(domains) || domains.length === 0) return null;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={domains.map((d) => d._id)}
        strategy={verticalListSortingStrategy}
      >
        {domains.map((domain, domain_idx) => (
          <SortableItem
            key={domain._id}
            domain={domain}
            domain_idx={domain_idx}
            path={path}
            classes={classes}
            collapsedDomains={collapsedDomains}
            toggleSection={toggleSection}
            expandSection={expandSection}
            handle_delete_job_domain={handle_delete_job_domain}
            set_domains={set_domains}
            sub_domains={sub_domains}
            set_sub_domains={set_sub_domains}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

const SortableItem = ({
  domain,
  domain_idx,
  path,
  classes,
  collapsedDomains,
  toggleSection,
  expandSection,
  handle_delete_job_domain,
  sub_domains,
  set_sub_domains,
  set_domains
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const belowLg = useIsBelowLg();
  const defaultLang = auth.interface?._company?.lang || 'fr';
  const [locale, setLocale] = useState(defaultLang);
  const [domainLabel, setDomainLabel] = useState(domain.i18n?.[locale] || '');
  const [handlePopover, setHandlePopover] = useState(null);
  const [selectedJob, setSelectedJob] = useState(domain._job);
  const [selectedCategories, setSelectedCategories] = useState(domain._categories);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: domain._id });
  const handle_delete_sub_job_domain = useCallback(
    (id) => set_sub_domains(sub_domains.filter(({ _id }) => _id !== id)),
    [sub_domains]
  );

  const handle_add_sub_job_domain = useCallback(
    ({ parent_id = null }) => {
      console.log(collapsedDomains, parent_id);
      expandSection(parent_id);
      set_sub_domains([
        {
          _id: get_random_id(),
          _job: null,
          _categories: null,
          parent_id: get_random_id(parent_id),
          i18n: { [defaultLang]: '' }
        },
        ...sub_domains
      ]);
    },
    [sub_domains, set_sub_domains]
  );

  useEffect(() => {
    setDomainLabel(domain.i18n?.[locale] || '');
  }, [locale, domain.i18n]);

  const update_domain_label_i18n = (label) => {
    setDomainLabel(label);
    set_domains((prev) => {
      const updated_domain = prev.find((d) => d._id === domain._id);
      if (!updated_domain.i18n) updated_domain.i18n = {};
      if (!domain.i18n) domain.i18n = {};
      updated_domain.i18n[locale] = label;
      domain.i18n[locale] = label;
      return [...prev];
    });
  };

  const onDragEnd = ({ active, over }) => {
    if (!over || active.id === over.id) return;
    set_sub_domains((prev) =>
      arrayMove(
        prev,
        prev.findIndex(({ _id }) => _id === active.id),
        prev.findIndex(({ _id }) => _id === over.id)
      )
    );
  };

  const count_sub_domains = sub_domains.filter((sd) => String(sd.parent_id) === String(domain._id)).length;

  const updateDomain = (key, value) => {
    set_domains((prev) => prev.map((d) => (String(d._id) === String(domain._id) ? { ...d, [key]: value } : d)));
  };

  const selectJob = (e) => {
    setSelectedJob(e);
    updateDomain('_job', e);
  };

  const selectCategory = (e) => {
    setSelectedCategories(e);
    updateDomain('_categories', e);
  };

  return (
    <>
      <div
        ref={setNodeRef}
        style={{ transform: CSS.Transform.toString(transform), transition }}
        {...attributes}
        className="flex items-center p-1 w-full mb-4 sm:mb-0"
      >
        <div className="hidden sm:block">
          <DragIndicator
            {...listeners}
            className={clsx(classes.dragHandle, 'cursor-grab')}
          />
        </div>
        <div
          onClick={() => toggleSection(domain._id)}
          className={`relative hidden sm:flex items-center justify-center transition rounded-full h-8 w-8 ${
            !count_sub_domains ? 'cursor-not-allowed' : 'hover:bg-black/5'
          }`}
        >
          <NotEmptyIndicator
            counter={count_sub_domains}
            showIndicator
            className="-top-2 -right-[2px]"
          />
          <FAIcon
            collection="fas"
            icon="chevron-up"
            className={`${collapsedDomains[domain._id] && count_sub_domains ? 'rotate-180' : 'rotate-90'} ${
              !count_sub_domains ? 'text-gray-300' : 'text-gray-500'
            } transition-transform mx-2`}
          />
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full p-2 gap-2 md:gap-4">
          <div className="w-full lg:w-[61%]">
            <TextField
              variant="outlined"
              label={t('domainName')}
              value={domainLabel}
              onChange={(e) => update_domain_label_i18n(e.target.value)}
              InputProps={{
                endAdornment: (
                  <div className="flex items-center w-20 h-full relative left-2">
                    <Select
                      value={locale}
                      onChange={(e) => setLocale(e.target.value)}
                      variant="standard"
                      disableUnderline
                      renderValue={(value) => (
                        <Flag
                          country={flag_keys[value]}
                          className="rounded-sm"
                        />
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            paddingRight: 6,
                            maxHeight: 366,
                            overflowY: 'auto',
                            scrollbarWidth: 'none' // Cache la scrollbar sur Firefox
                          },
                          sx: {
                            '&::-webkit-scrollbar': {
                              display: 'none' // Cache la scrollbar sur Chrome/Safari
                            }
                          }
                        }
                      }}
                    >
                      {languages.map(({ key, label }, i) => (
                        <MenuItem
                          key={i}
                          value={key}
                          className="flex items-center gap-2"
                        >
                          <Flag
                            country={flag_keys[key]}
                            className="rounded-sm"
                          />
                          <div className="flex items-center gap-2">
                            <span>{key.toUpperCase()}</span> -<span>{truncateText(domain?.i18n?.[key], 22)}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )
              }}
            />
          </div>
          <EntityX
            className="w-full lg:w-1/2"
            path={`${path}.${domain_idx}._job`}
            entity={Job}
            placeholder={t('job')}
            pickerUniq
            no_margin_style_select
            afterChange={selectJob}
          />
          <EntityX
            className="w-full lg:w-1/2"
            path={`${path}.${domain_idx}._categories`}
            entity={Scope}
            entityProps={{ type: 'equipmentCategory' }}
            placeholder={t('equipment-category')}
            no_margin_style_select
            afterChange={selectCategory}
            style={{
              maxWidth: !belowLg ? '350px' : 'none'
            }}
          />
          <div
            className="rounded-full hover:bg-black/5 transition p-1.5 hidden sm:block"
            onClick={(e) => setHandlePopover(e.currentTarget)}
          >
            <FAIcon
              collection="fas"
              icon="ellipsis-v"
              className="cursor-pointer"
            />
          </div>
          <DomainActionsPopover
            handlePopover={handlePopover}
            setHandlePopover={setHandlePopover}
            handle_delete_job_domain={handle_delete_job_domain}
            handle_add_sub_job_domain={handle_add_sub_job_domain}
            domain={domain}
          />
        </div>
      </div>

      {/* Sous Domaines */}
      {(collapsedDomains[domain._id] || count_sub_domains <= 0) && (
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            items={sub_domains.map((sd) => sd._id)}
            strategy={verticalListSortingStrategy}
          >
            <div className="mb-1 -mt-1.5 ml-10 mr-0.5">
              {/* Sous domaines du domaine courant */}
              {sub_domains
                .filter((sd) => String(sd.parent_id) === String(domain._id))
                .map((sjd) => {
                  const index = sub_domains.findIndex((sd) => String(sd._id) === String(sjd._id));

                  return (
                    <SubDomainItem
                      key={sjd._id}
                      index={index}
                      path={'sub_job_domains'}
                      domain={{
                        ...domain,
                        _job: selectedJob,
                        _categories: selectedCategories
                      }}
                      sub_domain={sjd}
                      handle_delete_sub_job_domain={handle_delete_sub_job_domain}
                      defaultLang={defaultLang}
                      classes={classes}
                      set_sub_domains={set_sub_domains}
                    />
                  );
                })}
            </div>
          </SortableContext>
        </DndContext>
      )}
    </>
  );
};

const DomainActionsPopover = ({
  handlePopover,
  setHandlePopover,
  handle_delete_job_domain,
  handle_add_sub_job_domain,
  domain
}) => {
  const { t } = useTranslation();
  const confirm = useConfirm();

  const deleteModal = (domain_id) => {
    confirm({
      icon: 'triangle-exclamation',
      title: 'delete-domain-title',
      helperText: 'delete-domain-description',
      confirmLabel: 'confirm',
      cancelLabel: 'cancel',
      onConfirm: () => handle_delete_job_domain(domain_id)
    });
  };

  return (
    <Popover
      open={handlePopover}
      anchorEl={handlePopover}
      onClose={() => setHandlePopover(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: -40
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      classes={{
        paper: '!rounded-md !shadow-md'
      }}
    >
      <div className="flex flex-col gap-1 p-1 text-sm relative">
        <button
          onClick={() => [handle_add_sub_job_domain({ parent_id: domain._id }), setHandlePopover(null)]}
          className="rounded-md flex items-center gap-1 p-1.5 hover:bg-black/5 transition"
        >
          <FAIcon
            collection="fal"
            icon="circle-plus"
          />
          {t('add-sub-domain')}
        </button>
        <button
          onClick={() => [deleteModal(domain._id), setHandlePopover(null)]}
          className="rounded-md flex items-center gap-1 p-1.5 hover:bg-black/5 transition"
        >
          <FAIcon
            collection="fal"
            icon="trash-can"
          />
          {t('delete-domain')}
        </button>
      </div>
    </Popover>
  );
};

const SubDomainItem = ({
  domain,
  sub_domain,
  index,
  path,
  handle_delete_sub_job_domain,
  defaultLang,
  classes,
  set_sub_domains
}) => {
  const { t } = useTranslation();
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [locale, setLocale] = useState(defaultLang);
  const [subDomainLabel, setSubDomainLabel] = useState(sub_domain.i18n?.[locale] || '');
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: sub_domain._id });
  const confirm = useConfirm();

  console.log(selectedJob, Boolean(domain._job && !sub_domain._job && !selectedJob), domain, sub_domain);

  useEffect(() => {
    setSubDomainLabel(sub_domain.i18n?.[locale] || '');
  }, [locale, sub_domain.i18n]);

  const update_sub_domain_label_i18n = (label) => {
    setSubDomainLabel(label);
    set_sub_domains((prev) => {
      const updated_domain = prev.find((d) => d._id === sub_domain._id);
      if (!updated_domain.i18n) updated_domain.i18n = {};
      if (!sub_domain.i18n) sub_domain.i18n = {};
      updated_domain.i18n[locale] = label;
      sub_domain.i18n[locale] = label;
      return [...prev];
    });
  };

  const deleteModal = (domain_id) => {
    confirm({
      icon: 'triangle-exclamation',
      title: 'delete-sub-domain-title',
      helperText: 'delete-sub-domain-description',
      confirmLabel: 'confirm',
      cancelLabel: 'cancel',
      onConfirm: () => handle_delete_sub_job_domain(domain_id)
    });
  };

  const updateSubDomain = (key, value) => {
    set_sub_domains((prev) => prev.map((d) => (String(d._id) === String(sub_domain._id) ? { ...d, [key]: value } : d)));
  };

  const selectJob = (e) => {
    setSelectedJob(e);
    updateSubDomain('_job', e);
  };

  const selectCategory = (e) => {
    setSelectedCategories(e);
    updateSubDomain('_categories', e);
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="flex items-center p-0.5 w-full mb-4 sm:mb-0"
    >
      <div className="hidden sm:block">
        <DragIndicator
          {...listeners}
          className={clsx(classes.dragHandle, 'cursor-grab')}
        />
      </div>
      <div className="flex flex-col sm:flex-row items-center w-full p-2 gap-2 sm:gap-4">
        <div className="w-full lg:w-[79%]">
          <TextField
            className="w-full"
            variant="outlined"
            label={t('sub-domain-name')}
            value={subDomainLabel}
            onChange={(e) => update_sub_domain_label_i18n(e.target.value)}
            InputProps={{
              endAdornment: (
                <div className="flex items-center w-20 h-full relative left-2 z-20">
                  <Select
                    value={locale}
                    onChange={(e) => setLocale(e.target.value)}
                    variant="standard"
                    disableUnderline
                    renderValue={(value) => (
                      <Flag
                        country={flag_keys[value]}
                        className="rounded-sm"
                      />
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          paddingRight: 6,
                          maxHeight: 366,
                          overflowY: 'auto',
                          scrollbarWidth: 'none' // Cache la scrollbar sur Firefox
                        },
                        sx: {
                          '&::-webkit-scrollbar': {
                            display: 'none' // Cache la scrollbar sur Chrome/Safari
                          }
                        }
                      }
                    }}
                  >
                    {languages.map(({ key, label }, i) => (
                      <MenuItem
                        key={i}
                        value={key}
                        className="flex items-center gap-2"
                      >
                        <Flag
                          country={flag_keys[key]}
                          className="rounded-sm"
                        />
                        <div className="flex items-center gap-2">
                          <span>{key.toUpperCase()}</span>-<span>{truncateText(sub_domain?.i18n?.[key], 22)}</span>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )
            }}
          />
        </div>

        <EntityX
          className="w-full lg:w-1/2"
          path={`${path}.${index}._job`}
          entity={Job}
          placeholder={
            domain._job && !sub_domain._job && !selectedJob
              ? t('parent-job-domain-name', { name: domain._job.name })
              : t('job')
          }
          pickerUniq
          no_margin_style_select
          afterChange={selectJob}
        />

        <EntityX
          className="w-full lg:w-1/2"
          path={`${path}.${index}._categories`}
          entity={Scope}
          entityProps={{ type: 'equipmentCategory' }}
          placeholder={
            domain._categories?.length && !sub_domain._categories?.length && !selectedCategories.length
              ? t('parent-categories-domain-name', { name: domain?._categories?.map((c) => c.name).join(', ') })
              : t('equipment-category')
          }
          no_margin_style_select
          afterChange={selectCategory}
        />

        <div
          onClick={() => deleteModal(sub_domain._id)}
          className="rounded-full hover:bg-black/5 transition p-1.5 hidden sm:block"
        >
          <FAIcon
            collection="fal"
            icon="trash-can"
          />
        </div>
      </div>
    </div>
  );
};
