import { useEffect, useMemo, useState } from 'react';
import DataTable from 'components/dataTable/DataTable';
import axios from 'axios';
import { apiBaseURL } from 'index';
import Ticket from 'entities/Ticket/Ticket';
import DataTableSkeleton from 'components/dataTable/DataTableSkeleton';
import useKeys from '@flowsn4ke/usekeys';
import useNotifications from 'hooks/useNotifications';
import FilterAndSearchBarForm from 'components/filters/FilterAndSearchBarForm';
import SectionPicker from 'components/filters/SectionPicker';
import EntityPopoverFilter from 'components/filters/EntityPopoverFilter';
import Equipment from 'entities/Equipment/Equipment';
import Location from 'entities/Location/Location';
import Intervener from 'entities/Intervener/Intervener';
import Maintenance from 'entities/Maintenance/Maintenance';
import useDebouncedState from 'hooks/useDebouncedState';
import MenuItem from 'components/menu/MenuItem';
import TableRow from './TableRow';
import Pagination from './Pagination';
import NoResultData from './NoResultData';
import Dialog from 'components/Dialog';
import { useField } from 'frmx';
import { useTranslation } from 'react-i18next';
import Feed from 'modules/comments/Feed';
import FAIcon from 'components/ui/FAIcon';
import classNames from 'classnames';
import { contractStates } from 'lists/contractStates';
import { pick } from 'lodash-es';
import { saveAs } from 'file-saver';
import ListPopoverFilter from 'components/filters/ListPopoverFilter';
import { IconButton } from '@material-ui/core';

const reportListFilters = [
  { _id: true, label: 'Avec', color: '#22c55e' },
  { _id: false, label: 'Sans', color: '#ef4444' }
];

const columns = ['interventions', 'generatorName', 'location', 'status', 'reserves', 'interventionDate', ''];

const filters = [
  <SectionPicker
    label="reserves"
    path="hasReserve"
    color="teal"
  />,
  <EntityPopoverFilter
    label="maintenancesTitle"
    entity={Maintenance}
    path="_preventifs"
  />,
  <SectionPicker
    label="ongoing"
    path="ongoing"
    color="green"
  />,
  <SectionPicker
    label="closed"
    path="closed"
    color="black-blue"
  />,
  <ListPopoverFilter
    label="reportsBob!Desk"
    options={reportListFilters}
    path="internal_reports"
  />,
  <ListPopoverFilter
    label="externalReports"
    options={reportListFilters}
    path="external_reports"
  />,
  <EntityPopoverFilter
    label="intervenersTitle"
    entity={Intervener}
    path="_interveners"
  />,
  <EntityPopoverFilter
    label="locations"
    entity={Location}
    path="_locations"
  />,
  <EntityPopoverFilter
    label="equipmentsTitle"
    entity={Equipment}
    path="_equipments"
  />,
  <EntityPopoverFilter
    label="state"
    entity={StatesTicketPicker}
    path="states"
    className="py-0"
  />
];

const sortingOrders = [
  {
    icon: 'sort-up',
    label: 'creationDateMostRecent',
    sortKey: -1
  },
  {
    icon: 'sort-down',
    label: 'creationDateLeastRecent',
    sortKey: 1
  }
];

export default function ReportFile() {
  const k = useKeys();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  const [showDialog, setShowDialog] = useState(false);

  const [openTicketId, setOpenTicketId] = useState(null);
  const [commentsTabId, setCommentsTabId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const notify = useNotifications();

  // Get current page
  const indexOfLastPage = currentPage * dataPerPage;
  const indexOfFirstPage = indexOfLastPage - dataPerPage;

  // * FILTERS
  const initialValues = useMemo(() => {
    return {
      search: '',
      _locations: [],
      _equipments: [],
      _preventifs: [],
      external_reports: [],
      internal_reports: [],
      ongoing: true,
      closed: false,
      hasReserve: true,
      hasReserveUrgency: false,
      states: [],
      sort: 'creation',
      order: -1
    };
  }, []);

  const [appliedFilters, setAppliedFilters, debouncedAppliedFilters] = useDebouncedState(initialValues);

  const getCommissions = async () => {
    setLoading(true);

    const skip = indexOfFirstPage;

    const query = {
      ...appliedFilters,
      internal_reports: appliedFilters?.internal_reports ? appliedFilters.internal_reports.map((l) => l._id) : [],
      external_reports: appliedFilters?.external_reports ? appliedFilters.external_reports.map((l) => l._id) : [],
      _locations: appliedFilters?._locations ? appliedFilters._locations.map((l) => l._id) : [],
      _equipments: appliedFilters?._equipments ? appliedFilters._equipments.map((l) => l._id) : [],
      _preventifs: appliedFilters?._preventifs ? appliedFilters._preventifs.map((l) => l._id) : [],
      _interveners: appliedFilters?._interveners ? appliedFilters._interveners.map((l) => l._id) : [],
      states: appliedFilters?.states ? appliedFilters.states.map((l) => l.value) : [],
      skip
    };

    return axios
      .get(`${apiBaseURL}/commissions`, { params: { ...query } })
      .then((res) => {
        setData(res.data.commissions);
        setCount(res.data.totalTicketsCount);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
        notify.error();
      });
  };

  useEffect(() => {
    getCommissions();
  }, [currentPage, debouncedAppliedFilters]);

  // Change page
  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleExportSafetyCommitee = () => {
    setDownloadLoading(true);

    const filters = {
      ...appliedFilters,
      internal_reports: appliedFilters?.internal_reports ? appliedFilters.internal_reports.map((l) => l._id) : [],
      external_reports: appliedFilters?.external_reports ? appliedFilters.external_reports.map((l) => l._id) : [],
      _locations: appliedFilters?._locations ? appliedFilters._locations.map((l) => l._id) : [],
      _equipments: appliedFilters?._equipments ? appliedFilters._equipments.map((l) => l._id) : [],
      _preventifs: appliedFilters?._preventifs ? appliedFilters._preventifs.map((l) => l._id) : [],
      _interveners: appliedFilters?._interveners ? appliedFilters._interveners.map((l) => l._id) : [],
      states: appliedFilters?.states ? appliedFilters.states.map((l) => l.value) : []
    };

    axios
      .post(`${apiBaseURL}/commissions/export`, { filters })
      .then((res) => {
        setDownloadLoading(false);
        notify.success(t('exportSuccessMessage'));

        const filename = res.request
          .getResponseHeader('Content-Disposition')
          .split(';')[1]
          .replace(/filename=/, '')
          .replace(/"/g, '')
          .trim();

        const csvExport = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
        });
        saveAs(csvExport, filename);
      })
      .catch((err) => {
        setDownloadLoading(false);
        notify.error(t('errorOccured'));
      });
  };

  return (
    <>
      <FilterAndSearchBarForm
        withFilters
        initialValues={initialValues}
        onChange={(data) => [setAppliedFilters(data), paginate(1)]}
        menuItems={[
          <MenuItem
            icon="rotate-right"
            label="refresh"
            onClick={() => getCommissions()}
          />,
          <MenuItem
            icon={'download'}
            label={t('ExportXEls', { count })}
            onClick={() => handleExportSafetyCommitee()}
            className={downloadLoading ? 'pointer-events-none' : ''}
          />
        ]}
        filters={filters}
        sortingOrders={sortingOrders}
        downloadLoading={downloadLoading}
      />
      {loading ? (
        <DataTableSkeleton columns={columns} />
      ) : data.length === 0 ? (
        <NoResultData />
      ) : (
        <div>
          <div className="max-h-[68vh] overflow-y-auto overflow-x-hidden">
            <DataTable
              columns={columns}
              count={count}
              rows={data.map((commission, commissionsIdx) => (
                <TableRow
                  key={k(commissionsIdx)}
                  commission={commission}
                  commissionsIdx={commissionsIdx}
                  setOpenTicketId={setOpenTicketId}
                  setShowDialog={setShowDialog}
                  data={data}
                  setData={setData}
                  setCommentsTabId={setCommentsTabId}
                />
              ))}
            />
          </div>
          <div className="sticky bottom-0 bg-white rounded-xl">
            <Pagination
              dataPerPage={dataPerPage}
              totalData={count}
              paginateBack={paginateBack}
              paginateFront={paginateFront}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
        </div>
      )}

      {/* ! MODAL TICKET */}
      <Ticket
        isDialog
        noFetch
        defaultOpenView
        childrenId={openTicketId}
        childrenAuto
        afterDialogClose={() => setOpenTicketId(null)}
      >
        <></>
      </Ticket>
      {showDialog && (
        <Dialog
          icon="message-lines"
          isOpen={showDialog}
          onClose={() => setShowDialog(false)}
          title="Commentaires"
          width="lg"
        >
          <div className="mt-2 flex flex-col max-h-[80vh] overflow-auto">
            <Feed comments_tab_id={commentsTabId} />
          </div>
        </Dialog>
      )}
    </>
  );
}

function StatesTicketPicker() {
  const k = useKeys();
  const { t } = useTranslation();

  const { value: elements, setValue: setElements } = useField('states');
  const [textFilter, setTextFilter] = useState('');

  const options = useMemo(() => {
    const ticketStates = Object.keys(contractStates).map((s) => ({
      label: t(contractStates[s].label),
      value: s,
      color: contractStates[s].color
    }));

    if (textFilter.length > 0) {
      const filtered = ticketStates.filter((s) => s.label.toLowerCase().includes(textFilter.toLowerCase()));

      if (!filtered.length) {
        return [{ label: t('noElementWasFound'), value: 'noResults' }];
      }
      return filtered;
    }

    return ticketStates;
  }, [textFilter]);

  const isSelected = (element) => {
    return elements.find((el) => el.value === element.value);
  };

  return (
    <div className="relative rounded-lg">
      <div className="flex items-center h-10 w-full bg-white my-auto drop-shadow rounded-t px-1">
        <FAIcon
          className="mx-1"
          collection="far"
          icon="magnifying-glass"
          size="small"
        />
        <input
          focus="true"
          className="block w-full sm:text-sm md:text-md outline-none"
          placeholder={t('search')}
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
        />
        <IconButton
          button
          onClick={() => [setElements([]), setTextFilter('')]}
          className="m-1 rounded-full p-1"
        >
          <FAIcon
            collection="far"
            icon="circle-xmark"
            size="small"
          />
        </IconButton>
      </div>
      <div className="w-full">
        <div className="max-h-[450px] overflow-y-auto">
          {options?.map((option, i) => {
            return option.value === 'noResults' ? (
              <div
                className="flex items-center justify-center p-2"
                key={k(i)}
              >
                <span className=" sm:text-sm md:text-md text-center text-gray-700">{option.label}</span>
              </div>
            ) : (
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  if (isSelected(option)) {
                    setElements((elements) => elements.filter((el) => el.value !== option.value));
                  } else {
                    setElements((elements) => [...elements, pick(option, ['value', 'label', 'color'])]);
                  }
                }}
                key={k(i)}
                className={classNames(
                  'flex w-full h-8 items-center text-base font-normal hover:font-medium text-gray-900 border-gray-300 hover:bg-slate-50',
                  isSelected(option) ? 'bg-slate-100' : ''
                )}
              >
                <div className="w-12 flex items-center justify-center text-center">
                  <FAIcon
                    collection="fal"
                    icon={isSelected(option) ? 'square-check' : 'square'}
                    className={classNames('mx-0.5', isSelected(option) ? ' text-slate-500' : 'text-slate-200')}
                  />
                </div>
                {option.color && (
                  <span
                    className="w-2 h-2 rounded-full mr-2"
                    style={{ backgroundColor: option.color }}
                  />
                )}
                {option.label}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
