import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import {
  setInterface,
  cleanupUser,
  transformUser,
  transformInterface,
  setToken,
  setPublicHeaders,
  unsetPublicHeaders
} from 'utils/authUtils';

import { updateUser } from 'store/usersSlice';
import { setSplashAvatar } from 'utils/uiUtils';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { ACCESS_TOKEN, INTERFACE_ID } from 'constants/localStorage';

import { createElement as intervenerCreateElement } from './intervenersSlice';
import { updateElement as updateRole } from './rolesSlice';

import { publicCreateElement } from 'store/ticketsSlice';
import { assign } from '@recursive/assign';
import { oldMergeWithArrays } from 'utils/mergeWithArrays';

export const retrieveUserInfos = generateAsyncThunk({ type: 'GET', endpoint: 'users2/new-me' });

export const switch_interface = generateAsyncThunk({ type: 'POST', endpoint: 'auth/switch/${id}' });
export const loginUser = generateAsyncThunk({ type: 'POST', endpoint: 'auth/login' });
export const signup = generateAsyncThunk({ type: 'POST', endpoint: 'auth/signup' });
export const logoutUser = generateAsyncThunk({ type: 'POST', endpoint: 'auth/logout' });
export const fetchUserData = generateAsyncThunk({ type: 'GET', endpoint: 'auth/me' });
export const fetchInterfaceData = generateAsyncThunk({
  type: 'GET',
  endpoint: 'auth/me/interface'
});
export const setPassword = generateAsyncThunk({ type: 'POST', endpoint: 'auth/reset/${token}' });
export const setUserAvatar = generateAsyncThunk({ type: 'FILE', endpoint: 'auth/infos/avatar' });
export const setCompanyAvatar = generateAsyncThunk({
  type: 'FILE',
  endpoint: 'auth/configs/avatar'
});
export const uploadDocument = generateAsyncThunk({ type: 'FILE', endpoint: 'image' });
export const uploadDocumentPublic = generateAsyncThunk({ type: 'FILE', endpoint: 'image' });
// TODO: Don't send back configs in http response
export const setInfo = generateAsyncThunk({ type: 'PUT', endpoint: 'auth/infos' });
export const setCompanyInfo = generateAsyncThunk({ type: 'PUT', endpoint: 'auth/configs' });
export const setConfiguration = generateAsyncThunk({ type: 'PUT', endpoint: 'configurations' });
export const setNewPassword = generateAsyncThunk({ type: 'PUT', endpoint: 'auth/password' });

export const uploadFile = generateAsyncThunk({ type: 'FILE', endpoint: 'miscs/upload' });

export const createMessage = generateAsyncThunk({ type: 'GET', endpoint: 'auth/message/${id}' });
export const attachClient = generateAsyncThunk({ type: 'POST', endpoint: 'auth/attach/${id}' });

export const fetchPublicData = generateAsyncThunk({ type: 'GET', endpoint: 'auth/public' });

/**
 * * Action for pricing and benefits marketplace
 */
export const setPricingBenefits = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'auth/configs/setPricingBenefits'
});

export const createApiToken = generateAsyncThunk({ type: 'POST', endpoint: 'tokens' });
export const updateApiToken = generateAsyncThunk({ type: 'PUT', endpoint: 'tokens/${id}' });
export const deleteApiToken = generateAsyncThunk({ type: 'DELETE', endpoint: 'tokens/${id}' });
export const getApiToken = generateAsyncThunk({ type: 'GET', endpoint: 'tokens' });

const accessToken = localStorage.getItem(ACCESS_TOKEN);

if (accessToken) {
  axios.defaults.headers.common['x-auth'] = accessToken;
}

const initialState = {
  accessToken,
  user: null,
  interface: null,
  publicTicketCreated: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      cleanupUser(state);
    },
    switchInterface: (state, action) => {
      if (action.payload._id !== state.interface._id) {
        setInterface(state, action.payload);
      }
    },
    firstConnect: (state, action) => {
      state.user.last_connect = true;
    },
    resetPublicTicketCreated: (state, action) => {
      state.publicTicketCreated = false;
    }
  },
  extraReducers: {
    [publicCreateElement.fulfilled]: (state, action) => {
      state.publicTicketCreated = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      setToken(state, action.payload.data.token);
    },
    [retrieveUserInfos.fulfilled]: (state, action) => {
      unsetPublicHeaders();
      const user = transformUser(action.payload.data.user);

      const client_id = localStorage.getItem(INTERFACE_ID);
      const lastUsedInterface = user.interfaces.find((client) => client._id === client_id);

      const client_interface = !client_id || !lastUsedInterface ? user.interfaces[0] : lastUsedInterface;

      state.user = user;
      setInterface(state, client_interface);
    },
    [retrieveUserInfos.rejected]: (state) => {
      cleanupUser(state);
    },
    [signup.fulfilled]: (state, action) => {
      const is_created_by_signup = action.payload.data.is_created_by_signup;
      setToken(state, action.payload.data.token);

      const interface_client = action.payload.data.interface_client;
      const interface_transformed = transformInterface(interface_client);

      setInterface(state, interface_transformed);

      state.is_created_by_signup = is_created_by_signup;
    },
    [fetchPublicData.fulfilled]: (state, action) => {
      const user = transformUser(action.payload.data.user);
      const _interface = user.interfaces[0];

      const clientId = _interface.publicClient;
      const contractorId = action.meta.arg.contractor;

      state.user = user;
      state.accessToken = null;

      setPublicHeaders({ clientId, contractorId });
      setInterface(state, _interface);
    },
    [fetchUserData.fulfilled]: (state, action) => {
      unsetPublicHeaders();
      const user = transformUser(action.payload.data.user);

      const client_id = localStorage.getItem(INTERFACE_ID);
      const lastUsedInterface = user.interfaces.find((client) => client._id === client_id);

      const client_interface = !client_id || !lastUsedInterface ? user.interfaces[0] : lastUsedInterface;

      state.user = user;
      setInterface(state, client_interface);
    },
    [fetchInterfaceData.fulfilled]: (state, action) => {
      state.interface.data = action.payload.data;
    },
    [updateRole.fulfilled]: (state, action) => {
      const { element } = action.payload.data;

      if (state.interface._role._id === element._id) {
        state.interface._role = element;
      }

      state.user.interfaces = state.user.interfaces.map((i) => {
        return i._role?._id === element._id
          ? {
              ...i,
              _role: element
            }
          : {
              ...i
            };
      });
    },
    [fetchUserData.rejected]: (state) => {
      cleanupUser(state);
    },
    [setCompanyAvatar.fulfilled]: (state, action) => {
      updateCompanySettingsEverywhere(state, action.payload.data);
      setSplashAvatar(state.interface);
    },
    [setUserAvatar.fulfilled]: (state, action) => {
      state.user.avatar = action.payload.data.avatar;
    },
    [setInfo.fulfilled]: (state, action) => {
      state.user = assign(state.user, action.meta.arg);
    },
    [updateUser.fulfilled]: (state, action) => {
      state.user = assign(state.user, action.meta.arg);
    },
    [setConfiguration.fulfilled]: (state, action) => {
      const updatedKeys = Object.keys(action.meta.arg);

      if (updatedKeys.includes('clients_settings')) {
        updateCompanySettingsEverywhere(state, action.meta.arg);
      } else {
        updateCompanyConfigurationEverywhere(state, action.meta.arg);
      }
    },
    [setCompanyInfo.fulfilled]: (state, action) => {
      const updates = action.meta.arg;

      const newName = updates?.contractor?.companyName || updates?.client?.name;
      if (newName) state.interface._company.name = newName;
      updateCompanySettingsEverywhere(state, updates?.contractor || updates?.client);
    },
    [attachClient.fulfilled]: (state, action) => {
      if (!action.meta.arg.attach) {
        state.user.interfaces = state.user.interfaces.filter((_i) => _i._company._id !== action.meta.id);
      } else {
        // TODO: Return only the new interface and the new token in the response
        setToken(state, action.payload.data.token);
        state.user.interfaces = action.payload.data.user._clients.map((i) => transformInterface(i, state.user));
      }
    },
    [intervenerCreateElement.fulfilled]: (state, action) => {
      if (state.interface.data) {
        state.interface.data.hasIntervener = true;
      }
    },
    [setPricingBenefits.fulfilled]: (state, action) => {},
    // get apiToken
    [getApiToken.fulfilled]: (state, action) => {
      state.api_tokens = action.payload.data.tokens;
    },
    // set apiToken
    [createApiToken.fulfilled]: (state, action) => {
      state.api_tokens = [...state.api_tokens, action.payload.data.token];
    },
    // updateApiToken
    [updateApiToken.fulfilled]: (state, action) => {
      const index = state.api_tokens.findIndex((t) => t._id === action.payload.data.token._id);
      state.api_tokens = [
        ...state.api_tokens.slice(0, index),
        action.payload.data.token,
        ...state.api_tokens.slice(index + 1)
      ];
    },
    // deleteApiToken
    [deleteApiToken.fulfilled]: (state, action) => {
      const id_of_token = state.api_tokens.findIndex((t) => t._id === action.meta.arg.token_id);
      state.api_tokens = [...state.api_tokens.slice(0, id_of_token), ...state.api_tokens.slice(id_of_token + 1)];
    }
  }
});

export const selectAuth = (state) => state.auth;
export const { logout, switchInterface, firstConnect, resetPublicTicketCreated } = authSlice.actions;

export default authSlice.reducer;

const updateCompanySettingsEverywhere = (state, updates) => {
  state.interface._company = oldMergeWithArrays(state.interface._company, updates);

  state.user.interfaces.forEach((i) => {
    if (i._id === state.interface._id) i._company = oldMergeWithArrays(i._company, updates);
  });
};

const updateCompanyConfigurationEverywhere = (state, updates) => {
  if (updates.companyName) state.interface._company.name = updates.companyName;

  state.interface._company._configuration = oldMergeWithArrays(state.interface._company?._configuration, updates);

  state.user.interfaces.forEach((_interface) => {
    if (_interface._id === state.interface._id) {
      oldMergeWithArrays(_interface._company?._configuration, updates);

      if (updates.companyName) _interface._company.name = updates.companyName;
    }
  });
};
