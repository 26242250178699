import { Grid, TextField, Typography } from '@material-ui/core';
import PhoneField from 'components/inputs/PhoneField';
import { Field, useField } from 'frmx';
import { useTranslation } from 'react-i18next';

export default function SupplierForm({ element }) {
  const { value: email, setValue: setEmail, error: emailError } = useField('email');

  const { t } = useTranslation();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field
          path="companyName"
          isErrorProp="error"
        >
          <TextField
            variant="outlined"
            label={t('companyNameSupplier')}
            fullWidth
            required
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="reference">
          <TextField
            variant="outlined"
            label={t('reference')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        container
        style={{ marginLeft: 14 }}
      >
        <Typography variant="h6">{t('informationContactSupplier')}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="firstName">
          <TextField
            variant="outlined"
            label={t('firstName')}
            fullWidth
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field
          path="lastName"
          isErrorProp="error"
        >
          <TextField
            variant="outlined"
            label={t('lastName')}
            fullWidth
            required
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          error={emailError}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          label={t('email')}
          helperText={emailError ? t('noEmailSupplierHelperText') : ''}
          fullWidth
          required
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="phone"
          label={t('cellphoneNumber')}
          variant="outlined"
          placeholder={t('cellphoneNumber')}
        />
      </Grid>
    </>
  );
}
