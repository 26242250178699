import Maintenance from 'entities/Maintenance/Maintenance';

import { Box, Dialog, DialogActions, Button, IconButton } from '@material-ui/core';
import { ChevronLeft, Visibility } from '@material-ui/icons';

import { useIsBelowMd, useIsBelowXl } from 'hooks/useMQ';

import { useEffect, useMemo, useRef, useState } from 'react';

import useStyles from 'layouts/entities/Entity.styles';

import clsx from 'clsx';
import Timeline from 'components/timeline/Timeline';
import { Pick } from 'layouts/entities/Element';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TicketFile from 'modules/tickets/ticket-list/TicketFile';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function MaintenancePage() {
  const isMobile = useIsBelowMd();
  if (isMobile) {
    return (
      <div className="h-full w-full px-[10px] pt-[10px]">
        <TicketFile
          showFilters
          defaultFilters={{ types: ['maintenance'] }}
        />
      </div>
    );
  }
  return <Maintenances />;
}

function Maintenances() {
  const isBelowXl = useIsBelowXl();
  const [dialog, setDialog] = useState(false);
  const [displayAllMaintenances, setDisplayAllMaintenances] = useState(false);

  const { id: urlId } = useParams();

  const [expand, setExpand] = useState(false);

  const [fullscreen, setFullscreen] = useState(isBelowXl && !!urlId);
  const [maintenanceId, setMaintenanceId] = useState(urlId);
  const classes = useStyles({ leftPane: true });
  const headerRef = useRef(null);
  const headerRefDialog = useRef(null);
  const history = useHistory()

  const { t } = useTranslation();

  const entityMenus = useMemo(
    () =>
      isBelowXl
        ? []
        : [
            {
              label: t('seeAllMaintenances'),
              icon: <Visibility />,
              action: () => {
                setDisplayAllMaintenances(!displayAllMaintenances);
                setMaintenanceId(null);
                const basePath = history.location.pathname.split('/').slice(0, 2).join('/'); // Garde "/maintenances"
                history.push(basePath);
              }
            }
          ],
    []
  );

  useEffect(() => setDialog(fullscreen), [fullscreen]);

  useEffect(() => {
    if (urlId) {
      setDisplayAllMaintenances(false);
    }
    setMaintenanceId(urlId);
  }, [urlId]);

  return (
    <Box
      className={classes.container}
      style={{ display: 'flex', background: 'transparent' }}
    >
      <Box
        className={clsx({
          [classes.list]: true,
          [classes.listExpandable]: true,
          ['isExpanded']: expand,
          ['fullExpanded']: isBelowXl
        })}
      >
        <Box
          width="100%"
          height="100%"
          position="relative"
          className="listContent"
        >
          <Maintenance
            page
            entityMenus={entityMenus}
            off
            maintenances
            afterClick={(m) => [isBelowXl && setDialog(true)]}
          />
          {!isBelowXl && (
            <IconButton
              className="expandBtn"
              onClick={() => setExpand(!expand)}
            >
              <ChevronLeft />
            </IconButton>
          )}
        </Box>
      </Box>

      {(!isBelowXl || !fullscreen) && (
        <Box
          className={classes.viewWithHeader}
          style={{ flexGrow: 1, maxWidth: 'auto', height: '100%' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            boxShadow={!!maintenanceId ? 1 : 0}
            height="100%"
            borderRadius="16px 16px 8px 8px"
            style={{ width: '100%' }}
          >
            {(!!maintenanceId || displayAllMaintenances) && (
              <>
                <Box
                  zIndex={5}
                  ref={headerRef}
                ></Box>
                <TimeLine
                  hiddenFilters={displayAllMaintenances ? {} : { preventifs: [maintenanceId] }}
                  maintenanceId={maintenanceId}
                  displayAllMaintenances={displayAllMaintenances}
                  headerRef={headerRef}
                  fullscreen={fullscreen}
                  setFullscreen={setFullscreen}
                />
              </>
            )}
            {!maintenanceId && !displayAllMaintenances && (
              <Pick
                icon={'heartbeat'}
                translations={{ pick: t('entityMaintenancePick') }}
              />
            )}
          </Box>
        </Box>
      )}

      {(isBelowXl || fullscreen) && (
        <Dialog
          fullWidth
          fullScreen={true}
          maxWidth={'lg'}
          disableEnforceFocus
          open={dialog}
          onClose={(e) => setDialog(false)}
        >
          <TimeLine
            hiddenFilters={displayAllMaintenances ? {} : { preventifs: [maintenanceId] }}
            maintenanceId={maintenanceId}
            displayAllMaintenances={displayAllMaintenances}
            headerRef={headerRefDialog}
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
          />
          <DialogActions className="bg-gray-100">
            <Box
              zIndex={5}
              ref={headerRefDialog}
              className="ml-24"
            ></Box>
            <Button
              onClick={(e) => [setDialog(false), setFullscreen(false)]}
              color="primary"
            >
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

function TimeLine({ hiddenFilters, maintenanceId, displayAllMaintenances, headerRef, fullscreen, setFullscreen }) {
  return (
    <Maintenance
      timeline
      disableCreate
      hiddenFilters={hiddenFilters}
      refreshFilters
      listComponent={({ elements, setIsOpenForm, fetchElements, isLoading, sort }) => {
        return (
          <Timeline
            sort={sort}
            displayAllMaintenances={displayAllMaintenances}
            isLoading={isLoading}
            fetchElements={fetchElements}
            headerRef={headerRef}
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
            maintenanceId={maintenanceId}
            setIsOpenForm={setIsOpenForm}
            elements={elements}
          />
        );
      }}
    />
  );
}
