import { Fab, Portal } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './Entity.styles';
import { useUI } from 'hooks/useUI';

function EntityCreate({ ctaContainer, in: _in, size, label, icon, onClick, fab }) {
  const classes = useStyles();
  const ui = useUI();
  const primaryColor = ui.getPaletteColors()?.primary?.main || '#31b55a';

  return (
    <>
      {!fab && _in && (
        <Portal container={ctaContainer?.current}>
          <div className="fixed bottom-5 right-5 lg:top-3 lg:bottom-auto lg:right-20 lg:z-[1200] z-20">
            <button
              onClick={onClick}
              className="w-12 h-12 text-sm font-medium 
                lg:w-auto lg:h-auto lg:pl-5 lg:pr-6 lg:py-2 
                rounded-full lg:rounded-full 
                text-white lg:text-gray-900 
                flex items-center justify-center 
                shadow-lg 
                bg-[var(--primary-color)] lg:bg-white 
                hover:bg-opacity-90 lg:hover:bg-gray-100 
                transition"
              style={{ '--primary-color': primaryColor }}
            >
              {icon()}
              <span className="hidden lg:inline ml-2">{label}</span>
            </button>
          </div>
        </Portal>
      )}

      {fab && _in && (
        <Fab
          size={size || 'small'}
          color="primary"
          className={clsx({
            [classes.fab]: true,
            [classes.fabList]: true
          })}
          onClick={onClick}
        >
          {icon()}
        </Fab>
      )}
    </>
  );
}

export default EntityCreate;
