import { TextField, Grid, Select, MenuItem } from '@material-ui/core';
import DateField from 'components/inputs/DateField';
import MonitoringPeriodField from 'components/inputs/MonitoringPeriodField';

import { useEntity } from 'contexts/entities/entityContext';
import { Field, useFieldObserver } from 'frmx';
import { useTranslation } from 'react-i18next';
import { addToAndFormatDate } from 'utils/dates';

export default function ContractForm() {
  const { translations } = useEntity();
  const { t } = useTranslation();

  const startDate = useFieldObserver('start_date');
  const duration = useFieldObserver('duration');
  const durationType = useFieldObserver('duration_type');
  const alert_deadline = useFieldObserver('alert_deadline');

  const end_date = startDate ? addToAndFormatDate(startDate, duration, durationType) : null;

  return (
    <>
      <Grid
        item
        md={12}
      >
        <Field path="name">
          <TextField
            variant="outlined"
            fullWidth
            label={t(translations.entityName)}
          />
        </Field>
      </Grid>

      <Grid
        item
        md={12}
      >
        <Field path="unit_price_schedule">
          <TextField
            variant="outlined"
            fullWidth
            label={t('unitPriceSchedule')}
          />
        </Field>
      </Grid>
      <Grid
        item
        md={12}
      >
        <Field path="intervention_delay">
          <TextField
            variant="outlined"
            fullWidth
            label={t('interventionDelay')}
          />
        </Field>
      </Grid>
      <Grid
        item
        md={12}
      >
        <Field path="travelling_expenses">
          <TextField
            variant="outlined"
            fullWidth
            label={t('travellingExpenses')}
          />
        </Field>
      </Grid>
      <Grid
        item
        md={12}
      >
        <Field path="hourly_rate">
          <TextField
            variant="outlined"
            fullWidth
            label={t('hourlyRate')}
          />
        </Field>
      </Grid>

      <Grid
        item
        md={12}
      >
        <Field path="standing_by">
          <TextField
            variant="outlined"
            fullWidth
            label={t('standingBy')}
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={6}
      >
        <Field
          path="duration"
          type="number"
        >
          <TextField
            variant="outlined"
            fullWidth
            label={t('duration')}
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={6}
      >
        <Field path="duration_type">
          <Select
            fullWidth
            variant={'outlined'}
          >
            <MenuItem
              key="g-form-year"
              value="years"
            >
              {t('years')}
            </MenuItem>
            <MenuItem
              key="g-form-month"
              value="months"
            >
              {t('month')}
            </MenuItem>
          </Select>
        </Field>
      </Grid>

      <Grid
        item
        xs={6}
      >
        <DateField
          path="start_date"
          variant="outlined"
          label={t('start')}
        />
      </Grid>

      <Grid
        item
        xs={6}
      >
        <TextField
          disabled={true}
          variant="outlined"
          fullWidth
          type="date"
          label={t('end')}
          value={end_date || ''}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <MonitoringPeriodField
          startDate={startDate}
          end_date={end_date}
          alert_deadline={alert_deadline}
        />
      </Grid>
    </>
  );
}
