import { useEffect, useMemo, useState } from 'react';
import { FormControl, Input, InputAdornment, makeStyles } from '@material-ui/core';

import { debounce } from 'lodash-es';
import { formatCurrency } from 'utils/formatCurrency';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { setPriceToIntervention, getElement } from 'store/accountingSlice';
import { useTranslation } from 'react-i18next';
import FAIcon from 'components/ui/FAIcon';
import { useAuth } from 'hooks/useAuth';
import Tooltip from 'components/ToolTip';

const useStyles = makeStyles((theme) => ({
  underline: {
    '&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0)'
    }
  }
}));

// TODO check permissions !!
export default function InterventionColumn({
  interventionPrice = 0,
  devisPrice,
  devis,
  ticketId,
  parentId,
  childrenTickets,
  invoiceState,
  viewId,
  _facture_ticket
}) {
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;
  const childrenInterventionPrice = childrenTickets.reduce((total, t) => total + t.intervention_price, 0);
  const totalInterventionPrice = interventionPrice + childrenInterventionPrice;

  // invoiceState.key === 'to_complete' && childrenTickets?.length >= 0 ? 0 : interventionPrice

  return parentId || !childrenTickets.length ? (
    <div className='flex items-center justify-end pr-2 w-full text-center text-sm min-w-[150px]'>
      {(devis.filter((devis) => devis.validated.state && !devis.deleted.state).length || devisPrice > 0) && (
        <Tooltip
          title={
            interventionPrice.toFixed(2) === devisPrice.toFixed(2) ? t('quotesLabelSuccess') : t('quotesLabelError')
          }
        >
          <FAIcon
            icon={interventionPrice.toFixed(2) === devisPrice.toFixed(2) ? 'equals' : 'not-equal'}
            collection="fas"
            size="small"
            className={interventionPrice.toFixed(2) === devisPrice.toFixed(2) ? 'text-[#31b559]' : 'text-[#e63d53]'}
          />
        </Tooltip>
      )}
      <PriceInput
        defaultValue={interventionPrice}
        type="intervention"
        ticketId={ticketId}
        _facture_ticket={_facture_ticket}
        viewId={viewId}
        disabled={invoiceState.lock || false /* TODO check roles !hasPermissions(role, 'compta.update_price') */}
      />
    </div>
  ) : (
    <span className='flex items-center justify-end pr-2 w-full text-center text-sm min-w-[150px]'>
      {formatCurrency({
        number: totalInterventionPrice,
        locale: company?.currency?.locale,
        currency: company?.currency?.code
      })}
    </span>
  );
}

const PriceInput = ({ defaultValue, ticketId, type, disabled, _facture_ticket }) => {
  const classes = useStyles();
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  const dispatchCallbacks = {
    onSuccess: () => notify.success(t('priceModifiedIntervention')),
    onError: () => notify.error(t('somethingWrongWith'))
  };
  const [price, setPrice] = useState(defaultValue > 0 ? defaultValue.toFixed(2) : Number(0).toFixed(2));
  const [debouncePrice, setDebouncePrice] = useState(price);

  // TODO change logic to use facture_ticket
  useEffect(() => {
    setPrice(defaultValue > 0 ? defaultValue.toFixed(2) : Number(0).toFixed(2));
  }, [defaultValue]);

  const debouncedChangePrice = useMemo(() => {
    return debounce((value) => {
      setDebouncePrice(value);
      dispatch(
        setPriceToIntervention,
        {
          type,
          price: parseFloat(value.length ? value.replace(',', '.') : 0),
          from: 'compta',
          isParent: false
        },
        dispatchCallbacks,
        { id: ticketId }
      ).then(({ data }) => {
        if (data.element._facture_ticket) {
          dispatch(getElement, {}, {}, { id: data.element._facture_ticket._id || data.element._facture_ticket });
        }
      });
    }, 1500);
  }, [debouncePrice]);

  const onChangeInterventionPrice = (value) => {
    setPrice(value);
    debouncedChangePrice(value);
  };

  return (
    <FormControl style={{ maxWidth: '100px', minWidth: '80px' }}>
      <Input
        disabled={disabled}
        type="text"
        value={price}
        onChange={(e) => onChangeInterventionPrice(e.target.value)}
        endAdornment={
          <InputAdornment position="end">{company?.currency?.symbol ? company?.currency?.symbol : '€'}</InputAdornment>
        }
        classes={classes}
        inputProps={{ style: { textAlign: 'right', fontSize: '14px' } }}
      />
    </FormControl>
  );
};
