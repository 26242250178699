import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Switch
} from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useTranslation } from 'react-i18next';
import { deleteAutomation, updateAutomation } from 'store/managersSlice';
import useNotifications from 'hooks/useNotifications';
import { useRole } from 'hooks/useRole';
import Tooltip from 'components/ToolTip';

export const AutomationActions = ({ automation, setCurrentAutomation, setOpenModal, manager, isContractor }) => {
  const { dispatch } = useAsyncDispatch();
  const { t } = useTranslation();
  const notify = useNotifications();
  const { permission } = useRole();

  const handleEdit = () => {
    setCurrentAutomation(automation);
    setOpenModal(true);
  };

  const handleToggle = () => {
    dispatch(
      updateAutomation,
      {
        ...automation,
        isActive: !automation.isActive
      },
      {},
      {
        id: manager._id,
        automation_id: automation._id
      }
    ).then(() => notify.success(t('editedAutomatisation')));
  };

  const handleDelete = () => {
    dispatch(
      deleteAutomation,
      {},
      {},
      {
        id: manager._id,
        automation_id: automation._id
      }
    ).then(() => notify.success(t('autoIsDeleted')));
  };

  const formatText = (allItems, items, defaultText) => {
    if (allItems) {
      return t(defaultText);
    }
    if (items?.length > 0) {
      const displayedItems = items
        .slice(0, 3)
        .map((item) => item.name)
        .join(', ');
      const additionalText = items.length > 3 ? '...' : '';
      return `${displayedItems}${additionalText} (${items.length})`;
    }
    return t(defaultText);
  };

  const primaryText = formatText(automation?.allJobs, automation?._jobs, 'allJobs');
  const secondaryText = formatText(automation?.allLocations, automation?._locations, 'allLocations');
  const clientText = formatText(automation?.allClients, automation?._clients, 'allClients');

  return (
    <ListItem
      className="border-b m-0 p-0 hover:bg-gray-100 cursor-pointer transition"
      onClick={handleEdit}
    >
      <ListItemAvatar>
        <FAIcon
          collection="fas"
          icon="bolt"
        />
      </ListItemAvatar>
      {isContractor ? (
        <ListItemText
          primary={clientText}
          // primaryText + retour a la ligne + secondaryText
          secondary={
            <>
              {primaryText} <br /> {secondaryText}
            </>
          }
          className="mr-28"
        />
      ) : (
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          className="mr-28"
        />
      )}

      <ListItemSecondaryAction>
        {permission('automatisations', 'update') && (
          <>
            <Tooltip title={t(automation.isActive ? 'autoIsActivated' : 'autoIsDeactivated')}>
              <Switch
                disabled={!permission('automatisations', 'update')}
                checked={automation.isActive}
                color="primary"
                onChange={handleToggle}
              />
            </Tooltip>
            <IconButton onClick={handleEdit}>
              <FAIcon
                collection="fas"
                icon="edit"
              />
            </IconButton>
          </>
        )}
        {permission('automatisations', 'delete') && (
          <IconButton onClick={handleDelete}>
            <FAIcon
              collection="fas"
              icon="trash"
            />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
