import { add, differenceInMinutes, endOfDay, isDate, startOfDay } from 'date-fns';
import { useSize } from 'hooks/useSize';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  compute_week_start,
  compute_week_end,
  filter_events_outside_range,
  break_down_multiple_days_events,
  start_of_week_of_start_of_month,
  end_of_week_of_end_of_month,
  extract_events_from_tickets,
  get_event_y_position_in_minutes,
  format_unavailabilities
} from 'modules/calendar/utils';
import {
  HOURS_COLUMN_WIDTH,
  DAYS_IN_A_WEEK,
  TOOLBAR_HEIGHT,
  WEEK_VIEW_TYPE,
  MONTH_VIEW_TYPE,
  YEAR_VIEW_TYPE,
  DAY_VIEW_TYPE,
  SET_RANGE_EVENT_ID
} from 'modules/calendar/config';
import Toolbar from 'modules/calendar/Toolbar';
import FilterAndSearchBarForm from 'components/filters/FilterAndSearchBarForm';
import DailyView from './daily/View';
import MonthlyView from './monthly/View';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import { apiBaseURL } from 'index';
import Maintenance from 'entities/Maintenance/Maintenance';
import Intervener from 'entities/Intervener/Intervener';
import EntityPopoverFilter from 'components/filters/EntityPopoverFilter';
import SectionPicker from 'components/filters/SectionPicker';
import ListPopoverFilter from 'components/filters/ListPopoverFilter';
import { ticket_states } from 'lists/contractStates';
import Scope from 'entities/Scope/Scope';
import Location from 'entities/Location/Location';
import Equipment from 'entities/Equipment/Equipment';
import useDebouncedState from 'hooks/useDebouncedState';
import { get_company_id_from_auth } from 'utils/authUtils';
import MenuItem from 'components/menu/MenuItem';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { getElements as getInterveners } from 'store/intervenersSlice';
import Bookmark from 'components/filters/Bookmark';
import useKeys from '@flowsn4ke/usekeys';
import Job from 'entities/Job/Job';
import Manager from 'entities/Manager/Manager';
import { periods_options } from 'lists/periods_checked';
import { priorities_options } from 'lists/priorities';
import Client from 'entities/Client/Client';
import Ticket from 'entities/Ticket/Ticket';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import UnavailabilityEditionDialog from 'modules/dialogs/UnavailabilityEditionDialog';
import { useIsBelowLg, useIsBelowMd, useIsBelowSm } from 'hooks/useMQ';
import UnavailabilityCreationDialog from 'modules/dialogs/UnavailabilityCreationDialog';
import DialogActionsCreate from 'modules/dialogs/DialogActionsCreate';
import { trigger, useDocumentListener } from 'react-events-utils';
import { formatFiltersCalendar } from 'pages/dashboard/CalendarPage2';

export default function Calendar() {
  const notify = useNotifications();
  const { t } = useTranslation();
  const isBelowSm = useIsBelowSm();
  const events_container_ref = useRef();
  const size = useSize(events_container_ref);
  const [days_offset, set_days_offset] = useState(0);
  const [months_offset, set_months_offset] = useState(0);
  const [view_type, set_view_type] = useState(WEEK_VIEW_TYPE);
  const [events, set_events] = useState([]);
  const auth = useAuth();
  const company_id = get_company_id_from_auth(auth);
  const [appliedFilters, setAppliedFilters, debouncedAppliedFilters] = useDebouncedState(initialValues);
  const [unavailabilities, set_unavailabilities] = useState([]);
  const [ticket_in_modal, set_ticket_in_modal] = useState(null);
  const [should_fetch_interventions, set_should_fetch_interventions] = useState(true);
  const [should_fetch_unavailabilities, set_should_fetch_unavailabilities] = useState(true);
  const [open_ticket_form, set_open_ticket_form] = useState(false);
  const [open_dialog_actions, set_open_dialog_actions] = useState(false);
  const [open_unavailability_creation_dialog, set_open_unavailability_creation_dialog] = useState(false);
  const [is_submit_unavailability, set_is_submit_unavailability] = useState(false);
  const [unavailability_id_to_edit, set_unavailability_id_to_edit] = useState(null);
  const [selected_range, set_selected_range] = useState({
    start: null,
    start_idx: null,
    end: null,
    end_idx: null
  });

  useDocumentListener(SET_RANGE_EVENT_ID, ({ detail }) => {
    set_selected_range((prev) => ({ ...prev, ...detail }));
  });

  const [technicians, setTechnicians] = useState([]);
  const { dispatch } = useAsyncDispatch();
  const k = useKeys();
  const [totalResults, setTotalResults] = useState(0);

  const all_technicians_bookmark = {
    label: 'allTechnicians',
    color: '#0369a1',
    value: 'reset'
  };

  useEffect(() => {
    dispatch(getInterveners, technicians_request_args).then(({ data }) => {
      setTechnicians([
        all_technicians_bookmark,
        ...data.elements?.map(({ _id, color, firstName, lastName }) => ({
          label: `${firstName} ${lastName ? lastName[0].toUpperCase() + '.' : ''}`,
          value: _id,
          color
        }))
      ]);
    });
  }, []);

  const bookmarks = useMemo(() => {
    return technicians.map(({ label, value, color }, technician_idx) => (
      <Bookmark
        key={k(technician_idx)}
        label={label}
        color={color}
        value={value}
        path="filters.interveners"
      />
    ));
  }, [technicians]);

  const get_events = async ({ start, end, should_fetch_interventions, should_fetch_unavailabilities, technician_ids }) => {
    appliedFilters.filters.start = start;
    appliedFilters.filters.end = end;

    appliedFilters.filters = formatFiltersCalendar(appliedFilters.filters, auth);

    if (should_fetch_interventions) {
      const interventions_res = await axios.post(apiBaseURL + '/tickets/list', appliedFilters);
      const intervention_events = extract_events_from_tickets({
        tickets: interventions_res.data?.elements || [],
        company_id
      });
      set_events(intervention_events);
      setTotalResults(interventions_res.data?.count || 0);
    } else {
      set_events([]);
    }

    if (should_fetch_unavailabilities) {
      // format technician_ids to be an array of ids sometimes include the whole technician object (when selected from the filter)
      technician_ids = technician_ids.map((technician) => technician._id || technician);

      const unavailabilities_res = await axios.get(apiBaseURL + '/unavailabilities', {
        params: { start: start.getTime(), end: end.getTime(), technician_ids }
      });
      const unavailability_events = format_unavailabilities(unavailabilities_res.data?.elements || []);
      set_unavailabilities(unavailability_events);
    } else {
      set_unavailabilities([]);
    }
  };

  const handle_create_unavailability = (data) => {
    //! we need to format technician_id to be only the id
    data.technician_id = data.technician._id;
    delete data.technician;

    set_is_submit_unavailability(true);

    axios
      .post(apiBaseURL + '/unavailabilities', data)
      .then((response) => {
        const unavailability = response?.data?.element;
        const unavailability_events = format_unavailabilities([unavailability]);

        set_unavailabilities((prev) => [...prev, unavailability_events[0]]);

        notify.success(t('unavailabilityAdded'));
      })
      .catch((error) => {
        console.log(error);
        notify.error();
      })
      .finally(() => {
        set_is_submit_unavailability(false);
      });
  };

  const handle_edit_unavailability = (data) => {
    const unavailability_edited = data;
    set_is_submit_unavailability(true);

    axios
      .put(apiBaseURL + '/unavailabilities/' + unavailability_id_to_edit, data)
      .then((response) => {
        set_unavailabilities(
          unavailabilities.map((unavailability) => {
            if (String(unavailability.unavailability_id) === String(unavailability_id_to_edit)) {
              return {
                ...unavailability,
                start: unavailability_edited.start,
                end: unavailability_edited.end,
                title: unavailability_edited.title,
                description: unavailability_edited.description,
                number: unavailability_edited.number
              };
            }
            return unavailability;
          })
        );

        notify.success(t('unavailabilityEdited'));
      })
      .catch((error) => {
        console.log(error);
        notify.error();
      })
      .finally(() => {
        set_is_submit_unavailability(false);
      });
  };

  const handle_delete_unavailability = (unavailability_id) => {
    set_is_submit_unavailability(true);

    axios
      .delete(apiBaseURL + '/unavailabilities/' + unavailability_id)
      .then((response) => {
        set_unavailabilities(
          unavailabilities.filter(
            ({ unavailability_id: unavailability_id_to_delete }) =>
              String(unavailability_id_to_delete) !== String(unavailability_id)
          )
        );
        notify.success(t('unavailabilityDeleted'));
      })
      .catch((error) => {
        console.log(error);
        notify.error();
      })
      .finally(() => {
        set_is_submit_unavailability(false);
      });
  };

  const now = useMemo(() => new Date(), []);

  useEffect(() => {
    if (events_container_ref.current) {
      const top = get_event_y_position_in_minutes(now);
      events_container_ref.current.scrollTop = top;
    }
  }, [view_type, now]);

  const isBelowMd = useIsBelowMd();


  const number_of_days_displayed = useMemo(() => {
    return !size?.width
      ? null
      : view_type === WEEK_VIEW_TYPE
      ? isBelowSm
        ? 3
        : isBelowMd
        ? 5
        : 7
      : view_type === MONTH_VIEW_TYPE
      ? 30
      : view_type === YEAR_VIEW_TYPE
      ? 365
      : 1;
  }, [size?.width, view_type]);

  const is_full_week = number_of_days_displayed === DAYS_IN_A_WEEK;
  const days_left = Math.floor((number_of_days_displayed - 1) / 2);
  const days_right = Math.ceil((number_of_days_displayed - 1) / 2);

  const { start, end } = useMemo(() => {
    const start =
      view_type === MONTH_VIEW_TYPE
        ? start_of_week_of_start_of_month(add(now, { months: months_offset }))
        : view_type === WEEK_VIEW_TYPE && is_full_week
        ? add(compute_week_start(), { days: days_offset })
        : add(startOfDay(now), { days: -days_left + days_offset });

    const end =
      view_type === MONTH_VIEW_TYPE
        ? end_of_week_of_end_of_month(add(now, { months: months_offset }))
        : view_type === WEEK_VIEW_TYPE && is_full_week
        ? add(compute_week_end(), { days: days_offset })
        : add(endOfDay(now), { days: +days_right + days_offset });

    return { start, end };
  }, [is_full_week, days_left, days_right, view_type, days_offset, months_offset, view_type]);

  useEffect(() => {
    const technician_ids = appliedFilters?.filters?.interveners;

    number_of_days_displayed &&
      isDate(start) &&
      isDate(end) &&
      get_events({
        start,
        end,
        should_fetch_interventions,
        should_fetch_unavailabilities,
        technician_ids
      });
  }, [
    debouncedAppliedFilters,
    start,
    end,
    should_fetch_unavailabilities,
    should_fetch_interventions,
    number_of_days_displayed
  ]);

  const pagination_unit = view_type === WEEK_VIEW_TYPE && is_full_week ? 7 : 1;
  const day_width_px = (size.width - HOURS_COLUMN_WIDTH) / number_of_days_displayed;

  const go_to_today = () => [set_days_offset(0), set_months_offset(0)];
  const advance = () => {
    if ([WEEK_VIEW_TYPE, DAY_VIEW_TYPE].includes(view_type)) {
      set_days_offset(days_offset + pagination_unit);
      set_months_offset(0);
    } else if (view_type === MONTH_VIEW_TYPE) {
      set_months_offset(months_offset + pagination_unit);
      set_days_offset(0);
    }
  };
  const go_back = () => {
    if ([WEEK_VIEW_TYPE, DAY_VIEW_TYPE].includes(view_type)) {
      set_days_offset(days_offset - pagination_unit);
      set_months_offset(0);
    } else if (view_type === MONTH_VIEW_TYPE) {
      set_months_offset(months_offset - pagination_unit);
      set_days_offset(0);
    }
  };

  const change_view_type = (type) => {
    if (type === DAY_VIEW_TYPE || type === WEEK_VIEW_TYPE || type === MONTH_VIEW_TYPE) {
      set_view_type(type);
    } else {
      set_days_offset(0);
    }
  };

  const visible_events = useMemo(() => {
    return filter_events_outside_range({
      events: break_down_multiple_days_events([...events, ...unavailabilities]),
      start,
      end
    });
  }, [filter_events_outside_range, break_down_multiple_days_events, unavailabilities, events, start, end]);

  const handle_close_modal = () => {
    trigger(SET_RANGE_EVENT_ID, { start: null, start_idx: null, end: null, end_idx: null });
  };

  return (
    <>
      <div className="flex flex-col w-full h-full">
        <FilterAndSearchBarForm
          onSubmit={(values) => setAppliedFilters(values)}
          className={`${isBelowSm ? 'hidden' : ''} border-b bg-white rounded-t-xl`}
          totalResults={totalResults}
          initialValues={initialValues}
          filters={filters}
          withFilters
          menuItems={[
            <MenuItem
              icon="rotate-right"
              label="refresh"
              onClick={() =>
                get_events({
                  start,
                  end,
                  should_fetch_interventions,
                  should_fetch_unavailabilities,
                  technician_ids: appliedFilters?.filters?.interveners
                })
              }
            />
          ]}
          bookmarks={bookmarks}
        />
        <div
          style={{ height: TOOLBAR_HEIGHT }}
          className="border-b shrink-0"
        >
          <Toolbar
            go_back={go_back}
            go_to_today={go_to_today}
            advance={advance}
            set_view_type={change_view_type}
            days_offset={days_offset}
            months_offset={months_offset}
            should_fetch_interventions={should_fetch_interventions}
            set_should_fetch_interventions={set_should_fetch_interventions}
            should_fetch_unavailabilities={should_fetch_unavailabilities}
            set_should_fetch_unavailabilities={set_should_fetch_unavailabilities}
            set_open_unavailability_creation_dialog={set_open_unavailability_creation_dialog}
          />
        </div>
        <DailyView
          now={now}
          start={start}
          end={end}
          view_type={view_type}
          events={visible_events}
          set_events={set_events}
          set_unavailabilities={set_unavailabilities}
          day_width_px={day_width_px}
          events_container_ref={events_container_ref}
          number_of_days_displayed={number_of_days_displayed}
          set_ticket_in_modal={set_ticket_in_modal}
          set_open_dialog_actions={set_open_dialog_actions}
          set_unavailability_id_to_edit={set_unavailability_id_to_edit}
          selected_range={selected_range}
        />
        <MonthlyView
          view_type={view_type}
          now={now}
          start={start}
          end={end}
          events={visible_events}
          set_ticket_in_modal={set_ticket_in_modal}
          set_unavailability_id_to_edit={set_unavailability_id_to_edit}
        />
      </div>
      {ticket_in_modal && (
        <Ticket
          isDialog
          noFetch
          defaultOpenView
          childrenId={ticket_in_modal}
          childrenAuto
          afterDialogClose={() => set_ticket_in_modal(null)}
        >
          <></>
        </Ticket>
      )}

      <DialogActionsCreate
        open_dialog_actions={open_dialog_actions}
        set_open_dialog_actions={set_open_dialog_actions}
        set_open_ticket_form={set_open_ticket_form}
        set_open_unavailability_creation_dialog={set_open_unavailability_creation_dialog}
        selected_range={selected_range}
      />

      {/* TICKET MODAL FORM CREATION */}
      <Ticket
        is_open_form={open_ticket_form}
        on_close_form={() => [set_open_ticket_form(false), handle_close_modal()]}
        visit_date={selected_range?.start || new Date()}
        duration={!!selected_range ? differenceInMinutes(selected_range.end, selected_range.start) : 60}
      >
        <></>
      </Ticket>

      <UnavailabilityCreationDialog
        open_modal_create_unavailability={open_unavailability_creation_dialog}
        onClose={() => [set_open_unavailability_creation_dialog(false), handle_close_modal()]}
        handle_create_unavailability={handle_create_unavailability}
        is_submit_unavailability={is_submit_unavailability}
        selected_range={selected_range}
      />

      <UnavailabilityEditionDialog
        unavailabilities={unavailabilities}
        unavailability_id_to_edit={unavailability_id_to_edit}
        onClose={() => set_unavailability_id_to_edit(null)}
        handle_edit_unavailability={handle_edit_unavailability}
        handle_delete_unavailability={handle_delete_unavailability}
        is_submit_unavailability={is_submit_unavailability}
      />
    </>
  );
}

const initialValues = {
  search: '',
  limit: { startIndex: 0, stopIndex: 30 },
  filters: {
    sort: {
      label: 'creationDateMostRecent',
      value: '_id',
      sort: -1,
      show: true
    },
    section: {
      open: true,
      closed: false,
      preventifs: true,
      withoutIntervener: false,
      immanager: false,
      deadline: false,
      nomanager: false
    },
    clients: [],
    locations: [],
    equipments: [],
    preventifs: [],
    states: [],
    interveners: [],
    typologies: [],
    jobs: [],
    managers: [],
    categories: [],
    preventif_checked: [],
    urgencies: [],
    calendar: true,
    bookmarks: []
  }
};

const filters = [
  <SectionPicker
    label="ongoing"
    path="filters.section.open"
    color="green"
  />,
  <SectionPicker
    label="closed"
    path="filters.section.closed"
    color="red"
  />,
  <EntityPopoverFilter
    label="maintenancesTitle"
    entity={Maintenance}
    path="filters.preventifs"
  />,
  <SectionPicker
    label="Sans Intervenant"
    path="filters.section.withoutIntervener"
    color="red"
  />,
  <EntityPopoverFilter
    label="intervenersTitle"
    entity={Intervener}
    path="filters.interveners"
  />,
  // TODO conditionnal filter when interface is contractor
  <EntityPopoverFilter
    label="clientsTitle"
    entity={Client}
    path="filters.clients"
  />,
  <ListPopoverFilter
    label="Etat"
    options={ticket_states}
    path="filters.states"
  />,
  <EntityPopoverFilter
    label="types"
    entity={Scope}
    path="filters.typologies"
    entityProps={{
      type: 'ticketTypology',
      hiddenFilters: {
        only: [],
        parents: []
      }
    }}
  />,
  <EntityPopoverFilter
    label="locations"
    entity={Location}
    path="filters.locations"
  />,
  <EntityPopoverFilter
    label="jobsTitle"
    entity={Job}
    path="filters.jobs"
  />,
  <EntityPopoverFilter
    label="managersTitle"
    entity={Manager}
    path="filters.managers"
  />,
  <SectionPicker
    label="immanagerSection"
    path="filters.section.immanager"
    color="pink"
  />,
  <SectionPicker
    label="deadlineSection"
    path="filters.section.deadline"
    color="purple"
  />,
  <SectionPicker
    label="withoutAManager"
    path="filters.section.nomanager"
    color="orange"
  />,
  <ListPopoverFilter
    label="validatedPeriod"
    options={periods_options}
    path="filters.preventif_checked"
  />,
  <ListPopoverFilter
    label="priorities"
    options={priorities_options}
    path="filters.urgencies"
  />,
  <EntityPopoverFilter
    label="equipmentsTitle"
    entity={Equipment}
    path="filters.equipments"
  />
];

const technicians_request_args = {
  limit: {
    startIndex: 0,
    stopIndex: 200
  },
  filters: {
    tab: {
      collaborator: true,
      mine: false,
      public: false
    },
    deleted: false,
    sort: {
      sort: 1,
      value: 'companyName'
    }
  }
};
