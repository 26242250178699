import { Button } from '@material-ui/core';
import axios from 'axios';
import FAIcon from 'components/ui/FAIcon';
import useFileViewer from 'hooks/useFileViewer';
import { apiBaseURL } from 'index';
import useStyles from 'layouts/entities/View.styles';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import ActionButton from '../buttons/ActionButton';
import ReasonDialog from 'components/dialogs/ReasonDialog';

const useBpu = (company_id) => {
  const [bpu, setBpu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBpu = async () => {
      if (!company_id) return;
      try {
        const { data } = await axios.get(`${apiBaseURL}/bpus/${company_id}/active`);
        setBpu(data.document);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBpu();
  }, [company_id]);

  return { bpu, isLoading };
};

const ValidationButton = ({ open, setOpen, onConfirm, icon, label, variant }) => (
  <>
    <ActionButton
      onClick={() => setOpen(true)}
      icon={icon}
      label={label}
      variant={variant}
    />
    <ReasonDialog
      open={open}
      onClose={() => setOpen(false)}
      onConfirm={onConfirm}
    />
  </>
);

export const FileActions = ({ company_id, actions, devis }) => {
  const { files, openIndex, setOpenIndex, append_files } = useFileViewer();
  const classes = useStyles();
  const { t } = useTranslation();
  const { bpu, isLoading } = useBpu(company_id);
  const [isReasonDialogOpen, setIsReasonDialogOpen] = useState(false);
  const [isReasonAcceptedDialogOpen, setIsReasonAcceptedDialogOpen] = useState(false);

  const handleConfirm = useCallback(
    (accepted) => (reason) => {
      actions.devis_validation.action(accepted, reason, devis._id);
      accepted ? setIsReasonAcceptedDialogOpen(false) : setIsReasonDialogOpen(false);
    },
    [actions, devis._id]
  );

  const handleBpuButtonClick = () => {
    if (isLoading) return;
    if (files.length <= 1) append_files([bpu]);
    setOpenIndex(files[openIndex]?.type === 'bpu' ? 0 : 1);
  };

  return (
    <div className="flex flex-col gap-y-4 items-center">
      {(bpu || isLoading) && (
        <Button
          onClick={handleBpuButtonClick}
          endIcon={
            <FAIcon
              collection="fal"
              icon="clipboard-list"
            />
          }
          variant="contained"
          size="large"
          className={classNames('w-36', classes.viewActionInfo)}
          style={{ marginBottom: '3rem' }}
          disabled={isLoading}
        >
          {isLoading ? (
            <FAIcon
              collection="fas"
              icon="spinner-third"
              className="animate-spin"
            />
          ) : (
            t(files[openIndex]?.type === 'bpu' ? 'Devis' : 'BPU')
          )}
        </Button>
      )}
      {actions.devis_validation?.show && (
        <>
          <ValidationButton
            open={isReasonAcceptedDialogOpen}
            setOpen={setIsReasonAcceptedDialogOpen}
            onConfirm={handleConfirm(true)}
            icon="thumbs-up"
            label={t('acceptQuote')}
            variant="success"
          />
          <ValidationButton
            open={isReasonDialogOpen}
            setOpen={setIsReasonDialogOpen}
            onConfirm={handleConfirm(false)}
            icon="thumbs-down"
            label={t('decline')}
            variant="error"
          />
        </>
      )}
    </div>
  );
};
