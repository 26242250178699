import { Add, ArrowBack, CloudUploadOutlined, Delete, Edit, Fingerprint } from '@material-ui/icons';

import {
  AppBar,
  Box,
  IconButton,
  List,
  ListItem,
  Typography,
  Toolbar,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  InputAdornment,
  DialogActions,
  CircularProgress,
  Button,
  TextField,
  Badge
} from '@material-ui/core';

import FAIcon from 'components/ui/FAIcon';
import { useIsBelowMd } from 'hooks/useMQ';

import { states } from 'constants/states';

import PopoverDrawer from 'components/ui/PopoverDrawer';

import useKeys from '@flowsn4ke/usekeys';
import { formatCurrency } from 'utils/formatCurrency';
/**
 * TODO change to date-fns
 */
import moment from 'moment';

import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useTranslation } from 'react-i18next';
import { useRef, useState, useEffect } from 'react';
import { updateAccounting, acceptDevis, addQuote, addInvoice, removeQuote, removeInvoice } from 'store/accountingSlice';
import useNotifications from 'hooks/useNotifications';
import { isObject } from 'lodash-es';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import { useRole } from 'hooks/useRole';
import ReasonDialog from 'components/dialogs/ReasonDialog';
import useFileViewer from 'hooks/useFileViewer';
import { useConfiguration } from 'hooks/useConfiguration';
import { useAuth } from 'hooks/useAuth';
import Tooltip from 'components/ToolTip';

const DEFAULT_DOCUMENT_VIEWER_CONFIG = {
  isOpen: false,
  ticketId: null,
  anchor: null,
  lock: false,
  type: null
};

export const factureLockerMessage = (
  <>
    La ligne ne peut être modifiée car la facture est en cours de traitement.
    <br /> Veuillez vous rapprocher de votre client/administrateur
  </>
);

/**
 * TODO Dry this file much as possible
 */

export default function ModalAccounting({ documentViewerConfig, tickets, setDocumentViewerConfig }) {
  const ticket = tickets.find((t) => t._id === documentViewerConfig.ticketId);

  const isBelowMd = useIsBelowMd();

  const devisValidated = ticket?.devis?.filter((d) => d.validated.state);
  const devisDropped = ticket?.devis?.filter((d) => d.dropped.state);
  const devisWaiting = ticket?.devis?.filter((d) => !d.validated.state && !d.dropped.state);

  let style;
  if (devisWaiting?.length > 0) {
    style = { background: states.validation.color };
  } else if (devisValidated?.length > 0) {
    style = { background: states.validated.color };
  } else if (devisDropped?.length > 0) {
    style = { background: states.dropped.color };
  }

  return (
    <>
      <ModalBoilerPlate
        documentViewerConfig={documentViewerConfig}
        setDocumentViewerConfig={setDocumentViewerConfig}
        isBelowMd={isBelowMd}
        style={style}
        ticket={ticket}
      />
    </>
  );
}

function ModalBoilerPlate({ documentViewerConfig, setDocumentViewerConfig, isBelowMd, style, ticket }) {
  const { t } = useTranslation();
  const k1 = useKeys();
  const [openModalDocument, setOpenModalDocument] = useState(false);
  const [isTrashOpen, setIsTrashOpen] = useState(false);
  const auth = useAuth();
  const company = auth.interface._company;

  const { openFiles } = useFileViewer();

  const documentsDeleted = ticket[documentViewerConfig.type].filter((document) => {
    return document.deleted.state;
  });

  return (
    <PopoverDrawer
      open={documentViewerConfig.isOpen}
      onClose={(e) => setDocumentViewerConfig(DEFAULT_DOCUMENT_VIEWER_CONFIG)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transitionDuration={0}
      style={{ width: !isBelowMd ? '100%' : documentViewerConfig.anchor.offsetWidth }}
      anchorEl={documentViewerConfig.anchor}
    >
      <AppBar
        style={documentViewerConfig.type === 'devis' ? style : {}}
        elevation={1}
        position="relative"
        color="primary"
      >
        <Box
          height="3em"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Toolbar>
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              disabled
            >
              <FAIcon
                collection="fas"
                icon={documentViewerConfig.type === 'devis' ? 'file-search' : 'file-invoice'}
                style={{ color: 'white' }}
              />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {isTrashOpen ? 'Corbeille de ' : ''}
              {documentViewerConfig.type === 'devis' ? t('quotes') : t('invoices')}
            </Typography>
          </Toolbar>

          <Toolbar>
            {documentsDeleted.length > 0 && (
              <>
                <Badge badgeContent={documentsDeleted.length} />
                <Tooltip
                  position="left"
                  title={
                    isTrashOpen
                      ? 'Revenir en arrière'
                      : documentViewerConfig.type === 'devis'
                      ? 'Voir le(s) devis supprimé(s)'
                      : 'Voir les factures supprimés'
                  }
                >
                  <IconButton
                    size="small"
                    color="inherit"
                    edge="end"
                    onClick={() => setIsTrashOpen((prev) => !prev)}
                  >
                    {isTrashOpen ? <ArrowBack /> : <Delete />}
                  </IconButton>
                </Tooltip>
              </>
            )}

            <Tooltip
              position="left"
              title={documentViewerConfig.type === 'devis' ? 'Ajouter un devis' : 'Ajouter une facture'}
            >
              <IconButton
                size="large"
                color="inherit"
                edge="end"
                onClick={() => setOpenModalDocument((prev) => !prev)}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </Box>
      </AppBar>
      <List style={{ width: 500, height: 250 }}>
        {/**! TODO  !!!! TODO Make a composant ? */}
        {isTrashOpen ? (
          documentsDeleted.map((document, i) => {
            return (
              <ListItem key={k1(i)}>
                <ListItemText
                  primary={
                    <span>
                      <span style={{ marginRight: 6 }}>{document.reference}</span>
                      <strong>
                        {formatCurrency({
                          number: document.price,
                          locale: company?.currency?.locale,
                          currency: company?.currency?.code
                        })}
                      </strong>
                    </span>
                  }
                  secondary={
                    <>
                      <Tooltip
                        position={'right'}
                        title={`par
                    ${document._user && document._user?.firstName}
                    ${document._user && document._user?.lastName}`}
                      >
                        <span>{moment(document.date).format('DD/MM/YYYY à HH:mm')}</span>
                      </Tooltip>
                      <Tooltip
                        position={'right'}
                        title={`par ${document.deleted._user?.firstName} ${document.deleted._user?.lastName}`}
                      >
                        <span>
                          <br />
                          <strong style={{ color: 'red' }}>
                            {moment(document.deleted.date).format('DD/MM/YYYY à HH:mm')}
                          </strong>
                        </span>
                      </Tooltip>

                      {document.dropped?.state && (
                        <Tooltip
                          position={'right'}
                          title={`par
                      ${document.dropped._user?.firstName}
                      ${document.dropped._user?.lastName}
                      le ${moment(document.dropped.date).format('DD/MM/YYYY à HH:mm')}`}
                        >
                          <span>
                            <br />
                            <strong style={{ color: 'red' }}>{document.dropped.reason}</strong>
                          </span>
                        </Tooltip>
                      )}
                    </>
                  }
                ></ListItemText>
                <ListItemSecondaryAction>
                  <div>
                    <span>
                      <Tooltip
                        position="left"
                        title="Ouvrir le document dans un nouvel onglet"
                      >
                        <IconButton onClick={(e) => window.open(document._document.url, '_blank')}>
                          <FAIcon
                            icon="fa-arrow-up-right-from-square"
                            collection="fad"
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        position="left"
                        title="Ouvrir le document"
                      >
                        <IconButton
                          onClick={(e) => {
                            openFiles(
                              documentsDeleted.map((doc) => doc._document),
                              i
                            );
                          }}
                        >
                          <FAIcon
                            icon="fa-eye"
                            collection="fad"
                          />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        ) : documentViewerConfig.type === 'devis' ? (
          <QuoteActions
            quotes={ticket.devis}
            setOpenAddDocument={setOpenModalDocument}
            type={'devis'}
            ticket={ticket}
            isParent={false}
          />
        ) : (
          <InvoiceActions
            invoices={ticket.factures}
            setOpenAddDocument={setOpenModalDocument}
            type={'facture'}
            ticket={ticket}
            isParent={false}
          />
        )}
      </List>

      {/* MODAL Adding Document */}
      <AddDocument
        open={openModalDocument}
        onClose={() => setOpenModalDocument((prev) => !prev)}
        order={ticket}
        update={isObject(openModalDocument) && openModalDocument}
        type={documentViewerConfig.type === 'devis' ? 'devis' : 'facture'}
      />
    </PopoverDrawer>
  );
}

/**
 * TODO make one Component ?? because is very similar
 */

function QuoteActions({ quotes, setOpenAddDocument, type, ticket, isParent }) {
  const k1 = useKeys();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [modalDecline, setModalDecline] = useState(false);
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  const { dispatch } = useAsyncDispatch();
  const { openFiles } = useFileViewer();

  const role = useRole();

  const documents = quotes.filter((document) => !document.deleted.state);
  const locked = ticket.facture_state.lock;

  return (
    <>
      {documents.map((devis, i) => {
        return (
          <ListItem key={k1(i)}>
            <ListItemText
              primary={
                <span>
                  <span style={{ marginRight: 6 }}>{devis.reference}</span>
                  <strong>
                    {formatCurrency({
                      number: devis.price,
                      locale: company?.currency?.locale,
                      currency: company?.currency?.code
                    })}
                  </strong>
                  {
                    <>
                      {!locked && role.permission('tickets', 'remove_devis') && (
                        <Tooltip
                          title="Supprimer le devis"
                          position="right"
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              setIsConfirmDeleteOpen(devis);
                            }}
                            style={{ marginLeft: 6 }}
                            size="small"
                          >
                            <FAIcon
                              size="small"
                              icon="trash"
                              collection="fad"
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      {!locked && role.permission('tickets', 'send_devis') && (
                        <Tooltip
                          title="Modifier le devis"
                          position="right"
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              setOpenAddDocument(devis);
                            }}
                            style={{ marginLeft: 6 }}
                            size="small"
                          >
                            <FAIcon
                              size="small"
                              icon="pencil"
                              collection="fad"
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      {locked && (
                        <Tooltip
                          title={factureLockerMessage}
                          position="right"
                        >
                          <IconButton
                            style={{ marginLeft: 6 }}
                            size="small"
                          >
                            <FAIcon
                              size="small"
                              icon="lock"
                              collection="fad"
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      <ConfirmDialog
                        text={t('areYouSureYouWantToDeleteXOfAmountYWithReferenceZ', {
                          type: t('leQuote'),
                          amount: isConfirmDeleteOpen.price,
                          reference: isConfirmDeleteOpen.reference
                        })}
                        open={isConfirmDeleteOpen}
                        onClose={(e) => [setIsConfirmDeleteOpen(false)]}
                        onConfirm={() => {
                          // ! NEW VERSION
                          dispatch(
                            removeQuote,
                            {
                              isParent,
                              type,
                              attachmentId: isConfirmDeleteOpen._id,
                              from: 'compta'
                            },
                            {},
                            { id: ticket._id }
                          );
                        }}
                      />
                    </>
                  }
                </span>
              }
              secondary={
                <>
                  <Tooltip
                    position={'right'}
                    title={devis?._document?.created_by?.name ? `par ${devis._document.created_by.name}` : ''}
                  >
                    <span>{moment(devis.date).format('DD/MM/YYYY à HH:mm')}</span>
                  </Tooltip>
                </>
              }
            />
            <ListItemSecondaryAction>
              <div>
                {/* TODO revoir les permissions ainsi que le champ d'actions */}
                {
                  /*!this.state.trash && this.checkPermissionDevis(devis, devis.price) && canVote */ true && (
                    <>
                      {!devis.validated.state &&
                        !devis.dropped.state &&
                        role.permission('tickets', 'validate_devis') && (
                          <Tooltip
                            position="left"
                            title="Refuser le devis"
                          >
                            <IconButton
                              onClick={() => {
                                setModalDecline(() => (reason) => {
                                  return dispatch(
                                    acceptDevis,
                                    {
                                      id: devis._id,
                                      reason,
                                      response: false,
                                      isParent
                                    },
                                    {},
                                    { id: ticket._id }
                                  );
                                });
                              }}
                            >
                              <FAIcon
                                style={{ color: 'red' }}
                                icon="fa-thumbs-down"
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      {!devis.validated.state &&
                        !devis.dropped.state &&
                        role.permission('tickets', 'validate_devis') && (
                          <Tooltip
                            position="left"
                            title="Accepter le devis"
                          >
                            <IconButton
                              onClick={() => {
                                dispatch(
                                  acceptDevis,
                                  {
                                    id: devis._id,
                                    response: true,
                                    isParent
                                  },
                                  {},
                                  { id: ticket._id }
                                );
                              }}
                            >
                              <FAIcon
                                style={{ color: '#2ecc71' }}
                                icon="fa-thumbs-up"
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                    </>
                  )
                }

                {devis.dropped.state && (
                  <Tooltip
                    position="right"
                    title={
                      'Devis refusé le ' +
                      moment(devis.dropped.date).format('DD/MM/YYYY à HH:mm') +
                      ' par ' +
                      (devis.bobdepannage
                        ? 'Bob!Dépannage'
                        : devis.dropped._user && devis.dropped._user.firstName + ' ' + devis.dropped._user.lastName)
                    }
                  >
                    <IconButton style={{ marginLeft: 6 }}>
                      <FAIcon
                        style={{ color: '#e74c3c' }}
                        icon="fa-circle-minus"
                        collection="fas"
                      />
                    </IconButton>
                  </Tooltip>
                )}

                {devis.validated.state && (
                  <Tooltip
                    position="right"
                    title={
                      'Devis accepté le ' +
                      moment(devis.validated.date).format('DD/MM/YYYY à HH:mm') +
                      ' par ' +
                      (devis.bobdepannage
                        ? 'Bob!Dépannage'
                        : devis.validated._user &&
                          devis.validated._user.firstName + ' ' + devis.validated._user.lastName)
                    }
                  >
                    <IconButton style={{ marginLeft: 6 }}>
                      <FAIcon
                        style={{ color: '#2ecc71' }}
                        icon="fa-circle-check"
                        collection="fas"
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <span>
                  {/* Just in case */}
                  <Tooltip
                    position="left"
                    title="Ouvrir le document dans un nouvel onglet"
                  >
                    <IconButton onClick={(e) => window.open(devis._document.url, '_blank')}>
                      <FAIcon
                        icon="fa-arrow-up-right-from-square"
                        collection="fad"
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    position="left"
                    title="Ouvrir le document"
                  >
                    <IconButton
                      onClick={(e) => {
                        openFiles(
                          documents.map((doc) => doc._document),
                          i
                        );
                      }}
                    >
                      <FAIcon
                        icon="fa-eye"
                        collection="fad"
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              </div>

              {/* MODAL FOR DECLINE QUOTE */}
              <ReasonDialog
                open={!!modalDecline}
                onClose={() => setModalDecline(false)}
                text={'Raison de refus pour le devis'}
                onConfirm={(reason) => modalDecline(reason)}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </>
  );
}

function InvoiceActions({ invoices, setOpenAddDocument, type, ticket, isParent }) {
  const k1 = useKeys();

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const { t } = useTranslation();
  const role = useRole();
  const auth = useAuth();
  const company = auth.interface._company;

  const { dispatch } = useAsyncDispatch();
  const { openFiles } = useFileViewer();

  const documents = invoices.filter((document) => !document.deleted.state);

  const locked = ticket.facture_state.lock;

  return (
    <>
      {/* TODO make a list of invoices */}
      {documents.map((invoice, i) => {
        return (
          <ListItem key={k1(i)}>
            <ListItemText
              primary={
                <span>
                  <span style={{ marginRight: 6 }}>{invoice.reference}</span>
                  <strong>
                    {formatCurrency({
                      number: invoice.price,
                      locale: company?.currency?.locale,
                      currency: company?.currency?.code
                    })}
                  </strong>
                  {
                    <>
                      {!locked && role.permission('tickets', 'remove_facture') && (
                        <Tooltip
                          title="Supprimer la facture"
                          position="right"
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              setIsConfirmDeleteOpen(invoice);
                            }}
                            style={{ marginLeft: 6 }}
                            size="small"
                          >
                            <FAIcon
                              size="small"
                              icon="fa-trash"
                              collection="fad"
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      {!locked && role.permission('tickets', 'send_facture') && (
                        <Tooltip
                          title="Modifier la facture"
                          position="right"
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              setOpenAddDocument(invoice);
                            }}
                            style={{ marginLeft: 6 }}
                            size="small"
                          >
                            <FAIcon
                              size="small"
                              icon="fa-pencil"
                              collection="fad"
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      {locked && (
                        <Tooltip
                          title={factureLockerMessage}
                          position="right"
                        >
                          <IconButton
                            style={{ marginLeft: 6 }}
                            size="small"
                          >
                            <FAIcon
                              size="small"
                              icon="fa-lock"
                              collection="fad"
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      <ConfirmDialog
                        text={t('areYouSureYouWantToDeleteXOfAmountYWithReferenceZ', {
                          type: t('leInvoice'),
                          amount: isConfirmDeleteOpen.price,
                          reference: isConfirmDeleteOpen.reference
                        })}
                        open={isConfirmDeleteOpen}
                        onClose={(e) => [setIsConfirmDeleteOpen(false)]}
                        onConfirm={() => {
                          // ! New Version
                          dispatch(
                            removeInvoice,
                            {
                              isParent,
                              type,
                              attachmentId: isConfirmDeleteOpen._id,
                              from: 'compta'
                            },
                            {},
                            { id: ticket._id }
                          );

                          // ! Old Version
                          // dispatch(removeAccounting, {
                          //   isParent,
                          //   type,
                          //   attachmentId: isConfirmDeleteOpen._id,
                          //   from: 'compta'
                          // }, {}, { id: ticket._id })
                        }}
                      />
                    </>
                  }
                </span>
              }
              secondary={
                <>
                  <Tooltip
                    position={'right'}
                    title={`par
                    ${invoice._user && invoice._user.firstName}
                    ${invoice._user && invoice._user.lastName}`}
                  >
                    <span>{moment(invoice.date || invoice._document.created_at).format('DD/MM/YYYY à HH:mm')}</span>
                  </Tooltip>
                </>
              }
            />
            <ListItemSecondaryAction>
              <div>
                <span>
                  {/* JUST IN CASE */}
                  <Tooltip
                    position="left"
                    title="Ouvrir le document dans un nouvel onglet"
                  >
                    <IconButton onClick={(e) => window.open(invoice._document.url, '_blank')}>
                      <FAIcon
                        icon="fa-arrow-up-right-from-square"
                        collection="fad"
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    position="left"
                    title="Ouvrir le document"
                  >
                    <IconButton
                      onClick={(e) => {
                        openFiles(
                          documents.map((doc) => doc._document),
                          i
                        );
                      }}
                    >
                      <FAIcon
                        icon="fa-eye"
                        collection="fad"
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              </div>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </>
  );
}

function AddDocument({ open, onClose, isParent, type, order, update }) {
  const [ref, setRef] = useState(update.reference || '');
  const [price, setPrice] = useState(update.price || null);
  const [priceError, setPriceError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const company = useAuth().interface?._company;

  const configuration = useConfiguration();
  const notify = useNotifications();

  const { dispatch } = useAsyncDispatch();

  const uploadAttachment = (e) => {
    setIsLoading(true);

    const formData = new FormData();

    if (e.target.files?.length && e.target.files[0]) {
      formData.append('document', e.target.files[0]);
    }

    formData.append(
      'fields',
      JSON.stringify({
        type,
        reference: ref,
        price: price || 0,
        isParent,
        from: 'compta',
        documents_tab_id: order.documents_tab_id
      })
    );

    if (type === 'devis') {
      dispatch(
        addQuote,
        { formData },
        {
          onSuccess: () => [setIsLoading(false), notify.success(), close()],
          onError: () => [setIsLoading(false), close()]
        },
        { id: order._id }
      );
    } else {
      dispatch(
        addInvoice,
        { formData },
        {
          onSuccess: () => [setIsLoading(false), notify.success(), close()],
          onError: () => [setIsLoading(false)]
        },
        { id: order._id }
      );
    }
  };

  const refFile = useRef(null);

  useEffect(() => {
    setPrice(update.price);
    setRef(update.reference);
  }, [update]);

  const close = () => {
    if (!isLoading) {
      setPrice(null);
      setRef(null);
      onClose();
    }
  };

  useEffect(() => {
    if (configuration?.quotes_price_not_null && (price === '' || price === '0' || price === null)) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
  }, [price, configuration?.quotes_price_not_null]);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      disableEnforceFocus
      open={open}
      onClose={close}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        ref={refFile}
        hidden
        type="file"
        multiple={false}
        onChange={(e) => uploadAttachment(e)}
      />

      <Box
        padding="16px"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Fingerprint />
              </InputAdornment>
            )
          }}
          variant="outlined"
          fullWidth
          placeholder={t('reference')}
          label={t('reference')}
          value={ref}
          onChange={(e) => setRef(e.target.value)}
          style={{ marginBottom: 18 }}
        />

        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="text-xl">{company?.currency?.symbol || '€'}</span>
              </InputAdornment>
            )
          }}
          variant="outlined"
          fullWidth
          inputProps={{ min: configuration?.quotes_price_not_null ? 1 : 0, type: 'number' }}
          type={'number'}
          placeholder={t('noVATAmount')}
          label={t('noVATAmount')}
          value={price}
          error={priceError}
          helperText={priceError && t('priceCannotBeEmptyOrZero')}
          onChange={(e) => {
            const value = e.target.value;
            setPrice(value);
          }}
        />
      </Box>
      <DialogActions>
        {isLoading && <CircularProgress size={20} />}
        <Button
          disabled={isLoading}
          onClick={onClose}
          color="secondary"
          style={{ marginLeft: 10 }}
        >
          {t('cancel')}
        </Button>
        {!update && (
          <Button
            startIcon={<CloudUploadOutlined />}
            disabled={
              isLoading || ((price === '' || price === '0' || price === null) && configuration?.quotes_price_not_null)
            }
            onClick={(e) => {
              refFile.current.click();
            }}
            color="primary"
          >
            {type === 'devis' ? t('uploadQuote') : t('uploadInvoice')}
          </Button>
        )}
        {!!update && (
          <Button
            startIcon={<Edit />}
            disabled={
              isLoading || ((price === '' || price === '0' || price === null) && configuration?.quotes_price_not_null)
            }
            onClick={(e) => {
              setIsLoading(true);
              dispatch(
                updateAccounting,
                {
                  type,
                  attachmentId: update._id,
                  isParent,
                  price,
                  reference: ref
                },
                {
                  onSuccess: () => [setIsLoading(false), notify.success(), close()],
                  onError: () => [setIsLoading(false)]
                },
                { id: order._id }
              );
            }}
            color="primary"
          >
            {type === 'devis' ? t('editQuote') : t('editInvoice')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
