import { formatFilter } from 'modules/tickets/ticket-list/baseFilters';
import { accountingList } from 'store/accountingListSlice';

export const fetchAccountingTickets = ({ auth, dispatch, limit }) => {
  return async (filters, nextSkip = 0) => {
    const formattedFilters = formatFilter(filters, auth, nextSkip, limit);

    try {
      dispatch(accountingList, { filters: formattedFilters });
    } catch {
      console.log('error');
    }
  };
};
