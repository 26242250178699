import {
  AccordionActions,
  AccordionDetails,
  Grid,
  Button,
  FormHelperText
} from '@material-ui/core';

import { Submit } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';
import AutoCompleteSelect from 'components/inputs/AutoCompleteSelect';
import { useTranslation } from 'react-i18next';
import { useConfiguration } from 'hooks/useConfiguration';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { setConfiguration } from 'store/authSlice';

export default function ContractorsConfiguration({ section }) {
  const { t } = useTranslation();

  const config = useConfiguration();

  const notify = useNotifications();
  const { dispatch } = useAsyncDispatch();

  const dispatchCallbacks = { onSuccess: () => notify.success(), onError: () => notify.error() };

  return (
    <AccordionForm
      section={section}
      defaultClose
      initialValues={{
        default_intervener_tab: config?.default_intervener_tab || 'mine'
      }}
      onSubmit={(updates) => dispatch(setConfiguration, updates, dispatchCallbacks)}
    >
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <div className="mt-4">
              <FormHelperText>{t('default-intervener-menu-helper')}</FormHelperText>
              <AutoCompleteSelect
                placeholder={t('defaultDisplay')}
                path="default_intervener_tab"
              />
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
      {/* <Divider /> */}
      <AccordionActions>
        <Button size="small">{t('cancel')}</Button>
        <Submit>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}
