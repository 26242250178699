import Popover from 'components/ui/Popover';
import { cloneElement, useState, useEffect } from 'react';

export default function Menu({ button, items, className, placement = 'bottom-start' }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  // Update the state when the window is resized
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 425);
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <Popover
      offset={[10, 1]}
      placement={placement}
      className="z-20"
      button={button}
      contents={({ close }) => (
        <div className={`flex flex-col z-20 rounded w-auto p-0.5 ${className}`}>
          {items
            .filter((_, menuIdx) => !(isMobile && menuIdx === 1)) // skip le export qui est tjr a l'indice 1
            .map((menuItem, menuIdx) => cloneElement(menuItem, { key: menuIdx, close }))}
        </div>
      )}
    />
  );
}
