const TicketExportsNew = (configuration, customFields, role) => {
  const show_quotes = role.permission('tickets', 'display_devis');
  const show_invoice = role.permission('tickets', 'display_facture');

  return [
    // Informations générales sur le ticket
    { label: 'ticketNumber', key: 'number', show: true },
    { label: 'createdDate', key: 'created_at', show: true },
    { label: 'last-updated-date', key: 'updated_at', show: true },
    { label: 'status', key: 'status', show: true },
    { label: 'priority', key: 'priorities', show: true },
    { label: 'domain', key: 'domains', show: configuration.job_domains?.length ? true : false },
    { label: 'title', key: 'title', show: true },
    { label: 'description', key: 'description', show: true },
    { label: 'typology', key: 'typology', show: true },
    { label: 'creator', key: 'creator', show: true },

    // Localisation et gestion des lieux
    { label: 'locationName', key: 'location_name', show: true },
    { label: 'exportLocation_reference', key: 'location_reference', show: true },
    { label: 'number-and-street-name', key: 'address', show: true },
    { label: 'postalCode', key: 'postalCode', show: true },
    { label: 'city', key: 'city', show: true },
    { label: 'country', key: 'country', show: true },
    { label: 'zone', key: 'zone', show: true },

    // Client / Utilisateur
    { label: 'clientName', key: 'client_name', show: configuration.isContractor ? true : false },
    { label: 'export-managers', key: 'managers', show: true },
    { label: 'contacts', key: 'contacts', show: true },

    // Métiers et équipements
    { label: 'skills', key: 'skills', show: true },
    { label: 'export-equipments', key: 'equipment_name', show: true },

    // Planifications
    { label: 'contractors', key: 'contractors', show: true },
    { label: 'assignation-date-export', key: 'assignation_date', show: configuration.isClient ? true : false },
    { label: 'programmedVisitDate', key: 'planned_dates', show: true },
    { label: 'indicationScheduledVisitDate', key: 'input_planned_date', show: configuration.isClient ? true : false },

    // Devis
    { label: 'quotesReference', key: 'quote_reference', show: show_quotes },
    { label: 'quotesAmount', key: 'quote_amount', show: show_quotes },
    { label: 'amountQuotesUnvalidated', key: 'amount_quoted_unvalidated', show: show_quotes },
    { label: 'validation-quotes-date-export', key: 'validation_quotes_date', show: show_quotes },

    // Intervention
    { label: 'interventionDate', key: 'intervention_dates', show: true },
    { label: 'TotalDurationIntervention', key: 'intervention_time', show: true },
    { label: 'invoiceReference', key: 'invoice_reference', show: show_invoice },
    { label: 'invoice-amount-export', key: 'invoice_amount', show: show_invoice },
    { label: 'AmountIntervention', key: 'total_spent', show: show_invoice },

    // Plannifications, Devis, Intervention (ST)
    { label: 'plannedDatesContractor', key: 'planned_dates_contractor', show: configuration.isContractor ? true : false },
    { label: 'quotesReferenceContractor', key: 'quotes_reference_contractor', show: configuration.isContractor ? show_quotes : false },
    { label: 'quotesAmountContractor', key: 'quotes_amount_contractor', show: configuration.isContractor ? show_quotes : false },
    { label: 'dateValidationQuotesContractor', key: 'validation_quotes_date_contractor', show: configuration.isContractor ? show_quotes : false },
    { label: 'interventionDatesContractor', key: 'intervention_dates_contractor', show: configuration.isContractor ? true : false },
    { label: 'invoiceReferenceContractor', key: 'invoice_reference_contractor', show: configuration.isContractor ? show_invoice : false },
    { label: 'invoiceAmountContractor', key: 'invoice_amount_contractor', show: configuration.isContractor ? show_invoice : false },

    // Fin du ticket
    { label: 'finished-at-export', key: 'finished_at', show: true },
    { label: 'ticketCloseDate', key: 'closed_at', show: true },
    { label: 'status-compta', key: 'facture_state', show: configuration.isContractor ? false : true },
    { label: 'invoice-transmitted-at', key: 'invoice_transmitted_at', show: configuration.isClient ? show_invoice : false },
    { label: 'totalCostsConsumptionsStock', key: 'stock_costs', show: configuration?.feature?.inventory ? true : false },

    // Champs personnalisés
    ...(customFields?.length ? customFields.map((field) => ({ label: field.label, key: field._id, show: true })) : [])
  ].filter((e) => e.show);
};

export default TicketExportsNew;
