import FilterBar from 'components/filters/FilterBar';
import SearchBar from 'components/filters/SearchBar';
import { Form } from 'frmx';
import BookmarkBar, { BookmarkBar2 } from './BookmarkBar';
import { useEffect, useState } from 'react';

export default function FilterAndSearchBarForm({
  search = true,
  filterSetsManager,
  setFilterSetsManager,
  totalResults,
  menuItems = [],
  searchPath = 'search',
  initialValues,
  setInitialValues,
  hiddenFilters,
  onChange,
  onSubmit,
  filters = [],
  sortingOrders = [],
  withFilters,
  downloadLoading,
  className = 'shadow-[0px_0.7px_0.7px_rgba(0,0,0,0.1)] z-10',
  bookmarkState,
  bookmarks,
  resizeable = false,
  appliedFilters,
  dataFilters,
  listAction,
  entity,
  limit,
  setResize = () => {}
}) {
  // Ajoutez un état local pour forcer la reconstruction du Form apres le changement de
  const [key, setKey] = useState(0);

  // Surveillez les changements d'initialValues (utiliser pour le refresh des filtres personnalisés)
  useEffect(() => {
    setKey((prev) => prev + 1);
    // if (onChange) onChange(initialValues);
  }, [initialValues]);

  return (
    <Form
      key={key}
      className={className}
      initialValues={initialValues}
      {...(onChange ? { afterChange: onChange } : {})}
      {...(onSubmit ? { onSubmit: onSubmit } : {})}
    >
      {search && (
        <SearchBar
          totalResults={totalResults}
          menuItems={menuItems}
          path={searchPath}
          filterSetsManager={filterSetsManager}
          setFilterSetsManager={setFilterSetsManager}
          downloadLoading={downloadLoading}
          resizeable={resizeable}
          setResize={setResize}
          appliedFilters={appliedFilters}
          setInitialValues={setInitialValues}
          dataFilters={dataFilters}
          onSubmit={onSubmit}
          listAction={listAction}
          entity={entity}
          limit={limit}
          hiddenFilters={hiddenFilters}
        />
      )}
      {withFilters && (
        <div className="mx-2">
          <FilterBar
            filters={filters}
            sortingOrders={sortingOrders}
          />
        </div>
      )}

      {/* TODO : migrer tous les bookmarks de la nouvelle liste vers BookmarkBar2 */}
      {bookmarkState && (
        <div className="mx-2">
          <BookmarkBar bookmarkState={bookmarkState} />
        </div>
      )}
      {bookmarks && (
        <div className="mx-2">
          <BookmarkBar2 bookmarks={bookmarks} />
        </div>
      )}
    </Form>
  );
}
