import { Box, Grid, Chip, List } from '@material-ui/core';
import { useConfiguration } from 'hooks/useConfiguration';

import { dptmts as frenchDepartments } from 'lists/frenchDepartments';

import useStyles from 'layouts/entities/View.styles';

import { ClientItem, EmailItem, PhoneItem } from 'components/views/ViewItems';
import CustomFields from '../CustomFields';
import ViewItem from 'components/views/ViewItem';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { getLocationName } from './LocationPreview';
import CopyableLink from 'components/CopyableLink';

export default function LocationView({ element }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const config = useConfiguration();
  const auth = useAuth();
  const company = auth.interface._company;
  const isContractor = config.isContractor;
  const contractorId = isContractor ? auth.interface._company?._id : undefined;
  const interfaceURL = `${process.env.REACT_APP_URL || 'https://bob-desk.com'}/public/${element._id}/local${
    isContractor ? `/${contractorId}` : ''
  }`;

  const department =
    element.address &&
    element.address.country === 'France' &&
    frenchDepartments.find((d) => d.departmentCode === String(element.address.zone));
  const fullAddress = element?.address?.fullAddress;

  const local_public_interface_activated = config.ticket_public?.local;
  const is_client = auth.interface.isClient;
  const contractor_id = element._contractor?._id || element._contractor;
  const connected_company_id = auth.interface._company._id;

  const should_see_public_interface_link =
    local_public_interface_activated && (is_client || contractor_id === connected_company_id);

  return (
    <>
      <Box className={classes.l1}>
        <Box
          alignItems="center"
          display="flex"
        >
          <Box
            flexDirection="column"
            alignItems="flex-start"
          >
            <Box className={classes.l1left}>
              {element?.number && <span className={classes.l1reference}>#{element.number}</span>}
              {!!element.name?.length ? element.name : element.address ? fullAddress : ''}
            </Box>
            {element._type && (
              <Box marginTop="10px">
                <Chip
                  className={classes.l1chip}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  label={element._type.name}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Grid
        className="mt-2"
        container
      >
        <Grid
          item
          xs={12}
          sm={element._cover ? 6 : 12}
        >
          <List>
            <CustomFields element={element}>
              {config.isContractor && element._client && <ClientItem client={element._client} />}

              {element._sector && (
                <ViewItem
                  secondaryIcon
                  icon="sitemap"
                  primary={t('entityScoSecName')}
                  secondary={element._sector.name}
                />
              )}

              {element?.contact_phone && (
                <PhoneItem
                  element={{ phone: element.contact_phone }}
                  label="Téléphone"
                />
              )}
              {element?.contact_email && <EmailItem element={{ email: element.contact_email }} />}

              {element.address && (
                <ViewItem
                  icon="map-marker-alt"
                  secondaryIcon
                  primary={t('address')}
                  secondary={getLocationName(element)}
                />
              )}

              {element.address && department && (
                <>
                  <ViewItem
                    icon="map-marker"
                    secondaryIcon
                    primary={t('region')}
                    secondary={department.regionName}
                  />
                  <ViewItem
                    icon="map-marker"
                    secondaryIcon
                    primary={t('department')}
                    secondary={department.departmentName}
                  />
                </>
              )}

              {!!element?.address?.length && (
                <ViewItem
                  icon={company?.currency?.icon || 'euro-sign'}
                  secondaryIcon
                  primary={t('billingAddress')}
                  secondary={
                    <Box
                      display={'inline-flex'}
                      flexDirection={'column'}
                    >
                      <Box display={'span'}>
                        {element.isSameFacturation
                          ? ''
                          : config._facturation
                          ? config._facturation.name
                          : element._facturation?.name
                          ? element._facturation.name
                          : ''}
                      </Box>
                      <Box display={'span'}>
                        {element.isSameFacturation
                          ? fullAddress
                          : config._facturation
                          ? config._facturation.address.fullAddress
                          : element._facturation?.address?.fullAddress
                          ? element._facturation?.address.fullAddress
                          : ''}
                      </Box>
                    </Box>
                  }
                />
              )}

              {should_see_public_interface_link && (
                <ViewItem
                  icon="link"
                  secondaryIcon
                  primary={t('publicInterfaceTitle')}
                  secondary={<CopyableLink link={interfaceURL} />}
                />
              )}

              {element.informations && (
                <ViewItem
                  icon="info-circle"
                  primary={t('informations')}
                  secondary={element.informations}
                />
              )}
            </CustomFields>
          </List>
        </Grid>
        {element._cover && (
          <Grid
            item
            xs={12}
            sm={6}
          >
            <div
              style={{
                backgroundImage: `url(${typeof element._cover === 'string' ? element._cover : element._cover.url})`
              }}
              className="w-full h-full bg-contain bg-no-repeat bg-center"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
