import { IconButton } from '@material-ui/core';
import { FindInPageOutlined } from '@material-ui/icons';
import NotEmptyIndicator from 'components/filters/NotEmptyIndicator';
import Tooltip from 'components/ToolTip';
import FAIcon from 'components/ui/FAIcon';

import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

import DevisHelper from 'utils/devis';
import { formatCurrency } from 'utils/formatCurrency';

export default function QuoteColumn({ ticket, setDocumentViewerConfig }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  const devis = [...ticket.devis, ...ticket._facture_tickets.flatMap((ticket) => ticket.devis)];

  return ticket.parentId || !ticket._facture_tickets.length ? (
    <div className="flex gap-1 items-center justify-end min-w-[160px]">
      <div className="w-[80px] flex flex-col items-center">
        {DevisHelper.getValidateds({ devis: ticket.devis }).length > 0 && (
          <strong>
            REF:{' '}
            {DevisHelper.getValidateds({ devis: ticket.devis })
              .map((d) => d.reference)
              .join(', ')}
          </strong>
        )}
        {formatCurrency({
          number: ticket.devis_price,
          locale: company?.currency?.locale,
          currency: company?.currency?.code
        })}
      </div>
      <div className="w-[40px] flex items-center justify-center">
        <Tooltip title={t('seeDocumentsTypeOfQuotes')}>
          <IconButton
            onClick={(ev) =>
              setDocumentViewerConfig({
                isOpen: true,
                ticketId: ticket._id,
                type: 'devis',
                anchor: ev.currentTarget,
                lock: ticket.facture_state.lock
              })
            }
            disabled={!ticket.devis.length}
          >
            <NotEmptyIndicator
              counter={DevisHelper.getAll({ devis: ticket.devis }).length}
              showIndicator
            />
            <FindInPageOutlined style={{ width: '100%', height: '1.2em' }} />
          </IconButton>
        </Tooltip>
      </div>
      <div className="w-[40px]">
        {!!ticket?.devis?.length && (
          <DevisBadge
            devis={ticket.devis}
            transparent
          />
        )}
      </div>
    </div>
  ) : (
    <div className="flex gap-1 items-center justify-end min-w-[160px]">
      <div className="w-[80px] flex flex-col items-center">
        {formatCurrency({
          number: ticket.total_devis_price,
          locale: company?.currency?.locale,
          currency: company?.currency?.code
        })}
      </div>
      <div className="w-[40px]"></div>
      <div className="w-[40px]">
        {!!devis?.length && (
          <DevisBadge
            devis={devis}
            transparent
          />
        )}
      </div>
    </div>
  );
}

function DevisBadge({ devis = [], intervenerId = null, transparent = false }) {
  const { t } = useTranslation();

  if (DevisHelper.getWaitings({ devis, intervenerId }).length)
    return (
      <Tooltip title={t('awaitValidationQuotes')}>
        <div className="p-1">
          <FAIcon
            collection="fas"
            icon="gavel"
            className="text-[#ef7a1f]"
          />
        </div>
      </Tooltip>
    );

  if (DevisHelper.getValidateds({ devis, intervenerId }).length)
    return (
      <Tooltip
        placement="left"
        title={t('acceptedQuote')}
      >
        <div className="p-1">
          <FAIcon
            collection="fas"
            icon="check-circle"
            className="text-[#2ecc71]"
          />
        </div>
      </Tooltip>
    );

  if (DevisHelper.getDroppeds({ devis, intervenerId }).length)
    return (
      <Tooltip
        placement="left"
        title={t('rejectedQuote')}
      >
        <div className="p-1">
          <FAIcon
            collection="fas"
            icon="minus-circle"
            className="text-[#e74c3c]"
          />
        </div>
      </Tooltip>
    );

  return transparent ? <div style={{ width: '40px' }} /> : null;
}
