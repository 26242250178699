import { faker } from '@faker-js/faker';
import { historyComponents } from './components';

export const modelEntries = [
  {
    _id: 'test',
    type: 'ticket-created',
    user: {
      company_name: 'ACME Inc.',
      company_avatar: 'https://via.placeholder.com/150',
      avatar: 'https://via.placeholder.com/150',
      name: 'John Doe',
      role: 'Technician'
    },
    data: {},
    at: new Date(),
    c: '', // company id
    f: '', // feed id
    s: '' // search text
  }
];

const types = Object.keys(historyComponents);

export const fakeEntries = Array.from({ length: 33 }, () => null).map(() => {
  const type = faker.helpers.arrayElement(types);

  return {
    _id: faker.database.mongodbObjectId(),
    type,
    data: {
      prev_title: faker.lorem.words(),
      next_title: faker.lorem.words(),
      ticket_id: faker.database.mongodbObjectId()
      //TODO: Rajouter de la fake data qui correspond a tous les types
    },
    user: {
      company_name: faker.company.buzzNoun(),
      company_avatar: faker.image.avatar(),
      avatar: faker.image.avatar(),
      name: faker.person.firstName() + ' ' + faker.person.lastName(),
      role: faker.person.jobTitle()
    },
    at: faker.date.recent(),
    c: faker.database.mongodbObjectId(),
    f: faker.database.mongodbObjectId(),
    s: faker.lorem.words()
  };
});
