import { useTranslation } from 'react-i18next';
import FAIcon from 'components/ui/FAIcon.js';
import FilterAndSearchBarForm from 'components/filters/FilterAndSearchBarForm';
import { useAuth } from 'hooks/useAuth';
import { useConfiguration } from 'hooks/useConfiguration';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useSelector } from 'react-redux';
import { getBookmarks } from 'modules/tickets/ticket-list/utils';
import { normalizeFilters } from 'modules/tickets/ticket-list/utilsFun';
import {
  getInitialValuesFromFilters,
  getTicketFilters,
  ticketSortingOrder
} from 'modules/tickets/ticket-list/baseFilters';
import {
  createFilterSetsManager,
  generateFilters,
  PopoverExportTickets,
  useFilteredBookmarks
} from 'modules/tickets/ticket-list/utilsFun';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useDebouncedState from 'hooks/useDebouncedState';
import { fetchAccountingTickets } from './utilsFun';
import MenuItem from 'components/menu/MenuItem';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { AccountingTable } from './AccountingTable';
import { SkeletonTicket } from 'modules/tickets/ticket-list/TicketFile';
import Menu from 'components/menu/Menu';

const LIMIT = 10;

const AccountingFile = ({ showFilters, showCustomFilters, defaultFilters, hiddenFilters, lockedFilters }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const config = useConfiguration();
  const { dispatch } = useAsyncDispatch();
  const customFields = useSelector((store) => store.fieldSections).ticket;
  const entity = 'accounting';

  const [skip, setSkip, debounceSkip] = useDebouncedState(0, 400);
  const data = useSelector((state) => state.accountingList);

  const isLoading = data.isLoading;

  const initialBookmarks = getBookmarks({ auth, isCompta: true });
  const [bookmarkState, setBookmarkState] = useState({
    bookmarks: initialBookmarks,
    isLoading: false
  });

  const showableFilters = getTicketFilters({
    configuration: config,
    customFields,
    deleted: false,
    defaultFilters,
    lockedFilters
  });

  const [initialValues, setInitialValues] = useState(
    getInitialValuesFromFilters({
      filters: showableFilters,
      hiddenFilters,
      deleted: false,
      showCustomFilters,
      entity
    })
  );

  const [appliedFilters, setAppliedFilters, debouncedAppliedFilters] = useDebouncedState(initialValues, 100);

  const [filterSetsManager, setFilterSetsManager] = useState(
    createFilterSetsManager(appliedFilters, showCustomFilters, entity)
  );

  useFilteredBookmarks(false, appliedFilters, setBookmarkState, initialBookmarks);
  const stableFetchAccountingTickets = useCallback(fetchAccountingTickets({ auth, dispatch, limit: LIMIT }), [
    auth,
    dispatch,
    LIMIT
  ]);

  // Fetch les tickets lors du select d'un filtre
  useEffect(() => {
    stableFetchAccountingTickets(debouncedAppliedFilters, debounceSkip);
  }, [debouncedAppliedFilters, debounceSkip]);

  // Création des éléments de menu et des composants de filtre
  const menuItems = [
    <MenuItem
      icon="rotate-right"
      label="refresh"
      onClick={() => stableFetchAccountingTickets(appliedFilters, skip)}
    />,
    <Menu
      button={() => (
        <MenuItem
          icon="download"
          label={t('ExportXEls', { count: data?.count || 0 })}
          hasSubMenu
        />
      )}
      items={[<PopoverExportTickets appliedFilters={appliedFilters} />]}
      placement="right-start"
    />
  ];

  const filters = useMemo(
    () => generateFilters(filterSetsManager.orderFilters, showableFilters),
    [filterSetsManager, showableFilters]
  );

  const pageNumber = Math.ceil(data.count / LIMIT);
  const currentPage = Math.floor(skip / LIMIT) + 1;

  const handleFilterChange = (filters) => {
    setSkip(0);
    setAppliedFilters(filters);
  };

  return (
    <div className="flex flex-col h-full bg-white shadow mb-0 rounded-xl overflow-hidden">
      <FilterAndSearchBarForm
        withFilters={showFilters}
        totalResults={data.count}
        initialValues={initialValues}
        setInitialValues={(filters) => {
          setInitialValues(filters);
          setAppliedFilters(filters);
        }}
        appliedFilters={appliedFilters}
        hiddenFilters={normalizeFilters({ filters: hiddenFilters || [] })}
        filterSetsManager={showCustomFilters ? filterSetsManager : null}
        setFilterSetsManager={setFilterSetsManager}
        // onChange={setAppliedFilters}
        onSubmit={handleFilterChange}
        menuItems={menuItems}
        filters={filters}
        dataFilters={showableFilters.map((filter) => ({
          label: filter.props.label,
          key: filter.props.path
        }))}
        sortingOrders={ticketSortingOrder}
        bookmarkState={bookmarkState}
        entity={entity}
        limit={LIMIT}
        className="mb-2 flex-shrink-0"
      />
      <div className="relative flex-grow">
        {isLoading ? (
          <SkeletonTicket />
        ) : !isLoading && !data?.tickets?.length ? (
          <TemplateNoAccountingTickets />
        ) : (
          <AccountingTable data={data.tickets} />
        )}
      </div>

      {(isLoading || Boolean(data?.tickets?.length)) && (
        <div className="flex justify-end items-center p-3 gap-2">
          <button
            onClick={() => {
              if (skip > 0) {
                setSkip(skip - LIMIT);
              }
            }}
            className={`p-2 rounded-full hover:bg-gray-100 ${skip === 0 ? 'cursor-not-allowed text-gray-300' : ''}`}
            disabled={skip === 0}
          >
            <ChevronLeft />
          </button>
          <div>
            {t('page')} {currentPage} / {pageNumber}
          </div>
          <button
            onClick={() => {
              if (skip + LIMIT < data.count) {
                setSkip(skip + LIMIT);
              }
            }}
            className={`p-2 rounded-full hover:bg-gray-100 ${
              skip + LIMIT >= data.count ? 'cursor-not-allowed text-gray-300' : ''
            }`}
            disabled={skip + LIMIT >= data.count}
          >
            <ChevronRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default AccountingFile;

export const TemplateNoAccountingTickets = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center h-full text-gray-500">
      <FAIcon
        className="mr-3"
        collection="fas"
        icon="calculator"
        size="medium"
      />
      <h1 className="text-xl font-semibold">{t('noResultFoundAccounting')}</h1>
    </div>
  );
};
