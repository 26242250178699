import { Box, Chip, useTheme } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import { AccessTime } from '@material-ui/icons';

import { useEntity } from 'contexts/entities/entityContext';

import useStyles from 'layouts/entities/Preview.styles';

import { dateToLocalFormat } from 'utils/dates';

import { contractStates, CONTRACT_CLOSED, CONTRACT_FINISHED } from 'lists/contractStates';
import clsx from 'clsx';
import { useAuth } from 'hooks/useAuth';

import { truncateText } from 'utils/uiUtils';
import { Ecart } from 'entities/Maintenance/PeriodPreview';
import preventAll from 'utils/preventAll';
import { useConfiguration } from 'hooks/useConfiguration';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';
import { sortByLatestDateFirst } from 'utils/sort';
import { ChatBubbleLeftEllipsisIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import Tooltip from 'components/ToolTip';

export default function TicketPreview({ element, isActive, payload: { scheduler, calendar } }) {
  const { entity } = useEntity();
  const config = useConfiguration();
  const { t } = useTranslation();

  const isProposition = entity === 'contractPropositions';
  const ticket = isProposition ? element._ticket : element;

  const state = ticket.contract.state;

  const classes = useStyles();
  const stateObject = contractStates[state];
  const auth = useAuth();

  const urgencies = ticket.creatorConfiguration?.urgencies;
  const urgency =
    urgencies && urgencies[ticket.urgency] && !!urgencies[ticket.urgency].label?.length && urgencies[ticket.urgency];

  const equipment =
    !ticket._locations?.length && !!ticket._equipments?.length
      ? ticket._equipments?.length === 1
        ? ticket._equipments[0].name
        : ticket._equipments?.length + ' ' + t('equipmentsS')
      : false;

  const info = (
    <>
      {!!ticket._locations?.length && (
        <>
          {!calendar && (
            <FAIcon
              style={{ position: 'relative', right: 6 }}
              icon={'map-marker-alt'}
              collection={calendar ? 'fas' : 'fal'}
              size="small"
            />
          )}
          {ticket._locations?.length === 1
            ? ticket._locations[0].name + ' ' + (ticket._locations[0].address?.postalCode || '')
            : ticket._locations?.length + ' ' + t('locationsS')}

          {!!equipment && (
            <>
              <FAIcon
                style={{ marginRight: 4 }}
                icon="wrench"
                collection={calendar ? 'fas' : 'fal'}
                size="small"
              />
              {equipment}
            </>
          )}
        </>
      )}
    </>
  );

  const deadlineExceed = !!ticket.contract.deadline && Date.now() > +new Date(ticket.contract.deadline.date);
  const theme = useTheme();

  const k1 = useKeys();
  const k2 = useKeys();

  const has_deadline_expired = ticket?.intervention_after_deadline || ticket?.resolution_after_deadline;

  return calendar ? (
    <Box
      style={{
        width: '100%',
        position: 'relative',
        height: '46px',
        paddingLeft: 10,
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Box
        marginBottom="-10px"
        paddingTop="4px"
        style={{ whiteSpace: 'pre' }}
      >
        {info}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        height="100%"
      >
        {[CONTRACT_FINISHED, CONTRACT_CLOSED].includes(state) && (
          <Tooltip
            position="left"
            title={t(stateObject.label)}
          >
            <Box
              width="8px"
              height="100%"
              position="absolute"
              top="0px"
              left="0px"
              bgcolor={stateObject.color}
            />
          </Tooltip>
        )}
        {config.isContractor && !!ticket.contractParent && <span>{ticket.contractParent._company.name}</span>}
        {config.isClient && <span>#{ticket.number}</span>}
        <span
          style={{
            fontWeight: 400,
            marginLeft: 6,
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'pre'
          }}
        >
          {!!element.period ? element.period._preventif?.name : element.title}
        </span>
      </Box>
    </Box>
  ) : (
    <>
      <Box style={{ width: '100%' }}>
        <div className="flex justify-between">
          <div className="flex items-center w-full mr-2">
            <div className="font-semibold text-xs flex items-center text-[#363640]">#{ticket.number}</div>
            <div
              className="font-semibold text-xs flex items-center ml-2"
              style={{ color: stateObject.color }}
            >
              {t(stateObject.label)}
              {ticket.contract.intervention_compute && (
                <Tooltip
                  position={'right'}
                  title={t('intervenerOnTheSpot')}
                >
                  <Box marginLeft="6px">
                    <FAIcon
                      style={{ color: '#f1c40f' }}
                      collection="fad"
                      icon="street-view"
                    />
                  </Box>
                </Tooltip>
              )}
              {!!ticket.contract.deadline && (
                <Tooltip
                  position="right"
                  title={
                    <>
                      {t('theBeforeDate')} {dateToLocalFormat(ticket.contract.deadline.date, 'PPPPp')}
                      {
                        <>
                          <br />
                          {ticket.contract.deadline.description}
                        </>
                      }
                    </>
                  }
                >
                  <AccessTime
                    style={{
                      marginLeft: 6,
                      color: deadlineExceed ? theme.palette.primary.dark : theme.palette.secondary.light
                    }}
                  />
                </Tooltip>
              )}
              {isProposition && !element.seen && (
                <Box
                  boxShadow={1}
                  display="flex"
                  alignItems="center"
                  height="16px"
                  fontSize={10}
                  padding="0px 6px"
                  borderRadius={8}
                  marginLeft="6px"
                  bgcolor="#e74c3c"
                  color="white"
                >
                  {t('newLabel')}
                </Box>
              )}
              {ticket.period && <Ecart period={ticket.period} />}
            </div>
          </div>

          <div className="flex items-center">
            {[
              {
                notes: element.notes,
                letter: 'P',
                title: element?.notes?.length > 1 ? t('notesVisibleByEveryone') : t('noteVisibleByEveryone'),
                color: '#297BD7'
              },
              {
                notes: element.viewNotes,
                letter: 'I',
                title: element?.viewNotes?.length > 1 ? t('internalNotes') : t('internalNote'),
                color: '#1F60A7'
              },
              config.isManager && {
                notes: element.viewNotesManagers,
                letter: 'G',
                title: element?.viewNotesManagers?.length > 1 ? t('managersNotes') : t('managersNote'),
                color: '#174578'
              }
            ].map(({ notes, letter, title, color }, i) => {
              return (
                !!notes?.length && (
                  <Tooltip
                    {...preventAll}
                    key={k1(i)}
                    title={
                      <>
                        <div
                          className={`mb-5 p-2 text-center text-sm text-white rounded-lg `}
                          style={{ backgroundColor: color }}
                        >
                          {notes?.length} {title}
                        </div>
                        <div className="flow-root px-4">
                          <ul>
                            {sortByLatestDateFirst(notes).map((note, noteIdx) => {
                              const first_name_or_last_name_known = !!note._user?.firstName || !!note._user?.lastName;
                              const note_from_api = !!note?.fromApi;

                              const author_note = note_from_api
                                ? t('fromApi')
                                : first_name_or_last_name_known
                                ? note?._user?.firstName + ' ' + note?._user?.lastName
                                : note?._user?.email;

                              return (
                                <li key={k2(noteIdx)}>
                                  <div className="relative pb-4">
                                    {noteIdx !== notes.length - 1 ? (
                                      <span
                                        className="absolute top-0 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                        aria-hidden="true"
                                      />
                                    ) : null}
                                    <div className="relative flex items-start space-x-3">
                                      <div className="relative">
                                        <div className="h-5 w-5 bg-gray-100 rounded-full ring-4 ring-white flex items-center justify-center">
                                          <UserCircleIcon
                                            className="h-5 w-5 text-gray-500"
                                            aria-hidden="true"
                                          />
                                        </div>
                                        <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                                          <ChatBubbleLeftEllipsisIcon
                                            className="h-3 w-3 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </div>
                                      <div className="group min-w-0 flex-1">
                                        <div className="text-sm font-normal flex justify-between">
                                          <p className="whitespace-pre-wrap inline-flex">{note.note}</p>
                                        </div>
                                        <div className="mt-2">
                                          <p className="text-right text-xs text-gray-400">
                                            {author_note}, {dateToLocalFormat(note.created_at, 'Pp')}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </>
                    }
                    position="top"
                    arrow
                  >
                    <Box
                      {...preventAll}
                      style={{
                        marginRight: '.5em',
                        backgroundColor: color,
                        borderRadius: 8,
                        color: 'white',
                        fontSize: '.7em',
                        height: '2.5em',
                        width: '2.5em',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {letter}
                    </Box>
                  </Tooltip>
                )
              );
            })}

            {!!has_deadline_expired && (
              <Tooltip
                position="left"
                title={t('afterDeadline')}
              >
                <Box className="flex items-center">
                  <FAIcon
                    style={{ marginLeft: 6, color: '#e63d53' }}
                    icon="circle"
                    collection="fas"
                    size="small"
                  />
                </Box>
              </Tooltip>
            )}

            {urgency && (
              <Chip
                className={classes.chip}
                color="secondary"
                style={isActive ? { backgroundColor: urgency.color } : { color: urgency.color, borderColor: urgency.color }}
                variant={isActive ? 'default' : 'outlined'}
                size="small"
                label={t(urgency.label)}
              />
            )}
          </div>
        </div>
        <div className="mt-2 md:flex">
          <div
            className={classes.title}
            style={{ color: auth.interface.isMaster ? theme.palette.primary.main : '' }}
          >
            {auth.interface.isMaster
              ? ticket.companyCreator.name || '-'
              : !!ticket.period
              ? ticket.period._preventif?.name
              : ticket.title}
          </div>
          <div className="w-full flex items-center md:justify-end">
            {ticket._jobs?.length > 0 ? (
              <>
                <FAIcon
                  icon="toolbox"
                  collection="fal"
                  size="small"
                  className="ml-[-4px]"
                />
                {ticket._jobs?.length === 1 ? ticket._jobs[0]?.name : `${ticket._jobs?.length} ${t('jobsS')}`}
              </>
            ) : null}
          </div>
        </div>

        <div className={clsx(classes.l, classes.description)}>
          <div>{truncateText(ticket.description, 160)}</div>
        </div>
        <div className="md:flex justify-between items-center mt-2">
          <div className="flex items-center">
            {ticket._locations?.length > 0 ? (
              <>
                <FAIcon
                  icon={'map-marker-alt'}
                  collection="fal"
                  size="small"
                  className="ml-[-4px]"
                />
                {ticket._locations?.length === 1
                  ? `${ticket._locations[0]?.name || ''} ${ticket._locations[0].address?.postalCode || ''}`
                  : `${ticket._locations?.length} ${t('locationsS')}`}
              </>
            ) : null}
          </div>
          <div className="flex items-center">
            {ticket._equipments?.length > 0 ? (
              <>
                <FAIcon
                  icon="wrench"
                  collection="fal"
                  size="small"
                  className="ml-[-4px]"
                />
                {ticket._equipments?.length === 1
                  ? `${ticket._equipments[0]?.name || ''}`
                  : `${ticket._equipments?.length} ${t('equipmentsS')}`}
              </>
            ) : null}
          </div>
        </div>
      </Box>
    </>
  );
}
