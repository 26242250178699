import {
  Box,
  ListItemSecondaryAction,
  TextField,
  List,
  ListItemText,
  Button,
  ListItemIcon,
  ListItem,
  CircularProgress,
  Tabs,
  Tab,
  useTheme,
  IconButton
} from '@material-ui/core';
import { EditOutlined, Save, Timer } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';
import PopoverDrawer from 'components/ui/PopoverDrawer';
import { useState, useRef, useMemo } from 'react';
// import Team from "libs/team/src/Team"
import useAsyncDispatch from 'hooks/useAsyncDispatch';

import { updatePrice, updateElement } from 'store/ticketsSlice';

import { Form, Submit } from 'frmx';
import Scope from 'entities/Scope/Scope';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useConfiguration } from 'hooks/useConfiguration';

import { formatCurrency } from 'utils/formatCurrency';

import PopoverBudget from './Budgets/PopoverBudget';

export default function TabDatas({ element }) {
  const [isOpenTypo, setisOpenType] = useState(false);
  const [isOpenPrice, setIsOpenPrice] = useState(false);
  const [isOpenTva, setIsOpenTva] = useState(false);
  const [isOpenBudgets, setIsOpenBudgets] = useState(false);

  const { t } = useTranslation();

  const [tab, setTab] = useState(0);

  const { dispatch, requestStatus } = useAsyncDispatch({ virtualized: true });
  const isLoading = requestStatus === 'loading';

  const typeRef = useRef(null);
  const priceRef = useRef(null);
  const tvaRef = useRef(null);
  const budgetRef = useRef(null);

  const auth = useAuth();
  const configuration = useConfiguration();

  const company = auth.interface._company;

  const contract = auth.interface.isClient ? element.contract : tab === 0 ? element.contractParent : element.contract;
  const duration = useMemo(
    () => contract.intervention_dates.filter((date) => date.compute).reduce((a, date) => a + date.duration, 0),
    [contract.intervention_dates]
  );

  const timeConvert = (n) => {
    const num = n;
    const hours = num / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    return { h: rhours, m: rminutes };
  };
  const time = timeConvert(duration);
  const [price, setPrice] = useState(contract.intervention_price);

  const xhrPrice = () =>
    dispatch(
      updatePrice,
      {
        price,
        isParent: auth.interface.isClient ? false : tab === 0 ? true : false,
        type: 'intervention'
      },
      {
        onSuccess: () => {
          setisOpenType(false);
          setIsOpenPrice(false);
          setIsOpenTva(false);
        }
      },
      { id: element._id }
    );

  const xhrUpdate = (body) =>
    dispatch(
      updateElement,
      body,
      {
        onSuccess: () => {
          setisOpenType(false);
          setIsOpenPrice(false);
          setIsOpenTva(false);
        }
      },
      { id: element._id }
    );

  const theme = useTheme();

  const budgets_in_use = useMemo(() => {
    return element?.budgets?.filter((budget) => budget.add_amount > 0) || [];
  }, [element]);

  const total_cost_consumptions_for_ticket = useMemo(() => {
    const consumptions = element?.contract?._consumptions;

    if (!consumptions) return 0;

    return consumptions.reduce((acc, consumption) => {
      const unit_value_price = consumption?._product?.unitValuationPrice || 0;

      return acc + Number(unit_value_price) * Number(consumption.quantity);
    }, 0);
  }, [element]);

  return (
    <>
      <Box
        width="100%"
        height="100%"
        maxHeight="100%"
        position="absolute"
        top={0}
        left={0}
        bgcolor="white"
      >
        {auth.interface.isContractor && (
          <Box bgcolor={theme.palette.primary.light}>
            <Tabs
              indicatorColor="primary"
              variant="fullWidth"
              value={tab}
              onChange={(e, index) => setTab(index)}
            >
              {!!element.contractParent && (
                <Tab
                  style={{ color: 'white' }}
                  value={0}
                  label={element.contractParent._company?.name}
                />
              )}
              {!!element.contractContractor && (
                <Tab
                  style={{ color: 'white' }}
                  value={1}
                  label={element.contractContractor._company?.name}
                />
              )}
            </Tabs>
          </Box>
        )}

        <List style={{ padding: 0 }}>
          <ListItem
            ref={typeRef}
            button
            onClick={() => setisOpenType(true)}
          >
            <ListItemIcon>
              <FAIcon
                icon={'files'}
                collection={'fal'}
              />
            </ListItemIcon>
            <ListItemText
              primary={<strong>{element._typology?.name}</strong>}
              secondary={'Typologie'}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => setisOpenType(true)}>
                <EditOutlined />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          {
            <PopoverDrawer
              open={isOpenTypo}
              onClose={() => setisOpenType(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transitionDuration={0}
              style={{ width: typeRef.current?.offsetWidth }}
              anchorEl={typeRef.current}
            >
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
              >
                <Form
                  style={{ width: '100%' }}
                  disableIfNoUpdates
                  initialValues={{
                    _typology: element._typology
                  }}
                  onSubmit={(form) => [xhrUpdate({ _typology: form._typology?._id })]}
                >
                  <Box
                    height="300px"
                    width="100%"
                  >
                    <Scope
                      type="ticketTypology"
                      pickerUniq
                      picker
                      pickerCreate
                      pickerField="_typology"
                    />
                  </Box>
                  <Submit>
                    <Button
                      disabled={isLoading}
                      endIcon={isLoading ? <CircularProgress size={10} /> : <Save />}
                      fullWidth
                      onClick={() => alert('Save')}
                      style={{ marginTop: 10 }}
                      variant={'contained'}
                      color={'primary'}
                    >
                      {t('save2')}
                    </Button>
                  </Submit>
                </Form>
              </Box>
            </PopoverDrawer>
          }

          {/* TODO change onClick  */}
          {configuration?.feature?.budgets ? (
            <ListItem
              ref={budgetRef}
              button
              disabled={element?.flag_canceled}
              onClick={() => [setIsOpenBudgets(true)]}
            >
              <ListItemIcon>
                <FAIcon
                  icon="wallet"
                  collection="fal"
                />
              </ListItemIcon>
              <ListItemText
                primary={<strong>{budgets_in_use.length > 1 ? t('budgetsTitle') : t('budgetTitle')}</strong>}
                secondary={
                  budgets_in_use.length > 0
                    ? budgets_in_use
                        .map(
                          (budget) =>
                            `${budget.title}(${budget.year}) : ${formatCurrency({
                              number: budget.add_amount,
                              locale: company?.currency?.locale,
                              currency: company?.currency?.code
                            })}`
                        )
                        .join(' ; ')
                    : t('toDefine')
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  disabled={element?.flag_canceled}
                  onClick={() => [setIsOpenBudgets(true)]}
                >
                  <EditOutlined />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ) : null}

          {isOpenBudgets && (
            <PopoverBudget
              isOpenBudgets={isOpenBudgets}
              setIsOpenBudgets={setIsOpenBudgets}
              budgetRef={budgetRef}
              budgets={element?.budgets}
              ticket_id={element._id}
              intervention_price={contract?.intervention_price || 0}
            />
          )}

          <ListItem
            ref={priceRef}
            button
            onClick={() => setIsOpenPrice(true)}
          >
            <ListItemIcon>
              <FAIcon
                icon={company?.currency?.icon || 'euro-sign'}
                collection="fal"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <strong>
                  {formatCurrency({
                    number: contract.intervention_price,
                    locale: company?.currency?.locale,
                    currency: company?.currency?.code
                  })}
                </strong>
              }
              secondary={t('interventionAmount')}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => setIsOpenPrice(true)}>
                <EditOutlined />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          {
            <PopoverDrawer
              open={isOpenPrice}
              onClose={() => setIsOpenPrice(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transitionDuration={0}
              style={{ width: priceRef.current?.offsetWidth }}
              anchorEl={priceRef.current}
            >
              <Box
                padding="16px"
                display="flex"
                flexDirection="column"
                width="100%"
              >
                <TextField
                  type="number"
                  value={price}
                  variant="outlined"
                  placeholder={t('interventionAmount')}
                  onChange={(e) => setPrice(e.target.value)}
                  fullWidth
                />
                <Button
                  onClick={() => xhrPrice()}
                  disabled={isLoading}
                  endIcon={isLoading ? <CircularProgress size={10} /> : <Save />}
                  fullWidth
                  style={{ marginTop: 10 }}
                  variant={'contained'}
                  color={'primary'}
                >
                  {t('save2')}
                </Button>
              </Box>
            </PopoverDrawer>
          }

          <ListItem
            ref={tvaRef}
            button
            onClick={() => setIsOpenTva(true)}
          >
            <ListItemIcon>
              <FAIcon
                icon="percent"
                collection="fal"
              />
            </ListItemIcon>
            <ListItemText
              primary={<strong>{contract._tva?.name + ' %'}</strong>}
              secondary={t('entityScoTikTvaName')}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => setIsOpenTva(true)}>
                <EditOutlined />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Timer />
            </ListItemIcon>
            <ListItemText
              primary={
                <strong>
                  {time.h}h : {time.m}min
                </strong>
              }
              secondary={t('TotalDurationIntervention')}
            />
          </ListItem>

          {configuration?.feature?.inventory && (
            <ListItem>
              <ListItemIcon>
                <FAIcon
                  icon="cash-register"
                  collection="far"
                />
              </ListItemIcon>
              <ListItemText
                primary={<strong>{t('totalCostsConsumptionsStock')}</strong>}
                secondary={formatCurrency({
                  number: total_cost_consumptions_for_ticket,
                  locale: company?.currency?.locale,
                  currency: company?.currency?.code
                })}
              />
            </ListItem>
          )}

          <PopoverDrawer
            open={isOpenTva}
            onClose={() => setIsOpenTva(false)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transitionDuration={0}
            style={{ width: tvaRef.current?.offsetWidth }}
            anchorEl={tvaRef.current}
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <Form
                style={{ width: '100%' }}
                disableIfNoUpdates
                initialValues={{
                  _tva: contract._tva
                }}
                onSubmit={(form) => [xhrUpdate({ _tva: form._tva?._id })]}
              >
                <Box
                  height="300px"
                  width="100%"
                >
                  <Scope
                    type="ticketTva"
                    pickerCreate
                    pickerUniq
                    picker
                    pickerField="_tva"
                  />
                </Box>
                <Submit>
                  <Button
                    disabled={isLoading}
                    endIcon={isLoading ? <CircularProgress size={10} /> : <Save />}
                    fullWidth
                    onClick={() => alert('Save')}
                    style={{ marginTop: 10 }}
                    variant="contained"
                    color="primary"
                  >
                    {t('save2')}
                  </Button>
                </Submit>
              </Form>
            </Box>
          </PopoverDrawer>
        </List>
      </Box>
    </>
  );
}
