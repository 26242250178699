import { Box, Button } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';
import React from 'react';
import { IntervenerShortCut } from '../hooks/IntervenerShortCut';
import { useTranslation } from 'react-i18next';
import useStyles from 'layouts/entities/View.styles';
import { useAuth } from 'hooks/useAuth';

const CreateContract = ({ isLoading, isProposition, setIntervenerPicker }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const auth = useAuth();

  const hintCreateIntervener = auth.interface.isManager && !auth.interface.data?.hasIntervener;

  return (
    <Box className="flex items-center gap-1 mt-2">
      <Button
        disabled={isLoading}
        onClick={() => setIntervenerPicker({ type: 'proposition' })}
        endIcon={
          <FAIcon
            collection={'fal'}
            icon={'mailbox'}
            className={classes.viewActionIcon}
          />
        }
        color={'secondary'}
        variant={'outlined'}
        className={classes.viewAction}
      >
        {t('propose')}
      </Button>

      {!hintCreateIntervener && (
        <Button
          disabled={isLoading}
          onClick={() => setIntervenerPicker({ type: 'assign' })}
          endIcon={
            <FAIcon
              collection={'fal'}
              icon="user-hard-hat"
              className={classes.viewActionIcon}
            />
          }
          color={'primary'}
          variant={'contained'}
          className={classes.viewAction}
        >
          {t('assign')}
        </Button>
      )}

      {!!hintCreateIntervener && !isProposition && (
        <Box
          className="mt-2"
          color="#a9afb5"
          alignItems="center"
          display="inline-flex"
        >
          <Info style={{ marginRight: 6 }} />
          {t('addA')}
          <IntervenerShortCut type={'collaborator'}>{t('technicianS')}</IntervenerShortCut> {t('or')}{' '}
          {
            <IntervenerShortCut type="mine">
              {auth.interface.isContractor ? t('aSubContractor') : t('aCompany')}
            </IntervenerShortCut>
          }{' '}
          {t('inOrderToAssignToIntervention')}
        </Box>
      )}
    </Box>
  );
};

export default CreateContract;
