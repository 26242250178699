import { Box, Chip, Button } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import { useEntity } from 'contexts/entities/entityContext';

import useStyles from 'layouts/entities/Preview.styles';
import { ClientItemPreview } from 'entities/Client/ClientPreview';

import { MaintenanceItemPreview } from 'entities/Maintenance/MaintenancePreview';
import { useConfiguration } from 'hooks/useConfiguration';
import { PeriodsMenu } from '../../components/timeline/Timeline';
import { useState } from 'react';

export function getLocationName(element) {
  const addressEqualName = element.name === element.address?.fullAddress;
  const checkPostalcode = element.address?.fullAddress?.includes(element.address?.postalCode);
  if (!addressEqualName && checkPostalcode) {
    return element.address?.fullAddress;
  } else
    return `${element.address?.fullAddress}, ${element.address?.postalCode} ${element.address?.city}`;
}

export default function LocationPreview({
  element: { maintenance, ...element },
  isActive,
  payload: { timeline }
}) {
  const { setSublistParent } = useEntity();
  const configuration = useConfiguration();
  const classes = useStyles();

  const [isHover, setIsHover] = useState(false);

  return (
    <>
      <Box
        style={{ width: '100%' }}
        onMouseEnter={(e) => setIsHover(true)}
        onMouseLeave={(e) => setIsHover(false)}
      >
        {timeline && isHover && <PeriodsMenu maintenance={maintenance} />}
        <Box className={classes.l}>
          {(element.number || element.name) && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              flexGrow={1}
              minWidth={0}
            >
              {element.number && <span className={classes.reference}>#{element.number}</span>}
              {element.name && (
                <span className={classes.title}>
                  {!!maintenance && (
                    <FAIcon
                      style={{ marginRight: 4 }}
                      icon={'map-marker-alt'}
                      collection={'fal'}
                      size={'small'}
                    />
                  )}
                  {element.name}
                  {!maintenance && !!element._locations?.length && (
                    <Button
                      variant={'outlined'}
                      size={'small'}
                      style={{ marginLeft: 4 }}
                      className={classes.subButton}
                      onClick={(e) => [
                        e.stopPropagation(),
                        e.preventDefault(),
                        setSublistParent(element)
                      ]}
                      disabled={configuration.isPublic}
                    >
                      {element._locations?.length}
                      <FAIcon
                        style={{ marginLeft: 4 }}
                        icon={'map-marker-alt'}
                        collection={'fal'}
                        size={'small'}
                      />
                    </Button>
                  )}
                </span>
              )}
            </Box>
          )}

          {!timeline && (
            <Box
              marginLeft={'auto'}
              display={'flex'}
              alignItems={'center'}
              position={'relative'}
              bottom={6}
            >
              <ClientItemPreview client={element._client} />
            </Box>
          )}
        </Box>

        {(element._type || element.address?.fullAddress) && (
          <Box className={classes.l}>
            <Box>{getLocationName(element)}</Box>
            <Box>
              {element._type && (
                <Chip
                  className={classes.chip}
                  color="secondary"
                  variant={isActive ? 'default' : 'outlined'}
                  size="small"
                  label={element._type.name}
                />
              )}
            </Box>
          </Box>
        )}

        {maintenance?._id && (
          <MaintenanceItemPreview maintenance={maintenance} />
        )}
      </Box>
    </>
  );
}
