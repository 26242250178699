import LocationMap from './Tabs/LocationMap';
import Location from 'entities/Location/Location';
import Equipment from 'entities/Equipment/Equipment';
import Maintenance from 'entities/Maintenance/Maintenance';
import User from 'entities/User/User';
import Guarantee from 'entities/Guarantee/Guarantee';
import Lease from 'entities/Lease/Lease';
import BoxWrapperScrollable from 'components/BoxWrapperScrollable';
import Documents from 'modules/documents';
import QRCode from 'entities/QRCode';
import TicketFile from 'modules/tickets/ticket-list/TicketFile';

export default function getocationTabs(configuration, element, role, auth) {
  const tabs =
    !!element &&
    [
      {
        label: 'map',
        icon: 'route',
        count: 0,
        show: element.address?.lng && element.address?.lat,
        render: () => <LocationMap address={element.address} />,
        class: 'tabNoPadding'
      },
      {
        label: 'entitySubLocName',
        icon: 'map-marker-alt',
        count: element._locations?.length,
        show: true,
        render: () => (
          <Location
            disableGutters
            sublistParent={element}
          />
        ),
        class: 'tabNoPadding'
      },
      {
        label: 'menuItemDocuments',
        icon: 'clouds',
        count: element.tabs?.docs || 0,
        show: true,
        render: (element, isLoading) => (
          <Documents
            endpoint_prefix={`location_documents/${element._id}`}
            tab_id={element.documents_tab_id}
          />
        )
      },
      {
        label: 'qrCode',
        icon: 'qrcode',
        count: 0,
        show: Boolean(element) && configuration.equipments_qr_code,
        render: () => (
          <QRCode
            element={element}
            type="locations"
            line1={element.name}
            line2={element.address?.fullAddress || ''}
          />
        ),
        class: 'tabNoPadding'
      },
      {
        label: 'equipmentsTitle',
        icon: 'wrench-simple',
        count: element.tabs?.equipments,
        show: configuration.feature.equipment,
        render: () => (
          <Equipment
            disableGutters
            disableCreate={
              !(
                configuration.isClient ||
                (configuration.isContractor &&
                  Boolean(element._contractors?.find((contractor) => contractor._id === auth.interface._company._id)))
              )
            }
            defaultForm={{ _location: element }}
            defaultFilters={{ locations: [element] }}
          />
        ),
        class: 'tabNoPadding'
      },
      {
        label: 'interventions',
        icon: 'files',
        //TODO: Add index to optimize the tabs requests
        count: element.tabs?.tickets,
        show: true,
        render: () => (
          <BoxWrapperScrollable>
            <TicketFile
              showFilters
              defaultFilters={{ locations: [element], closed: true, clients: [element?._client] }}
              lockedFilters={['locations', 'clients']}
            />
          </BoxWrapperScrollable>
        ),
        class: 'tabNoPadding'
      },
      {
        label: 'entityMaintenanceName',
        icon: 'heartbeat',
        count: element.tabs?.maintenances,
        show: configuration.feature.preventif,
        render: () => (
          <BoxWrapperScrollable>
            <Maintenance
              disableCreate={
                !(
                  configuration.isClient ||
                  (configuration.isContractor &&
                    Boolean(element._contractors?.find((contractor) => contractor._id === auth.interface._company._id)))
                )
              }
              disableGutters
              defaultForm={{ _locations: [element] }}
              defaultFilters={{ locations: [element] }}
            />
          </BoxWrapperScrollable>
        ),
        class: 'tabNoPadding'
      },
      {
        label: 'guaranteesTitle',
        icon: 'shield-halved',
        count: element.tabs?.guarantees,
        show: configuration.feature.guarantee && role?.permission('guarantees', 'read'),
        render: () => (
          <Guarantee
            disableGutters
            defaultForm={{ _location: element }}
            hiddenFilters={{ locations: [element._id] }}
          />
        ),
        class: 'tabNoPadding'
      },
      {
        label: 'leasesTitle',
        icon: 'house',
        count: element.tabs?.leases,
        show: configuration.feature.lease && role?.permission('leases', 'read'),
        render: () => (
          <Lease
            disableGutters
            defaultForm={{ _location: element }}
            hiddenFilters={{ locations: [element._id] }}
          />
        ),
        class: 'tabNoPadding'
      },
      {
        label: 'usersTitle',
        icon: 'user',
        count: element.tabs?.users,
        show: configuration.feature.contact,
        render: () => (
          <User
            disableGutters
            defaultForm={{ _locations: [element] }}
            defaultFilters={{ locations: [element] }}
          />
        ),
        class: 'tabNoPadding'
      }
    ].filter((e) => e.show);

  return tabs;
}
