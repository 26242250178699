import { useLayoutEffect, useState } from 'react';
import { useFieldObserver } from 'frmx';

export default function NotEmptyIndicator({ path, showIndicator = false, counter, bgColor = 'bg-gray-600', className }) {
  const [hasData, setHasData] = useState(false);
  const [isNegative, setIsNegative] = useState(false);

  const value = counter !== undefined ? counter : useFieldObserver(path); // Priorité au counter

  useLayoutEffect(() => {
    const length = typeof value === 'number' ? value : value?.length || 0;

    setHasData(length > 0);
    const negativeCondition = value === false || (length === 1 && (value?.[0] === false || value?.[0]?._id === false));
    setIsNegative(negativeCondition);
  }, [value]);

  return hasData && showIndicator ? (
    <div
      className={`${className} ${
        counter && !className ? 'top-0 right-1' : '-top-2 -right-1.5'
      } absolute text-[10px] text-white font-medium  w-5 h-5 flex items-center justify-center rounded-full ${
        isNegative ? 'bg-red-500' : bgColor
      }`}
    >
      {typeof value === 'number' ? value : value?.length || 0}
    </div>
  ) : hasData ? (
    <svg
      className="absolute -top-0.5 -right-0.5 w-3 h-3"
      viewBox="0 0 10 10"
    >
      <circle
        cx="5"
        cy="5"
        r="5"
        className={isNegative ? 'fill-red-500' : 'fill-green-500'}
      />
    </svg>
  ) : null;
}
