import { Box, Button, Typography, useTheme } from '@material-ui/core';
import Avatar from 'components/Avatar';
import Ticket from 'entities/Ticket/Ticket';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchPublicData, resetPublicTicketCreated } from 'store/authSlice';
import { dateToLocalFormat } from 'utils/dates';
import logo from 'images/logo.svg';
import Check from 'libs/swal/Check';
import { useIsBelowMd } from 'hooks/useMQ';
import FullScreenError from './FullScreenError';
import { useTranslation } from 'react-i18next';
import Splash from 'components/Splash';
import { useAuth } from 'hooks/useAuth';
import { LanguageSwitch } from 'components/LanguageSwitch';
const prependZero = (n) => (n > 9 ? n : '0' + n);

function getTime(time = new Date()) {
  const hours = prependZero(time.getHours());
  const minutes = prependZero(time.getMinutes());
  const seconds = prependZero(time.getSeconds());
  return `${hours} : ${minutes} : ${seconds}`;
}

function Clock() {
  const clockRef = useRef();

  useEffect(() => {
    const intervalId = setInterval(() => {
      clockRef.current && (clockRef.current.innerText = getTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Typography
      ref={clockRef}
      variant="subtitle1"
      style={{ color: 'white' }}
    >
      {getTime()}
    </Typography>
  );
}

export default function PublicPage() {
  const { id, scope, contractorId } = useParams();
  const { dispatch, requestStatus, statusCode } = useAsyncDispatch();
  const { publicTicketCreated } = useSelector((store) => store.auth);
  const auth = useAuth();
  const _company = auth?.interface?._company;
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(
    () =>
      dispatch(fetchPublicData, {
        [scope === 'local' ? 'location' : 'client']: id,
        contractor: contractorId
      }),
    []
  );

  const bgURL = _company?._configuration?.ticket_public?.background;
  const background = bgURL
    ? `url(${bgURL}) no-repeat center center fixed`
    : 'linear-gradient(230deg,#a24bcf,#4b79cf,#4bc5cf)';

  return requestStatus === 'loading' ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        background: 'linear-gradient(230deg,#a24bcf,#4b79cf,#4bc5cf)'
      }}
    >
      <Splash
        noAvatar
        text={t('stillLoading')}
      />
    </div>
  ) : requestStatus === 'success' ? (
    <Box
      style={{
        background,
        WebkitBackgroundSize: 'cover',
        MozBackgroundSize: 'cover',
        OBackgroundSize: 'cover',
        backgroundSize: 'cover'
      }}
      className="h-screen w-full flex flex-col items-center p-2"
    >
      <Box className="flex gap-1 justify-between w-full items-center p-2 mb-2">
        <div className="flex">
          <Avatar
            size="large2"
            entity={{
              avatar: _company?.avatar,
              name: _company?.name
            }}
          />
          <Box className="ml-4">
            <Typography
              variant="h5"
              style={{ color: 'white' }}
            >
              {_company?.name} - {auth.interface._locations[0]?.name || 'Global'}
            </Typography>
            <Box>
              <Typography
                variant="subtitle1"
                style={{ color: 'white' }}
              >
                {dateToLocalFormat(new Date())}
              </Typography>
              <Clock />
            </Box>
          </Box>
        </div>
        <LanguageSwitch />
      </Box>
      <div className="flex z-[999] md:h-full">{publicTicketCreated ? <SuccessMessage /> : <Ticket publicForm />} </div>
      <img
        style={{ position: 'absolute', bottom: '1em', right: '1em', height: '4em' }}
        src={logo}
        alt=""
      />
    </Box>
  ) : statusCode === 401 ? (
    <FullScreenError /> // TODO: Different message for unactivated public interface?
  ) : statusCode === 404 ? (
    <FullScreenError />
  ) : statusCode === 500 ? (
    <FullScreenError />
  ) : null;
}

function SuccessMessage() {
  const syncDispatch = useDispatch();
  const { t } = useTranslation();

  const goBackToForm = () => syncDispatch(resetPublicTicketCreated());

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      goBackToForm();
    }, 3000);
    return () => clearTimeout(timeOutId);
  }, []);

  return (
    <div className="flex flex-col gap-10 items-center justify-center h-[50vh]">
      <div>
        <Check />
      </div>
      <Typography variant="h2">{t('ticketSuccessFullyCreated')}</Typography>
      <button
        variant="outlined"
        onClick={goBackToForm}
      >
        <Typography variant="subtitle1">← {t('createAnotherTicket')}</Typography>
      </button>
    </div>
  );
}
