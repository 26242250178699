import useClipboard from 'hooks/useClipboard';
import { FileCopy, Send } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function CopyableEmail({ email }) {
  const send = () => window.open(`mailto: ${email}`, '_self');
  const { copy, copied } = useClipboard();
  const { t } = useTranslation();

  return (
    <div className="flex justify-center bg-blue gap-x-3">
      {email}
      <button onClick={() => send()}>
        <Send style={{ fontSize: 13, width: 13, height: 13 }} />
      </button>
      <Tooltip title={t(copied ? 'copied-to-clipboard' : 'copy-to-clipboard')}>
        <button onClick={() => copy(email)}>
          <FileCopy style={{ fontSize: 13, width: 13, height: 13 }} />
        </button>
      </Tooltip>
    </div>
  );
}
