import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';

import { Cancel, MoreVertOutlined, Notifications } from '@material-ui/icons';

import { useAuth } from 'hooks/useAuth';
import UserChip from 'components/Interface/UserChip';
import { useContract } from 'contexts/entities/contractContext';
import { useState } from 'react';

import { COMPANY_CONTRACTOR, COMPANY_CLIENT } from 'constants/roles';

import { ClientItem, ContractorItem } from 'components/views/ViewItems';

import Contract from './Contract';
import { CONTRACT_CANCELED, PROPOSITION_DECLINED, PROPOSITION_EXPIRED } from 'lists/contractStates';
import useKeys from '@flowsn4ke/usekeys';
import { RelanceIntervenerMenu } from './ContractContractor';
import { cancelIntervener } from 'store/ticketsSlice';
import { useTranslation } from 'react-i18next';
import { useRole } from 'hooks/useRole';

export default function ContractParent({ children }) {
  const auth = useAuth();
  const { t } = useTranslation();
  const role = useRole();

  const { contractParent, contract, order, dispatch } = useContract();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const displayContract =
    ((auth.interface.isClient && !contract.summonsIsCollaborator && !contract.summonsIsContractor) ||
      (auth.interface.isContractor && !contract.summonsIsCollaborator)) &&
    ![PROPOSITION_DECLINED, PROPOSITION_EXPIRED].includes(contract.state);

  const k = useKeys();

  const [relanceMenuOpen, setRelanceMenuOpen] = useState(null);

  const [contractParentMenuOpen, setContractParentMenuOpen] = useState(null);
  const contractParentActionsVisible = ![CONTRACT_CANCELED].includes(contract.state) && contractParent && (
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      <IconButton
        size={'small'}
        style={{ marginLeft: 6 }}
        onClick={(e) => [e.preventDefault(), e.stopPropagation(), setContractParentMenuOpen(e.currentTarget)]}
      >
        <MoreVertOutlined />
      </IconButton>
    </Box>
  );
  const contractParentActions = contractParent && (
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      {contractParent._managers?.map((manager, i) => (
        <UserChip
          key={k(i)}
          user={manager}
          type={auth.interface.type}
          style={{ marginLeft: 6 }}
        />
      ))}
    </Box>
  );

  const contractParentMenu = contractParent && (
    <Menu
      anchorEl={contractParentMenuOpen}
      transitionDuration={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={!!contractParentMenuOpen}
      onClose={() => setContractParentMenuOpen(null)}
    >
      <MenuItem
        dense
        onClick={(e) => [setRelanceMenuOpen(e.currentTarget), setContractParentMenuOpen(null)]}
      >
        <ListItemIcon>
          <Notifications />
        </ListItemIcon>
        <ListItemText primary={t('remindClient')} />
      </MenuItem>
      {role.permission('tickets', 'decline_ticket') && (
        <MenuItem
          dense
          onClick={(e) => [setConfirmOpen(true), setContractParentMenuOpen(null)]}
        >
          <ListItemIcon>
            <Cancel />
          </ListItemIcon>
          <ListItemText primary={t('notAcceptTicket')} />
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <>
      {contractParent && (
        <>
          {contractParent._company.type === COMPANY_CONTRACTOR && (
            <ContractorItem
              primary={t('client')}
              contractor={contractParent._company}
              actions={contractParentActions}
              actionsVisible={contractParentActionsVisible}
            />
          )}
          {contractParent._company.type === COMPANY_CLIENT && (
            <ClientItem
              primary={t('client')}
              client={contractParent._company}
              actions={contractParentActions}
              actionsVisible={contractParentActionsVisible}
            />
          )}
          {contractParentMenu}
        </>
      )}

      {children}

      {displayContract && <Contract isParent={!auth.interface.isClient} />}

      {
        <RelanceIntervenerMenu
          id={order._id}
          intervener={contractParent._company?._id}
          anchorEl={relanceMenuOpen}
          dispatch={dispatch}
          onClose={() => [setRelanceMenuOpen(false), setContractParentMenuOpen(null)]}
        />
      }

      <ConfirmDialog
        text={<span>Voulez-vous vraiment décliner le ticket ?</span>}
        open={confirmOpen}
        onClose={(e) => [setConfirmOpen(false)]}
        onConfirm={() => dispatch(cancelIntervener, { isParent: true }, {}, { id: order._id })}
      />
    </>
  );
}
