import {
  AccordionDetails,
  Box,
  Button,
  ButtonGroup,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  List,
  Checkbox,
  FormControlLabel,
  ListItemAvatar,
  Avatar,
  AccordionActions,
  Divider,
  IconButton
} from '@material-ui/core';
import { Add, AddPhotoAlternateOutlined, Cancel, DeleteForever, Edit, Visibility, FileCopy } from '@material-ui/icons';
import DisplayMore from 'components/DisplayMore';
import { Submit, Field, Reset, useField, useFieldObserver } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';
import useKeys from '@flowsn4ke/usekeys';
import { useSections } from 'hooks/useSections';

import EntityX from 'components/inputs/EntityX';
import Location from 'entities/Location/Location';
import Client from 'entities/Client/Client';
import Job from 'entities/Job/Job';
import Scope from 'entities/Scope/Scope';

import { uploadFile } from 'store/authSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useAuth } from 'hooks/useAuth';
import BlurredProgress from 'components/BlurredProgress';
import FieldsManager from 'fieldSections/fields/FieldsManager';
import SectionEditor from 'fieldSections/fields/SectionEditor';
import { useState } from 'react';
import { duplicateFieldSection, updateFieldSection } from 'store/fieldSectionsSlice';
import useNotifications from 'hooks/useNotifications';
import { deleteFieldSection } from 'store/fieldSectionsSlice';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import { useConfiguration } from 'hooks/useConfiguration';
import useFileViewer from 'hooks/useFileViewer';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash-es';
import { Empty } from 'layouts/entities/List';
import { apiBaseURL } from 'index';
import axios from 'axios';
import Tooltip from 'components/ToolTip';

export default function ReportsSettings({ section }) {
  const [element, setElement] = useState();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const sections = useSections();
  const k = useKeys();

  const { openFiles } = useFileViewer();

  const notify = useNotifications();
  const { dispatch, requestStatus, setRequestStatus } = useAsyncDispatch();
  const dispatchCallbacks = (args) => ({
    onSuccess: () => notify.success(args?.success),
    onError: () => notify.error(args?.error)
  });

  const { t } = useTranslation();

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      {sections.report.map((report, i) => (
        <AccordionForm
          key={k(i)}
          section={{
            label: report.label,
            icon: 'file-chart-pie'
          }}
          initialValues={{
            _id: report._id,
            label: report?.label || '',
            client_signature: report?.client_signature || false,
            contractor_signature: report?.contractor_signature || false,
            client_signature_mandatory: report?.client_signature_mandatory || false,
            contractor_signature_mandatory: report?.contractor_signature_mandatory || false,
            client_signature_contractor_access: report?.client_signature_contractor_access || false,
            contractor_signature_client_access: report?.contractor_signature_client_access || false,
            header_content: report?.header_content || '',
            footer_content: report?.footer_content || '',
            headerLogo: { url: report?.headerLogo?.url || '' },
            footerLogo: { url: report?.footerLogo?.url || '' },
            fields: report?.fields || [],
            _typologies: report?._typologies || [],
            _clients: report?._clients || [],
            _equipmentCategories: report?._equipmentCategories || [],
            _equipmentSubcategories: report?._equipmentSubcategories || [],
            _jobs: report?._jobs || [],
            _locations: report?._locations || []
          }}
          onSubmit={(formData) => {
            dispatch(updateFieldSection, formData, dispatchCallbacks(), { id: report._id });
          }}
          defaultClose
          secondaryActionButton={
            <>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmDeleteOpen({
                    onConfirm: () =>
                      dispatch(deleteFieldSection, undefined, dispatchCallbacks({ success: 'Rapport supprimé' }), {
                        id: report._id,
                        entity: 'report'
                      })
                  });
                }}
              >
                <DeleteForever />
              </IconButton>
            </>
          }
          thirdActionButton={
            <>
              <IconButton
                disabled={requestStatus === 'loading'}
                onClick={(e) => {
                  e.stopPropagation();
                  setRequestStatus('loading');

                  axios
                    .get(`${apiBaseURL}/miscs/interventionReport/${report._id}/preview`, {
                      responseType: 'blob'
                    })
                    .then(({ data }) => {
                      setRequestStatus('success');
                      openFiles([
                        {
                          url: window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' })),
                          mimetype: 'application/pdf' // TODO : better mimetype handling
                        }
                      ]);
                    })
                    .catch(() => setRequestStatus('error'));
                }}
              >
                {requestStatus !== 'loading' ? (
                  <Visibility />
                ) : (
                  <FAIcon
                    collection="fas"
                    icon="spinner"
                    className="fa-spin"
                  />
                )}
              </IconButton>

              <Tooltip title={t('duplicateReport')}>
                <IconButton
                  disabled={requestStatus === 'loading'}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(duplicateFieldSection, undefined, undefined, { id: report._id });
                  }}
                >
                  <FileCopy />
                </IconButton>
              </Tooltip>

              <IconButton
                disabled={requestStatus === 'loading'}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation();
                  setElement({ id: report._id, label: report.label });
                }}
              >
                <Edit />
              </IconButton>
            </>
          }
        >
          <AccordionDetails>
            <ReportFormFields sectionId={report._id} />
          </AccordionDetails>

          <Divider />
          <AccordionActions>
            <Reset>
              <Button>{t('cancel')}</Button>
            </Reset>
            <Submit>
              <Button>{t('save')}</Button>
            </Submit>
          </AccordionActions>
        </AccordionForm>
      ))}
      <SectionEditor
        open={!!element}
        onClose={() => setElement(undefined)}
        entity="interventionReport"
        label="Nom du rapport"
        id={element?.id}
        labelValue={element?.label}
      />
      <ConfirmDialog
        text={<span>{t('confirmDeleteSection')}</span>}
        open={!!confirmDeleteOpen}
        onClose={() => [setConfirmDeleteOpen(undefined)]}
        onConfirm={confirmDeleteOpen?.onConfirm}
      />
      {!!sections.report.length && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            fullWidth
            onClick={() => setElement('new')}
            endIcon={<Add />}
            color={'secondary'}
            variant="contained"
          >
            {t('addReportTemplate')}
          </Button>
        </Box>
      )}

      {!sections.report.length && (
        <Box
          boxShadow={1}
          borderRadius={8}
          height={'500px'}
          overflow={'hidden'}
        >
          <Empty
            icon="file-chart-pie"
            translations={{
              noResultLabel: t('interventionReports'),
              noResultText: t('noModel')
            }}
            cta={
              <Button
                variant="outlined"
                onClick={() => setElement('new')}
                endIcon={<Add />}
              >
                {t('createYourFirstInterventionReport')}
              </Button>
            }
          />
        </Box>
      )}
    </Box>
  );
}

function ReportFormFields({ sectionId }) {
  const { value: fields, setValue: setFields } = useField('fields');
  const { value: headerLogo, setValue: setHeaderLogo } = useField('headerLogo.url');
  const { value: footerLogo, setValue: setFooterLogo } = useField('footerLogo.url');

  const { dispatch, requestStatus } = useAsyncDispatch();
  const auth = useAuth();

  const config = useConfiguration();

  const { t } = useTranslation();

  const handleLogoUpload = (e, position = 'header') => {
    if (e.target.files?.length) {
      const formData = new FormData();
      formData.append('logo', e.target.files[0]);
      dispatch(uploadFile, { formData }).then(({ data, error }) => {
        if (!error) {
          position === 'header' && setHeaderLogo(data.url);
          position === 'footer' && setFooterLogo(data.url);
        }
      });
    }
  };
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Field path="label">
          <TextField
            variant="outlined"
            required
            fullWidth
            label={t('reportName')}
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <DisplayMore label={t('logos')}>
          <List>
            <BlurredProgress in={requestStatus === 'loading'} />

            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt=""
                  src={headerLogo ? headerLogo : auth.interface._company?.avatar}
                />
              </ListItemAvatar>
              <ListItemText>{t('headerLogo')}</ListItemText>
              <ListItemSecondaryAction>
                <ButtonGroup variant="text">
                  <Button
                    disabled={!headerLogo || requestStatus === 'loading'}
                    onClick={() => setHeaderLogo(null)}
                  >
                    <Cancel />
                  </Button>
                  <Button
                    component="label"
                    disabled={requestStatus === 'loading'}
                  >
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleLogoUpload(e, 'header')}
                    />
                    <AddPhotoAlternateOutlined />
                  </Button>
                </ButtonGroup>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt=""
                  src={footerLogo}
                />
              </ListItemAvatar>
              <ListItemText>{t('footerLogo')}</ListItemText>
              <ListItemSecondaryAction>
                <ButtonGroup variant="text">
                  <Button
                    disabled={!footerLogo || requestStatus === 'loading'}
                    onClick={() => setFooterLogo(null)}
                  >
                    <Cancel />
                  </Button>
                  <Button
                    component="label"
                    disabled={requestStatus === 'loading'}
                  >
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleLogoUpload(e, 'footer')}
                    />
                    <AddPhotoAlternateOutlined />
                  </Button>
                </ButtonGroup>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </DisplayMore>

        <DisplayMore label={t('reportFields')}>
          <Grid container>
            <Grid
              item
              xs={12}
            >
              <FieldsManager
                fields={fields}
                setFields={setFields}
                sectionId={sectionId}
                sectionEntity="report"
              />
            </Grid>
          </Grid>
        </DisplayMore>

        <DisplayMore label="Contenus">
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Field path="header_content">
                <TextField
                  multiline
                  rows={4}
                  variant="outlined"
                  label={t('header')}
                  fullWidth
                />
              </Field>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Field path="footer_content">
                <TextField
                  multiline
                  variant="outlined"
                  rows={4}
                  label={t('footer')}
                  fullWidth
                />
              </Field>
            </Grid>
          </Grid>
        </DisplayMore>

        <DisplayMore label={t('clientSignature')}>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="client_signature"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('collectClientSignature')}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="client_signature_mandatory"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('collectClientSignatureMandatory')}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="client_signature_contractor_access"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('clientSignFromIntervenerInterface')}
            />
          </Grid>
        </DisplayMore>

        <DisplayMore label={t('intervenerSignature')}>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="contractor_signature"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('collectIntervenerSignature')}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="contractor_signature_mandatory"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('collectIntervenerSignatureMandatory')}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="contractor_signature_client_access"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('intervenerSignFromClientInterface')}
            />
          </Grid>
        </DisplayMore>

        <DisplayMore label="Visibilité">
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <EntityX
                path="_typologies"
                entity={Scope}
                entityProps={{
                  type: 'ticketTypology',
                  hiddenFilters: {
                    only: [],
                    parents: []
                  }
                }}
                placeholder={t('reportAvailableForFollowingTypologies')}
              />
            </Grid>

            {/* TODO v5.1: Si entreprise technique, rapport dispo pour les clients suivants */}
            {/* TODO v5.1: Si entreprise technique, rapport dispo pour les sous-traitants suivants */}
            {/* TODO: Si client, rapport dispo pour entreprises exterieures suivantes */}
            {config.isContractor && (
              <Grid
                item
                xs={12}
              >
                <EntityX
                  path="_clients"
                  entity={Client}
                  entityName={'clients'}
                  placeholder={t('reportAvailableForFollowingClients')}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
            >
              <EntityX
                path="_locations"
                entity={Location}
                entityName="locations"
                entityProps={{
                  hiddenFilters: {
                    section: { facturation: false, location: true, warehouse: false }
                  }
                }}
                placeholder={t('reportAvailableForFollowingLocations')}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <EntityX
                path="_jobs"
                entity={Job}
                entityProps={{
                  hiddenFilters: {
                    section: {
                      mine: true,
                      bobdesk: true,
                      bobdepannage: true
                    }
                  }
                }}
                placeholder={t('reportAvailableForFollowingJobs')}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <EntityX
                path="_equipmentCategories"
                entity={Scope}
                entityProps={{ type: 'equipmentCategory' }}
                placeholder={t('reportAvailableForFollowingEquipmentCat')}
                picker
              />
            </Grid>

            <SubcategoriesField />
          </Grid>
        </DisplayMore>
      </Grid>
    </Grid>
  );
}

function SubcategoriesField() {
  const parentCategory = useFieldObserver('_equipmentCategories');
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
    >
      <EntityX
        disabled={!parentCategory?.length}
        path="_equipmentSubcategories"
        entity={Scope}
        entityProps={{
          type: 'equipmentSubCategory',
          hiddenFilters: {
            only: [],
            parents: isArray(parentCategory) ? parentCategory.map((s) => s._id) : [parentCategory?._id]
          }
        }}
        placeholder={t('reportAvailableForFollowingEquipmentSubCat')}
        picker
      />
    </Grid>
  );
}
