import { useState, useMemo } from 'react';
import { Grid, IconButton, TextField, Typography, Box, InputAdornment } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import { Field, useFieldObserver, useForm } from 'frmx';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PopoverDrawer from 'components/ui/PopoverDrawer';
import { useIsBelowSm } from 'hooks/useMQ';
import Location from 'entities/Location/Location';
import useKeys from '@flowsn4ke/usekeys';
import { Delete } from '@material-ui/icons';
import EntityX from 'components/inputs/EntityX';

import Supplier from 'entities/Suppliers/Supplier';
import Equipment from 'entities/Equipment/Equipment';
import EmailAlertFormThreshold from './FormComponents/EmailAlertFormThreshold';
import { useAuth } from 'hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

function ControlledAccordions({ removeWarehouse, isCreate, element, setExpanded, expanded }) {
  const k1 = useKeys();
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  const warehouses = useFieldObserver('warehouses');
  const [editExpanded, setEditExpanded] = useState(0);

  const handleChange = (panel) => (event) => {
    isCreate ? setExpanded(panel === expanded ? -1 : panel) : setEditExpanded(panel === editExpanded ? null : panel);
  };

  const handleDeleteWarehouseAccordion = (e, warehouseId) => {
    e.stopPropagation();
    removeWarehouse(warehouseId);
  };
  return (
    <div className={classes.root}>
      {warehouses.length === 0 ? (
        <Typography variant="body2">{t('emptyWarehouseForm')}</Typography>
      ) : (
        warehouses.map((warehouse, i) => {
          return (
            <Accordion
              key={k1(i)}
              expanded={isCreate ? expanded === i : editExpanded === i}
              style={{ marginBottom: 6 }}
              onChange={handleChange(i)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <FAIcon
                      collection="fad"
                      icon="warehouse"
                      style={{ marginRight: '12px' }}
                    />
                    {isCreate ? warehouse?._location?.name : element?._warehouse?.name}
                  </Typography>
                  <IconButton
                    style={{ visibility: isCreate ? '' : 'hidden' }}
                    onClick={(e) => handleDeleteWarehouseAccordion(e, warehouse?._location?._id)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 12 }}
                  >
                    <Field
                      path={`warehouses.${i}.locationPrecision`}
                      isErrorProp="error"
                    >
                      <TextField
                        id="standard-number"
                        label={t('folderLocation')}
                        type="string"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        placeholder={t('folderLocationPlaceholder')}
                      />
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 12 }}
                  >
                    <EntityX
                      placeholder={t('suppliers')}
                      required
                      entity={Supplier}
                      entityName="suppliers"
                      path={`warehouses.${i}._suppliers`}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 12 }}
                  >
                    <Field
                      path={`warehouses.${i}.supplierItemCode`}
                      isErrorProp="error"
                      type="string"
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        label={t('supplierItemCode')}
                      />
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 12 }}
                  >
                    <Field
                      path={`warehouses.${i}.unitValuationPrice`}
                      type="number"
                    >
                      <TextField
                        required
                        label={t('unitValuationProduct')}
                        type="number"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {company?.currency?.symbol ? company?.currency?.symbol : '€'}
                            </InputAdornment>
                          )
                        }}
                      />
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 24 }}
                  >
                    <Typography variant="subtitle2">{t('defineStockThresholds')}</Typography>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 12 }}
                  >
                    <Field
                      path={`warehouses.${i}.stock`}
                      type="number"
                    >
                      <TextField
                        id="standard-number"
                        label={t('initialStock')}
                        type="number"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        value={warehouse.stock}
                      />
                    </Field>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 12 }}
                  >
                    <Field
                      path={`warehouses.${i}.orderStock`}
                      type="number"
                    >
                      <TextField
                        id="standard-number"
                        label={t('alertThreshold')}
                        type="number"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        value={warehouse.orderStock}
                      />
                    </Field>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 12 }}
                  >
                    <Field
                      path={`warehouses.${i}.targetStock`}
                      type="number"
                    >
                      <TextField
                        id="standard-number"
                        label={t('targetStock')}
                        type="number"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        value={warehouse.targetStock}
                      />
                    </Field>
                  </Grid>

                  <EmailAlertFormThreshold warehouse_index={i} />

                  <Grid
                    item
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 24 }}
                  >
                    <Typography variant="subtitle2">{t('productOrReferencesUsable')}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 12 }}
                  >
                    <EntityX
                      placeholder={t('entityLocName')}
                      required
                      entity={Location}
                      entityName="locations"
                      path={`warehouses.${i}._locations`}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: '0 6px', marginTop: 12 }}
                  >
                    <EntityX
                      placeholder={t('equipmentsTitle')}
                      required
                      entity={Equipment}
                      entityName="equipments"
                      path={`warehouses.${i}._equipments`}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
    </div>
  );
}

export default function StockForm({ isCreate, element }) {
  const { t } = useTranslation();

  const { setOneField, getOneField } = useForm();
  const path = 'warehouses';

  const warehouses = useFieldObserver('warehouses');
  const [expanded, setExpanded] = useState(-1);

  const formatWarehouse = (w) => {
    return {
      _location: w,
      _managers: [],
      _locations: [],
      _equipments: [],
      unitValuationPrice: 0,
      locationPrecision: '',
      stock: 0,
      orderStock: 0,
      targetStock: 0,
      _suppliers: [],
      emailAlert: false
    };
  };

  const addWareHouse = (warehouse) => {
    setExpanded(warehouses.length);
    setOneField(path, [...(getOneField(path) || []), formatWarehouse(warehouse)]);
  };

  const removeWarehouse = (warehouseId) => {
    setOneField(path, [...(getOneField(path).filter((w) => w?._location?._id !== warehouseId) || [])]);
  };

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Field
          path="name"
          isErrorProp="error"
        >
          <TextField
            required
            variant="outlined"
            fullWidth
            label={t('name')}
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field
          path="referenceMaker"
          isErrorProp="error"
        >
          <TextField
            required
            variant="outlined"
            fullWidth
            label={t('reference')}
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field
          path="comments"
          isErrorProp="error"
        >
          <TextField
            variant="outlined"
            fullWidth
            label={t('comments')}
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{isCreate ? t('storageLocations') : t('storageLocation')}</Typography>
          <EntityPlus
            path="warehouses"
            addWareHouse={addWareHouse}
            isCreate={isCreate}
          />
        </div>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <ControlledAccordions
          setExpanded={setExpanded}
          expanded={expanded}
          removeWarehouse={removeWarehouse}
          isCreate={isCreate}
          element={element}
        />
      </Grid>
    </>
  );
}

const useStyles2 = makeStyles((theme) => ({
  entityX: {
    border: '1px solid #c4c4c4',
    boxShadow: theme.shadows[1],
    transform: 'translateY(6px) !important'
  },
  header: {
    background: theme.palette.secondary.main,
    color: 'white',
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 10px',
    alignItems: 'center',
    borderRadius: `${theme.radius}px ${theme.radius}px 0px 0px`
  },
  headerIcon: {
    marginRight: 16,
    fontSize: 16
  }
}));

function EntityPlus({ path, addWareHouse, isCreate }) {
  const warehouses = useFieldObserver(path);
  const { t } = useTranslation();

  const [entityPopoverAnchor, setEntityPopoverAnchor] = useState(null);
  const [entityPopoverIsVisible, setEntityPopoverIsVisible] = useState(false);

  const openEntityPopover = (e) => [setEntityPopoverAnchor(e.currentTarget), setEntityPopoverIsVisible(true)];
  const closeEntityPopover = () => {
    setEntityPopoverIsVisible(false);
    setTimeout(() => setEntityPopoverAnchor(null), 300);
  };
  const classes = useStyles2();
  const isBelowSm = useIsBelowSm();

  const hiddenFilters = useMemo(() => {
    const excepts = warehouses.map((w) => ({ ...w._location }));

    return { section: { warehouse: true }, excepts };
  }, [warehouses]);

  return (
    <>
      <IconButton
        style={{ marginLeft: '1em', visibility: isCreate ? '' : 'hidden' }}
        onClick={openEntityPopover}
      >
        <FAIcon
          collection="fal"
          icon="circle-plus"
          size="large"
        />
      </IconButton>
      <PopoverDrawer
        fullWidth
        fromBottom
        anchorEl={entityPopoverAnchor}
        open={entityPopoverIsVisible}
        onOpen={openEntityPopover}
        onClose={() => closeEntityPopover()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        popoverStyle={{ width: '500px' }}
        PaperProps={{ className: classes.entityX, style: { overflow: 'hidden' } }}
      >
        <Box style={{ width: '100%', background: 'white', overflow: 'hidden', borderRadius: 8 }}>
          {isBelowSm && (
            <Box className={classes.header}>
              <FAIcon
                className={classes.headerIcon}
                collection="fad"
                icon="map-marker-alt"
              />
              {t('storageLocation')}
            </Box>
          )}
          <Box style={{ width: '100%', background: 'white', borderRadius: 8 }}>
            <Box style={{ height: 400, width: '100%' }}>
              <Location
                warehouse
                hiddenFilters={hiddenFilters}
                form
                pickerCreate
                onClickPreview={(location) => {
                  addWareHouse(location);
                  closeEntityPopover();
                }}
                picker
                pickerUniq
                pickerField={path}
              />
            </Box>
          </Box>
        </Box>
      </PopoverDrawer>
    </>
  );
}
