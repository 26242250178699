import { format } from 'date-fns';

export const historyComponents = {
  'ticket-created': TicketCreated,
  'intervention-date-added': InterventionDateAdded
};

function TicketCreated({ type, at, user }) {
  return (
    <div className="flex w-full justify-between items-center">
      <EntryUser user={user} />
      <div>Ticket created</div>
      <EntryDate at={at} />
    </div>
  );
}

function InterventionDateAdded({ type, at, user }) {
  return (
    <div className="flex w-full justify-between items-center">
      <EntryUser user={user} />
      <div>Intervention date added</div>
      <EntryDate at={at} />
    </div>
  );
}

function EntryDate({ at }) {
  return <div>{format(new Date(at), 'dd/MM/yyyy')}</div>;
}

function EntryUser({ user }) {
  return (
    <img
      src={user.avatar}
      className="w-5 h-5 rounded-full"
    />
  );
}
