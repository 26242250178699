import { useState, useRef, useEffect } from 'react';
import { Box, DialogActions, Dialog, InputAdornment, Button, CircularProgress, TextField } from '@material-ui/core';
import { CloudUploadOutlined, Fingerprint, Edit } from '@material-ui/icons';
import useNotifications from 'hooks/useNotifications';
import { updateAccounting, addQuote, addInvoice } from 'store/ticketsSlice';
import { useConfiguration } from 'hooks/useConfiguration';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';

const AddAccountingDocument = ({ open, onClose, isParent, type, dispatch, order, update }) => {
  const [ref, setRef] = useState(update.reference || '');
  const [price, setPrice] = useState(update.price || null);
  const [priceError, setPriceError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const auth = useAuth();
  const company = auth?.interface?._company;

  const configuration = useConfiguration();
  const notify = useNotifications();

  const uploadAttachment = (e) => {
    setIsLoading(true);

    const formData = new FormData();

    if (e.target.files?.length && e.target.files[0]) {
      formData.append('document', e.target.files[0]);
    }

    formData.append(
      'fields',
      JSON.stringify({
        type,
        reference: ref,
        price: price || 0,
        isParent,
        documents_tab_id: order.documents_tab_id
      })
    );

    if (type === 'devis') {
      dispatch(
        addQuote,
        { formData },
        {
          onSuccess: () => [setIsLoading(false), notify.success(), close()],
          onError: () => [setIsLoading(false), close()]
        },
        { id: order._id }
      );
    } else {
      dispatch(
        addInvoice,
        { formData },
        {
          onSuccess: () => [setIsLoading(false), notify.success(), close()],
          onError: () => [setIsLoading(false)]
        },
        { id: order._id }
      );
    }
  };

  const refFile = useRef(null);

  const close = () => {
    if (!isLoading) {
      setPrice(null);
      setRef(null);
      onClose();
    }
  };

  useEffect(() => {
    if (configuration?.quotes_price_not_null && (price === '' || price === '0' || price === null)) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
  }, [price, configuration?.quotes_price_not_null]);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      disableEnforceFocus
      open={open}
      onClose={close}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        ref={refFile}
        hidden
        type="file"
        multiple={false}
        onChange={(e) => uploadAttachment(e)}
      />

      <Box
        padding="16px"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Fingerprint />
              </InputAdornment>
            )
          }}
          variant="outlined"
          fullWidth
          placeholder={t('reference')}
          label={t('reference')}
          value={ref}
          onChange={(e) => setRef(e.target.value)}
          style={{ marginBottom: 18 }}
        />

        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="text-xl">{company?.currency?.symbol || '€'}</span>
              </InputAdornment>
            )
          }}
          variant="outlined"
          fullWidth
          inputProps={{ min: configuration?.quotes_price_not_null ? 1 : 0, type: 'number' }}
          type={'number'}
          placeholder={t('noVATAmount')}
          label={t('noVATAmount')}
          value={price}
          error={priceError}
          helperText={priceError && t('priceCannotBeEmptyOrZero')}
          onChange={(e) => {
            const value = e.target.value;
            setPrice(value);
          }}
        />
      </Box>
      <DialogActions>
        {isLoading && <CircularProgress size={20} />}
        <Button
          disabled={isLoading}
          onClick={onClose}
          color="secondary"
          style={{ marginLeft: 10 }}
        >
          {t('cancel')}
        </Button>
        {!update && (
          <Button
            startIcon={<CloudUploadOutlined />}
            disabled={
              isLoading || ((price === '' || price === '0' || price === null) && configuration?.quotes_price_not_null)
            }
            onClick={(e) => {
              refFile.current.click();
            }}
            color="primary"
          >
            {type === 'devis' ? t('uploadQuote') : t('uploadInvoice')}
          </Button>
        )}
        {!!update && (
          <Button
            startIcon={<Edit />}
            disabled={
              isLoading || ((price === '' || price === '0' || price === null) && configuration?.quotes_price_not_null)
            }
            onClick={(e) => {
              setIsLoading(true);
              dispatch(
                updateAccounting,
                {
                  type,
                  attachmentId: update._id,
                  isParent,
                  price,
                  reference: ref
                },
                {
                  onSuccess: () => [setIsLoading(false), notify.success(), close()],
                  onError: () => [setIsLoading(false)]
                },
                { id: order._id }
              );
            }}
            color="primary"
          >
            {type === 'devis' ? t('editQuote') : t('editInvoice')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddAccountingDocument;
