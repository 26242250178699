import { Tab, Tabs } from '@material-ui/core';
import useKeys from '@flowsn4ke/usekeys';
import { useState } from 'react';
import TabPanel from './TabPannel';

export default function CustomTabs({ tabs, isLoading, fullWidth, noPosition, style = {} }) {
  const [tab, setTab] = useState(0);

  const k1 = useKeys();
  const k2 = useKeys();

  const handleChange = (_, newValue) => setTab(newValue);

  const justTheOne = tabs?.length < 2 && tabs?.length > 0;

  return justTheOne ? (
    tabs[0].content
  ) : (
    <>
      <Tabs
        variant={fullWidth ? 'fullWidth' : 'scrollable'}
        scrollButtons="auto"
        value={tab}
        style={{
          display: 'flex',
          marginBottom: '1em',
          width: '100%',
          overflowY: 'hidden',
          ...style
        }}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        TabIndicatorProps={{
          style: {
            height: 3,
            borderRadius: 8
          }
        }}
      >
        {tabs.map((t, i) => (
          <Tab
            key={k1(i)}
            disabled={isLoading}
            style={{
              display: 'flex',
              marginBottom: -2,
              borderRadius: 0
            }}
            label={t.label}
          />
        ))}
      </Tabs>
      {tabs.map((t, i) => (
        <TabPanel
          noPosition={noPosition}
          key={k2(i)}
          value={tab}
          index={i}
        >
          {t.content}
        </TabPanel>
      ))}
    </>
  );
}
