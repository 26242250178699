import { Badge, Box } from '@material-ui/core';

import useStyles from 'layouts/entities/View.styles';
import useStylesTicket from '../../../entities/Ticket/TicketView.styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/ToolTip';

const FacturePrimary = ({ documents }) => {
  const classesTicket = useStylesTicket();
  const classes = useStyles();
  const { t } = useTranslation();

  const factureBadgeTitle = !!documents?.length ? t('available') : '';

  return (
    <Tooltip
      position="right"
      title={factureBadgeTitle}
    >
      <Badge
        className={clsx(classes.listItemPrimaryBadge, {
          [classesTicket.listItemPrimaryBadgeSuccess]: true
        })}
        color="secondary"
        badgeContent={documents?.length}
      >
        <Box>{t('invoice')}</Box>
      </Badge>
    </Tooltip>
  );
};

export default FacturePrimary;
