import { Box, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import FAIcon from 'components/ui/FAIcon';
import useStyles from 'layouts/entities/View.styles';
import classNames from 'utils/classNames';

export default function ViewItem({
  icon = false,
  iconAction = false,
  collection = false,
  secondaryIcon = false,
  primary = '',
  secondary = '',
  className,
  actions = false,
  ...rest
}) {
  const c = useStyles();

  return (
    <ListItem
      className={classNames(c.listItem, className)}
      dense
      {...rest}
    >
      <ListItemIcon>
        {icon && (
          <FAIcon
            icon={icon}
            collection={collection || (secondaryIcon ? 'fas' : 'fal')}
            className={clsx(c.listIcon, {
              [c.listIconSecondary]: secondaryIcon
            })}
          />
        )}
        {!!iconAction && iconAction}
      </ListItemIcon>
      <ListItemText
        primary={!primary ? null : <strong>{primary}</strong>}
        secondary={
          <Box
            component="span"
            display="flex"
            alignItems="center"
          >
            {secondary}
          </Box>
        }
      />
      {!!actions && <ListItemSecondaryAction>{actions}</ListItemSecondaryAction>}
    </ListItem>
  );
}
