import { Grid, TextField } from '@material-ui/core';
import GMLocationField from 'components/inputs/GMLocationField';
import { Field, useField } from 'frmx';
import PhoneField from 'components/inputs/PhoneField';
import EntityX from 'components/inputs/EntityX';
import Scope from 'entities/Scope/Scope';
import DisplayMore from 'components/DisplayMore';
import InterfaceForm from 'components/Interface/InterfaceForm';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { LangSelect } from 'entities/User/UserForm';

export default function ClientsForm({ isCreate, element }) {
  const { value: regime, setValue: setRegime } = useField('regime');
  const { setValue: setAdress } = useField('address');
  const { value: email, setValue: setEmail, error: emailError } = useField('email');

  const { t } = useTranslation();

  return (
    <>
      <Grid
        item
        xs={12}
        style={{ paddingTop: 0 }}
      >
        <ToggleButtonGroup
          style={{ width: '100%' }}
          exclusive
          value={regime}
          onChange={(e, next) => setRegime(next)}
        >
          <ToggleButton
            style={{ width: '100%' }}
            value="pro"
          >
            {t('professional')}
          </ToggleButton>
          <ToggleButton
            style={{ width: '100%' }}
            value="part"
          >
            {t('individual')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {regime === 'pro' && (
        <>
          <Grid
            item
            xs={12}
          >
            <Field path="name">
              <TextField
                variant="outlined"
                label="Client"
                fullWidth
              />
            </Field>
          </Grid>
        </>
      )}

      {
        <>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Field path="firstName">
              <TextField
                variant="outlined"
                label={t('firstName')}
                fullWidth
              />
            </Field>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <Field path="lastName">
              <TextField
                variant="outlined"
                label={t('lastName')}
                fullWidth
              />
            </Field>
          </Grid>
        </>
      }

      {isCreate && (
        <Grid
          item
          xs={12}
        >
          <GMLocationField
            path="address"
            placeholder={t('interventionAddress')}
            setValue={setAdress}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <TextField
          error={emailError}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          label="Email"
          helperText={emailError ? t('noEmailForAccountHelperText') : ''}
          fullWidth
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="phone"
          label={t('cellphoneNumber')}
          variant="outlined"
          placeholder={t('cellphoneNumber')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="fix"
          label={t('landlineNumber')}
          variant="outlined"
          placeholder={t('landlineNumber')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <LangSelect
          path="lang"
          label={t('lang')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <DisplayMore>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <EntityX
                path="_category"
                entity={Scope}
                entityProps={{ type: 'clientCategory' }}
                placeholder={t('category')}
                pickerUniq
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Field path="ref">
                <TextField
                  variant="outlined"
                  label={t('reference')}
                  fullWidth
                />
              </Field>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Field path="siret">
                <TextField
                  variant="outlined"
                  label={t('siret')}
                  fullWidth
                />
              </Field>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Field path="description">
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  label={t('description')}
                  fullWidth
                />
              </Field>
            </Grid>
          </Grid>
        </DisplayMore>
      </Grid>

      <Grid
        container
        spacing={2}
      >
        {
          <Grid
            item
            xs={12}
          >
            <InterfaceForm
              isCreate={isCreate}
              element={element}
            />
          </Grid>
        }
      </Grid>
    </>
  );
}
