import FilterAndSearchBarForm from 'components/filters/FilterAndSearchBarForm';
import useAsyncDispatch from 'hooks/useAsyncDispatch.js';
import { useAuth } from 'hooks/useAuth.js';
import { useConfiguration } from 'hooks/useConfiguration';
import useDebouncedState from 'hooks/useDebouncedState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialValuesFromFilters, getTicketFilters, LIMIT, ticketSortingOrder } from './baseFilters.js';
import { getBookmarks } from './utils.js';
import {
  createFilterSetsManager,
  ticketMenuItems,
  fetchTickets,
  generateFilters,
  useFilteredBookmarks,
  normalizeFilters
} from './utilsFun.js';
import PreviewRenderer from './Virtuoso/PreviewRenderer.js';
import FAIcon from 'components/ui/FAIcon.js';
import TrashFile from './TrashFile.js';
import { TicketView } from './TicketView.js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import { addList, removeList } from 'store/ticketsListSlice.js';
import useNotifications from 'hooks/useNotifications.js';

const TicketFile = ({
  scheduler,
  userMaintenance,
  calendar,
  page = false,
  showFilters,
  showCustomFilters,
  defaultFilters,
  hiddenFilters,
  listAction,
  lockedFilters
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const config = useConfiguration();
  const location = useLocation();
  const notify = useNotifications();
  const reduxDispatch = useDispatch();
  const { dispatch } = useAsyncDispatch();
  const customFields = useSelector((store) => store.fieldSections).ticket;
  const entity = 'tickets';
  const listId = useMemo(() => Math.random().toString(36).substring(2, 15), []);
  const { count, tickets, isLoading, hasMore } = useSelector(
    (state) => state.ticketList[listId] || { tickets: [], count: 0, isLoading: false, hasMore: false }
  );
  const [skip, setSkip] = useState(0);
  const [isTrashOpen, setIsTrashOpen] = useState(false);

  const initialBookmarks = getBookmarks({ scheduler, calendar, auth });
  const [bookmarkState, setBookmarkState] = useState({
    bookmarks: initialBookmarks,
    isLoading: false
  });

  const [activeTicket, setActiveTicket] = useState(null); // Here we have the id of the active ticket

  // check in url if filters are set
  const filterInUrl = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filters');
    return filterParam ? JSON.parse(decodeURIComponent(filterParam)) : {};
  }, [location]);

  const computedDefaultFilters = useMemo(() => {
    const filters = defaultFilters || {};
    return { ...filters, ...filterInUrl };
  }, [defaultFilters, filterInUrl]);

  const showableFilters = getTicketFilters({
    configuration: config,
    userMaintenance,
    calendar,
    scheduler,
    customFields,
    deleted: false,
    defaultFilters: computedDefaultFilters,
    lockedFilters
  });

  // Gestion de l'état des filtres avec debounce
  const [initialValues, setInitialValues] = useState(
    getInitialValuesFromFilters({
      filters: showableFilters,
      hiddenFilters,
      deleted: false,
      showCustomFilters,
      entity
    })
  );

  const [appliedFilters, setAppliedFilters, debouncedAppliedFilters] = useDebouncedState(initialValues, 100);

  const [filterSetsManager, setFilterSetsManager] = useState(
    createFilterSetsManager(appliedFilters, showCustomFilters, entity)
  );

  useFilteredBookmarks(calendar, appliedFilters, setBookmarkState, initialBookmarks);

  const stableFetchTickets = useCallback(fetchTickets({ auth, dispatch, setSkip, skip, count, listId, notify }), [
    auth,
    dispatch,
    setSkip,
    skip,
    count,
    listId,
    notify
  ]);

  // Fetch les tickets lors du select d'un filtre
  useEffect(() => {
    stableFetchTickets(debouncedAppliedFilters, false);
  }, [debouncedAppliedFilters]);

  useEffect(() => {
    reduxDispatch(addList(listId));
    return () => {
      reduxDispatch(removeList(listId));
    };
  }, [reduxDispatch]);

  // Création des éléments de menu et des composants de filtre
  const menuItems = ticketMenuItems({
    t,
    fetchTickets: stableFetchTickets,
    appliedFilters,
    setIsTrashOpen,
    count
  });

  const filters = useMemo(
    () => generateFilters(filterSetsManager.orderFilters, showableFilters),
    [filterSetsManager, showableFilters]
  );

  const endReached = useCallback(() => {
    if (hasMore) stableFetchTickets(appliedFilters, true);
  }, [hasMore, stableFetchTickets, appliedFilters]);

  useEffect(() => {
    const newTicketId = location.pathname.split('/')[2];
    page && setActiveTicket(newTicketId || null);
  }, [location]);

  return (
    <div className="w-full h-full flex">
      <div className={`w-full ${page && 'lg:w-[43%] px-[10px] pt-[10px]'} h-full`}>
        <TrashFile
          setIsModalOpen={setIsTrashOpen}
          isModalOpen={isTrashOpen}
        />
        <div className="bg-white shadow mb-0 h-full rounded-xl rounded-b-none overflow-hidden flex flex-col">
          <FilterAndSearchBarForm
            withFilters={showFilters}
            totalResults={count}
            initialValues={initialValues}
            setInitialValues={(filters) => {
              setInitialValues(filters);
              setAppliedFilters(filters);
            }}
            appliedFilters={appliedFilters}
            hiddenFilters={normalizeFilters({ filters: hiddenFilters || [] })}
            filterSetsManager={showCustomFilters ? filterSetsManager : null}
            setFilterSetsManager={setFilterSetsManager}
            // onChange={setAppliedFilters}
            onSubmit={setAppliedFilters}
            menuItems={menuItems}
            filters={filters}
            dataFilters={showableFilters.map((filter) => ({
              label: filter.props.label,
              key: filter.props.path
            }))}
            sortingOrders={ticketSortingOrder}
            bookmarkState={bookmarkState}
            listAction={listAction}
            entity={entity}
            limit={LIMIT}
          />
          {isLoading ? (
            <SkeletonTicket />
          ) : !tickets?.length ? (
            <TemplateNoTickets scheduler={scheduler} />
          ) : (
            <PreviewRenderer
              entity={entity}
              data={tickets}
              endReached={endReached}
              hasMore={hasMore}
              appliedFilters={appliedFilters}
              activeTicket={activeTicket}
              setActiveTicket={setActiveTicket}
              page={page}
            />
          )}
        </div>
      </div>
      <div className={`w-[57%] h-full`}>
        <TicketView
          ticketId={activeTicket}
          setActiveTicket={setActiveTicket}
          page={page}
          defaultFilters={defaultFilters}
        />
      </div>
    </div>
  );
};

export default TicketFile;

export const TemplateNoTickets = ({ scheduler }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center h-full text-gray-500">
      <FAIcon
        className="mr-3"
        collection="fas"
        icon="files"
        size="medium"
      />
      <h1 className="text-xl font-semibold">{t(scheduler ? 'entityTicketnoResultLabel1' : 'entityTicketnoResultLabel2')}</h1>
    </div>
  );
};

export const SkeletonTicket = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center h-full text-gray-400">
      <FAIcon
        className="mr-3 animate-spin"
        collection="fas"
        icon="spinner"
        size="medium"
      />
      <h1 className="text-sm">{t('loading')}</h1>
    </div>
  );
};
