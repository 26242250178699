import { historyComponents } from './components';
import { createElement } from 'react';
import { fakeEntries, modelEntries } from './fake';

export default function Feed({ feedId = '' }) {
  // TODO (Filters)
  // TODO (Fetch data)

  const entries = fakeEntries;

  return (
    <div className="flex flex-col gap-y-2">
      {entries.map((entry) => {
        const component = historyComponents[entry.type];

        if (!component) return null;

        return createElement(component, { key: entry._id, ...entry, ...entry.data });
      })}
    </div>
  );
}
