import { useState } from 'react';
import { Box, AppBar, Tabs, Tab, Slide, Badge, Drawer } from '@material-ui/core';
import useStyles from './Tabs.styles';
import FAIcon from 'components/ui/FAIcon';
import { useIsBelowLg } from 'hooks/useMQ';
import { useEntity } from 'contexts/entities/entityContext';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';
import DialogTitle from 'components/dialogs/DialogTitle';

export default function _Tabs({ element, isDialog, isLoading }) {
  const { tabs, defaultTab } = useEntity();

  const classes = useStyles({ isDialog });
  const isBelowLg = useIsBelowLg();

  const [tab, setTab] = useState(!isBelowLg && defaultTab ? tabs.findIndex((t) => t.key === defaultTab) : false);
  const [open, setOpen] = useState(!!tab);

  const k = useKeys();
  const { t } = useTranslation();

  const panel = !isLoading && !!tabs[tab] && (
    <Box className={classes.tab}>
      <Box
        className={classes[tabs[tab].class]}
        style={{ width: '100%' }}
      >
        {tabs[tab].render(element)}
      </Box>
    </Box>
  );

  return (
    <Box className={classes.tabs}>
      <Box
        className={classes.top}
        style={{ color: 'white' }}
      >
        <AppBar
          position={'static'}
          color="primary"
          className={classes.appbar}
          elevation={2}
        >
          <Tabs
            classes={{
              indicator: classes.tabIndicator
            }}
            value={tab}
            onChange={(e, v) => {
              if (v === tab) {
                setTimeout(() => setTab(false), 300);
                setOpen(false);
              } else {
                setTab(v);
                setOpen(true);
              }
            }}
            variant="scrollable"
            scrollButtons="on"
            textColor="inherit"
          >
            {tabs.map((tab, i) => (
              <Tab
                className="w-28 lg:w-auto"
                key={k(i)}
                label={t(tab.label)}
                value={i}
                disabled={isLoading}
                icon={
                  <Badge
                    showZero={false}
                    max={10000}
                    className={classes.badge}
                    badgeContent={tab.count}
                  >
                    <FAIcon
                      collection={tab.iconCollection || 'fad'}
                      icon={tab.icon}
                    />
                  </Badge>
                }
              />
            ))}
          </Tabs>
        </AppBar>
      </Box>
      {!isBelowLg && (
        <Slide
          in={open}
          mountOnEnter={true}
          unmountOnExit={true}
          direction={'up'}
        >
          <Box style={{ flexGrow: 1 }}>{panel}</Box>
        </Slide>
      )}
      {isBelowLg && (
        <Drawer
          open={open}
          onClose={() => [setOpen(false), setTimeout(() => setTab(false), 300)]}
          anchor="bottom"
          classes={{
            paper: classes.underlyingComponent
          }}
        >
          {tabs[tab] && (
            <DialogTitle
              className="rounded-t-xl relative"
              onClose={() => [setOpen(false), setTimeout(() => setTab(false), 300)]}
              title={
                <>
                  <FAIcon
                    className={classes.headerIcon}
                    collection={tab.iconCollection || 'fad'}
                    icon={tabs[tab].icon}
                  />
                  {t(tabs[tab].label)}
                </>
              }
            ></DialogTitle>
          )}
          {panel}
        </Drawer>
      )}
    </Box>
  );
}
