import { useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';

export default function VirtualList({ fetchMore, data, item: Item }) {
  useEffect(() => {
    const timeout = fetchMore();
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Virtuoso
      className="rounded-b-xl bg-white h-full z-0"
      itemContent={(idx, element) => <Item {...element} />}
      components={<Footer />}
      endReached={fetchMore}
      data={data}
    />
  );
}

const Footer = () => {
  return (
    <div
      style={{
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      Chargement...
    </div>
  );
};