import FileViewer from 'components/dialogs/FileViewer';
import { createContext, useContext, useRef, useState } from 'react';
import { useIsBelowMd } from './useMQ';

const FileViewerContext = createContext();

export function FileViewerProvider({ children }) {
  const [files, setFiles] = useState([]);
  const [actions, setActions] = useState(null);
  const [openIndex, setOpenIndex] = useState(0);

  const openFiles = (files, index = 0) => {
    setOpenIndex(index);
    setFiles(files.filter((f) => !!f && f.type !== 'folder'));
  };

  const append_files = (extra_files, index = files.length) => {
    const validExtraFiles = Array.isArray(extra_files)
      ? extra_files.filter((file) => !!file && typeof file === 'object' && file.type !== 'folder')
      : [];

    setFiles((prevFiles) => [...prevFiles, ...validExtraFiles]);

    if (typeof index === 'number') {
      setOpenIndex(index);
    }
  };

  const setFilesActions = (actions) => setActions(actions);

  const closeFiles = () => {
    setFiles([]);
    setActions(null);
    setOpenIndex(0);
  };

  return (
    <>
      <FileViewerContext.Provider
        value={{
          openFiles,
          setFilesActions,
          closeFiles,
          files,
          openIndex,
          setOpenIndex,
          append_files
        }}
      >
        {!!files.length && (
          <FileViewer
            actions={actions}
            files={files}
            onClose={closeFiles}
            openIndex={openIndex}
            setOpenIndex={setOpenIndex}
          />
        )}
        {children}
      </FileViewerContext.Provider>
    </>
  );
}

export default function useFileViewer() {
  return useContext(FileViewerContext);
}
