import { Box } from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';
import Tabs from './Tabs';
import ViewMenu from './ViewMenu';
import TimelineView from 'entities/Ticket/View/TimelineView';
import { useIsBelowLg } from 'hooks/useMQ';
import { useEntity } from 'contexts/entities/entityContext';

export default function View({ onClose, setIsOpenForm, isDialog, refresh, isLoading, dispatch }) {
  const { viewComponentFun, viewComponent: _ViewComponent, element, tabs, entity, fromTimeline } = useEntity();

  const ViewComponent = viewComponentFun ? _ViewComponent({ element }) : _ViewComponent;

  const isBelowLg = useIsBelowLg();

  return element ? (
    <>
      {/* div outside click with Hooks, if we use useOutsideClick the ticket will be close*/}
      <div
        className={`${isBelowLg && 'absolute top-0 left-0 w-full h-full z-10'}`}
        onClick={onClose}
      ></div>
      <Box
        className={`${
          isBelowLg
            ? 'mt-1 z-[1200] fixed left-1/2 rounded-xl -translate-x-1/2 w-[99vw] h-full overflow-y-auto'
            : 'relative rounded-xl'
        } bg-white  shadow`}
        style={{ height: !isBelowLg ? '' : 'calc(100% - 80px)' }}
      >
        <Box
          className={
            isBelowLg
              ? 'sticky z-[1200] bg-white shadow-sm flex justify-between top-0 h-14 px-4 w-full items-center'
              : 'absolute flex justify-end right-5 top-4 z-50'
          }
        >
          <ViewMenu
            onClose={onClose}
            isLoading={isLoading}
            refresh={refresh}
            setIsOpenForm={setIsOpenForm}
          />
          <div
            className="block lg:hidden text-gray-600 rounded-full p-2 hover:bg-gray-100 cursor-pointer"
            onClick={onClose}
          >
            <ClearRounded />
          </div>
        </Box>

        <div className="p-6 lg:p-8">
          <ViewComponent
            dispatch={dispatch}
            isLoading={isLoading}
            setIsOpenForm={setIsOpenForm}
            close={onClose}
            element={element}
          />
        </div>
      </Box>

      {entity === 'tickets' && element.period && !fromTimeline && <TimelineView element={element} />}

      {!!tabs?.length && (
        <div className="absolute z-50 lg:z-0 lg:relative">
          <Tabs
            isLoading={isLoading}
            isDialog={isDialog}
            element={element}
          />
        </div>
      )}
    </>
  ) : null;
}
