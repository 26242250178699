import {
  add,
  addDays,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getTime,
  isAfter,
  isBefore,
  startOfDay,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import PriorityQueue from 'utils/PriorityQueue';
import {
  EVENT_START,
  EVENT_END,
  HALF_HOURS_IN_A_DAY,
  DAYS_IN_MONTHLY_VIEW,
  ONE_MINUTE_TRIM_TO_AVOID_USELESS_OVERLAP,
  INTERVENTION_TYPE,
  UNAVAILABILITY_TYPE,
  CELL_UNIQUE_CLASSNAME,
  INTERVENTION_DATE_TYPE,
  VISIT_DATE_TYPE
} from 'modules/calendar/config';
import { nanoid } from 'nanoid';

export function compute_week_start(date = new Date()) {
  return startOfWeek(new Date(date), { weekStartsOn: 1 });
}

export function compute_week_end(date = new Date()) {
  return endOfWeek(new Date(date), { weekStartsOn: 1 });
}

export function round_to_nearest_5(x) {
  return Math.round(x / 5) * 5;
}

export function get_displayed_days(start, end, locale) {
  const days = [];
  const number_of_spawned_days = differenceInCalendarDays(end, start) + 1;

  for (let i = 0; i < number_of_spawned_days; i++) {
    days.push({
      label: format(add(start, { days: i }), 'eee', { locale }),
      date: addDays(start, i)
    });
  }

  return days;
}

export function middle_date(start, end) {
  return add(start, { days: Math.floor(differenceInCalendarDays(end, start) / 2) });
}

export function start_of_week_of_start_of_month(start = new Date()) {
  const start_of_month = startOfMonth(start);
  return startOfWeek(start_of_month, { weekStartsOn: 1 });
}

export function end_of_week_of_end_of_month(start = new Date()) {
  const end_of_month = endOfMonth(start);
  return endOfWeek(end_of_month, { weekStartsOn: 1 });
}

export function get_month_events(start = new Date()) {
  const events = [];

  for (let i = 0; i < DAYS_IN_MONTHLY_VIEW; i++) {
    const date = addDays(start, i);
    events.push({ date: startOfDay(date) });
  }

  return events;
}

export function compute_cells_events({ start, end }) {
  const events = [];
  const number_of_spawned_days = differenceInCalendarDays(end, start) + 1;

  for (let i = 0; i < number_of_spawned_days; i++) {
    for (let j = 0; j < HALF_HOURS_IN_A_DAY; j++) {
      const is_last_event_of_the_day = j === HALF_HOURS_IN_A_DAY - 1;
      const cell_start = add(start, { days: i, minutes: j * 30 });
      const cell_end = is_last_event_of_the_day
        ? add(start, { days: i, minutes: (j + 1) * 30 - 1, seconds: 59, milliseconds: 999 })
        : add(start, { days: i, minutes: (j + 1) * 30 });

      events.push({ start: cell_start, end: cell_end });
    }
  }

  return events;
}

export function filter_events_outside_range({ events, start, end }) {
  return events.filter((event) => {
    const eventStart = new Date(event.start);
    const eventEnd = new Date(event.end);

    return !isAfter(eventStart, new Date(end)) && !isBefore(eventEnd, new Date(start));
  });
}

export function break_down_multiple_days_events(events) {
  const splitted_events = [];

  for (const event of events) {
    const start = new Date(event.start);
    const end = new Date(event.end);

    const number_of_days = differenceInCalendarDays(end, start);

    for (let i = 0; i <= number_of_days; i++) {
      const newStart = i === 0 ? addDays(start, i) : startOfDay(addDays(start, i));
      const newEnd = i !== number_of_days ? endOfDay(newStart) : end;

      splitted_events.push(
        new Event({
          ...event,
          start: newStart,
          end: newEnd,
          real_start: event.start,
          real_end: event.end
        })
      );
    }
  }

  return splitted_events;
}

export function get_event_y_position_in_minutes(datetime) {
  const time = new Date(datetime);
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const minutesPerHour = 60;
  const total_minutes = hours * minutesPerHour + minutes;

  return round_to_nearest_5(total_minutes);
}

// https://stackoverflow.com/questions/53215825/return-optimized-x-coordinates-to-normalize-maximize-area-for-an-array-of-rectan
export function compute_events_with_x_overlap_offset({ events, compute_initial_position }) {
  const events_to_enqueue = [];
  const events_with_positions = [];

  events.forEach((event) => {
    events_to_enqueue.push({
      timestamp: getTime(new Date(event.start)),
      sort_type: EVENT_START,
      _id: event._id,
      ...event
    });
    events_to_enqueue.push({
      timestamp: getTime(new Date(event.end)) - ONE_MINUTE_TRIM_TO_AVOID_USELESS_OVERLAP,
      sort_type: EVENT_END,
      _id: event._id,
      ...event
    });
  });

  const event_queue = new PriorityQueue((a, b) => {
    if (a.timestamp !== b.timestamp) return a.timestamp < b.timestamp;
    if (a.sort_type !== b.sort_type) return a.sort_type < b.sort_type;
    return a.created_at < b.created_at;
  });

  event_queue.batch_enqueue(events_to_enqueue);

  while (event_queue.has_elements()) {
    const region_queue = []; // group of events that overlap
    let overlap_count = 0;
    let max_overlap_count = 0;

    // fill a region queue with events that overlap
    while (event_queue.has_elements()) {
      const event = event_queue.dequeue();

      region_queue.push(event);

      event.sort_type === EVENT_START && overlap_count++;
      event.sort_type === EVENT_END && overlap_count--;
      max_overlap_count = Math.max(max_overlap_count, overlap_count);

      if (overlap_count === 0) {
        break;
      }
    }

    // compute left offset for each event in the region queue
    const number_of_columns = max_overlap_count;
    const columns = new Array(number_of_columns).fill(null);
    const find_column = (id) => columns.findIndex((column) => column === id);
    const alloc_column = (id, column) => {
      const idx = column || columns.findIndex((column) => column === null);
      columns[idx] = id;
      return idx;
    };
    const free_column = (id) => {
      const idx = find_column(id);
      idx > -1 && (columns[idx] = null);
    };

    for (const event of region_queue) {
      const is_start = event.sort_type === EVENT_START;
      const is_end = event.sort_type === EVENT_END;

      if (is_start) {
        const initial_position = compute_initial_position(event);
        const free_column_idx = alloc_column(event._id);
        const left_offset = initial_position.width / number_of_columns;

        const left_with_offset = initial_position.left + left_offset * free_column_idx;
        const width_with_offset = initial_position.width - left_offset * free_column_idx;

        initial_position.left = left_with_offset;
        initial_position.width = width_with_offset;
        initial_position.zIndex = free_column_idx;

        events_with_positions.push({ ...event, ...initial_position });
      } else if (is_end) {
        free_column(event._id);
      }
    }
  }

  return events_with_positions;
}

export function get_ticket_company_view({ ticket, company_id }) {
  const views = ticket.views;
  const company_view = views.find((view) => (view._owner?._id || view._owner) === company_id);
  return company_view;
}

export function get_event_color({ ticket, company_id }) {
  const company_view = get_ticket_company_view({ ticket, company_id });
  const color = company_view._summons[0]?.color || '#d4d4d4';
  return color;
}

export function extract_events_from_tickets({ tickets, company_id }) {
  let events = [];

  for (const ticket of tickets) {
    const views = ticket.views;
    const company_view = get_ticket_company_view({ ticket, company_id });
    const parent_view_owner_id = company_view._parent?._owner?._id || company_view._parent;
    const root_client_view_index = 0;
    const view_with_dates = parent_view_owner_id
      ? views.find((view) => (view._owner?._id || view._owner) === parent_view_owner_id)
      : views[root_client_view_index];
    const planned_dates = view_with_dates.visit_dates;
    const intervention_dates = view_with_dates.intervention_dates;

    const color = get_event_color({ ticket, company_id });
    const state = company_view.state;
    const done = state === 'finished' || state === 'closed';

    const intervention_dates_events = intervention_dates.map((date) => {
      return new Event({
        start: new Date(date.date),
        end: add(new Date(date.date), { minutes: date.duration }),
        title: ticket.title,
        description: ticket.description,
        number: ticket.number,
        ticket_id: ticket._id,
        created_at: date.created_at,
        date_type: INTERVENTION_DATE_TYPE,
        done,
        color
      });
    });

    const planned_dates_events = planned_dates.map((date) => {
      return new Event({
        start: new Date(date.date),
        end: add(new Date(date.date), { minutes: date.duration }),
        title: ticket.title,
        description: ticket.description,
        number: ticket.number,
        ticket_id: ticket._id,
        created_at: date.created_at,
        date_type: VISIT_DATE_TYPE,
        done,
        color
      });
    });

    const planned_dates_events_without_duplicates = planned_dates_events.filter(
      (planned_date) =>
        !intervention_dates_events.find((intervention_date) =>
          eventsHaveIntersection(planned_date, intervention_date)
        )
    );

    events = [...events, ...planned_dates_events_without_duplicates, ...intervention_dates_events];
  }

  return events;
}

export function eventsHaveIntersection(event1, event2) {
  const event1_start = event1.start;
  const event1_end = event1.end;
  const event2_start = event2.start;
  const event2_end = event2.end;

  return (
    (event2_start >= event1_start && event2_start <= event1_end) ||
    (event2_end >= event1_start && event2_end <= event1_end)
  );
}

export class Event {
  constructor({
    start,
    end,
    real_start,
    real_end,
    title,
    description,
    number,
    ticket_id,
    unavailability_id,
    created_at,
    color,
    done,
    type = INTERVENTION_TYPE,
    date_type = VISIT_DATE_TYPE
  }) {
    this._id = nanoid();
    this.start = start;
    this.end = end;
    this.real_start = real_start || start;
    this.real_end = real_end || end;
    this.title = title;
    this.description = description;
    this.number = number;
    this.ticket_id = ticket_id || null;
    this.unavailability_id = unavailability_id || null;
    this.created_at = created_at || new Date();
    this.color = color || '#d4d4d4';
    this.done = done || false;
    this.type = type;
    this.date_type = date_type;
  }
}

export function format_unavailabilities(unavailabilities) {
  return unavailabilities.map((unavailability) => {
    const technician = unavailability.technician;

    return new Event({
      unavailability_id: unavailability._id,
      start: new Date(unavailability.start),
      end: new Date(unavailability.end),
      title: technician ? technician.firstName + ' ' + technician.lastName : 'Indisponible',
      description: unavailability.description,
      number: unavailability.number,
      created_at: unavailability.created_at,
      color: technician?.color || '#ffbb00',
      type: UNAVAILABILITY_TYPE
    });
  });
}

export function bubbleUpCells() {
  const cells = document.getElementsByClassName(CELL_UNIQUE_CLASSNAME);
  for (let i = 0; i < cells.length; i++) {
    cells[i].style.zIndex = 1;
  }
}

export function bubbleDownCells() {
  const cells = document.getElementsByClassName(CELL_UNIQUE_CLASSNAME);
  for (let i = 0; i < cells.length; i++) {
    cells[i].style.zIndex = null;
  }
}
