import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement,
  getBookmarks,
  publicCreateElement
} from 'store/ticketsSlice';

import { getElements as getInterveners } from 'store/intervenersSlice';

import TicketMenus from './TicketMenus';
import TicketPreview from './TicketPreview';
import TicketSkeleton from './TicketSkeleton';
import TicketView from './TicketView';
import TicketTabs from './TicketTabs';
import TicketFilters, { formatFilter } from './TicketFilters';
import TicketFormValues, { TicketValidation, formatForm, injectFormValues } from './TicketFormValues';
import TicketTranslations from './TicketTranslations';
import TicketExports from './TicketExports';
import TicketForm from './TicketForm';
import TicketSorts from './TicketSorts';

import { trigger } from 'react-events-utils';

import EntityWrapper from 'entities/EntityWrapper';

import { useState, useMemo, useEffect } from 'react';
import { useDocumentListener } from 'react-events-utils';
import { nanoid } from 'nanoid';
import { useAuth } from 'hooks/useAuth';
import FAIcon from 'components/ui/FAIcon';
import { useRole } from 'hooks/useRole';
import { contractStates } from 'lists/contractStates';
import TicketTable from './TicketTable';
import { isArray, isObject } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useConfirm from 'hooks/useConfirm';
import axios from 'axios';
import { apiBaseURL } from 'index';
import classNames from 'classnames';
import TicketPreview2 from './TicketPreview2';
import ObjectID from 'bson-objectid';

export const checkSpiral = (equipments) => {
  const categories = equipments
    ?.filter((equipment) => isObject(equipment))
    .reduce((acc, curr) => [...acc, curr._subcategory?._id, curr._category?._id], []);
  return categories?.some((category) =>
    ['5ebb0aa6e1bde10a7ee271f4', '5ebb0aa6e1bde10a7ee271eb', '5ebb0aa6e1bde10a7ee271f0'].includes(category)
  );
};

export default function Ticket({
  calendarRange,
  calendar,
  scheduler,
  hiddenFilters,
  entityFieldsSlice = 'ticket',
  onClickLeftArrow,
  onClickRightArrow,
  period_ids,
  showList = true,
  ...rest
}) {
  const auth = useAuth();
  const role = useRole();
  const confirm = useConfirm();

  const { t } = useTranslation();
  const [technicians, setTechnicians] = useState([]);

  const [tab, setTab] = useState('tickets');
  const tabChangedId = useMemo(() => nanoid());
  useDocumentListener('tabChanged' + tabChangedId, (e) => {
    setTab(e.detail);
  });

  const _hiddenFilters = useMemo(() => {
    const hf = {};

    if (rest.userMaintenance) {
      hf.typologies = ['5ebb096fe1bde10a7ee21349'];
    }

    return {
      ...hiddenFilters,
      ...(calendar
        ? {
            calendar: true,
            ...calendarRange
          }
        : {}),
      ...(scheduler
        ? {
            scheduler: true
          }
        : {}),
      ...hf
    };
  }, [
    hiddenFilters,
    calendar,
    String(calendarRange?.start),
    String(calendarRange?.end),
    scheduler,
    auth.interface?._id,
    rest.userMaintenance
  ]);
  const { dispatch } = useAsyncDispatch();

  const all_technicians_bookmark = {
    label: 'allTechnicians',
    color: '#0369a1',
    key: 'reset'
  };

  useEffect(() => {
    calendar &&
      dispatch(getInterveners, {
        limit: {
          startIndex: 0,
          stopIndex: 30
        },
        filters: {
          tab: {
            collaborator: true,
            mine: false,
            public: false
          },
          deleted: false,
          sort: {
            sort: 1,
            value: 'companyName'
          }
        }
      }).then(({ data }) => {
        setTechnicians([
          all_technicians_bookmark,
          ...data.elements?.slice(0, 9).map(({ _id, color, firstName, lastName }) => ({
            label: `${firstName} ${lastName ? lastName[0].toUpperCase() + '.' : ''}`,
            key: _id,
            color
          }))
        ]);
      });
  }, []);

  const bookmarks = useMemo(() => {
    return calendar
      ? technicians
      : scheduler
      ? [
          { label: 'toPlan', color: contractStates.toplan.color, key: 'toplan' },
          { label: 'toReplan', color: contractStates.toreplan.color, key: 'toreplan' },
          {
            label: 'waitingToBeReplaned',
            color: contractStates.waitingplan.color,
            key: 'waitingplan'
          }
        ]
      : [
          auth.interface.isClient
            ? {
                label: 'toBeValidated',
                color: contractStates.opened.color,
                key: 'opened',
                filters: { states: ['opened'] },
                alert: 'opened_alert'
              }
            : {},
          { label: 'toBeAssignated', color: contractStates.waiting.color, key: 'waiting' },
          { label: 'toBeReplaned', color: contractStates.toreplan.color, key: 'toreplan' },
          {
            label: 'quoteToBeValidated',
            color: contractStates.validation.color,
            key: 'validation'
          },

          auth.interface.isContractor
            ? {
                label: 'quoteAtt',
                color: contractStates.validation.color,
                key: 'validation_waiting'
              }
            : {},

          auth.interface.isContractor
            ? { label: 'quotePl', color: contractStates.validated.color, key: 'validation_plan' }
            : {},

          { label: 'waitingForInfo', color: contractStates.assigned.color, key: 'assigned' },
          { label: 'bookmark-planned', color: contractStates.visit_devis.color, key: 'visit' },
          { label: 'toBeClosed', color: contractStates.finished.color, key: 'finished' }
        ].filter((o) => Object.keys(o)?.length > 0);
  }, [auth.interface?._id, technicians]);

  const beforeCreateOrEdit = async ({ getOneField, setOneField, handleSubmit, isCreate }) => {
    if (window.isSubmitting) return; // Empêche plusieurs exécutions simultanées
    window.isSubmitting = true;

    const equipments = getOneField('_equipments');

    function send_changes() {
      try {
        if (
          auth.interface.isBlachere &&
          role.permission('tickets', 'validate_ticket') &&
          checkSpiral(isArray(equipments) ? equipments : [equipments])
        ) {
          return trigger('openSpiral', {
            cb: (isSpiral) => {
              if (isSpiral) {
                setOneField('_summons', [{ _id: '5f1fef4604c5ae1d9fa4d1dd' }]);
              }
              handleSubmit();
              window.isSubmitting = false; // Déverrouille après soumission
            }
          });
        }

        handleSubmit();
      } finally {
        window.isSubmitting = false; // Déverrouille après soumission
      }
    }

    try {
      if (!isCreate || !ObjectID.isValid(auth.user._id)) {
        return send_changes();
      }

      const equipments = [getOneField('_equipments')]
        .flat()
        .filter(Boolean)
        .map((e) => e._id || e);
      const locations = [getOneField('_locations')]
        .flat()
        .filter(Boolean)
        .map((e) => e._id || e);
      const skills = [getOneField('_jobs')]
        .flat()
        .filter(Boolean)
        .map((e) => e._id || e);

      const { data } = await axios.post(
        `${apiBaseURL}/ticket-list/find-duplicates`,
        { equipments, locations, skills },
        { withCredentials: true }
      );

      if (!Boolean(data.length)) {
        send_changes();
      } else {
        confirm({
          icon: 'triangle-exclamation',
          title: 'potential-duplicates-title',
          helperText: 'potential-duplicates-description',
          confirmLabel: 'potential-duplicates-create-button',
          cancelLabel: 'potential-duplicates-cancel-button',
          nodeChildren: (
            <div className="my-2 w-full rounded-md border border-slate-400">
              {data.map((duplicate, index) => (
                <TicketPreview2
                  created_at={duplicate.cat}
                  number={duplicate.n}
                  title={duplicate.t}
                  description={duplicate.d}
                  key={duplicate._id}
                  className={classNames(index !== data.length - 1 && 'border-b border-slate-400')}
                >
                  <p className="text-lg">
                    #{duplicate.n} - {duplicate.t}
                  </p>
                </TicketPreview2>
              ))}
            </div>
          ),
          width: '2xl',
          onConfirm: () => {
            send_changes();
          },
          onCancel: () => {
            document.getElementById('close-form-button')?.click();
            window.isSubmitting = false; // Déverrouille en cas d'annulation
          }
        });
      }
    } catch (error) {
      console.error(error);
      send_changes();
    }
  };

  return (
    <EntityWrapper
      tabChangedId={tabChangedId}
      icon={scheduler ? 'calendar' : null}
      beforeCreateOrEdit={beforeCreateOrEdit}
      entitySlice="tickets"
      entityFieldsSlice={entityFieldsSlice}
      entity={tab === 'maintenances' ? 'maintenances' : 'tickets'}
      entityTab={scheduler ? 'scheduler' : calendar ? 'calendar' : 'tickets'}
      disableCreate={calendar || tab === 'maintenances'}
      tab={tab}
      calendar={calendar}
      scheduler={scheduler}
      skeletonComponent={TicketSkeleton}
      previewComponent={TicketPreview}
      viewComponent={TicketView}
      formComponent={TicketForm}
      injectFormValues={injectFormValues}
      formatForm={formatForm}
      formatFilter={formatFilter}
      getFilters={TicketFilters}
      getInitialValues={TicketFormValues}
      getFormValidation={TicketValidation}
      getTabs={TicketTabs}
      getTables={TicketTable}
      getMenus={TicketMenus}
      getExportColumns={TicketExports}
      getTranslations={TicketTranslations}
      getSorts={TicketSorts}
      technicians={technicians}
      bookmarks={bookmarks}
      getBookmarks={getBookmarks}
      defaultBookmarks={scheduler ? ['toplan'] : []}
      getElement={getElement}
      getElements={getElements}
      createElement={auth.interface.isPublic ? publicCreateElement : createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      draftEnabled
      draftField="draft"
      formAttachment
      formAttachmentMultiple
      formAttachmentIcon={
        <FAIcon
          icon="paperclip"
          collection="fal"
        />
      }
      formAttachmentOnCreation
      formAttachmentLabel={t('attachments')}
      hiddenFilters={_hiddenFilters}
      onClickLeftArrow={onClickLeftArrow}
      onClickRightArrow={onClickRightArrow}
      period_ids={period_ids}
      showList={showList}
      {...rest}
    />
  );
}
