import { CheckIcon } from '@heroicons/react/24/solid';
import Tooltip from 'components/ToolTip';
import FAIcon from 'components/ui/FAIcon';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useFileViewer from 'hooks/useFileViewer';
import useNotifications from 'hooks/useNotifications';
import { contractStates } from 'lists/contractStates';
import { useTranslation } from 'react-i18next';
import { liftTheReserve } from 'store/ticketsSlice';
import { dateToLocalFormat } from 'utils/dates';
import { trim } from 'utils/trim';

function formatDate(date) {
  return dateToLocalFormat(date, 'P');
}

function InterventionDate({ dates }) {
  if (dates.length === 0) {
    return <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">—</td>;
  }

  return (
    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center font-bold hidden sm:table-cell">
      {formatDate(dates[0])}
    </td>
  );
}

function ActionButton({ onClick, icon, message, disabled = false }) {
  return (
    <Tooltip title={message}>
      <button
        type="button"
        onClick={onClick}
        disabled={disabled}
        className="inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium"
      >
        <FAIcon icon={icon} />
      </button>
    </Tooltip>
  );
}

export default function TableRow({
  setOpenTicketId,
  setShowDialog,
  commission,
  commissionsIdx,
  data,
  setData,
  setCommentsTabId
}) {
  const notify = useNotifications();
  const { t } = useTranslation();
  const { openFiles } = useFileViewer();
  const { dispatch } = useAsyncDispatch();

  const { ticketState, hasReserve, ticketId, ticketNumber, generatorName, locationName, interventionReports, interventionDate, comments_tab_id } = commission;
  const colorText = contractStates[ticketState].color;
  const label = contractStates[ticketState].label;

  const matchingReport = interventionReports?.find(
    (report) => report._ticket === ticketId && report.reserve && report.loaded
  );
  const interventionReportId = matchingReport ? matchingReport._id : null;

  const dispatchCallbacks = ({ success, error }) => ({
    onSuccess: () => notify.success(success),
    onError: () => notify.error(error)
  });

  const updateCommissions = () => {
    const updatedCommission = data.find((c) => c.ticketId === ticketId);
    updatedCommission.hasReserve = false;
    setData([...data]);
  }

  const liftReserve = async () => {
    if (!interventionReportId || !ticketId) {
      updateCommissions()
      notify.success(t('reserveAlreadyLifted'));
      return
    }

    dispatch(liftTheReserve, undefined, dispatchCallbacks({ success: t('reserveWasIndeedLifted'), error: t('reserve deja levee') }), {
      id: interventionReportId,
      _ticket: ticketId
    });
    updateCommissions()
  };

  return (
    <tr className={commissionsIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <span
          onClick={() => setOpenTicketId(ticketId)}
          className="hover:cursor-pointer hover:font-bold hover:underline"
        >
          #{ticketNumber}
        </span>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
        {trim(generatorName, 33)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{locationName}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
        <span
          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-white"
          style={{ border: `1px solid ${colorText}`, color: colorText }}
        >
          {t(label)}
        </span>
      </td>
      <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 ${hasReserve ? '' : 'text-center font-bold'}`}>
        {hasReserve ? (
          <button
            type="button"
            onClick={liftReserve}
            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            <CheckIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            {t('liftTheReserve')}
          </button>
        ) : (
          '—'
        )}
      </td>
      <InterventionDate dates={interventionDate} />
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <div className="flex">
          <ActionButton
            onClick={() => openFiles(commission.interventionReport)}
            icon="file-chart-pie"
            message={commission.interventionReport.length === 0 ? t('noReport') : t('seeReports')}
            disabled={commission.interventionReport.length === 0}
          />
          <ActionButton
            onClick={() => [setShowDialog(true), setCommentsTabId(comments_tab_id)]}
            icon="message-lines"
            message={t('seeComments')}
          />
        </div>
      </td>
    </tr>
  );
}
