import Client from 'entities/Client/Client';
import Equipment from 'entities/Equipment/Equipment';
import Intervener from 'entities/Intervener/Intervener';
import Location from 'entities/Location/Location';
import Manager from 'entities/Manager/Manager';
import { ticketStateFilter } from 'entities/Ticket/TicketFilters';

const MaintenanceFilters = (configuration, { timeline, customFields }, auth) => {
  return [
    {
      type: 'Section',
      color: '#31b55a',
      label: 'ongoing',
      key: 'good',
      default: true,
      show: true
    },
    {
      type: 'Section',
      color: '#d35400',
      label: 'toMonitor',
      key: 'alert',
      default: true,
      show: true
    },
    {
      label: 'late',
      color: '#c0392b',
      type: 'Section',
      key: 'late',
      default: true,
      show: true
    },
    {
      label: 'client',
      key: 'clients',
      type: 'Entity',
      params: {
        entity: Client,
        entityProps: {
          authorisations: [],
          regime: [],
          section: {}
        }
      },
      show: configuration.isContractor || configuration.isMaster,
      disabled: false,
      default: []
    },
    {
      label: 'intervener',
      key: 'interveners',
      type: 'Entity',
      params: {
        entity: Intervener
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'entityManagerName',
      key: 'managers',
      type: 'Entity',
      params: {
        entity: Manager,
        entityProps: {
          type: 'jobs',
          hiddenFilters: {
            section: {
              global: false,
              local: true
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'location',
      key: 'locations',
      type: 'Entity',
      params: {
        entity: Location,
        entityProps: {
          type: 'locations',
          hiddenFilters: {
            section: {
              location: true,
              facturation: false
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: auth.interface?._locations?.length ? auth.interface._locations : []
    },
    {
      label: 'equipment',
      key: 'equipments',
      type: 'Entity',
      params: {
        entity: Equipment,
        entityProps: {}
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      ...ticketStateFilter,
      show: !!timeline,
      disabled: false,
      default: []
    },
    {
      label: 'field',
      key: 'fields',
      type: 'Field',
      params: {},
      show: customFields?.some((section) => !!section.fields?.length),
      disabled: false,
      default: []
    }
  ].filter((e) => e.show);
};

export const formatFilter = (filters, auth) => {
  if (!filters.locations?.length && auth.interface?._locations) {
    filters['locations'] = auth.interface?._locations;
  }

  if (!filters.clients?.length && auth.interface._scope_clients) {
    filters['clients'] = auth.interface._scope_clients;
  }

  return filters;
};

export default MaintenanceFilters;
