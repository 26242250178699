import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, List, ListItem, ListItemText, useTheme } from '@material-ui/core';
import PopoverDrawer from 'components/ui/PopoverDrawer';
import { useIsBelowSm } from 'hooks/useMQ';
import { Add, ArrowBack, Delete, Lock } from '@material-ui/icons';
import Ticket from 'entities/Ticket/Ticket';
import FAIcon from 'components/ui/FAIcon';
import Edit from '@material-ui/icons/Edit';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import { FileActions } from 'modules/tickets/files/FileActions';
import { formatCurrency } from 'utils/formatCurrency';
import useStyles from 'layouts/entities/View.styles';
import { useState } from 'react';
import useKeys from '@flowsn4ke/usekeys';
import useFileViewer from 'hooks/useFileViewer';
import { useAuth } from 'hooks/useAuth';
import Tooltip from 'components/ToolTip';

export const InvoicesDrawer = ({
  type,
  contract,
  order,
  company_id,
  actions,
  documents,
  totalQuotes,
  isListOpen,
  setIsListOpen,
  setIsModalOpen,
  listItemRef,
  isParent,
  isLoading,
  isTrashOpen,
  invoice_is_being_processed,
  isFacturesLinked,
  canUpload,
  documentsDeleted,
  setIsTrashOpen,
  createDocument,
  renderDocument,
  removeInvoice,
  removeQuote,
  dispatch
}) => {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const { openFiles, setFilesActions } = useFileViewer();
  const theme = useTheme();
  const isBelowSm = useIsBelowSm();
  const { t } = useTranslation();
  const classes = useStyles();
  const k1 = useKeys();
  const auth = useAuth();
  const company = auth.interface._company;
  const ticketPermissions = auth.interface._role?.permissions?.tickets || [];
  const validateDevisPermission = ticketPermissions.find((key) => /validate_devis_range/.test(key)) || '';

  const [valMin = '0', valMax = '0'] = validateDevisPermission.split(':')[1]?.split('-') || [];

  const valueMin = valMin === '0' ? 0 : parseInt(valMin);
  const valueMax = valMax === '0' ? Infinity : parseInt(valMax);

  return (
    <PopoverDrawer
      open={isListOpen}
      onClose={() => setIsListOpen(false)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transitionDuration={0}
      style={{
        width: isBelowSm ? '100%' : listItemRef.current?.offsetWidth
      }}
      anchorEl={listItemRef.current}
    >
      <Box
        width="100%"
        bgcolor="white"
      >
        <Box
          padding="6px 6px 6px 16px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="inline-flex"
            fontWeight={500}
            fontSize="14px"
            color={theme.palette.primary.main}
          >
            {type === 'devis' && isTrashOpen
              ? t('deletedQuotes')
              : type === 'devis'
              ? t('quotes')
              : type === 'facture' && isTrashOpen
              ? t('deletedInvoices')
              : type === 'facture'
              ? t('invoices')
              : ''}
          </Box>

          {invoice_is_being_processed && (
            <Box
              display="flex"
              alignItems="center"
              width="80%"
            >
              <Lock style={{ marginRight: 10 }} />
              {t('lineCannotBeEditedInProgress')}
              <br />
              {t('pleaseApproachClientOrManager')}.
            </Box>
          )}

          {!invoice_is_being_processed && (
            <Box
              display="flex"
              alignItems="center"
            >
              {isFacturesLinked && (
                <Ticket childrenId={contract._facture_ticket._id}>
                  <Button
                    disabled={isLoading}
                    style={{ marginLeft: 6 }}
                    variant="contained"
                    color="primary"
                    endIcon={
                      <FAIcon
                        collection="fad"
                        icon="files"
                      />
                    }
                    size="small"
                    className={classes.viewActionVisitDate}
                  >
                    Ouvrir l'intervention liée
                  </Button>
                </Ticket>
              )}

              {canUpload && (
                <>
                  {!!documentsDeleted?.length && (
                    <IconButton
                      size={'small'}
                      onClick={() => setIsTrashOpen(!isTrashOpen)}
                    >
                      {isTrashOpen ? <ArrowBack /> : <Delete />}
                    </IconButton>
                  )}
                  <Button
                    disabled={isLoading}
                    style={{ marginLeft: 6 }}
                    variant="outlined"
                    color="primary"
                    endIcon={<Add />}
                    size="small"
                    className={classes.viewActionVisitDate}
                    onClick={() => createDocument(true)}
                  >
                    {t('addDocument')}
                  </Button>
                </>
              )}
            </Box>
          )}
        </Box>

        <Box overflow="hidden">
          <List className={classes.listPopover}>
            {(isTrashOpen ? documentsDeleted : documents).map((document, i) => (
              <ListItem
                disabled={isLoading}
                key={k1(i)}
              >
                <ListItemText
                  secondary={document.dropped?.state && document.dropped.reason}
                  primary={
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                      {renderDocument(document)}

                      {(!document.validated?.state || type === 'facture') &&
                        !invoice_is_being_processed &&
                        canUpload &&
                        !isTrashOpen && (
                          <Box display="inline-flex">
                            <Tooltip
                              position="top"
                              title={t('edit')}
                            >
                              <IconButton
                                disabled={isLoading}
                                size="small"
                                style={{ marginLeft: 10 }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  setIsModalOpen(document);
                                }}
                              >
                                <Edit style={{ height: 14 }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                    </span>
                  }
                />

                <ConfirmDialog
                  text={t('areYouSureYouWantToDeleteXOfAmountYWithReferenceZ', {
                    type: type === 'devis' ? t('leQuote') : t('leInvoice'),
                    amount: isConfirmDeleteOpen.price,
                    reference: isConfirmDeleteOpen.reference,
                    symbol: company?.currency?.symbol ? company?.currency?.symbol : '€'
                  })}
                  open={isConfirmDeleteOpen}
                  onClose={(e) => [setIsConfirmDeleteOpen(false)]}
                  onConfirm={() => {
                    if (type === 'devis') {
                      dispatch(
                        removeQuote,
                        {
                          isParent,
                          type,
                          attachmentId: isConfirmDeleteOpen._id
                        },
                        {},
                        { id: order._id }
                      );
                    } else {
                      dispatch(
                        removeInvoice,
                        {
                          isParent,
                          type,
                          attachmentId: isConfirmDeleteOpen._id
                        },
                        {},
                        { id: order._id }
                      );
                    }
                  }}
                />

                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    <IconButton
                      onClick={() => {
                        if (type === 'devis' && !document.dropped.state && !document.validated.state) {
                          const showAction = !(document.price > valueMax || document.price < valueMin);
                          if (showAction)
                            setFilesActions(() => () => (
                              <FileActions
                                company_id={company_id}
                                actions={actions}
                                devis={document}
                              />
                            ));
                        }
                        openFiles([document._document]);
                      }}
                    >
                      <IconButton size="small">
                        <FAIcon
                          size="small"
                          collection="fal"
                          icon="eye"
                        />
                      </IconButton>
                    </IconButton>

                    {type === 'devis' && document.dropped.state && (
                      <Tooltip
                        position="left"
                        title={`${t('rejectedQuote')}${document?.dropped?.reason ? `: ${document.dropped.reason}` : ''}`}
                      >
                        <div>
                          <FAIcon
                            collection="fal"
                            icon="minus-circle"
                            style={{
                              marginLeft: 10,
                              marginRight: 10,
                              color: theme.palette.error.main
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}
                    {type === 'devis' && document.validated.state && (
                      <Tooltip
                        position="left"
                        title={`${t('acceptedQuote')}${document?.validated?.reason ? `: ${document.validated.reason}` : ''}`}
                      >
                        <div>
                          <FAIcon
                            collection="fal"
                            icon="check-circle"
                            style={{
                              marginLeft: 10,
                              marginRight: 10,
                              color: theme.palette.success.main
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}

                    {!invoice_is_being_processed && canUpload && !isTrashOpen && (
                      <Tooltip
                        position={'top'}
                        title={'Supprimer'}
                      >
                        <IconButton
                          disabled={isLoading}
                          size={'small'}
                          style={{ marginLeft: 0 }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            setIsConfirmDeleteOpen(document);
                          }}
                        >
                          <Delete style={{ height: 14 }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </ListItem>
            ))}
            {!isTrashOpen && (
              <ListItem
                style={{
                  justifyContent: 'flex-end',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  borderTop: '1px solid #363640'
                }}
              >
                <Box
                  style={{
                    cursor: 'pointer',
                    marginRight: 0,
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <strong>{t('totalAmount')} : </strong>
                  <strong style={{ marginLeft: 4, cursor: 'pointer' }}>
                    {type === 'facture' &&
                      formatCurrency({
                        number: contract.intervention_price,
                        locale: company?.currency?.locale,
                        currency: company?.currency?.code
                      })}
                    {type === 'devis' &&
                      formatCurrency({
                        number: totalQuotes,
                        locale: company?.currency?.locale,
                        currency: company?.currency?.code
                      })}
                  </strong>
                </Box>
              </ListItem>
            )}
          </List>
        </Box>
      </Box>
    </PopoverDrawer>
  );
};
